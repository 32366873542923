import { SendConditionalOfferPayload } from '../helpers';
import { imtApi } from './imt-api';

const conditionalOfferUrl = `/admin/conditional-offers`;

export async function sendConditionalOffer(payload: SendConditionalOfferPayload): Promise<unknown> {
  const res = await imtApi.post(conditionalOfferUrl, payload);
  // returns data object so that data.status can be used later
  return res.data;
}

export async function revokeConditionalOffer(conditionalOfferId: string): Promise<unknown> {
  const url = `${conditionalOfferUrl}/${conditionalOfferId}/revoke`;

  const res = await imtApi.put(url);
  // returns data object so that data.status can be used later
  return res.data;
}
