import * as t from 'io-ts';
import { imtApi } from './imt-api';
import { decodeResponseArray, decodeResponse } from '../api-helpers';

const ManagementInvitationStatusDecoder = t.keyof(
  {
    pending: null,
    accepted: null,
    declined: null,
  },
  'Management invitation status'
);

export type ManagementInvitationStatus = t.TypeOf<typeof ManagementInvitationStatusDecoder>;

const ManagementInvitationDecoder = t.type(
  {
    id: t.number,
    influencerManagerId: t.number,
    channelId: t.string,
    channelName: t.string,
    managerName: t.string,
    managerAvatar: t.string,
    managerEmail: t.string,
    managerTeamId: t.number,
    status: ManagementInvitationStatusDecoder,
  },
  'ManagementInvitation'
);

export type ManagementInvitation = t.TypeOf<typeof ManagementInvitationDecoder>;

export async function getManagementInvitations(accountId: number): Promise<ManagementInvitation[]> {
  const url = `/admin/management-invites/${accountId}`;
  const res = await imtApi.get(url);
  return decodeResponseArray(res, ManagementInvitationDecoder);
}

const ManagerToYoutubeChannelDecoder = t.type(
  {
    channelId: t.string,
    managerTeamId: t.number,
  },
  'ManagerToYoutubeChannel'
);

export type ManagerToYoutubeChannel = t.TypeOf<typeof ManagerToYoutubeChannelDecoder>;

export async function getManagerToYoutubeChannel(
  channelId: string
): Promise<ManagerToYoutubeChannel> {
  const url = `/admin/channel-manager/${channelId}`;
  const res = await imtApi.get(url);
  return decodeResponse(res, ManagerToYoutubeChannelDecoder);
}
