import 'regenerator-runtime/runtime'; // for react-table useAsyncDebounce https://github.com/tannerlinsley/react-table/issues/2071#issuecomment-679999096
import React from 'react';
import * as C from '@chakra-ui/react';
import {
  ArrowDownIcon,
  ArrowUpDownIcon,
  ArrowUpIcon,
  ChevronDownIcon,
  SearchIcon,
} from '@chakra-ui/icons';
import { TableInstance, useAsyncDebounce } from 'react-table';
import TablePaginator from './TablePaginator';

function GlobalFilter({
  globalFilter,
  setGlobalFilter,
}: {
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<String>>;
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <C.InputGroup size="sm" width="60">
      <C.Input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search...`}
      />
      <C.InputRightElement pointerEvents="none" children={<SearchIcon />} />
    </C.InputGroup>
  );
}

const ReactTableBase = <T extends object = {}>({
  tableInstance,
  numericCellColumnIds = [],
  withGlobalFilter = false,
  withColumnFilters = false,
  withFilterButton = false,
  isLoading = false,
}: {
  tableInstance: TableInstance<T>;
  numericCellColumnIds?: string[];
  withGlobalFilter?: boolean;
  withColumnFilters?: boolean;
  withFilterButton?: boolean;
  isLoading?: boolean;
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    pageCount,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = tableInstance;

  const withPagination = page && page.length > 0;
  const data = withPagination ? page : rows;
  const Paginator = C.chakra((props: C.HTMLChakraProps<'div'>) => (
    <TablePaginator
      pageIndex={pageIndex}
      pageSize={pageSize}
      pageCount={pageCount}
      pageOptions={pageOptions}
      canNextPage={canNextPage}
      canPreviousPage={canPreviousPage}
      gotoPage={gotoPage}
      previousPage={previousPage}
      nextPage={nextPage}
      setPageSize={setPageSize}
      {...props}
    />
  ));

  return (
    <>
      {(withPagination || withGlobalFilter || withFilterButton) && (
        <C.HStack alignItems="flex-end" mt={4}>
          {withPagination && <Paginator />}
          {withGlobalFilter && (
            <>
              <C.Spacer />
              {withFilterButton && (
                <C.Menu>
                  <C.MenuButton
                    size="sm"
                    variant="outline"
                    as={C.Button}
                    rightIcon={<ChevronDownIcon />}>
                    Filter
                  </C.MenuButton>
                  <C.MenuList>
                    {headerGroups.map((headerGroup) => {
                      return (
                        <C.HStack
                          alignItems="start"
                          key={headerGroup.getHeaderGroupProps().key}
                          spacing={8}
                          px={5}
                          py={2}>
                          {headerGroup.headers.map((column) => {
                            if (!column.canFilter) {
                              return null;
                            }
                            return (
                              <C.Box key={column.getHeaderProps().key}>
                                <C.Heading as="h3" size="sm" mb={2}>
                                  {column.render('Header')}
                                </C.Heading>
                                {column.render('Filter')}
                              </C.Box>
                            );
                          })}
                        </C.HStack>
                      );
                    })}
                  </C.MenuList>
                </C.Menu>
              )}
              <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
            </>
          )}
        </C.HStack>
      )}
      <C.Table variant="simple" {...getTableProps()}>
        <C.Thead>
          {headerGroups.map((headerGroup) => (
            <C.Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                const sortByToggleProps = column.getSortByToggleProps();
                return (
                  <C.Th
                    {...column.getHeaderProps((props) => ({
                      ...props,

                      isNumeric: numericCellColumnIds.includes(column.id),
                    }))}>
                    <C.HStack
                      spacing={2}
                      justifyContent={
                        numericCellColumnIds.includes(column.id) ? 'flex-end' : undefined
                      }>
                      <C.Box>{column.render('Header')}</C.Box>
                      {!column.canSort ? (
                        ''
                      ) : column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDownIcon {...sortByToggleProps} />
                        ) : (
                          <ArrowUpIcon {...sortByToggleProps} />
                        )
                      ) : (
                        <ArrowUpDownIcon {...sortByToggleProps} />
                      )}
                    </C.HStack>
                    {withColumnFilters && column.canFilter && (
                      <C.Box textTransform="none" fontWeight="normal" whiteSpace="nowrap" mt="1">
                        {column.render('Filter')}
                      </C.Box>
                    )}
                  </C.Th>
                );
              })}
            </C.Tr>
          ))}
        </C.Thead>
        <C.Tbody {...getTableBodyProps()}>
          {isLoading ? (
            // Use our custom loading state to show a loading indicator
            <tr>
              <td colSpan={1000}>
                <C.Flex justifyContent="center">
                  <C.CircularProgress isIndeterminate padding="16rem" />
                </C.Flex>
              </td>
            </tr>
          ) : (
            data.map((row, i) => {
              prepareRow(row);
              return (
                <C.Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <C.Td
                        {...cell.getCellProps((props) => ({
                          ...props,
                          isNumeric: numericCellColumnIds.includes(cell.column.id),
                        }))}>
                        {cell.render('Cell')}
                      </C.Td>
                    );
                  })}
                </C.Tr>
              );
            })
          )}
        </C.Tbody>
      </C.Table>
      {withPagination && <Paginator mt={4} />}
    </>
  );
};

export default ReactTableBase;
