import * as C from '@chakra-ui/react';
import { FailedOffer } from '../../useSendOffers';

export function SentOffersInformationBox(props: {
  succeeded: number;
  failed: FailedOffer[];
  totalOffersCount: number;
  conditionalOffersCount: number;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { succeeded, failed, totalOffersCount, conditionalOffersCount, isOpen, onClose } = props;
  return (
    <>
      <C.Modal isOpen={isOpen} onClose={onClose}>
        <C.ModalOverlay />
        <C.ModalContent minWidth="lg">
          <C.ModalHeader>Sending offers</C.ModalHeader>
          <C.ModalCloseButton />
          <C.ModalBody>
            <C.Progress
              size="md"
              min={0}
              max={totalOffersCount}
              value={succeeded + failed.length}
            />
            Currently sent offers: {`${succeeded + failed.length} out of ${totalOffersCount}`}
            {succeeded === totalOffersCount && (
              <C.Alert
                status="success"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                padding={10}
                borderRadius="md"
                mt={4}>
                <C.AlertIcon boxSize="40px" mr={0} />
                <C.AlertTitle mt={4} mb={1} fontSize="lg">
                  Offers successfully sent!
                </C.AlertTitle>
                <C.AlertDescription>
                  {`Successfully sent ${succeeded} out of ${totalOffersCount} offers.`}
                  {conditionalOffersCount > 0 && (
                    <C.Text fontSize="xs">
                      Conditional offers are only sent to the creator if they pass filtering.
                    </C.Text>
                  )}
                </C.AlertDescription>
              </C.Alert>
            )}
            {succeeded + failed.length === totalOffersCount && failed.length > 0 && (
              <C.Alert
                status="warning"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                padding={10}
                borderRadius="md"
                mt={4}>
                <C.AlertIcon boxSize="40px" mr={0} />
                <C.AlertTitle mt={4} mb={1} fontSize="lg">
                  Some offers failed to be sent!
                </C.AlertTitle>
                <C.AlertDescription>
                  {`Successfully sent ${succeeded} out of ${totalOffersCount} offers.`}
                  {conditionalOffersCount > 0 && (
                    <C.Text fontSize="xs">
                      Conditional offers are only sent to the creator if they pass filtering.
                    </C.Text>
                  )}
                </C.AlertDescription>
                <C.Accordion allowToggle mt={4}>
                  <C.AccordionItem>
                    <h2>
                      <C.AccordionButton>
                        <C.Box flex="1" textAlign="left" minWidth="sm">
                          Click here to see which offers failed and why
                        </C.Box>
                        <C.AccordionIcon />
                      </C.AccordionButton>
                    </h2>
                    <C.AccordionPanel>
                      <C.Table>
                        <C.TableCaption>Failed offers</C.TableCaption>
                        <C.Thead>
                          <C.Tr>
                            <C.Th>Channel id</C.Th>
                            <C.Th>Failure reason</C.Th>
                          </C.Tr>
                        </C.Thead>
                        <C.Tbody>
                          {failed.map((f) => (
                            <C.Tr key={f.channelId}>
                              <C.Td>
                                <C.Text fontSize="xs">{f.channelId}</C.Text>
                              </C.Td>
                              <C.Td>
                                <C.Text fontSize="xs">{f.reason}</C.Text>
                              </C.Td>
                            </C.Tr>
                          ))}
                        </C.Tbody>
                      </C.Table>
                    </C.AccordionPanel>
                  </C.AccordionItem>
                </C.Accordion>
              </C.Alert>
            )}
          </C.ModalBody>

          <C.ModalFooter>
            <C.Button colorScheme="blue" onClick={onClose}>
              Close
            </C.Button>
          </C.ModalFooter>
        </C.ModalContent>
      </C.Modal>
    </>
  );
}
