import React from 'react';
import * as C from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import { alterValueInFilters, Filters } from '../../helpers';

import 'react-datepicker/dist/react-datepicker.css';
import './chakra-react-datepicker.css';

export const ChakraDateInput = React.forwardRef<HTMLButtonElement, C.ButtonProps>(
  ({ value, onChange, onClick, minW, mr }, ref) => (
    <C.Button
      mr={mr}
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      size="sm"
      leftIcon={<CalendarIcon w={4} h={4} color="gray.400" />}
      fontSize="sm"
      minW={minW}>
      {typeof value === 'string' && value.trim().slice(-1) === '-' ? value.replace('-', '') : value}
    </C.Button>
  )
);

function DateRangeFilter({
  filterId,
  filters,
  onSetFilters,
}: {
  filterId: string;
  filters: Filters[];
  onSetFilters: (filters: Filters[]) => void;
}) {
  const exisitingDeadlineF = filters.find((f) => f.id === filterId),
    exisitingDeadlineStart = filters.find((f) => f.id === `${filterId}[0]`)?.value[0],
    exisitingDeadlineEnd = filters.find((f) => f.id === `${filterId}[1]`)?.value[0];

  const customPrefix = exisitingDeadlineF?.customPrefix || '';

  const startDate = exisitingDeadlineF
    ? new Date(exisitingDeadlineF.value[0])
    : exisitingDeadlineStart
    ? new Date(exisitingDeadlineStart)
    : null;
  const endDate = exisitingDeadlineEnd ? new Date(exisitingDeadlineEnd) : null;

  function setNewFilters(
    newStart: Date | null,
    newEnd: Date | null,
    rangeType?: 'before' | 'after'
  ) {
    let clearedF = alterValueInFilters(filters, filterId, []);
    clearedF = alterValueInFilters(clearedF, `${filterId}[0]`, []);
    clearedF = alterValueInFilters(clearedF, `${filterId}[1]`, []);

    let nextFilters;
    if (newStart && !newEnd) {
      // Single date selected
      switch (rangeType) {
        case 'before':
          nextFilters = alterValueInFilters(
            clearedF,
            filterId,
            [format(newStart, 'yyyy-MM-dd')],
            'lt.'
          );
          break;
        case 'after':
          nextFilters = alterValueInFilters(
            clearedF,
            filterId,
            [format(newStart, 'yyyy-MM-dd')],
            'gt.'
          );
          break;
        default:
          // match to single date
          nextFilters = alterValueInFilters(clearedF, filterId, [format(newStart, 'yyyy-MM-dd')]);
      }
    } else if (newStart && newEnd) {
      // date range selected
      nextFilters = alterValueInFilters(clearedF, `${filterId}[0]`, [
        format(newStart, 'yyyy-MM-dd'),
      ]);
      nextFilters = alterValueInFilters(nextFilters, `${filterId}[1]`, [
        format(newEnd, 'yyyy-MM-dd'),
      ]);
    }

    onSetFilters(nextFilters || clearedF);
  }

  return (
    <C.ButtonGroup
      className={C.useColorMode().colorMode === 'light' ? 'light-theme' : 'dark-theme'}>
      <C.Stack>
        <DatePicker
          onChange={(update) => {
            setNewFilters(update[0], update[1]);
          }}
          selectsRange
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd.MM.yyyy"
          calendarStartDay={1}
          isClearable
          placeholderText=""
          todayButton="Today"
          shouldCloseOnSelect={false}
          customInput={<ChakraDateInput minW="13rem" mr="8" />}
        />
        <C.Box>
          <C.Tooltip
            label={
              !startDate || endDate
                ? 'Selects all dates before/after chosen date. You must have a single date selected.'
                : ''
            }
            shouldWrapChildren
            hasArrow
            openDelay={200}>
            <C.ButtonGroup
              isDisabled={!startDate || endDate ? true : false}
              isAttached
              variant="outline"
              size="xs">
              <C.Button
                isActive={customPrefix === 'lt.'}
                onClick={() => setNewFilters(startDate, null, 'before')}>
                Before
              </C.Button>
              <C.Button
                isActive={customPrefix === 'gt.'}
                onClick={() => setNewFilters(startDate, null, 'after')}>
                After
              </C.Button>
            </C.ButtonGroup>
          </C.Tooltip>
        </C.Box>
      </C.Stack>
    </C.ButtonGroup>
  );
}

export default DateRangeFilter;
