import * as React from 'react';
import * as t from 'io-ts';
import * as C from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { useConfirmDialog } from '@sharkpunch/idun';

import { DetailsPerChannel } from '../../helpers';

import type { OffereeToCommissionRateObject } from '../../helpers';
import CampaignSelector from '../campaign-selector/CampaignSelector';
import { CsvUpload } from '../csv-upload/CsvUpload';
import { PreparedOffers } from './BulkOfferCreation';

import CampaignWarnings from './CampaignWarnings';
import { getChannelsByIdsAndPlatform, getVideosById } from '../../api-clients/imt-api-accounts';
import { isRight } from 'fp-ts/lib/These';
import { PathReporter } from 'io-ts/lib/PathReporter';

const noWhitespaceValidation = (value?: unknown) =>
  typeof value === 'string' && value === value.trim();

const defaultRules = { required: true, validate: noWhitespaceValidation };

const submitDataBase = {
  campaign_id: t.string,
  expires: t.string,
  deadline: t.string,
  license_duration_days: t.string,
  license_terms: t.union([t.string, t.null]),
  batch_commission_rate: t.union([t.number, t.null]),
  batch_name: t.union([t.string, t.null]),
};

const SubmitDataDecoder = t.type(submitDataBase, 'FormSubmitData');

const BuyoutOfferCsvDecoder = t.type(
  {
    channel_id: t.union([t.string, t.number]),
    content_id: t.string,
    creator_fixed_fee_cents: t.number,
    variable_commission_rate: t.number,
  },
  'Buyout offer CSV'
);
type BuyoutOfferCsv = t.TypeOf<typeof BuyoutOfferCsvDecoder>;

type SubmitData = t.TypeOf<typeof SubmitDataDecoder>;

const columns: { col: string; type: string; example: string }[] = [
  {
    col: 'channel_id',
    type: 'text',
    example: 'UC4fe-r0UHramluaor4Ej74Q',
  },
  { col: 'content_id', type: 'text', example: 'gpvcEP0u2P8' },
  { col: 'variable_commission_rate', type: 'number (from 0 to 1)', example: '0.42' },
  { col: 'creator_fixed_fee_cents', type: 'number (cents amount in USD)', example: '40242' },
];

const CsvExample = () => (
  <C.Accordion allowToggle>
    <C.AccordionItem>
      <C.AccordionButton>
        <C.Box flex="1" textAlign="left">
          Click here to view accepted formatting for the CSV file
        </C.Box>
        <C.AccordionIcon />
      </C.AccordionButton>
      <C.AccordionPanel>
        <C.Table data-testid="csv-columns-help">
          <C.Thead>
            <C.Tr>
              <C.Th>Column</C.Th>
              <C.Th>Format</C.Th>
              <C.Th>Example</C.Th>
            </C.Tr>
          </C.Thead>
          <C.Tbody>
            {columns.map((column) => (
              <C.Tr key={column.col}>
                <C.Td>
                  <C.Text fontFamily="monospace">{column.col}</C.Text>
                </C.Td>
                <C.Td>
                  <C.Text>{column.type}</C.Text>
                </C.Td>
                <C.Td>
                  <C.Code>{column.example}</C.Code>
                </C.Td>
              </C.Tr>
            ))}
          </C.Tbody>
        </C.Table>
        <C.Text mt={10}>Example file:</C.Text>
        <C.Box backgroundColor="gray.100" padding={4} fontFamily="monospace">
          <C.Text>channel_id,content_id,variable_commission_rate,creator_fixed_fee_cents</C.Text>
          <C.Text>UC4fe-r0UHramluaor4Ej74Q,6FOX5ZdKYvI,0.400000000000000000,6000</C.Text>
        </C.Box>
      </C.AccordionPanel>
    </C.AccordionItem>
  </C.Accordion>
);

export const BuyoutOfferForm = ({
  setPreparedOffers,
}: {
  setPreparedOffers: React.Dispatch<React.SetStateAction<PreparedOffers>>;
}) => {
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [buyoutOffers, setBuyoutOffers] = React.useState<BuyoutOfferCsv[] | null>(null);
  const [selectedPlatform, setSelectedPlatform] = React.useState<'youtube' | 'twitch'>('youtube');

  const { confirm, Dialog } = useConfirmDialog();

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
    getValues,
  } = useForm<SubmitData>({
    mode: 'onBlur',
    defaultValues: {
      campaign_id: '',
      deadline: '',
      expires: '',
      license_duration_days: '30',
      license_terms: '',
    },
  });

  React.useEffect(() => {
    // formState is a Proxy [0][1], so we need to react to it via
    // useEffect.
    //
    // Check GitHub report [2] for more details
    //
    // [0] https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy
    // [1] https://www.keithcirkel.co.uk/metaprogramming-in-es6-part-3-proxies/
    // [2] https://github.com/react-hook-form/react-hook-form/issues/3750
    setIsFormValid(isValid);
  }, [isValid]);

  const hasErrors = Object.keys(errors).length > 0;
  if (hasErrors) {
    console.error('Error log: Form has errors:', errors);
  }

  return (
    <C.Box p={[0, 6]}>
      <form
        onSubmit={handleSubmit(async (formData) => {
          if (!buyoutOffers) {
            throw new Error('Upload CSV first');
          }

          const decoded = SubmitDataDecoder.decode(formData);
          if (!isRight(decoded)) {
            const report = PathReporter.report(decoded);
            console.error('Failed to parse form data:', report);
            throw new Error(`Failed to parse form data: ${report}`);
          }
          const data = decoded.right;
          const channels = await getChannelsByIdsAndPlatform(
            buyoutOffers.map((o) => o.channel_id.toString()),
            selectedPlatform
          );
          const videos = await getVideosById(buyoutOffers.map((o) => o.content_id));
          const detailsPerChannel: DetailsPerChannel = buyoutOffers.reduce((acc, cur) => {
            const channel: any = channels.find((c) => c.id === cur.channel_id.toString());
            if (!channel) {
              return acc;
            }

            return {
              ...acc,
              [cur.channel_id]: {
                channelName: channel.name || 'Unnamed channel',
                channelAvatarUrl: channel.avatar_url || '',
              },
            };
          }, {});

          const failedToParse: string[] = [];

          const offers = buyoutOffers.map((offer) => ({
            expires: data.expires,
            title: '_',
            description: '_',
            allow_counter_offers: false,
            offeror: { platform: 'campaign' as const, platform_id: data.campaign_id },
            offeree: { platform: selectedPlatform, platform_id: offer.channel_id.toString() },
            status: {
              offeror_status: 'accepted' as const,
              offeror_reason: 'no_reason',
              offeree_status: 'pending' as const,
              offeree_reason: 'no_reason',
            },

            pricing: {
              currency: 'USD',
              type: 'fixed_fee' as const,
              price: (offer.creator_fixed_fee_cents / 100).toFixed(2),
            },

            deliverables: [
              {
                type: 'buyout' as const,
                description: '_',
                deadline: data.deadline,
                target_content_platform: selectedPlatform,
                target_content_id: offer.content_id,
                target_name: videos.find((v) => v.id === offer.content_id)?.title || '',
                license_duration_days: Number(data.license_duration_days),
                license_terms: data.license_terms,
              },
            ],
          }));

          const commissionRates = buyoutOffers.reduce<OffereeToCommissionRateObject>(
            (acc, offer) => ({
              ...acc,
              [offer.channel_id]: data.batch_commission_rate || offer.variable_commission_rate,
            }),
            {}
          );

          setPreparedOffers({
            offers,
            conditionalOffers: [],
            commissionRates,
            failedToParse,
            detailsPerChannel,
            prices: {},
          });
        })}>
        <C.Stack spacing={8}>
          <C.Stack spacing={8}>
            <C.Box w={['auto', 'md']}>
              <CampaignSelector<SubmitData>
                name="campaign_id"
                errors={errors?.campaign_id}
                required={true}
                control={control}
                // eslint-disable-next-line no-console
                setSelectedPlatform={(platform) => setSelectedPlatform(platform)}
              />
              <C.Box mt={1}>
                <CampaignWarnings campaignId={getValues('campaign_id')} />
              </C.Box>
            </C.Box>
            <C.FormControl>
              <C.FormLabel>Deadline</C.FormLabel>
              <C.FormControl isInvalid={!!errors.deadline} maxW="xs">
                <C.Input
                  placeholder="YYYY-MM-DD"
                  data-testid="deadline"
                  type="date"
                  {...register('deadline', defaultRules)}
                />
                <C.FormErrorMessage>{errors?.deadline?.message}</C.FormErrorMessage>
              </C.FormControl>
              <C.FormHelperText>
                By which date creator should submit content for buyout deal
              </C.FormHelperText>
            </C.FormControl>
            <C.FormControl>
              <C.Box w={['auto', 'md']}>
                <C.FormLabel>Expiration date</C.FormLabel>
                <C.Input
                  {...register('expires', { ...defaultRules })}
                  placeholder="YYYY-MM-DD"
                  type="date"
                />
              </C.Box>
              <C.FormHelperText>
                Date when all pending offers should be automatically revoked
              </C.FormHelperText>
            </C.FormControl>
            <C.FormControl>
              <C.FormLabel>License duration in days</C.FormLabel>
              <C.FormControl isInvalid={!!errors.deadline} maxW="xs">
                <C.Input
                  placeholder="30"
                  type="number"
                  {...register('license_duration_days', { ...defaultRules, min: 0 })}
                />
                <C.FormErrorMessage>{errors?.deadline?.message}</C.FormErrorMessage>
              </C.FormControl>
              <C.FormHelperText>For how long advertiser can use integration</C.FormHelperText>
            </C.FormControl>
            <C.FormControl>
              <C.Textarea placeholder="License terms" {...register('license_terms')} />
            </C.FormControl>
          </C.Stack>
          <C.Divider />

          <C.HStack>
            <CsvUpload<BuyoutOfferCsv>
              codec={BuyoutOfferCsvDecoder}
              onData={(d) => {
                setBuyoutOffers(d);
              }}
              size="sm"
            />
            <C.Button
              size="sm"
              variant="outline"
              onClick={() => {
                confirm({
                  headerText: 'Are you sure?',
                  bodyText: 'You are about to reset CSV data',
                  cancelText: 'Abort mission',
                  confirmText: 'Reset CSV data',
                  confirmColorScheme: 'red',
                });
                setBuyoutOffers([]);
              }}>
              Clear all
            </C.Button>
          </C.HStack>

          <CsvExample />

          {buyoutOffers && (
            <C.Alert status="info">Parsed {buyoutOffers.length} rows from uploaded CSV</C.Alert>
          )}

          <C.Divider />
          <C.FormControl maxW="s" isInvalid={!!errors.batch_commission_rate}>
            <C.FormLabel as="legend">Batch commission rate</C.FormLabel>
            <C.Input
              type="number"
              step="0.01"
              {...register('batch_commission_rate', {
                valueAsNumber: true,
                validate: (value: number | null) => {
                  return value === null || isNaN(value) || (value > 0 && value < 1);
                },
              })}
              placeholder="Leave blank to default to campaign commission rate"
            />
            <C.FormErrorMessage>
              {errors.batch_commission_rate && errors.batch_commission_rate.message}
            </C.FormErrorMessage>
            <C.FormHelperText>
              A manually set commission rate for offers and conditional offers in this batch. Must
              be a decimal number between 0 and 1, for example 0.35. This number will override the
              campaign default commission rate for all offers and conditional offers in this batch.
              Only use this if you know what you are doing.
            </C.FormHelperText>
          </C.FormControl>
          <C.FormControl maxW="s">
            <C.FormLabel as="legend">Offer batch name</C.FormLabel>
            <C.Input
              {...register('batch_name')}
              placeholder="Leave blank to not have a batch name"
            />
            <C.FormHelperText>
              You can specify a batch name for offers sent. It can be used to do identify offer
              batches and do analytics on performance of different kinds of buyout offer conditions.
            </C.FormHelperText>
          </C.FormControl>
          <C.Box>
            <C.Button
              colorScheme="cyan"
              type="submit"
              disabled={!isFormValid || !buyoutOffers?.length}>
              Preview offers
            </C.Button>
          </C.Box>
        </C.Stack>
      </form>
      <Dialog />
    </C.Box>
  );
};
