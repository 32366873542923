import * as t from 'io-ts';
import { imtApi } from './imt-api';
import { decodeResponse, decodeResponseArray } from '../api-helpers';

const ManagerTeamDecoder = t.type(
  {
    id: t.number,
    name: t.string,
  },
  'ManagerTeam'
);

export type ManagerTeam = t.TypeOf<typeof ManagerTeamDecoder>;

const ManagerTeamMemberDecoder = t.type(
  {
    id: t.number,
    email: t.string,
    avatarUrl: t.union([t.null, t.string]),
    displayName: t.string,
  },
  'ManagerTeamMember'
);

export type ManagerTeamMember = t.TypeOf<typeof ManagerTeamMemberDecoder>;

const ManagerTeamChannelDecoder = t.type(
  {
    id: t.number,
    channelName: t.string,
    channelId: t.string,
    avatarUrl: t.union([t.null, t.string]),
    displayName: t.string,
    email: t.string,
    contactEmail: t.union([t.null, t.string]),
  },
  'ManagerTeamChannel'
);

export type ManagerTeamChannel = t.TypeOf<typeof ManagerTeamChannelDecoder>;

export async function getManagerTeamByManagerId(managerId: number): Promise<ManagerTeam> {
  const url = `/admin/manager-team/${managerId}`;
  const res = await imtApi.get(url);
  return decodeResponse(res, ManagerTeamDecoder);
}

export async function getManagerTeamMembers(teamId: number): Promise<ManagerTeamMember[]> {
  const url = `/manager-teams/${teamId}/members`;
  const res = await imtApi.get(url);
  return decodeResponseArray(res, ManagerTeamMemberDecoder);
}

export async function getManagerTeamChannels(teamId: number): Promise<ManagerTeamChannel[]> {
  const url = `/manager-teams/${teamId}/channels`;
  const res = await imtApi.get(url);
  return decodeResponseArray(res, ManagerTeamChannelDecoder);
}
