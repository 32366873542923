import axios from 'axios';

export const isNotFoundError = (e: unknown) => axios.isAxiosError(e) && e.response?.status === 404;

export class ApiResponseDecodingError extends Error {
  report: string[] | null;
  requestId: string | null;

  constructor(
    message: string,
    { report, requestId }: { report: string[] | null; requestId: string | null }
  ) {
    super(message);
    this.report = report;
    this.requestId = requestId;
  }
}
