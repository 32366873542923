// This is just a convenience wrapper of Chakra's Select component,
// so that we don't need to map all the options every time.
// It is intended to be used in cases where we want to have
// option groups.
//
// Note the `forwardRef` bit, we need it to pass the ref to
// the actual Select component, so that RHF knows of it's existance
// and keeps a track of what user has selected.
import { forwardRef } from 'react';
import { Select } from '@chakra-ui/react';

type OptGroup = {
  groupKey: string;
  groupLabel: string;
  options: (string | { id: string; label: string })[];
};
type Props = {
  options: OptGroup[];
  defaultValue?: string;
};

const Selector = forwardRef<HTMLSelectElement, Props>(
  ({ options, defaultValue, ...props }, ref) => (
    <Select ref={ref} defaultValue={defaultValue} {...props}>
      {options.map(({ groupLabel, groupKey, options }) => (
        <optgroup label={groupLabel} key={groupKey}>
          {options.map((i) => {
            let label, value;
            if (typeof i === 'string') {
              label = i;
              value = i;
            } else {
              label = i.label;
              value = i.id;
            }
            return (
              <option key={label} value={`${groupKey}.${value}`}>
                {label}
              </option>
            );
          })}
        </optgroup>
      ))}
    </Select>
  )
);
export default Selector;
