import React from 'react';
import Papa from 'papaparse';
import * as C from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import * as t from 'io-ts';

import { isRight } from 'fp-ts/Either';
import { PathReporter } from 'io-ts/PathReporter';
import { showErrorToast } from '../../helpers';

export class InvalidCsvDataError extends Error {}

export function handleFileRead(e: React.ChangeEvent<HTMLInputElement>): Promise<string> {
  return new Promise((resolve, reject) => {
    if (!e || !e.target || !e.target.files) {
      return reject();
    }
    const file = e.target.files[0];
    const reader: FileReader = new FileReader();
    reader.onloadend = () => {
      if (reader.result && typeof reader.result === 'string') {
        resolve(reader.result);
      }
    };
    reader.readAsText(file);
  });
}

// This is exported for tests
export function handleCsvParse<Decoded>(s: string, codec: t.Mixed) {
  const csvData = Papa.parse(s, { skipEmptyLines: true, header: true, dynamicTyping: true }).data;
  return validateAndFormatCsvData<Decoded>(csvData, codec);
}

export function CsvUpload<Decoded>({
  onData,
  codec,
  ...buttonStyles
}: {
  onData: (d: Decoded[]) => void;
  codec: t.Mixed;
} & C.ButtonProps) {
  return (
    <FileUpload
      onData={(s) => {
        try {
          onData(handleCsvParse<Decoded>(s, codec));
        } catch (e) {
          // Throw only in case something unexpected happened,
          // invalid csv data is handled and logged in
          // handleCsvParse already
          if (!(e instanceof InvalidCsvDataError)) {
            throw e;
          }
        }
      }}
      {...buttonStyles}
    />
  );
}

const showInvalidCSVToast = (): void => {
  showErrorToast(
    'Error',
    'Failed to parse offers for preview due to invalid data in CSV. Please check the data and try again.'
  );
};

// This function will try transform an unknown input into an
// OfferData array. If it fails, or if the types in the unknown
// data are wrong, it will throw.
function validateAndFormatCsvData<Decoded>(data: unknown, codec: t.Mixed): Decoded[] {
  if (!Array.isArray(data)) {
    showInvalidCSVToast();
    throw new InvalidCsvDataError('Invalid CSV data');
  }

  return data.map((row) => {
    const result = codec.decode(row);
    if (!isRight(result)) {
      showInvalidCSVToast();
      console.error('Error log: Invalid row in CSV', PathReporter.report(result));
      const error = new InvalidCsvDataError('Invalid row in CSV!');
      Sentry.captureException(error, { extra: row });
      throw error;
    }
    return result.right;
  });
}

const FileUpload = (props: { onData: (d: string) => void } & C.ButtonProps) => {
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const { onData, ...buttonStyles } = props;
  return (
    <C.Box>
      <input
        type="file"
        multiple={false}
        hidden
        ref={(e) => {
          inputRef.current = e;
        }}
        onChange={async (e) => {
          const res = await handleFileRead(e);
          onData(res);
        }}
      />
      <C.Button {...buttonStyles} onClick={() => inputRef?.current?.click()}>
        Select CSV
      </C.Button>
    </C.Box>
  );
};
