import React from 'react';
import { Checkbox } from '@chakra-ui/react';

interface Props {
  indeterminate?: boolean;
  checked?: boolean;
}

const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, Props>(
  ({ indeterminate, ...rest }, ref: React.Ref<HTMLInputElement>) => {
    const defaultRef = React.useRef(null);
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      if (typeof resolvedRef === 'object' && resolvedRef.current) {
        resolvedRef.current.indeterminate = Boolean(indeterminate);
      }
    }, [resolvedRef, indeterminate]);

    return (
      <Checkbox
        ref={resolvedRef}
        isIndeterminate={indeterminate}
        isChecked={rest.checked}
        {...rest}></Checkbox>
    );
  }
);

export default IndeterminateCheckbox;
