import axios from 'axios';
import { AUTH_URL } from '../config';

const PUPPE_URL = AUTH_URL;

export const REFRESH_API = `${PUPPE_URL}/token/v1/refresh`;

class JwtParsingError extends Error {}

const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
};

const getJwtExpireAt = (token: string) => {
  try {
    return parseJwt(token).exp;
  } catch (e) {
    throw new JwtParsingError('Could not parse expiration from token');
  }
};

const shouldRefreshAccessToken = (token: Token) => {
  if (!token) {
    return true;
  } else {
    const inFiveMinutes = Math.floor(Date.now() / 1000 + 300);
    return token.accessTokenExpiresAt < inFiveMinutes;
  }
};

type Token = {
  accessToken: string;
  accessTokenExpiresAt: number;
};

let token: Token | null;

class LogInRequired extends Error {
  constructor() {
    super('Login required');
    this.name = 'LogInRequired';
  }
}

const getAccessToken = async (): Promise<Token | null> => {
  try {
    const result: any = await axios.get(REFRESH_API, {
      withCredentials: true,
    });
    const { data } = result;
    return {
      accessToken: data.access_token,
      accessTokenExpiresAt: data.e2eExpiration || getJwtExpireAt(data.access_token),
    };
  } catch (e) {
    if (axios.isAxiosError(e) && e.response && e.response.status === 401) {
      throw new LogInRequired();
    } else if (e instanceof JwtParsingError) {
      throw new Error(`Failed to refresh access token: ${e.message}`);
    } else {
      console.error('Error log: Something failed terribly while getting access token', e);
      return null;
    }
  }
};

const refreshAccessToken = async () => {
  if (!token || shouldRefreshAccessToken(token)) {
    token = await getAccessToken();
  }
};

export const getAuthHeader = async () => {
  // Catch error and return null here, so that we can go on sending
  // the legacy cookie token instead of an authorization header
  try {
    await refreshAccessToken();
  } catch (e) {
    console.error('Error log: Failed to refresh access token', e);
    return undefined;
  }
  return token && token.accessToken ? { Authorization: `Bearer ${token.accessToken}` } : undefined;
};

export const clearAccessToken = async () => {
  token = null;
};
