interface Config {
  [propName: string]: string;
}

interface MyWindow extends Window {
  env: Config;
}

declare let window: MyWindow;

if (!window || !window.env) {
  // Placeholders for `window.env` in case those aren't defined. This
  // would make it slightly easier for our tests to distinguish whether
  // correct API endpoints were called.
  window.env = {
    ROOT_URL: 'http://fake-app-url.dev',
    AUTH_URL: 'https://fake-auth-url.dev',
    IMT_API_URL: 'https://fake-imt-url.dev',
    CAMPAIGN_APP_URL: 'https://app.fake-imt-url.dev',
    PUBLIC_URL: 'https://fake-bulk-offers-url.dev',
    VELLAMO_API_URL: 'https://fake-imt-url.dev',
    SENTRY_DSN: '',
    TRACKING_URL: 'https://fake-tracking-url.dev',
    CREATOR_APP_URL: 'https://fake-bragi-url.dev',
  };
}

// Special handling for ROOT_URL in case it has
// multiple domains specified
// (for bulk-offer to admin transition)
const appUrlsFromEnv = window?.env?.ROOT_URL;
const urls = appUrlsFromEnv.split(',');
let deductedUrl: string;
if (urls.length === 1) {
  deductedUrl = urls[0];
} else {
  // Fallback, in case env doesn't have matching host
  // -- just use the first available url
  deductedUrl = urls.find((u) => new URL(u).hostname === document.location.hostname) || urls[0];
}

export const ROOT_URL = deductedUrl;

export const AUTH_URL = window?.env?.AUTH_URL;
export const IMT_API_URL = window?.env?.IMT_API_URL;
export const CAMPAIGN_APP_URL = window?.env?.CAMPAIGN_APP_URL;
export const SENTRY_DSN = window?.env?.SENTRY_DSN;
export const TRACKING_URL = window?.env?.TRACKING_URL;
export const CREATOR_APP_URL = window?.env?.CREATOR_APP_URL;
