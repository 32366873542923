import * as C from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { CardBody, EmptyState } from '@sharkpunch/idun';
import { Deal } from '../../api-clients/imt-api-deals';
import DealLink from './DealLink';

const TitleText = ({ children, ...rest }: C.TextProps) => {
  const headerFontColor = C.useColorModeValue('gray.600', 'gray.300');
  return (
    <C.Text
      textTransform="uppercase"
      fontSize="xs"
      fontWeight="bold"
      color={headerFontColor}
      {...rest}>
      {children}
    </C.Text>
  );
};

function DealsList({
  deals,
  dealsOffset,
  offsetStepSize,
  getPrevDeals,
  getNextDeals,
  loadingDeals,
}: {
  deals: Deal[];
  dealsOffset: number;
  offsetStepSize: number;
  getPrevDeals: () => void;
  getNextDeals: () => void;
  loadingDeals: boolean;
}) {
  const { id } = useParams();
  return (
    <>
      {!id && (
        <C.Grid
          w="100%"
          templateColumns="repeat(6, minmax(0, 1fr))"
          gap={10}
          mb="3"
          px="var(--space-md)">
          <C.GridItem />
          <C.GridItem>
            <TitleText align="right">Message sent</TitleText>
          </C.GridItem>
          <C.GridItem>
            <TitleText align="center">Campaign name</TitleText>
          </C.GridItem>
          <C.GridItem>
            <TitleText align="center">Publishing date</TitleText>
          </C.GridItem>
          <C.GridItem>
            <TitleText align="center">Content review</TitleText>
          </C.GridItem>
          <C.GridItem>
            <TitleText align="center">Video</TitleText>
          </C.GridItem>
        </C.Grid>
      )}

      <C.Box flex="1" overflow="scroll" flexDirection="column">
        <C.Divider />
        {dealsOffset > 0 && (
          <>
            <C.Divider />
            <C.Center my="var(--space-sm)">
              <C.Button size="xs" onClick={getPrevDeals} isDisabled={loadingDeals}>
                Show previous {offsetStepSize} deals
              </C.Button>
            </C.Center>
          </>
        )}

        {deals.length === 0 && !loadingDeals && (
          <CardBody>
            <EmptyState title="No deals found" description="Try to adjust the filters" />
          </CardBody>
        )}

        {deals?.map((deal) => (
          <C.Box key={deal.deal.id}>
            <DealLink deal={deal} />
            <C.Divider />
          </C.Box>
        ))}

        {deals.length === offsetStepSize && (
          <>
            <C.Divider />
            <C.Center my="var(--space-sm)">
              <C.Button size="xs" onClick={getNextDeals} isDisabled={loadingDeals}>
                Show next {offsetStepSize} deals
              </C.Button>
            </C.Center>
          </>
        )}
      </C.Box>
    </>
  );
}

export default DealsList;
