import * as React from 'react';
import * as C from '@chakra-ui/react';
import {
  useTable,
  useSortBy,
  useRowSelect,
  UseRowSelectRowProps,
  usePagination,
  useGlobalFilter,
  useFilters,
  Column,
} from 'react-table';
import uniq from 'lodash/uniq';
import {
  calculateValueWithCommission,
  ConditionalOfferCreateRequest,
  getDeadlines,
  isYoutubeVideoDeliverable,
  OfferCreateRequest,
  Pricing,
} from '../../helpers';
import { DeleteIcon, InfoIcon, RepeatClockIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { currencyFormatter, numberFormatter } from '../../helpers';
import { CsvDownload } from '../bulk-offer/CsvDownload';
import ReactTableBase from '../react-table/ReactTableBase';
import { isEmpty } from 'lodash';
import IndeterminateCheckbox from '../react-table/IndeterminateCheckbox';

import { TwitchChannelDetails, YoutubeChannelDetails } from './ChannelDetails';
import { PreparedOffers } from './BulkOfferCreation';
import { CampaignFromSingleEndpoint, fetchCampaign } from '../../api-clients/imt-api-campaigns';
import { useAsync } from 'react-async-hook';

export type MetaItem = {
  views: string;
  cpm: string;
  price: string;
  channelId: string;
  estimateToAverageOffset: number;
};

export type CampaignsInfo = { [key: string]: CampaignFromSingleEndpoint | void };

function parseCampaignIdsFromCreateRequests(
  offers: OfferCreateRequest[],
  conditionalOffers: ConditionalOfferCreateRequest[]
): string[] {
  return uniq(
    offers
      .map((o) => o.offeror.platform_id)
      .concat(conditionalOffers.map((co) => co.offeror.platform_id))
  );
}

export async function getCampaignsInfo(campaignIds: string[]): Promise<CampaignsInfo> {
  const campaigns: CampaignsInfo = {};
  for (const id of campaignIds) {
    if (!campaigns[id]) {
      try {
        const campaignResponse = await fetchCampaign(id);
        campaigns[id] = campaignResponse;
      } catch (e) {
        console.info(
          e,
          "Failure getting commission rate from API for campaign. This is not critical, but offers for these campaigns won't be sent if batch commission rate (or individual variable commission rates from CSV upload tool) haven't been set. The user will get feedback about this and can call fixer if it persists (which it shouldn't)"
        );
      }
    }
  }
  return campaigns;
}

const PreviewTableWithCampaignCommissionRateData = (props: {
  preparedOffers: PreparedOffers;
  onPricedOffersSelected: React.Dispatch<React.SetStateAction<OfferCreateRequest[]>>;
  campaignsInfo: CampaignsInfo;
}) => {
  const { preparedOffers, onPricedOffersSelected, campaignsInfo } = props;
  const {
    offers: pricedOffers,
    conditionalOffers,
    commissionRates,
    prices: meta,
    detailsPerChannel,
  } = preparedOffers;
  const [tabIndex, setTabIndex] = React.useState(0);
  const [pricedOffersSelected, setPricedOffersSelected] = React.useState(pricedOffers);

  // use useCallback hook so that setTabByOffers is memoized (only called when
  // data and conditionalOffers props change)
  const setTabByOffers = React.useCallback(() => {
    // set to conditional offers tab if there are conditional offers and no priced offers
    setTabIndex(pricedOffers.length === 0 && conditionalOffers.length > 0 ? 1 : 0);
  }, [pricedOffers, conditionalOffers]);

  React.useEffect(() => {
    // code to run after render so that tabs change when pricedOffers and conditionalOffers props change
    setTabByOffers();
    setPricedOffersSelected(pricedOffers);
    onPricedOffersSelected(pricedOffers);
  }, [setTabByOffers, pricedOffers, onPricedOffersSelected]);

  // needed to still be able to change tabs manually and controlled
  function handleTabsChange(index: number): void {
    setTabIndex(index);
  }

  type OfferTableRow = {
    campaignId: string;
    channel: string;
    channelId: string;
    promotionType: string;
    creatorPayout: Pricing;
    deliverableType: string;
    commissionRate: number | string;
    advertiserFee: { pricing: Pricing; commissionRate?: number };
    cpm: string;
    viewsForecast: string;
    publishingDate: string;
    budgetId?: string;
  };

  type ConditionalOfferTableRow = {
    campaignId: string;
    channel: string;
    channelId: string;
    deliverableType: string;
    commissionRate: number | string;
    publishingDate: string;
    budgetId?: string;
  };

  function ForecastFilter({ column: { setFilter } }: { column: { setFilter: Function } }) {
    return (
      <C.Checkbox
        onChange={(e) => {
          setFilter(e.target.checked || false);
        }}>
        Show only warnings
      </C.Checkbox>
    );
  }

  const forecastFilterFn = React.useCallback(
    (rows: Array<any>, id: string, filterValue: boolean) => {
      if (filterValue === false) {
        return rows;
      }
      return rows.filter((row) => {
        const channelId = row.values['channelId'];
        const { estimateToAverageOffset } = (meta && meta[channelId]) || {
          estimateToAverageOffset: 0,
        };
        return Boolean(estimateToAverageOffset > 0.25);
      });
    },
    [meta]
  );

  const filterTypes: any = React.useMemo(
    () => ({
      forecastFilter: forecastFilterFn,
    }),
    [forecastFilterFn]
  );

  const data = React.useMemo(() => {
    if (!pricedOffersSelected || !pricedOffersSelected.length || !commissionRates) {
      return [];
    }
    return pricedOffersSelected.map((offer) => {
      const campaignId = offer.offeror.platform_id;
      const channelId = offer.offeree.platform_id;
      const platform = offer.offeree.platform;
      const { views, cpm } = (meta && meta[channelId]) || { views: '', cpm: '' };
      const deliverable = offer.deliverables[0];
      const commissionRate =
        commissionRates[channelId] || campaignsInfo[campaignId]?.commissionRate;
      return {
        campaignId,
        channel: detailsPerChannel[channelId]?.channelName,
        channelId,
        promotionType: isYoutubeVideoDeliverable(deliverable) ? deliverable.format : '',
        deliverableType: deliverable.type,
        creatorPayout: offer.pricing,
        commissionRate: commissionRate || 'N/A',
        advertiserFee: { commissionRate, pricing: offer.pricing },
        cpm: cpm || 'N/A',
        viewsForecast: views ? numberFormatter(views) : 'N/A',
        publishingDate: getDeadlines(offer.deliverables[0]),
        budgetId: offer.budget_id,
        platform,
      };
    });
  }, [pricedOffersSelected, commissionRates, meta, detailsPerChannel, campaignsInfo]);

  const columns = React.useMemo(
    () =>
      [
        {
          Header: 'Campaign id',
          accessor: 'campaignId' as const,
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ value }: { value: string }) => <C.Badge textTransform="none">{value}</C.Badge>,
        },
        {
          Header: 'Channel',
          accessor: 'channel' as const,
          disableSortBy: true,
          disableFilters: true,
          Cell: ({
            value,
            row,
          }: {
            value: string;
            row: { original: { channelId: string; platform: string } };
          }) => {
            const channelId = row.original.channelId;
            const platform = row.original.platform;
            if (platform === 'youtube') {
              return (
                <YoutubeChannelDetails
                  id={channelId}
                  name={value}
                  avatarUrl={detailsPerChannel[channelId]?.channelAvatarUrl}
                />
              );
            } else if (platform === 'twitch') {
              return (
                <TwitchChannelDetails
                  id={channelId}
                  name={value}
                  avatarUrl={detailsPerChannel[channelId]?.channelAvatarUrl}
                />
              );
            }
          },
        },
        {
          Header: 'Channel id',
          accessor: 'channelId' as const,
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ value }: { value: string }) => <C.Badge textTransform="none">{value}</C.Badge>,
        },
        {
          Header: 'Promotion type',
          accessor: 'promotionType' as const,
          disableSortBy: true,
          disableFilters: true,
        },
        {
          Header: 'Deliverable type',
          accessor: 'deliverableType' as const,
          disableSortBy: true,
          disableFilters: true,
        },
        {
          Header: 'Creator payout',
          accessor: 'creatorPayout' as const,
          sortType: (a, b) => {
            if (a.values.creatorPayout.type === 'fixed_fee') {
              return a.values.creatorPayout.price - b.values.creatorPayout.price;
            }
            return a.values.creatorPayout.min_fee - b.values.creatorPayout.min_fee;
          },
          disableFilters: true,
          Cell: ({ value: pricing }: { value: Pricing }) =>
            pricing.type === 'fixed_fee'
              ? currencyFormatter(pricing.price)
              : pricing.type === 'cpi'
              ? `${currencyFormatter(pricing.min_fee || NaN)} + ${currencyFormatter(
                  pricing.price
                )} / install, max cap ${currencyFormatter(pricing.max_cap || NaN)}`
              : '-.--',
        },
        {
          Header: 'Commission rate',
          accessor: 'commissionRate' as const,
          sortType: 'number',
          disableFilters: true,
          Cell: ({ value }: { value: number }) => value,
        },
        {
          Header: 'Advertiser pays',
          accessor: 'advertiserFee' as const,
          disableFilters: true,
          sortType: (a, b) => {
            if (a.values.advertiserFee.pricing.type === 'fixed_fee') {
              return a.values.advertiserFee.pricing.price - b.values.advertiserFee.pricing.price;
            }
            return a.values.advertiserFee.pricing.min_fee - b.values.advertiserFee.pricing.min_fee;
          },
          Cell: ({
            value: { commissionRate, pricing },
          }: {
            value: { commissionRate?: number; pricing: Pricing };
          }) =>
            !commissionRate
              ? 'N/A'
              : pricing.type === 'fixed_fee'
              ? currencyFormatter(
                  calculateValueWithCommission(Number(pricing.price), commissionRate)
                )
              : pricing.type === 'cpi'
              ? `${currencyFormatter(
                  pricing.min_fee
                    ? calculateValueWithCommission(Number(pricing.min_fee), commissionRate)
                    : NaN
                )} + ${currencyFormatter(
                  calculateValueWithCommission(Number(pricing.price), commissionRate)
                )} / install, max cap ${currencyFormatter(
                  pricing.max_cap
                    ? calculateValueWithCommission(Number(pricing.max_cap), commissionRate)
                    : NaN
                )}`
              : '-.--',
        },
        {
          Header: 'CPM',
          accessor: 'cpm' as const,
          disableFilters: true,
          sortType: 'number',
          Cell: ({ value }: { value: string }) =>
            value === 'N/A' ? 'N/A' : currencyFormatter(value, 'en-US', 'USD', 2, 10),
        },
        {
          Header: 'Views forecast',
          accessor: 'viewsForecast' as const,
          filter: 'forecastFilter',
          Filter: ForecastFilter,
          sortType: 'number',
          Cell: ({ value, row }: { value: string; row: { original: { channelId: string } } }) => {
            const { estimateToAverageOffset } = (meta && meta[row.original.channelId]) || {
              estimateToAverageOffset: 0,
            };
            return (
              <C.Box whiteSpace="nowrap">
                {estimateToAverageOffset > 0.25 && (
                  <C.Tooltip
                    label="The views forecast is over 25% higher than the average amount of views in last 30 days"
                    hasArrow
                    placement="top">
                    <WarningTwoIcon color="red.500" mr={2} />
                  </C.Tooltip>
                )}
                {value}
              </C.Box>
            );
          },
        },
        {
          Header: 'Publishing date',
          accessor: 'publishingDate' as const,
          disableSortBy: true,
          disableFilters: true,
        },
        {
          Header: 'Budget id',
          accessor: 'budgetId' as const,
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ value }: { value: string }) => <C.Badge textTransform="none">{value}</C.Badge>,
        },
      ] as Column<OfferTableRow>[],
    // eslint-disable-next-line react-hooks/exhaustive-deps -- pass pricedOffersSelected so that table update when offers are removed
    [meta, pricedOffersSelected, detailsPerChannel]
  );

  const numericCellColumnIds = [
    'creatorPayout',
    'commissionRate',
    'advertiserFee',
    'cpm',
    'viewsForecast',
  ];

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 50 },
      defaultColumn: {
        // This could be a default filter, but we don't use that anywhere now so just return empty
        // (`defaultColumn.Filter` is required when using col filters)
        Filter: <></>,
      },
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }: { row: UseRowSelectRowProps<OfferTableRow> }) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ]);
    }
  );

  const conditionalOffersTableColumns = React.useMemo(
    () =>
      [
        {
          Header: 'Campaign id',
          accessor: 'campaignId' as const,
          disableSortBy: true,
          Cell: ({ value }: { value: string }) => <C.Badge textTransform="none">{value}</C.Badge>,
        },
        {
          Header: 'Channel',
          accessor: 'channel' as const,
          disableSortBy: true,
          Cell: ({ value, row }: { value: string; row: { original: { channelId: string } } }) => {
            const channelId = row.original.channelId;
            return (
              <YoutubeChannelDetails
                id={channelId}
                name={value}
                avatarUrl={detailsPerChannel[channelId]?.channelAvatarUrl}
              />
            );
          },
        },
        {
          Header: 'Channel id',
          accessor: 'channelId' as const,
          disableSortBy: true,
          Cell: ({ value }: { value: string }) => <C.Badge textTransform="none">{value}</C.Badge>,
        },
        {
          Header: 'Commission rate',
          accessor: 'commissionRate' as const,
          sortType: 'number',
          disableFilters: true,
          Cell: ({ value }: { value: number }) => value,
        },
        {
          Header: 'Promotion type',
          accessor: 'promotionType' as const,
          disableSortBy: true,
        },
        {
          Header: 'Deliverable type',
          accessor: 'deliverableType' as const,
          disableSortBy: true,
        },
        {
          Header: 'Deadline',
          accessor: 'publishingDate' as const,
          disableSortBy: true,
        },
        {
          Header: 'Budget id',
          accessor: 'budgetId' as const,
          disableSortBy: true,
          Cell: ({ value }: { value: string }) => <C.Badge textTransform="none">{value}</C.Badge>,
        },
      ] as Column<ConditionalOfferTableRow>[],
    [detailsPerChannel]
  );

  const conditionalOffersData = React.useMemo(() => {
    if (!conditionalOffers) {
      return [];
    }
    return conditionalOffers.map((offer) => {
      const campaignId = offer.offeror.platform_id;
      const channelId = offer.offeree.platform_id;
      const deliverable = offer.deliverables[0];

      return {
        campaignId,
        channel: detailsPerChannel[channelId]?.channelName,
        channelId,
        commissionRate:
          commissionRates[channelId] || campaignsInfo[channelId]?.commissionRate || 'N/A',
        promotionType: isYoutubeVideoDeliverable(deliverable) ? deliverable.format : '',
        deliverableType: deliverable.type,
        publishingDate: getDeadlines(offer.deliverables[0]),
        budgetId: offer.budget_id,
      };
    });
  }, [conditionalOffers, detailsPerChannel, campaignsInfo, commissionRates]);

  const conditionalOffersTableInstance = useTable(
    {
      columns: conditionalOffersTableColumns,
      data: conditionalOffersData,
      initialState: { pageSize: 50 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const removeOffers = () => {
    const selectedIds = tableInstance.state.selectedRowIds;
    const offersToKeep = pricedOffersSelected.filter((offer, i) => !selectedIds[i]);
    onPricedOffersSelected(offersToKeep);
    setPricedOffersSelected(offersToKeep);
  };

  const getCheckedOffers = () => {
    const selectedIds = tableInstance.state.selectedRowIds;
    return pricedOffersSelected.filter((offer, i) => selectedIds[i]);
  };

  const resetRemovedOffers = () => {
    onPricedOffersSelected(pricedOffers);
    setPricedOffersSelected(pricedOffers);
  };

  const {
    state: { selectedRowIds },
  } = tableInstance;

  return (
    <C.Tabs index={tabIndex} onChange={handleTabsChange}>
      <C.TabList>
        <C.Tab>Priced offers ({pricedOffersSelected.length})</C.Tab>
        <C.Tab>Conditional offers ({conditionalOffers.length})</C.Tab>
      </C.TabList>
      <C.TabPanels>
        <C.TabPanel px="0">
          {/* This help text is just for when conditional offers are being rolled out, can be removed after some weeks (let's say beginning of July) */}
          <C.Text color="gray.500" mb={4}>
            <InfoIcon mr={1} />
            Priced offers are the "normal" offers for in-network creators.
          </C.Text>
          <C.Stack direction={['column', 'row']} spacing={8}>
            <C.ButtonGroup size="sm" isAttached variant="outline">
              <C.Button
                disabled={isEmpty(selectedRowIds)}
                size="sm"
                leftIcon={<DeleteIcon />}
                onClick={removeOffers}>
                Remove selected
              </C.Button>
              <C.Button
                disabled={pricedOffersSelected.length === pricedOffers.length}
                size="sm"
                leftIcon={<RepeatClockIcon />}
                onClick={resetRemovedOffers}>
                Reset
              </C.Button>
            </C.ButtonGroup>
            <CsvDownload
              pricedOffersSelected={pricedOffersSelected}
              pricedOffersChecked={getCheckedOffers()}
              commissionRates={commissionRates}
              detailsPerChannel={detailsPerChannel}
            />
          </C.Stack>
          <ReactTableBase
            tableInstance={tableInstance}
            numericCellColumnIds={numericCellColumnIds}
            withGlobalFilter
            withColumnFilters
          />
        </C.TabPanel>

        <C.TabPanel px="0">
          <C.Text color="gray.500">
            <InfoIcon mr={1} />
            Conditional offers are for out-of-network creators that will be turned into priced
            ("normal") offers if they decide to sign up to Matchmade (and still satify to any set
            rules).
          </C.Text>
          <ReactTableBase tableInstance={conditionalOffersTableInstance} withGlobalFilter />
        </C.TabPanel>
      </C.TabPanels>
    </C.Tabs>
  );
};

export const PreviewTable = (props: {
  preparedOffers: PreparedOffers;
  onPricedOffersSelected: React.Dispatch<React.SetStateAction<OfferCreateRequest[]>>;
}) => {
  const { preparedOffers, onPricedOffersSelected } = props;
  const campaignIds = parseCampaignIdsFromCreateRequests(
    preparedOffers.offers,
    preparedOffers.conditionalOffers
  );

  const { result: campaignsInfo = {}, loading } = useAsync(async () => {
    return getCampaignsInfo(campaignIds);
  }, [JSON.stringify(campaignIds)]);

  return loading ? (
    <C.Spinner />
  ) : (
    <PreviewTableWithCampaignCommissionRateData
      preparedOffers={preparedOffers}
      onPricedOffersSelected={onPricedOffersSelected}
      campaignsInfo={campaignsInfo}
    />
  );
};
