import React from 'react';
import { BatchOutreach, BatchOutreachStats } from '../../api-clients/imt-api-outreaches';
import ReactTableBase from '../react-table/ReactTableBase';
import { useRowSelect, useSortBy, useTable } from 'react-table';
import { format } from 'date-fns';

const OutreachTaskListingTable = ({
  batches,
  stats,
}: {
  batches: BatchOutreach[];
  stats: BatchOutreachStats[];
}) => {
  const data = React.useMemo(() => {
    if (!batches) {
      return [];
    }

    return batches.map((batch) => {
      const batchStat = stats.find((stat) => stat.batch_outreach_id === batch.id);

      return {
        name: batch.name,
        created: new Date(batch.created),
        totalCount: batchStat?.total_count || 0,
        completedCount: batchStat?.completed_count || 0,
        fetchEmailFailedCount: batchStat?.fetch_email_failed_count || 0,
        sendNotificationFailedCount: batchStat?.send_notification_failed_count || 0,
        noEmailFoundCount: batchStat?.no_email_found_count || 0,
      };
    });
  }, [batches, stats]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Batch name',
        accessor: 'name' as const,
      },
      {
        Header: 'Batch created',
        accessor: 'created' as const,
        Cell: ({ value }: { value: Date }) => {
          return <>{format(value, 'dd.MM.yyyy')}</>;
        },
        sortType: 'datetime',
      },
      {
        Header: 'Total outreaches',
        accessor: 'totalCount' as const,
      },
      {
        Header: 'Completed successfully',
        accessor: 'completedCount' as const,
      },
      {
        Header: 'Fetching email failed',
        accessor: 'fetchEmailFailedCount' as const,
      },
      {
        Header: 'Sending notification failed',
        accessor: 'sendNotificationFailedCount' as const,
      },
      {
        Header: 'No email found',
        accessor: 'noEmailFoundCount' as const,
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [{ id: 'created', desc: true }],
      },
    },
    useSortBy,
    useRowSelect
  );

  return <ReactTableBase tableInstance={tableInstance} />;
};

export default OutreachTaskListingTable;
