import * as React from 'react';
import * as C from '@chakra-ui/react';
import { ChevronDownIcon, CheckIcon } from '@chakra-ui/icons';
import { useAsync } from 'react-async';
import { contentSubmissionStatuses } from './ContentSubmissionStatus';
import { cloneDeep } from 'lodash';
import type { Filter } from './ContentSubmissionListingTable';
import { alterValueInFilters } from '../../helpers';
import { CampaignSelectorMulti } from '../campaign-selector/CampaignSelector';
import { fetchAdmins } from '../../api-clients/imt-api-admins';

const AVAILABLE_FILTERS = [
  {
    key: 'status',
    description: 'Status',
  },
  {
    key: 'meta->>campaign_id',
    description: 'Campaign',
  },
  {
    key: 'reviewed_by_platform_id',
    description: 'Reviewed by',
  },
];

function PropertyFilters({
  filters,
  setFilters,
}: {
  filters: Filter[];
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
}) {
  return (
    <>
      <C.Menu>
        <C.MenuButton
          width="20"
          size="sm"
          variant="outline"
          as={C.Button}
          rightIcon={<ChevronDownIcon />}>
          Filter
        </C.MenuButton>
        <C.MenuList>
          <C.MenuGroup>
            {Object.values(AVAILABLE_FILTERS).map((filter) => (
              <C.MenuItemOption
                value={filter.description}
                key={filter.description}
                onClick={() => {
                  const nextFilters = cloneDeep(filters);
                  const existingIndex = nextFilters.findIndex((f) => f.id === filter.key);

                  if (existingIndex > -1) {
                    nextFilters.splice(existingIndex, 1);
                  } else {
                    nextFilters.push({ id: filter.key, value: [] });
                  }
                  setFilters(nextFilters);
                }}>
                {filters.find((f) => f.id === filter.key) && <CheckIcon mr={2} />}
                {filter.description}
              </C.MenuItemOption>
            ))}
          </C.MenuGroup>
        </C.MenuList>
      </C.Menu>
      {filters.find((f) => f.id === 'status') && (
        <StatusFilter filters={filters} setFilters={setFilters} />
      )}
      {filters.find((f) => f.id === 'meta->>campaign_id') && (
        <C.Stack maxW="100%" minW="22rem">
          <C.Text>Campaign is one of:</C.Text>
          <CampaignSelectorMulti
            initialSelectedCampaignIds={
              filters.find((f) => f.id === 'meta->>campaign_id')?.value || []
            }
            onSetSelectedCampaigns={(campaigns) => {
              const campaignIds = campaigns?.map((c) => c.id.toString()) || [];
              const nextFilters = alterValueInFilters(filters, 'meta->>campaign_id', campaignIds);
              setFilters(nextFilters);
            }}
          />
        </C.Stack>
      )}
      {filters.find((f) => f.id === 'reviewed_by_platform_id') && (
        <ReviewedByFilter filters={filters} setFilters={setFilters} />
      )}
    </>
  );
}

const statusesArray = Object.entries(contentSubmissionStatuses);
function StatusFilter({
  filters,
  setFilters,
}: {
  filters: Filter[];
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
}) {
  return (
    <C.Flex>
      <C.Box>
        <C.Text mr="4">Status is one of </C.Text>
      </C.Box>
      <C.Menu>
        <C.MenuButton size="sm" variant="outline" as={C.Button} rightIcon={<ChevronDownIcon />}>
          {filters
            .find((f) => f.id === 'status')
            ?.value.map((v) => contentSubmissionStatuses[v])
            .join(', ')}
        </C.MenuButton>
        <C.MenuList>
          <C.MenuOptionGroup
            type="checkbox"
            onChange={(e) => {
              const nextFilters = cloneDeep(filters);
              const existingIndex = nextFilters.findIndex((f) => f.id === 'status');

              if (existingIndex > -1) {
                nextFilters.splice(existingIndex, 1);
              }

              // Chakra has typed the `onChange` event as `string | string[]`
              if (Array.isArray(e)) {
                nextFilters.push({ id: 'status', value: e });
                setFilters(nextFilters);
              } else {
                nextFilters.push({ id: 'status', value: [e] });
                setFilters(nextFilters);
              }
            }}>
            {statusesArray.map(([key, label]) => (
              <C.MenuItemOption value={key} key={key}>
                {label}
              </C.MenuItemOption>
            ))}
          </C.MenuOptionGroup>
        </C.MenuList>
      </C.Menu>
    </C.Flex>
  );
}

function ReviewedByFilter({
  filters,
  setFilters,
}: {
  filters: Filter[];
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
}) {
  const { data: adminList, isPending, error } = useAsync(fetchAdmins);

  return error ? (
    <C.Text>{`Cannot load this filter because of error: ${error.message}`}</C.Text>
  ) : (
    <C.Flex align="center">
      <C.Box>
        <C.Text mr="4">Reviewed by one of</C.Text>
      </C.Box>
      {isPending ? (
        <C.Spinner />
      ) : (
        <C.Menu>
          <C.MenuButton size="sm" variant="outline" as={C.Button} rightIcon={<ChevronDownIcon />}>
            {filters
              .find((f) => f.id === 'reviewed_by_platform_id')
              ?.value.map((v) => {
                for (const a of adminList || []) {
                  if (String(a.id) === v) {
                    return a.displayName;
                  }
                }
                return null;
              })
              .join(', ')}
          </C.MenuButton>
          <C.MenuList>
            <C.MenuOptionGroup
              type="checkbox"
              onChange={(e) => {
                const nextFilters = cloneDeep(filters);
                const existingIndex = nextFilters.findIndex(
                  (f) => f.id === 'reviewed_by_platform_id'
                );

                if (existingIndex > -1) {
                  nextFilters.splice(existingIndex, 1);
                }

                // Chakra has typed the `onChange` event as `string | string[]`
                if (Array.isArray(e)) {
                  nextFilters.push({ id: 'reviewed_by_platform_id', value: e });
                  setFilters(nextFilters);
                } else {
                  nextFilters.push({ id: 'reviewed_by_platform_id', value: [e] });
                  setFilters(nextFilters);
                }
              }}>
              {(adminList || []).map((a) => (
                <C.MenuItemOption value={String(a.id)} key={a.displayName}>
                  {`${a.displayName}`}
                </C.MenuItemOption>
              ))}
            </C.MenuOptionGroup>
          </C.MenuList>
        </C.Menu>
      )}
    </C.Flex>
  );
}

export default PropertyFilters;
