import React from 'react';
import type { AxiosError } from 'axios';
import { revokeConditionalOffer } from './api-clients/imt-api-conditional-offers';
import { ConditionalOfferResponse } from './api-clients/imt-api-campaigns';
import { FailedOffer } from './useSendOffers';
import { chunk } from 'lodash';
import { PricedOffer } from './helpers';
import { revokePricedOffer } from './api-clients/imt-api-offers';

type RevokeParams = {
  conditionalOffers?: ConditionalOfferResponse[];
  pricedOffers?: PricedOffer[];
  revokeReason?: string;
};

function getReason(e: AxiosError): string {
  if (e.response?.status === 404) {
    return 'The offer was not found.';
  }
  // return error message if it's status 400 and not default "Something went wrong"
  if (e.response?.status === 400 && e.response?.data.error?.message !== 'Something went wrong') {
    return e.response?.data.error?.message;
  }
  return 'Could not revoke offer for an unspecified reason.';
}

export function useRevokeOffers() {
  const [succeeded, setSucceeded] = React.useState<number>(0);
  const [failed, setFailed] = React.useState<FailedOffer[]>([]);
  const [totalOffersToRevokeCount, setTotalOffersToRevokeCount] = React.useState<number>(0);

  function triggerRevokeOffers(params: RevokeParams) {
    const { conditionalOffers = [], pricedOffers = [], revokeReason = '' } = params;
    setFailed([]);
    setSucceeded(0);
    let succeededCount = 0;
    setTotalOffersToRevokeCount(conditionalOffers.length + pricedOffers.length);

    const handleRevokeConditionalOffer = async (conditionalOffer: ConditionalOfferResponse) => {
      return revokeConditionalOffer(conditionalOffer.id)
        .then(() => {
          succeededCount = succeededCount + 1;
        })
        .catch((e: AxiosError) => {
          const reason = getReason(e);
          setFailed((old) => [...old, { channelId: conditionalOffer.offeree.platform_id, reason }]);
        })
        .finally(() => {
          // in finally so counting insn't interrupted by hook due to forEach
          setSucceeded(succeededCount);
        });
    };
    const handleRevokePricedOffer = async (pricedOffer: PricedOffer) => {
      return revokePricedOffer(pricedOffer.id, pricedOffer.hash, revokeReason)
        .then(() => {
          succeededCount = succeededCount + 1;
        })
        .catch((e: AxiosError) => {
          const reason = getReason(e);
          setFailed((old) => [...old, { channelId: pricedOffer.offeree.platform_id, reason }]);
        })
        .finally(() => {
          // in finally so counting insn't interrupted by hook due to forEach
          setSucceeded(succeededCount);
        });
    };

    const batchSize = 5;
    const batchesConditional = chunk(conditionalOffers, batchSize);
    const batchesPriced = chunk(pricedOffers, batchSize);

    const revokeOffersInBatches = async () => {
      for (const batch of batchesConditional) {
        await Promise.all(batch.map(handleRevokeConditionalOffer));
      }
      for (const batch of batchesPriced) {
        await Promise.all(batch.map(handleRevokePricedOffer));
      }
    };

    return revokeOffersInBatches();
  }

  return {
    succeeded,
    failed,
    totalOffersToRevokeCount,
    triggerRevokeOffers,
  };
}
