import * as C from '@chakra-ui/react';
import { alterValueInFilters, Filters } from '../../helpers';

const closed = 'deal.chat_closed';
const handled = 'latest_message.handled_by_id';
const role = 'latest_message.role';

function ChatFilters({
  filters,
  onSetFilters,
}: {
  filters: Filters[];
  onSetFilters: (filters: Filters[]) => void;
}) {
  function onChange(filterId: string, newValue: string[] | null) {
    const nextFilters = alterValueInFilters(filters, filterId, newValue ? newValue : []);
    let roleLogic;
    if (filterId === handled && newValue) {
      roleLogic = alterValueInFilters(nextFilters, role, ['influencer', 'influencer_manager']);
    }
    onSetFilters(roleLogic || nextFilters);
  }

  const chatClosedValue = filters.find((f) => f.id === closed)?.value[0] || 'any';
  const handledValue = filters.find((f) => f.id === handled)?.value[0] || 'any';
  const roleValue = filters.find((f) => f.id === role)?.value[0] || 'any';

  const activeFiltersCount = [chatClosedValue, handledValue, roleValue].reduce(
    (acc, cur) => (cur !== 'any' ? acc + 1 : acc),
    0
  );

  return (
    <C.Menu closeOnSelect={false}>
      <C.MenuButton
        as={C.Button}
        size="sm"
        rightIcon={
          activeFiltersCount ? (
            <C.Tag size="sm" borderRadius="full" variant="solid" colorScheme="cyan">
              {activeFiltersCount}
            </C.Tag>
          ) : (
            <></>
          )
        }>
        Chat
      </C.MenuButton>
      <C.MenuList>
        <C.MenuOptionGroup title="Enabled and disabled" value={chatClosedValue} type="radio">
          <C.MenuItemOption
            value="any"
            onClick={() => {
              onChange(closed, null);
            }}>
            Any
          </C.MenuItemOption>
          <C.MenuItemOption value="true" onClick={() => onChange(closed, ['true'])}>
            Disabled
          </C.MenuItemOption>
          <C.MenuItemOption value="false" onClick={() => onChange(closed, ['false'])}>
            Enabled
          </C.MenuItemOption>
        </C.MenuOptionGroup>

        <C.MenuDivider />
        <C.MenuOptionGroup title="Handled and unhandled" value={handledValue} type="radio">
          <C.MenuItemOption
            value="any"
            onClick={() => {
              onChange(handled, null);
            }}>
            Any
          </C.MenuItemOption>
          <C.MenuItemOption
            value="null"
            onClick={() => {
              onChange(handled, ['null']);
            }}>
            Unhandled
          </C.MenuItemOption>
        </C.MenuOptionGroup>

        <C.MenuDivider />
        <C.MenuOptionGroup title="Latest message is from" value={roleValue} type="radio">
          <C.MenuItemOption
            value="any"
            isDisabled={filters.find((f) => f.id === handled)?.value[0] === 'null'}
            onClick={() => {
              onChange(role, null);
            }}>
            Any
          </C.MenuItemOption>
          <C.MenuItemOption
            value={'influencer' || 'influencer_manager'}
            isDisabled={filters.find((f) => f.id === handled)?.value[0] === 'null'}
            onClick={() => onChange(role, ['influencer', 'influencer_manager'])}>
            Creator / Manager
          </C.MenuItemOption>
          <C.MenuItemOption
            value={'admin' || 'whitelabel_admin'}
            isDisabled={filters.find((f) => f.id === handled)?.value[0] === 'null'}
            onClick={() => onChange(role, ['admin', 'whitelabel_admin'])}>
            Admin
          </C.MenuItemOption>
        </C.MenuOptionGroup>
      </C.MenuList>
    </C.Menu>
  );
}

export default ChatFilters;
