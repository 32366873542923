import * as C from '@chakra-ui/react';
import * as t from 'io-ts';
import { isRight } from 'fp-ts/Either';
import { Card, CardBody, UserMessage, EmptyState } from '@sharkpunch/idun';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-async-hook';
import { useEffect } from 'react';
import { fetchMessages, AgreementStatus } from '../../api-clients/imt-api-agreements';
import DealInfo from './DealInfo';
import DealSystemMessage from './DealSystemMessage';
import AlertBox from '../alert-box/AlertBox';
import { filtersToPostgrestFormat, getRoleLabel } from '../../helpers';
import DealNewMessage from './DealNewMessage';
import DateText from './DateText';
import { MdCircle } from 'react-icons/md';
import DealHandledButton from './DealHandled';

import ChakraMarkdown from '../chakra-markdown/ChakraMarkdown';
import { fetchDeals } from '../../api-clients/imt-api-deals';

const DealPageParamsDecoder = t.exact(t.type({ id: t.string }), 'Deal page params');

function Deal({ agreementId }: { agreementId: number }) {
  const {
    result: deal,
    loading: loadingDeal,
    error: dealError,
    merge,
  } = useAsync(async () => {
    const deals = await fetchDeals({
      filters: filtersToPostgrestFormat([{ id: 'deal.id', value: [agreementId.toString()] }]),
    });
    return deals[0];
  }, [agreementId]);

  const {
    result: messages,
    error: messagesError,
    execute,
    merge: mergeMessages,
    loading: messagesLoading,
  } = useAsync(fetchMessages, [agreementId], {
    // by default useAsync resets result back to `null` on every
    // new request, so here we preserve old results to avoid
    // UI flickering
    setLoading: (state) => ({ ...state, loading: true }),
  });

  useEffect(() => {
    const messagesInterval = setInterval(() => execute(agreementId), 30000);
    return () => clearInterval(messagesInterval);
  }, [agreementId, execute]);

  const latestMessageFromInfluencer = messages?.find(
    (m) => m.type === 'message' && m.role.includes('influencer')
  );

  // check if the latest message is from influencer
  const latestMessage = messages?.find((m) => m.type === 'message');
  const latestMessageIsFromInfluencer = Boolean(latestMessage?.role.includes('influencer'));

  let infoContent;
  if (loadingDeal) {
    infoContent = (
      <C.Box p={10}>
        <C.Progress size="xs" isIndeterminate />
      </C.Box>
    );
  } else if (dealError?.message) {
    infoContent = <AlertBox>{`Failed fetching deal info: [${dealError.message}]`}</AlertBox>;
  } else if (deal) {
    infoContent = (
      <DealInfo
        deal={deal}
        onNewChatClosed={(chatClosed: boolean) => {
          merge({ result: { ...deal, deal: { ...deal.deal, chat_closed: chatClosed } } });
        }}
        onNewStatus={(status: AgreementStatus) => {
          merge({ result: { ...deal, deal: { ...deal.deal, status } } });
        }}
      />
    );
  }

  let messagesContent;
  if (messagesLoading && !messages) {
    messagesContent = (
      <C.Box px={10} py={40}>
        <C.Progress size="xs" isIndeterminate />
      </C.Box>
    );
  } else if (messagesError?.message) {
    messagesContent = <AlertBox>{`Failed fetching messages: [${messagesError.message}]`}</AlertBox>;
  } else if (messages) {
    messagesContent = messages?.map((message) => {
      const strippedName = message.author.displayName.replace('(Matchmade Admin)', '');
      const name = (
        <>
          {strippedName} <C.Badge ml="1">{getRoleLabel(message.role)}</C.Badge>
        </>
      );
      return (
        <C.Box key={message.id} px="var(--space-md)" py="3">
          {message.type === 'message' ? (
            <UserMessage
              name={name}
              avatarSrc={message.author.avatarUrl || undefined}
              messageDetail={
                <C.HStack>
                  <DateText date={message.created} />
                  {latestMessageIsFromInfluencer &&
                    latestMessageFromInfluencer?.id === message.id && (
                      <>
                        {!latestMessageFromInfluencer.handledById && (
                          <C.Icon as={MdCircle} w={5} h={5} color="red.300"></C.Icon>
                        )}
                        <DealHandledButton
                          message={message}
                          setMessage={(m) => {
                            mergeMessages({
                              result: messages.map((message) =>
                                message.id === m.id ? m : message
                              ),
                            });
                          }}
                        />
                      </>
                    )}
                </C.HStack>
              }
              message={
                /* UserMessage does nasty things with whiteSpace, so reset it */
                <C.Box whiteSpace="normal">
                  <ChakraMarkdown content={message.content} />
                </C.Box>
              }
            />
          ) : (
            <DealSystemMessage message={message} />
          )}
        </C.Box>
      );
    });
  } else {
    messagesContent = (
      <EmptyState
        title="No chat messages yet"
        description="You can send a message to the creator"
      />
    );
  }

  return (
    <Card height="100%" overflow="hidden" display="flex" flexDirection="column">
      {infoContent}
      <C.Divider />
      <C.Box flex="1" overflow="scroll" display="flex" flexDirection="column-reverse">
        {messagesContent}
      </C.Box>
      <CardBody>
        {deal?.deal.chat_closed ? (
          <AlertBox status="info">
            This chat has been disabled. You can enable it again under deal options.
          </AlertBox>
        ) : (
          <DealNewMessage
            agreementId={agreementId}
            onNewMessage={() => {
              execute(agreementId);
            }}
          />
        )}
      </CardBody>
    </Card>
  );
}

const DealRouteContainer = () => {
  // Params might be undefined, so let's make sure that we
  // indeed got a campaign agreement id
  const rawParams = useParams();
  const params = DealPageParamsDecoder.decode(rawParams);

  const BrokenLink = (
    <EmptyState title="Deal not found" description="This might be a broken link." />
  );

  if (!isRight(params)) {
    return BrokenLink;
  }

  // Agreement id is number, so let's pass it as number
  const agreementId = +params.right.id;
  if (isNaN(agreementId)) {
    return BrokenLink;
  }

  return <Deal agreementId={agreementId} />;
};

export default DealRouteContainer;
