import { BrowserRouter, Routes, Route, NavLink } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ReactComponent as MatchmadeLogo } from './MatchmadeMark.svg';
import { ReactComponent as MatchmadeLogoLight } from './MatchmadeMarkLight.svg';
import React from 'react';
import * as C from '@chakra-ui/react';
import { useAsync } from 'react-async';

import {
  AppHeader,
  AccountButton,
  Card,
  CardBody,
  ColorModeSwitcher,
  Fonts,
  theme,
} from '@sharkpunch/idun';

import { fetchCurrentIMTUser, UserObject, LOGIN_URL, LOGOUT_URL } from './api-clients/imt-user';

import {
  UserStoreProvider,
  UserActionKind,
  useUserDispatch,
  useUserState,
} from './stores/UserStore';

import { ROOT_URL } from './config';

import BulkOfferCreation from './components/bulk-offer/BulkOfferCreation';
import OfferListing from './components/bulk-offer/OfferListing';
import ContentSubmissionListing from './components/content-submission/ContentSubmissionListing';
import ContentSubmissionPage from './components/content-submission/ContentSubmissionPage';
import DealsPage from './components/deal/DealsPage';
import BatchOutreachListing from './components/batch-outreach/BatchOutreachListing';
import CollectionsPage from './components/collections/Collections';
import { MatchmadeDataExplorer } from './components/matchmade-data-explorer/MatchmadeDataExplorer';
import { WarningIcon } from '@chakra-ui/icons';
import { format } from 'date-fns';

const extendedTheme = C.extendTheme(theme);

const ADMIN = 'whitelabel_admin';

function validateAdmin(adminObject: UserObject | undefined) {
  try {
    return adminObject?.token.role === ADMIN;
  } catch (e) {
    return false;
  }
}

const ErrorBoundary = ({ children }: { children: JSX.Element }) => (
  <Sentry.ErrorBoundary
    fallback={({ error, componentStack }) => (
      <C.Container maxW="container.lg">
        <C.Center mt={8}>
          <Card p={8}>
            <C.Flex>
              <WarningIcon boxSize="2rem" color="red.500" mr={8} />
              <C.Box>
                <C.Stack>
                  <C.Heading size="md">Something went terribly wrong</C.Heading>
                  <C.Text>
                    Try refreshing the page. If the problem persist, please contact our support.
                  </C.Text>
                </C.Stack>
                <C.Box mt={6}>
                  <C.Divider />
                  <C.Heading opacity={0.5} mb={2} mt={6} fontSize="sm">
                    Technical information
                  </C.Heading>
                  <C.Stack spacing={0}>
                    <C.Text opacity={0.5} fontSize="sm">
                      {format(new Date(), 'MMM do yyyy k:mm')}
                    </C.Text>
                    <C.Text opacity={0.5} fontSize="sm">
                      {error.name}
                    </C.Text>
                    <C.Text opacity={0.5} fontSize="sm">
                      {componentStack}
                    </C.Text>
                    <C.Text opacity={0.5} fontSize="sm">
                      {String(window.document.location)}
                    </C.Text>
                  </C.Stack>
                </C.Box>
              </C.Box>
            </C.Flex>
          </Card>
        </C.Center>
      </C.Container>
    )}>
    {children}
  </Sentry.ErrorBoundary>
);

export function MainContainer() {
  const { user } = useUserState();

  const { ToastContainer } = C.createStandaloneToast({ theme: extendedTheme });
  const Logo = C.useColorModeValue(
    C.chakra(C.chakra(MatchmadeLogo)),
    C.chakra(C.chakra(MatchmadeLogoLight))
  );

  return (
    <>
      <BrowserRouter>
        <AppHeader
          subtitle="Admin tools"
          logo={<Logo height={7} />}
          mobileDrawer={
            <>
              <C.Button variant="verticalNav" as={NavLink} end to="/">
                Send offers
              </C.Button>
              <C.Button variant="verticalNav" as={NavLink} end to="/list-offers">
                List offers
              </C.Button>
              <C.Button variant="verticalNav" as={NavLink} end to="/list-outreaches">
                List outreaches
              </C.Button>
              <C.Button variant="verticalNav" as={NavLink} end to="/express-campaigns">
                Express campaigns
              </C.Button>
              <C.Button variant="verticalNav" as={NavLink} end to="/content-submissions">
                Content review
              </C.Button>
              <C.Button variant="verticalNav" as={NavLink} end to="/deals">
                Deals
              </C.Button>
              <C.Button variant="verticalNav" as={NavLink} end to="/collections">
                Collections
              </C.Button>
            </>
          }
          right={
            <>
              <ColorModeSwitcher variant="ghost" />
              {user && (
                <AccountButton name={user.displayName} avatarSrc={user.avatarUrl}>
                  <C.MenuItem as="a" href={LOGOUT_URL}>
                    Log out
                  </C.MenuItem>
                </AccountButton>
              )}
            </>
          }>
          <C.Button variant="nav" as={NavLink} end to="/">
            Send offers
          </C.Button>
          <C.Button variant="nav" as={NavLink} end to="/list-offers">
            List offers
          </C.Button>
          <C.Button variant="nav" as={NavLink} end to="/list-outreaches">
            List outreaches
          </C.Button>
          <C.Button variant="nav" as={NavLink} end to="/content-submissions">
            Content review
          </C.Button>
          <C.Button variant="nav" as={NavLink} end to="/deals">
            Deals
          </C.Button>
          <C.Button variant="nav" as={NavLink} end to="/collections">
            Collections
          </C.Button>
          <C.Button variant="nav" as={NavLink} end to="/data-explorer">
            Data explorer
          </C.Button>
        </AppHeader>
        <ErrorBoundary>
          <Routes>
            <Route path="/list-offers" element={<OfferListing />} />
            <Route path="/list-outreaches" element={<BatchOutreachListing />} />
            <Route path="/collections" element={<CollectionsPage />} />
            <Route path="/content-submissions" element={<ContentSubmissionListing />} />
            <Route path="/content-submissions/:id" element={<ContentSubmissionPage />} />
            <Route path="/deals" element={<DealsPage />} />
            <Route path="/deals/:id" element={<DealsPage />} />
            <Route path="/data-explorer" element={<MatchmadeDataExplorer />} />
            <Route path="/" element={<BulkOfferCreation />} />
          </Routes>
        </ErrorBoundary>
      </BrowserRouter>

      <ToastContainer />
    </>
  );
}

export function RootLoading() {
  return (
    <C.Center h="100vh">
      <C.Box w="sm">
        <C.Progress size="xs" isIndeterminate />
      </C.Box>
    </C.Center>
  );
}

export function LoginPrompt() {
  return (
    <C.Center h="100vh">
      <Card>
        <CardBody>
          <C.VStack spacing={10} alignItems="start">
            <C.Box>
              <C.Heading>Matchmade admin tools</C.Heading>
              <C.Text>Login as a Matchmade admin to proceed</C.Text>
            </C.Box>
            <C.LinkBox>
              <C.LinkOverlay href={`${LOGIN_URL}?next_url=${ROOT_URL}`} _hover={undefined}>
                <C.Button colorScheme="cyan" size="lg">
                  Continue to login
                </C.Button>
              </C.LinkOverlay>
            </C.LinkBox>
          </C.VStack>
        </CardBody>
      </Card>
    </C.Center>
  );
}

function App() {
  const userStoreDispatch = useUserDispatch();
  const userStoreState = useUserState();

  const { data: user, isPending } = useAsync<UserObject>({ promiseFn: fetchCurrentIMTUser });

  React.useEffect(() => {
    if (user && !userStoreState.user) {
      // set user in store so other components can use it to determine if user is logged in
      userStoreDispatch({ type: UserActionKind.SetUser, payload: { user } });
    }
  }, [user, userStoreState.user, userStoreDispatch]);

  return isPending ? <RootLoading /> : validateAdmin(user) ? <MainContainer /> : <LoginPrompt />;
}

function StoreProvider() {
  return (
    <C.ChakraProvider theme={extendedTheme}>
      <Fonts />
      <UserStoreProvider>
        <App />
      </UserStoreProvider>
    </C.ChakraProvider>
  );
}

export default StoreProvider;
