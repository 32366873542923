import * as Sentry from '@sentry/react';
import { isRight } from 'fp-ts/Either';
import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { imtApi } from './imt-api';
import {
  ParticipantDecoder,
  PricedOffer,
  PricedOfferDecoder,
  ConditionalOfferStatusTypeDecoder,
  SendOfferPayload,
  DeliverableDecoder,
} from '../helpers';

const BudgetLockData = t.type(
  {
    id: t.string,
    updated: t.string,
    created: t.string,
    maximum: t.number,
    currency: t.string,
  },
  'Budget lock response'
);

const CampaignDecoder = t.exact(
  t.type({
    id: t.number,
    name: t.string,
    archived: t.boolean,
    contentPlatforms: t.array(t.string),
  }),
  'Campaign'
);

const CampaignFromSingleEndpointDecoder = t.exact(
  t.type({
    id: t.number,
    name: t.string,
    commissionRate: t.number,
    defaultPromotionType: t.keyof({ dedicated: null, integration: null }),
    attributionPartner: t.string,
    visible: t.boolean,
    trackingPeriod: t.number,
  }),
  'Campaign by ID'
);

const ConditionalOfferResponseDecoder = t.exact(
  t.type({
    id: t.string,
    created: t.string,
    status: ConditionalOfferStatusTypeDecoder,
    expires: t.string,
    offeror: ParticipantDecoder,
    offeree: ParticipantDecoder,
    deliverables: t.array(DeliverableDecoder),
  }),
  'Conditional Offer Response'
);
export type ConditionalOfferResponse = t.TypeOf<typeof ConditionalOfferResponseDecoder>;

export type BudgetLock = t.TypeOf<typeof BudgetLockData>;
export type Campaign = t.TypeOf<typeof CampaignDecoder>;
export type CampaignFromSingleEndpoint = t.TypeOf<typeof CampaignFromSingleEndpointDecoder>;

export async function fetchCampaigns(): Promise<Campaign[]> {
  const url = `/admin/dashboard/campaigns`;
  const res = await imtApi.get(url);
  const resData = res.data.data;

  if (resData === null || !Array.isArray(resData)) {
    console.error('Error log: IMT API response was not an array of objects', resData);
    throw new Error('Failed to parse campaigns from IMT API response!');
  }
  const campaigns = resData.map((c) => {
    const ret = CampaignDecoder.decode(c);
    if (!isRight(ret)) {
      console.error('Error log: Invalid campaign in response object:', ret);

      const err = new Error('Invalid campaign in list of campaigns');
      Sentry.captureException(err, { extra: c });
      throw err;
    }
    return ret.right;
  });
  return campaigns;
}

export async function fetchCampaign(id: string): Promise<CampaignFromSingleEndpoint> {
  const url = `/campaigns/${id}`;
  const res = await imtApi.get(url);
  const resData = res.data.data;

  if (resData === null) {
    console.error('Error log: IMT API response was not a campaign', resData);
    throw new Error('IMT API response was not a campaign');
  }

  const campaign = CampaignFromSingleEndpointDecoder.decode(resData);
  if (!isRight(campaign)) {
    console.error('Error log: Invalid campaign in getById object:', campaign);

    const err = new Error('Invalid campaign');
    Sentry.captureException(err, { extra: resData as Record<string, unknown> });
    throw err;
  }
  return campaign.right;
}

export const fetchBudgetId = async (campaignId: string): Promise<BudgetLock | null> => {
  const url = `/campaigns/${campaignId}/budget-lock`;
  const res = await imtApi.get(url);

  if (res.data.data) {
    try {
      const responseData = await tPromise.decode(BudgetLockData, res.data.data);
      return responseData;
    } catch (e) {
      console.error('Error log: Failed to parse data from budget lock id request!', e);
      return null;
    }
  }
  return null;
};

export async function fetchPricedOffers(campaignId: string): Promise<PricedOffer[]> {
  const url = `/admin/campaigns/${campaignId}/offers`;
  const res = await imtApi.get(url);
  const offers = res.data.data;

  if (offers === null || !Array.isArray(offers)) {
    throw new Error('Campaigns API did not return array');
  }
  const offersDecoded = offers.map((c) => {
    const ret = PricedOfferDecoder.decode(c);
    if (!isRight(ret)) {
      console.error('Error log: Invalid offer in list of priced offers:', ret.left);
      const err = new Error('Invalid offer in list of priced offers');
      Sentry.captureException(err, { extra: { leftObject: ret.left } });
      throw err;
    }
    return ret.right;
  });
  return offersDecoded;
}

export async function fetchConditionalOffers(
  campaignId: string
): Promise<ConditionalOfferResponse[]> {
  const fields = ['id', 'created', 'status', 'offeror', 'offeree', 'deliverables', 'expires'];
  const params = new URLSearchParams();
  fields.forEach((field) => {
    params.append('fields', field);
  });
  const url = `/admin/offers/v1/conditional-offers/offeror/campaign/${campaignId}?${params.toString()}`;
  const res = await imtApi.get(url);
  const offers = res.data.data;

  if (offers === null || !Array.isArray(offers)) {
    throw new Error('Campaigns API did not return array');
  }
  const offersDecoded = offers.map((c) => {
    const ret = ConditionalOfferResponseDecoder.decode(c);
    if (!isRight(ret)) {
      console.error('Error log: Invalid offer in list of conditional offers:', ret.left);
      const err = new Error('Invalid offer in list of conditional offers');
      Sentry.captureException(err, { extra: { leftObject: ret.left } });
      throw err;
    }
    return ret.right;
  });
  // returns array of offers
  return offersDecoded;
}

export async function sendOffer(payload: SendOfferPayload): Promise<PricedOffer> {
  const url = `/campaigns/${payload.offer.offeror.platform_id}/offer`;

  const res = await imtApi.post(url, payload);

  const offerResponse = res.data.data;
  const decoded = PricedOfferDecoder.decode(offerResponse);
  if (isRight(decoded)) {
    // returns data object so that data.status can be used later
    return res.data;
  } else {
    console.error('Error log: Failed to parse data from offer response', offerResponse, decoded);

    const err = new Error('Failed to parse data from successful sent offer response!');
    Sentry.captureException(err, { extra: res.data });
    throw err;
  }
}
