import * as C from '@chakra-ui/react';

type YoutubeChannelDetailsProps = {
  id: string;
  name: string;
  avatarUrl: string;
};

type AdminDetailsProps = {
  name: string;
  avatarUrl: string;
};

type TwichChannelDetailsProps = {
  id: string;
  name: string;
  avatarUrl: string;
};

export const YoutubeChannelDetails = ({
  id,
  name = '',
  avatarUrl = '',
}: YoutubeChannelDetailsProps) => {
  // manipulate avatar URL so that we get smaller file sizes
  const smallAvatarUrl = avatarUrl.replace('=s800-', '=s40-');
  return (
    <C.Flex align="center" wrap="nowrap">
      <C.Avatar size="sm" mr={2} name={name} src={smallAvatarUrl} />
      <C.Link isExternal href={`https://www.youtube.com/channel/${id}`}>
        {name || id}
      </C.Link>
    </C.Flex>
  );
};

export const TwitchChannelDetails = ({
  id,
  name = '',
  avatarUrl = '',
}: TwichChannelDetailsProps) => {
  // manipulate avatar URL so that we get smaller file sizes
  const smallAvatarUrl = avatarUrl.replace('=s800-', '=s40-');
  return (
    <C.Flex align="center" wrap="nowrap">
      <C.Avatar size="sm" mr={2} name={name} src={smallAvatarUrl} />
      <C.Link isExternal href={`https://www.twitch.tv/${name}`}>
        {name || id}
      </C.Link>
    </C.Flex>
  );
};

export const AdminDetails = ({ name = '', avatarUrl = '' }: AdminDetailsProps) => {
  // manipulate avatar URL so that we get smaller file sizes
  const smallAvatarUrl = avatarUrl.replace('=s800-', '=s40-');
  return (
    <C.Flex align="center" wrap="nowrap">
      <C.Avatar size="sm" mr={2} name={name} src={smallAvatarUrl} />
      {name}
    </C.Flex>
  );
};
