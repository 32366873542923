// An input with dollar sign on the left, and valid/invalid indicator on the right
// Something like this:
// +----------------------+
// | $ |              | ✓ |
// +----------------------+
//
// Note that we use Chakra's `forwardRef` here. Reason for this is that we
// want to get access to parent's FormControl data (like input validity).
// Conveniently, this also let's us to pass ref to native input (wrapped by
// chakra's input), so that react hook form can track this input's value.
//
// So full example of how this should be used is like this:
//
// <C.FormControl isInvalid={!!errors.cpm_cap}>
//   <C.FormLabel>CPM Cap</C.FormLabel>
//   <CurrencyInput
//     {...register('cpm_cap', {
//       validate: noWhitespaceValidation,
//       pattern: { value: ValidCurrencyPattern, message: 'Format is incorrect!' }
//     })}
//   />
//   <C.FormHelperText>
//     CPM used to calculate price per offer will be capped to this value
//   </C.FormHelperText>
// </C.FormControl>

import { forwardRef, PropsOf } from '@chakra-ui/system';
import * as C from '@chakra-ui/react';
import { CheckIcon, WarningIcon } from '@chakra-ui/icons';

// To be used with RHF's register `pattern` prop, like
// <CurrencyInput {...register('money', { pattern: currencyPattern })} />
export const currencyPattern = {
  value: /^\d+([.]\d{2})?$/,
  message: 'Prices should be in 123.45 format',
};

export const currencyPatternWithMultipleDecimals = {
  value: /^\d+([.]\d{2,})?$/,
  message: 'Prices should be in 123.45 format',
};

type InputProps = PropsOf<typeof C.Input> & C.FormControlOptions;
const CurrencyInput = forwardRef<InputProps, 'input'>((props, ref) => {
  const formControlProps = C.useFormControl(props);
  return (
    <C.InputGroup>
      <C.InputLeftElement children="$" />
      <C.Input {...props} ref={ref} />
      <C.InputRightElement>
        {formControlProps['aria-invalid'] ? (
          <WarningIcon color="red.500" />
        ) : (
          <CheckIcon color="green.500" />
        )}
      </C.InputRightElement>
    </C.InputGroup>
  );
});
export default CurrencyInput;
