import * as C from '@chakra-ui/react';
import { useParams, useLocation, NavLink } from 'react-router-dom';
import { NotAllowedIcon, ExternalLinkIcon, CheckIcon, CopyIcon } from '@chakra-ui/icons';
import { MdCircle, MdOndemandVideo } from 'react-icons/md';

import { Deal } from '../../api-clients/imt-api-deals';
import DateText from './DateText';
import { format } from 'date-fns';
import ContentSubmissionStatusBadge from '../content-submission/ContentSubmissionStatus';

const VideoInfo = ({ children: content }: { children: Deal['content'] }) => {
  const { hasCopied, onCopy } = C.useClipboard(content ? content.url : '');

  return content ? (
    <C.Popover trigger="hover" id="video_popover">
      <C.PopoverTrigger>
        <C.Tag
          size="sm"
          colorScheme={
            content.status === 'deleted'
              ? 'purple'
              : content.status === 'in_review'
              ? 'yellow'
              : 'green'
          }>
          <C.Icon as={MdOndemandVideo} />
        </C.Tag>
      </C.PopoverTrigger>
      <C.Portal>
        <C.PopoverContent
          onClick={(e) => {
            e.preventDefault();
          }}>
          <C.PopoverHeader pt={4} fontWeight="bold" border="0">
            Video details
          </C.PopoverHeader>
          <C.PopoverArrow />
          <C.PopoverBody>
            <C.HStack>
              <C.Text maxW="13rem">{content.url}</C.Text>
              <C.IconButton
                aria-label="Copy video url"
                size="sm"
                icon={hasCopied ? <CheckIcon color="green" /> : <CopyIcon />}
                onClick={(e) => {
                  onCopy();
                  e.preventDefault();
                }}
              />
              <C.IconButton
                aria-label="Link to video"
                size="sm"
                icon={<ExternalLinkIcon />}
                // Not an actual link since this goes under a NavLink
                onClick={(e) => {
                  e.preventDefault();
                  window.open(content.url, '_blank');
                }}
              />
            </C.HStack>
            <C.HStack pt="2">
              <C.Text>Status:</C.Text>
              <C.Text layerStyle="textDark">
                {content.status === 'in_review'
                  ? 'In review'
                  : content.status === 'deleted'
                  ? 'Deleted'
                  : 'Published'}
              </C.Text>
            </C.HStack>
            <C.HStack>
              <C.Text>Submitted:</C.Text>
              <C.Text layerStyle="textDark">
                {content.submitted ? format(new Date(content.submitted), 'dd.MM.yyyy') : '--'}
              </C.Text>
            </C.HStack>
            <C.HStack>
              <C.Text>Published:</C.Text>
              <C.Text layerStyle="textDark">
                {content.published ? format(new Date(content.published), 'dd.MM.yyyy') : '--'}
              </C.Text>
            </C.HStack>
          </C.PopoverBody>
        </C.PopoverContent>
      </C.Portal>
    </C.Popover>
  ) : null;
};

function DealLink({ deal }: { deal: Deal }) {
  const { id } = useParams();
  const location = useLocation();

  const accentColor = C.useColorModeValue('gray.50', 'gray.900');
  const boxShadowColor = C.useColorModeValue('#ededed', '#020202');

  const latestMessage = deal.latest_message;

  let author: string;
  switch (latestMessage && latestMessage.role) {
    case 'admin':
    case 'whitelabel_admin':
      author = 'Admin';
      break;
    case 'influencer_manager':
      author = 'Manager';
      break;
    case 'influencer':
      author = 'Creator';
      break;
    default:
      author = '';
  }

  const channelAvatarAndName = (
    <C.HStack>
      <C.Avatar w={6} h={6} src={deal.channel.avatar_url} />
      <C.Heading size="sm" className="ellipsis">
        {deal.channel.display_name}
      </C.Heading>
    </C.HStack>
  );

  const latestMessageTimestamp = latestMessage ? (
    <DateText date={latestMessage.created} fontSize="sm" color="gray.500" whiteSpace="nowrap" />
  ) : (
    <C.Text>N/A</C.Text>
  );

  const statusIcon =
    latestMessage && !latestMessage.handled_by_id && latestMessage.role.includes('influencer') ? (
      <C.Tooltip label="Unhandled creator message" openDelay={500} hasArrow>
        <C.Flex>
          <C.Icon as={MdCircle} w={5} h={5} color="red.300" />
          <C.VisuallyHidden>Unhandled creator message</C.VisuallyHidden>
        </C.Flex>
      </C.Tooltip>
    ) : deal.deal.chat_closed ? (
      <C.Tooltip label="Chat disabled" openDelay={500} hasArrow>
        <C.Flex>
          <NotAllowedIcon color="blue.200" w={5} h={5} />
          <C.VisuallyHidden>Chat disabled</C.VisuallyHidden>
        </C.Flex>
      </C.Tooltip>
    ) : (
      <C.Box w={5} />
    );

  const LatestMessage = latestMessage ? (
    <C.Text maxW={!id ? '15rem' : 'unset'} fontSize="xs" opacity="0.8" className="ellipsis">
      {author && <strong>{author}: </strong>}
      {latestMessage.content}
    </C.Text>
  ) : null;

  const campaignNameTag = deal.campaign.name && (
    <C.Tooltip label={deal.campaign.name} openDelay={500} hasArrow>
      <C.Tag
        size="sm"
        maxW="9rem"
        display="block"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        lineHeight="1.6">
        {deal.campaign.name}
      </C.Tag>
    </C.Tooltip>
  );

  const deadlineTag = deal.deal.deadline && (
    <C.Tag size="sm">
      <C.TagLabel overflow="unset">{format(new Date(deal.deal.deadline), 'dd.MM.yyyy')}</C.TagLabel>
    </C.Tag>
  );

  const contentStatusTag = deal.content_submission_task && (
    <ContentSubmissionStatusBadge status={deal.content_submission_task.status} size="sm" />
  );

  const videoTag = deal.content?.url && (
    <C.WrapItem>
      <VideoInfo>{deal.content}</VideoInfo>
      <C.VisuallyHidden>Video for this deal</C.VisuallyHidden>
    </C.WrapItem>
  );

  const toDeal = `/deals/${deal.deal.id}${window.location.search}`;
  const isActive = location.pathname + location.search === toDeal;
  if (id) {
    return (
      <NavLink to={toDeal} style={{ position: 'relative' }}>
        <C.VStack
          alignItems="flex-start"
          py={3}
          px="var(--space-md)"
          sx={{ '&:hover': { backgroundColor: accentColor } }}
          boxShadow={isActive ? `inset 0px 0px 8px ${boxShadowColor}` : undefined}
          backgroundColor={isActive ? accentColor : undefined}>
          <C.HStack justifyContent="space-between" alignItems="center" w="100%">
            {channelAvatarAndName}
            <C.Spacer />
            {latestMessageTimestamp}
            {statusIcon}
          </C.HStack>
          {LatestMessage}
          <C.HStack opacity="0.8">
            {campaignNameTag}
            {deadlineTag}
            {contentStatusTag}
            {videoTag}
          </C.HStack>
        </C.VStack>
      </NavLink>
    );
  } else {
    return (
      <NavLink to={toDeal} style={{ position: 'relative' }}>
        <C.Stack py={3} px="var(--space-md)" sx={{ '&:hover': { backgroundColor: accentColor } }}>
          <C.Grid w="100%" templateColumns="repeat(6, minmax(0, 1fr))" gap={10}>
            <C.GridItem>
              <C.VStack alignItems="flex-start">
                {channelAvatarAndName}
                {LatestMessage}
              </C.VStack>
            </C.GridItem>
            <C.GridItem>
              <C.HStack justifyContent="flex-end">
                {latestMessageTimestamp}
                {statusIcon}
              </C.HStack>
            </C.GridItem>
            <C.GridItem>
              <C.Flex justifyContent="center">{campaignNameTag}</C.Flex>
            </C.GridItem>
            <C.GridItem>
              <C.Flex justifyContent="center">{deadlineTag}</C.Flex>
            </C.GridItem>
            <C.GridItem>
              <C.Flex justifyContent="center">{contentStatusTag}</C.Flex>
            </C.GridItem>
            <C.GridItem>
              <C.Flex justifyContent="center">{videoTag}</C.Flex>
            </C.GridItem>
          </C.Grid>
        </C.Stack>
      </NavLink>
    );
  }
}

export default DealLink;
