import React from 'react';
import * as C from '@chakra-ui/react';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { fetchContentSubmissionId } from '../../api-clients/imt-api-agreements';
import {
  ContentSubmissionTask,
  getContentSubmissionTaskNew,
} from '../../api-clients/imt-api-content-submission';
import ContentSubmissionStatusBadge from '../content-submission/ContentSubmissionStatus';
import { ReviewDrawer } from '../content-submission/ContentSubmissionListingTable';

function DealContentReview({ agreementId }: { agreementId: number }) {
  const [CSTask, setCSTask] = React.useState<ContentSubmissionTask>();
  const { isOpen, onOpen, onClose } = C.useDisclosure();

  const { loading: loadingId } = useAsync(fetchContentSubmissionId, [agreementId], {
    onSuccess: (result) => {
      if (result && !contentSubmissionTask) {
        execute(result);
      }
    },
  });

  const {
    execute,
    result: contentSubmissionTask,
    loading: loadingTask,
  } = useAsyncCallback(getContentSubmissionTaskNew, {
    onSuccess: (result) => {
      setCSTask(result);
    },
  });

  return (
    <>
      {CSTask && (
        <ReviewDrawer
          isOpen={isOpen}
          onClose={onClose}
          contentSubmissionTask={CSTask}
          approveTask={setCSTask}
          requestForChanges={setCSTask}
        />
      )}
      <C.Button
        onClick={onOpen}
        aria-label="Open task"
        size="sm"
        isDisabled={!CSTask && !loadingId && !loadingTask}
        isLoading={loadingId || loadingTask}
        variant="ghost"
        fontSize="md">
        {CSTask && !loadingId && !loadingTask ? (
          <>
            <C.Text fontWeight="normal" mr="1">
              Content review
            </C.Text>{' '}
            <ContentSubmissionStatusBadge status={CSTask?.status} />
          </>
        ) : (
          <C.Text fontWeight="normal">No content review task</C.Text>
        )}
      </C.Button>
    </>
  );
}

export default DealContentReview;
