import * as t from 'io-ts';
import axios from 'axios';
import { imtApi } from './imt-api';
import { handleError } from '../helpers';
import { decodeResponse, decodeResponseArray, getRequestId } from '../api-helpers';
import { RoleDecoder } from './imt-api-accounts';

export const AgreementStatusDecoder = t.keyof(
  {
    waiting_for_approval: null,
    invited_to_join: null,
    publisher_set_cpi: null,
    influencer_set_cpi: null,
    influencer_declined: null,
    publisher_declined: null,
    influencer_withdrew: null,
    publisher_withdrew: null,
    auto_withdrew: null,
    cancelled: null,
    settled: null,
  },
  'Agreement status'
);
export type AgreementStatus = t.TypeOf<typeof AgreementStatusDecoder>;

const PublishingDateDecoder = t.type(
  {
    deadline: t.string,
  },
  'Publishing date'
);
type PublishingDate = t.TypeOf<typeof PublishingDateDecoder>;

const PublisherDisplayData = t.intersection(
  [
    t.type({
      displayName: t.string,
    }),
    t.partial({
      avatarUrl: t.union([t.null, t.string]),
    }),
  ],
  'Publisher display data'
);

const InfluencerDisplayData = t.type(
  {
    displayName: t.string,
    avatarUrl: t.union([t.null, t.string]),
    channelId: t.union([t.string, t.null]),
    influencerManagerTeamName: t.union([t.string, t.null]),
    influencerManagerTeamId: t.union([t.string, t.null]),
  },
  'Influencer display data'
);

const PublishContentResponseDecoder = t.exact(
  t.type({
    campaignAgreementId: t.number,
    contentId: t.string,
    contentPlatform: t.string,
    contentText: t.string,
    contentType: t.string,
    contentUrl: t.string,
    id: t.number,
    published: t.string,
    publishedAt: t.string,
    status: t.string,
    submitted: t.string,
  }),
  'Publish content response'
);
export type PublishContentResponse = t.TypeOf<typeof PublishContentResponseDecoder>;

const DealItemDecoder = t.union([
  t.null,
  t.undefined,
  t.type(
    { contentText: t.union([t.null, t.string]), contentUrl: t.string, status: t.string },
    'dealItem'
  ),
]);
export type DealItem = t.TypeOf<typeof DealItemDecoder>;

const AgreementInfoDecoder = t.exact(
  t.type({
    id: t.number,
    status: AgreementStatusDecoder,
    trackingCode: t.union([t.string, t.null]),
    // For pending deals, depending on
    // who originally sent the offer
    publisherMinGuarantee: t.number,
    influencerMinGuarantee: t.number,
    // For settled deals
    minGuarantee: t.number,
    dealTags: t.array(t.string),
    chatClosed: t.boolean,
    deadline: t.string,
    campaignId: t.number,
    campaign: t.exact(
      t.type({
        name: t.string,
      })
    ),
    dealItems: t.array(DealItemDecoder),
    influencerId: t.number,
    stats: t.union([
      t.undefined,
      t.partial({
        cost: t.number,
        commissionRate: t.number,
      }),
    ]),
  }),
  'Agreement info'
);
export type AgreementInfo = t.TypeOf<typeof AgreementInfoDecoder>;

const AgreementResponseInfoDecoder = t.exact(
  t.type({
    id: t.number,
    status: AgreementStatusDecoder,
    trackingCode: t.union([t.string, t.null]),
    // For pending deals, depending on
    // who originally sent the offer
    publisherMinGuarantee: t.number,
    influencerMinGuarantee: t.number,
    // For settled deals
    minGuarantee: t.number,
    dealTags: t.array(t.string),
    chatClosed: t.boolean,
    deadline: t.string,
    influencerId: t.number,
    stats: t.union([
      t.undefined,
      t.partial({
        cost: t.number,
        commissionRate: t.number,
      }),
    ]),
  }),
  'Agreement response info'
);
export type AgreementResponseInfo = t.TypeOf<typeof AgreementResponseInfoDecoder>;

const AgreementMessageResponseDecoder = t.exact(
  t.type(
    {
      id: t.string,
      author: t.union([PublisherDisplayData, InfluencerDisplayData]),
      role: t.union([RoleDecoder, t.null]),
      type: t.string,
      content: t.string,
      created: t.string,
    },
    'Agreement message response'
  )
);
export type AgreementMessageResponse = t.TypeOf<typeof AgreementMessageResponseDecoder>;

const DeclineReasonDecoder = t.partial(
  {
    predefined: t.array(t.string),
    other: t.string,
  },
  'Decline reason'
);
export type DeclineReason = t.TypeOf<typeof DeclineReasonDecoder>;

const AgreementMessageDecoder = t.intersection(
  [
    t.type(
      {
        id: t.string,
        author: t.union([PublisherDisplayData, InfluencerDisplayData]),
        role: RoleDecoder,
        type: t.string,
        content: t.string,
        created: t.string,
        dealItem: DealItemDecoder,
        dealValues: t.union([
          t.null,
          t.type(
            {
              deadline: t.string,
              declineReason: t.union([DeclineReasonDecoder, t.null]),
              cpi: t.number,
              cpm: t.number,
              maxPayment: t.number,
              minGuarantee: t.number,
              promotionType: t.string,
              influencerCpi: t.number,
              influencerCpm: t.number,
              influencerMaxPayment: t.number,
              influencerMinGuarantee: t.number,
              publisherCpi: t.number,
              publisherCpm: t.number,
              publisherMaxPayment: t.number,
              publisherMinGuarantee: t.number,
            },
            'dealValues'
          ),
        ]),
      },
      'Message required fields'
    ),
    t.partial(
      {
        handledById: t.union([t.number, t.null]),
      },
      'Message optional fields'
    ),
  ],
  'Agreement message'
);
export type AgreementMessage = t.TypeOf<typeof AgreementMessageDecoder>;

// https://github.com/SharkPunch/freyja-api/blob/main/src/entities/PayoutTask.ts#L4-L11
export const PayoutTaskStatusDecoder = t.keyof(
  {
    payment_info_pending: null,
    payment_info_added: null,
    payment_info_confirmed: null,
    payment_info_invalid: null,
    paid: null,
    payment_failed: null,
  },
  'Payout task status'
);

export type PayoutTaskStatus = t.TypeOf<typeof PayoutTaskStatusDecoder>;

const PayoutTaskDecoder = t.exact(
  t.type(
    {
      amount: t.string,
      url: t.string,
      status: PayoutTaskStatusDecoder,
    },
    'Payout task'
  )
);
export type PayoutTask = t.TypeOf<typeof PayoutTaskDecoder>;

const InvoiceItemStatus = t.keyof(
  {
    pending: null,
    invoiced: null,
    paid: null,
  },
  'Invoice item status'
);

export const InvoiceItemDecoder = t.exact(
  t.type({
    amount: t.string,
    status: InvoiceItemStatus,
    id: t.string,
  }),
  'Invoice item'
);
export type InvoiceItem = t.TypeOf<typeof InvoiceItemDecoder>;

export async function fetchContentSubmissionId(agreementId: number): Promise<string | null> {
  const url = `/agreements/${agreementId}/content-submission-task-id`;
  const res = await imtApi.get(url).catch(handleError('Fetching content submission task id'));
  return res.data.data;
}

export async function fetchMessages(agreementId: number): Promise<AgreementMessage[]> {
  const url = `/agreements/${agreementId}/messages`;
  const res = await imtApi.get(url).catch(handleError('Fetching messages'));
  const decoded = decodeResponseArray<AgreementMessage>(res, AgreementMessageDecoder);
  return decoded;
}

export async function postMessage(
  agreementId: number,
  content: string
): Promise<AgreementMessageResponse> {
  const url = `/agreements/${agreementId}/messages`;
  const res = await imtApi
    .post(url, { content, type: 'message' })
    .catch(handleError('Posting message'));
  const decoded = decodeResponse<AgreementMessageResponse>(res, AgreementMessageResponseDecoder);
  return decoded;
}

export async function setDealTags(
  dealTags: string[],
  agreementId: number
): Promise<AgreementResponseInfo> {
  const url = `/admin/deal-tags/${agreementId}`;
  const res = await imtApi.post(url, { dealTags }).catch(handleError('Setting deal tags'));
  const decoded = decodeResponse<AgreementResponseInfo>(res, AgreementResponseInfoDecoder);
  return decoded;
}

export async function putAgreement(
  payload: { chatClosed: boolean },
  agreementId: number
): Promise<AgreementResponseInfo> {
  const url = `/agreements/${agreementId}`;
  const res = await imtApi
    .put(url, payload)
    .catch(handleError(`Put agreement with payload ${payload}`));
  const decoded = decodeResponse<AgreementResponseInfo>(res, AgreementResponseInfoDecoder);
  return decoded;
}

export async function setPublishingDate(
  deadline: string,
  agreementId: number
): Promise<PublishingDate> {
  const url = `/agreements/${agreementId}/deadline`;
  const res = await imtApi.put(url, { deadline }).catch(handleError('Setting publishing date'));
  const decoded = decodeResponse<PublishingDate>(res, PublishingDateDecoder);
  return decoded;
}

export async function getPayoutTask(agreementId: number): Promise<PayoutTask> {
  const url = `/agreements/${agreementId}/payout-task`;
  const res = await imtApi.get(url).catch(handleError('Fetching payout task'));

  const resData = res.data.data;
  if (resData === null) {
    return resData;
  }

  const decoded = decodeResponse<PayoutTask>(res, PayoutTaskDecoder);
  return decoded;
}

export async function getInvoiceItems(agreementId: number): Promise<InvoiceItem[]> {
  const url = `/agreements/${agreementId}/invoice-items`;
  const res = await imtApi.get(url).catch(handleError('Fetching invoice items'));
  const decoded = decodeResponseArray<InvoiceItem>(res, InvoiceItemDecoder);
  return decoded;
}

const PayoutAndInvoiceItemDecoder = t.type(
  {
    payout: t.union([t.null, PayoutTaskDecoder]),
    invoiceItem: t.union([t.null, InvoiceItemDecoder]),
    invoiceItemError: t.union([t.null, t.string]),
    payoutError: t.union([t.null, t.string]),
  },
  'Payout and invoice item'
);
export async function approveDeal(
  payoutAmount: string,
  invoiceItemAmount: string,
  revenueRecognitionDate: string,
  agreementId: number
) {
  const res = await imtApi
    .post(`/agreements/${agreementId}/approve-deal`, {
      payoutAmount,
      invoiceItemAmount,
      revenueRecognitionDate,
    })
    .catch(handleError('Approving deal'));
  const decoded = decodeResponse(res, PayoutAndInvoiceItemDecoder);
  // This is one of those APIs that always returns 200 no matter what happened
  // inside of the api :| so in order to have access to request id for the
  // error cases, we need to pass it down here like this.
  return { ...decoded, requestId: getRequestId(res) };
}

export async function resetPaymentMethod(agreementId: number) {
  const url = `/agreements/${agreementId}/payout-task/payment-method`;

  let res;
  try {
    res = await imtApi.delete(url);
  } catch (e) {
    if (axios.isAxiosError(e)) {
      handleError('Resetting payment method');
    } else {
      console.error('Error log: Resetting payment method: Unknown error type:', e);
      throw new Error('Resetting payment method: Unknown error type');
    }
  }

  return res;
}

export async function cancelSettledDeal(agreementId: number): Promise<AgreementResponseInfo> {
  const url = `/agreements/${agreementId}/cancel-settled-deal`;
  const res = await imtApi.delete(url).catch(handleError('Cancelling settled deal'));
  const decoded = decodeResponse<AgreementResponseInfo>(res, AgreementResponseInfoDecoder);
  return decoded;
}

export async function publishContent(
  contentUrl: string,
  contentType: string,
  agreementId: number
): Promise<PublishContentResponse> {
  const url = `/agreements/${agreementId}/${contentType}/publish`;
  const res = await imtApi
    .post(url, { contentUrl })
    .catch(handleError('Linking content to agreement'));
  const decoded = decodeResponse<PublishContentResponse>(res, PublishContentResponseDecoder);
  return decoded;
}
