import * as C from '@chakra-ui/react';
import { useAsyncCallback } from 'react-async-hook';
import { useForm } from 'react-hook-form';
import { postMessage } from '../../api-clients/imt-api-agreements';
import { growElementByContent, showErrorToast } from '../../helpers';

const DealNewMessage = ({
  agreementId,
  onNewMessage,
}: {
  agreementId: number;
  onNewMessage: () => void;
}) => {
  const { register, formState, handleSubmit, reset } = useForm<{ content: string }>({
    mode: 'onChange',
    defaultValues: {
      content: '',
    },
  });

  const doPostMessage = useAsyncCallback(async (content) => postMessage(agreementId, content), {
    onSuccess: () => {
      onNewMessage();
      reset();
      resizeTextarea();
    },
    onError: (error) => {
      showErrorToast('Error: Failed to send message', error.message);
    },
  });

  const triggerPostMessage = (data: { content: string }) => {
    doPostMessage.execute(data.content);
  };

  const resizeTextarea = () => {
    const textareaElement = document.getElementById('new-message-textarea');
    if (textareaElement) {
      growElementByContent(textareaElement);
    }
  };

  return (
    <form onSubmit={handleSubmit(triggerPostMessage)}>
      <C.HStack alignItems="flex-start">
        <C.FormControl>
          <C.Textarea
            id="new-message-textarea"
            placeholder="Write a message to the creator"
            {...register('content', { required: true })}
            onKeyUp={resizeTextarea}
            maxHeight="50vh"
          />
        </C.FormControl>

        <C.Popover>
          {({ onClose }) => (
            <>
              <C.PopoverTrigger>
                <C.Button
                  colorScheme="cyan"
                  disabled={!formState.isValid}
                  isLoading={doPostMessage.loading}>
                  Send
                </C.Button>
              </C.PopoverTrigger>
              <C.PopoverContent>
                <C.PopoverArrow />
                <C.PopoverHeader>
                  Do you want to send this message to the creator now? You can't edit or cancel
                  after sending.
                </C.PopoverHeader>
                <C.PopoverBody>
                  <C.VStack>
                    <C.Button
                      type="submit"
                      onClick={() => onClose()}
                      disabled={!formState.isValid}
                      colorScheme="cyan"
                      isLoading={doPostMessage.loading}>
                      Send message now
                    </C.Button>
                  </C.VStack>
                </C.PopoverBody>
              </C.PopoverContent>
            </>
          )}
        </C.Popover>
      </C.HStack>
    </form>
  );
};

export default DealNewMessage;
