import * as C from '@chakra-ui/react';

export const contentSubmissionStatuses: { [key: string]: string } = {
  pending: 'Pending',
  request_for_changes: 'Changes requested',
  submitted: 'Submitted',
  approved: 'Approved',
  canceled: 'Canceled',
};

interface PropsStatusBadge extends C.PropsOf<typeof C.Tag> {
  status: string | undefined;
}

const ContentSubmissionStatusBadge = ({ status, ...rest }: PropsStatusBadge) => {
  let tagColor = 'gray';
  let variant = 'subtle';
  let statusText = contentSubmissionStatuses[status || 'pending'];

  switch (status) {
    case 'pending':
      tagColor = 'purple';
      break;
    case 'request_for_changes':
      tagColor = 'yellow';
      break;
    case 'submitted':
      tagColor = 'green';
      break;
    case 'approved':
      tagColor = 'teal';
      variant = 'outline'; // outline for "dead ends"
      break;
    case 'canceled':
      tagColor = 'gray'; // gray so that it looks dead instead of red "error / take action"
      variant = 'outline';
      break;
    default:
      break;
  }
  return (
    <C.Tag borderRadius="full" colorScheme={tagColor} variant={variant} {...rest}>
      <C.TagLabel
        textTransform="uppercase"
        fontSize="0.85em"
        fontWeight="bold"
        textOverflow="unset">
        {statusText}
      </C.TagLabel>
    </C.Tag>
  );
};

export default ContentSubmissionStatusBadge;
