import React from 'react';
import _ from 'lodash';
import * as C from '@chakra-ui/react';
import { CheckIcon, DeleteIcon } from '@chakra-ui/icons';

import { Rule, Item, SelectedRuleParams, operators } from './Rule';
import { v4 as uuid } from 'uuid';

type RuleGroupProps = {
  targetItems: Item[];
  onChange: (params: RuleListItem[]) => void;
};

export type RuleListItem = { id: string; params: SelectedRuleParams | null; errors: string | null };

const defaultRuleParams = {
  target: null,
  operator: null,
  ruleValue: null,
};

const paramsErrorText = (params: SelectedRuleParams | null): string | null => {
  if (!params) {
    return 'Missing parameters for rule';
  }
  if (!params.target) {
    return 'Missing target for rule';
  }
  if (!params.operator) {
    return 'Missing operator for rules';
  }
  if (!params.ruleValue) {
    return 'Missing value for rule';
  }
  return null;
};

export const RuleGroup = (props: RuleGroupProps) => {
  const { targetItems, onChange } = props;

  const [ruleList, setRuleList] = React.useState<RuleListItem[]>([]);

  const [isInitialState, setIsInitialState] = React.useState(true);

  React.useEffect(() => {
    if (!isInitialState) {
      onChange(ruleList);
    }
  }, [onChange, ruleList, isInitialState]);

  if (targetItems.length < 1 || targetItems[0].options.length < 1) {
    return null;
  }

  return (
    <C.Stack>
      <C.Button
        mb={4}
        colorScheme="cyan"
        width={32}
        onClick={() => {
          if (isInitialState) {
            setIsInitialState(false);
          }
          setRuleList(
            ruleList.concat({
              id: uuid(),
              params: {
                target: { id: targetItems[0].options[0].id, groupKey: targetItems[0].groupKey },
                operator: operators.find((o) => o.id === 'gte') || operators[0],
                ruleValue: null,
              },
              errors: paramsErrorText(null),
            })
          );
        }}>
        Add rule
      </C.Button>
      {ruleList.length > 0 && (
        <C.Box>
          <C.Alert mb={4} status="info">
            <C.AlertIcon />
            The values expected are decimal numbers between 0 and 1.
          </C.Alert>
        </C.Box>
      )}
      {ruleList.map((r) => {
        const errorText = paramsErrorText(r.params);
        return (
          <C.HStack key={r.id}>
            <Rule
              targetItems={targetItems}
              selectedParams={r.params || defaultRuleParams}
              onChange={(ruleParams: SelectedRuleParams) => {
                const newRuleList = _.cloneDeep(ruleList);
                const targetRule = newRuleList.find((rule) => rule.id === r.id);
                if (targetRule) {
                  targetRule.params = ruleParams;
                  targetRule.errors = paramsErrorText(ruleParams);
                  setRuleList(newRuleList);
                }
              }}
            />
            <C.IconButton
              size="sm"
              aria-label="Remove rule"
              icon={<DeleteIcon />}
              onClick={() => {
                const newRuleList = _.cloneDeep(ruleList);
                _.remove(newRuleList, (rule) => rule.id === r.id);
                setRuleList(newRuleList);
              }}
            />
            {errorText ? (
              <C.Text fontSize={12} color="red">
                {errorText}
              </C.Text>
            ) : (
              <CheckIcon color="green" />
            )}
          </C.HStack>
        );
      })}
    </C.Stack>
  );
};
