import * as C from '@chakra-ui/react';
import { format } from 'date-fns';
import _ from 'lodash';
import { Role } from '../../api-clients/imt-api-accounts';
import { AgreementMessage, DeclineReason } from '../../api-clients/imt-api-agreements';
import { currencyFormatter } from '../../helpers';
import DateText from './DateText';

const ChangedDeadlineMessage = ({
  displayName,
  deadline,
  created,
  role,
}: {
  displayName: string; // This is the user displayName
  deadline: string;
  created: string;
  role: Role;
}) => {
  return (
    <C.Box textAlign="center">
      <C.Text>
        {role === 'admin' ? 'Matchmade' : displayName} changed the publishing date to:{' '}
        <strong>
          {deadline !== '' ? format(new Date(deadline), 'MMM do yyyy') : 'Error: No deal values'}
        </strong>
      </C.Text>
      <C.Text as="span" color="gray.500" fontSize="sm">
        {role === 'admin' ? `${displayName} (Matchmade Admin) - ` : ''}
        <DateText as="span" date={created} />
      </C.Text>
    </C.Box>
  );
};

const PublishedContentMessage = ({
  channelName,
  contentText,
  contentUrl,
  created,
  status,
}: {
  channelName: string;
  contentText: string;
  contentUrl: string;
  created: string;
  status: string;
}) => {
  let message;
  switch (status) {
    case 'published':
      message = `${channelName} published video`;
      break;
    case 'in_review':
      message = `${channelName} created video for review`;
      break;
    default:
      message = 'invalid status';
  }

  return (
    <C.Box textAlign="center">
      <C.Text>{message}</C.Text>
      <C.Link href={contentUrl} isExternal>
        {contentText}
      </C.Link>
      <DateText date={created} color="gray.500" fontSize="sm" />
    </C.Box>
  );
};

const NegotiationMessage = ({
  created,
  content,
  offer,
  authorDisplayName,
}: {
  created: string;
  content: string;
  offer: JSX.Element;
  authorDisplayName: string;
}) => {
  let message;
  switch (content) {
    case 'invited_to_join':
      message = `${authorDisplayName} invited creator with the offer:`;
      break;
    case 'changed_deadline':
      message = `${authorDisplayName} changed the publishing date to:`;
      break;
    case 'waiting_for_approval':
      message = `${authorDisplayName} wants to join campaign and made a new offer:`;
      break;
    case 'influencer_manager_set_cpi':
    case 'influencer_set_cpi':
    case 'publisher_set_cpi':
      message = `${authorDisplayName} made a new offer:`;
      break;
    case 'influencer_manager_changed_cpi':
    case 'influencer_changed_cpi':
    case 'publisher_changed_cpi':
      message = `${authorDisplayName} changed the offer:`;
      break;
    case 'influencer_declined':
    case 'publisher_declined':
      message = `${authorDisplayName} declined`;
      break;
    case 'influencer_withdrew':
    case 'publisher_withdrew':
      message = `${authorDisplayName} withdrew the offer`;
      break;
    case 'settled':
      message = `${authorDisplayName} accepted the offer:`;
      break;
    case 'cancelled':
      message = 'Deal has been cancelled. Sorry.';
      break;
    case 'updated_offer':
      message = 'The offer has been updated';
      break;
    default:
      message = 'invalid content';
  }

  return (
    <C.Box textAlign="center">
      <C.Text>{message}</C.Text>
      {offer}
      <DateText date={created} color="gray.500" fontSize="sm" />
    </C.Box>
  );
};

// These were originally defined in campaign webapp
// campaign-webapp/blob/3d2664f2dfcc4de0c81dfd70b653807550895f81/src/components/messages/DeclineReasonMessage.js#L6-L19
const PredefinedMessageLabels: { [key: string]: string | undefined } = {
  influencer_content_inappropriate: 'the content of the channel is not adequate',
  influencer_audience_inappropriate: `creator's audience doesn't match our target audience`,
  price_high: 'the price is too high',
  channel_small: 'the channel is too small',
  channel_big: 'the channel is too big',
  publisher_content_inappropriate: "the brand/product doesn't match the content on my channel",
  price_low: 'the price is too low',
  publisher_deal_inappropriate: 'the deal type is not adequate',
  campaign_not_available: 'campaign is no longer available',
};

const DeclineReasonMessage = ({ declineReason }: { declineReason: DeclineReason }) => {
  let message: string;

  if (declineReason.predefined) {
    message = `Declination reason: ${declineReason.predefined
      .map((cur) => PredefinedMessageLabels[cur] || '')
      .join(', ')}.`;
  } else if (declineReason.other) {
    message = `Declination reason: ${declineReason.other}`;
  } else {
    message = 'Declined for unknown reason.';
  }

  return <C.Text>{message}</C.Text>;
};

function getMonetaryValues({
  cpi,
  cpm,
  minGuarantee,
  maxPayment,
}: {
  cpi: number;
  cpm: number;
  minGuarantee: number;
  maxPayment: number;
}) {
  return (
    <>
      {cpi > 0 && (
        <>
          {' '}
          · CPI <strong>{currencyFormatter(cpi / 100)}</strong>
        </>
      )}
      {cpm > 0 && (
        <>
          {' '}
          · CPM <strong>{currencyFormatter(cpm / 100)}</strong>
        </>
      )}
      {cpi === 0 && cpm === 0 && (
        <>
          {' '}
          · Fixed fee <strong>{currencyFormatter(minGuarantee / 100)}</strong>
        </>
      )}
      {(cpi > 0 || cpm > 0) &&
        (minGuarantee ? (
          <>
            {' '}
            · Minimum guarantee <strong>{currencyFormatter(minGuarantee / 100)}</strong>
          </>
        ) : (
          ' · No minimum guarantee'
        ))}
      {(cpi > 0 || cpm > 0) &&
        (maxPayment ? (
          <>
            {' '}
            · Max payment <strong>{currencyFormatter(maxPayment / 100)}</strong>
          </>
        ) : (
          ' · No max payment'
        ))}
    </>
  );
}

const DealSystemMessage = ({ message }: { message: AgreementMessage }) => {
  let messageContent = null;
  if (message.type === 'published_content') {
    if (message.dealItem) {
      messageContent = (
        <PublishedContentMessage
          channelName={message.author.displayName}
          contentText={message.dealItem.contentText || ''}
          contentUrl={message.dealItem.contentUrl}
          created={message.created}
          status={message.dealItem.status}
        />
      );
    } else {
      messageContent = <C.Box>Published content message: No linked video</C.Box>;
    }
  } else if (message.type === 'changed_deadline') {
    messageContent = (
      <ChangedDeadlineMessage
        displayName={message.author.displayName}
        deadline={message.dealValues ? message.dealValues.deadline : ''}
        created={message.created}
        role={message.role}
      />
    );
    // commission messages use the same style as negotiation messages
  } else if (message.type === 'negotiation') {
    let offer = <></>;
    if (!message.dealValues) {
      messageContent = <C.Box>Negotiation message: No deal values</C.Box>;
    } else if (message.dealValues && message.dealValues.declineReason) {
      offer = <DeclineReasonMessage declineReason={message.dealValues.declineReason} />;
    } else if (message.dealValues && message.content !== 'cancelled') {
      const cpi = message.dealValues.cpi;
      const cpm = message.dealValues.cpm;
      const minGuarantee = message.dealValues.minGuarantee;
      const maxPayment = message.dealValues.maxPayment;
      const promotionType = _.upperFirst(message.dealValues.promotionType);

      offer = (
        <C.Text>
          <strong>{promotionType}</strong>
          {getMonetaryValues({ cpi, cpm, minGuarantee, maxPayment })}
        </C.Text>
      );
    }

    messageContent = (
      <NegotiationMessage
        authorDisplayName={message.author.displayName}
        created={message.created}
        content={message.content}
        offer={offer}
      />
    );
  } else if (message.type === 'commission') {
    if (!message.dealValues) {
      messageContent = <C.Box>Commission change message: No deal values</C.Box>;
    } else if (message.dealValues && message.content !== 'cancelled') {
      const cpi = message.dealValues.cpi;
      const cpm = message.dealValues.cpm;
      const minGuarantee = message.dealValues.minGuarantee;
      const maxPayment = message.dealValues.maxPayment;

      messageContent = (
        <C.Box textAlign="center">
          <C.Text>
            Commission changed
            {getMonetaryValues({ cpi, cpm, minGuarantee, maxPayment })}
          </C.Text>
          <DateText date={message.created} color="gray.500" fontSize="sm" />
        </C.Box>
      );
    }
  }

  return messageContent;
};

export default DealSystemMessage;
