// Generic Axios instance utility. You can use it as standalone, like
// imtApi.get('/some/api/path', {foo: 'fa'})
// or pass it to `useAsync` -- this function isn't really any different from other
// axios-based functions that we have.
//
// The goal here is to do all generic validations like "did we get correct HTTP response
// code from IMT" or "is IMT response object of correct shape".
//
// Note also that we deliberately type `data` returned by IMT as `unknown`, so the caller
// is forced to cast-type it and ensure that returned object is indeed of correct type:

// type Foofa = {
//   foofa: number;
// };
// function parseFoofa(res: unknown): Foofa {
//   if (
//     typeof res !== 'object' ||
//     res === null ||
//     !hasOwnProperty(res, 'foofa') ||
//     typeof res.foofa !== 'number' ||
//     res.foofa === null
//   ) {
//     throw new Error('no');
//   }
//   return { foofa: res.foofa };
// }
// ...
// async function boo() {
//   const parsed = parseFoofa(await imtApi.get('/foofa' }));
//   // parsed is of type Foofa
//   const f = parsed.foofa;
//   const isNumber = typeof f === 'number'; // true
// }

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { IMT_API_URL } from '../config';
import { getAuthHeader } from './puppe';

import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

const API_ROOT = `${IMT_API_URL}/api`;

const IMT_API_ERROR_DECODER = t.type(
  {
    // the code associated with this error,
    // it can be the same as statusCode or some specific number
    code: t.string,
    // error message
    message: t.string,
    // related params for this error
    params: t.union([t.array(t.unknown), t.null]),
    // i18n string for old webapp, we can ignore this.
    i18n: t.string,
  },
  'IMT API error'
);

const IMT_API_RESPONSE_DECODER = t.exact(
  t.type({
    // Caller should explicitly cast this to correct type and ensure data shape
    // See top comment
    data: t.unknown,
    success: t.boolean,
    error: t.union([t.null, IMT_API_ERROR_DECODER]),
  }),
  'IMT API response'
);

export const imtApi = axios.create({
  baseURL: API_ROOT,
});

export async function requestInterceptor(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
  // Add auth header before request is sent
  const authHeader = await getAuthHeader();
  config.headers = authHeader;
  return config;
}

imtApi.interceptors.request.use(requestInterceptor, function (error) {
  // Possibility to do something with request error, nothing for now
  return Promise.reject(error);
});

function shouldHaveBody(statusCode: number) {
  const NO_BODY_STATUSES = [202];
  return !NO_BODY_STATUSES.includes(statusCode);
}

export async function responseInterceptor(response: AxiosResponse): Promise<AxiosResponse> {
  // Any status code that lie within the range of 2xx cause this function to trigger
  let responseData;

  try {
    responseData = await tPromise.decode(IMT_API_RESPONSE_DECODER, response.data);
  } catch (e) {
    console.error(
      'Error log: Failed to parse data from successful IMT API response for [%s%s]!',
      response.config.baseURL,
      response.config.url,
      e
    );
    throw new Error('Failed to parse data from IMT API response!');
  }

  if (
    shouldHaveBody(response.status) &&
    !Object.keys(responseData).every((k) => Object.keys(response.data).includes(k))
  ) {
    console.error(
      'Error log: Strange IMT API response shape for url [%s%s], aborting',
      response.config.baseURL,
      response.config.url,
      response.data
    );
    throw new Error('Invalid IMT API response shape');
  }
  if (!responseData.success && responseData.error) {
    const error = responseData.error;
    console.error(
      `Error log: IMT request [${response.config.baseURL}${response.config.url}] failed with [${error.message}]`,
      error
    );
    throw new Error(error.message);
  }

  return response;
}

imtApi.interceptors.response.use(responseInterceptor, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  return Promise.reject(error);
});
