import * as React from 'react';
import { useAsync } from 'react-async';
import { useLocation, useNavigate } from 'react-router-dom';
import * as t from 'io-ts';
import { isRight } from 'fp-ts/Either';
import { PathReporter } from 'io-ts/PathReporter';
import * as C from '@chakra-ui/react';
import { ChevronDownIcon, DeleteIcon, QuestionIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import Async from 'react-async';

import { useConfirmDialog } from '@sharkpunch/idun';

import debounce from 'lodash/debounce';

import CpmRulesFromCsvAccordion from './CpmRulesFromCsvAccordion';

import { DropdownSelect, DropdownSelectMulti } from '@sharkpunch/idun';
import { BudgetLock, fetchBudgetId, fetchCampaign } from '../../api-clients/imt-api-campaigns';
import {
  Collection,
  CollectionResponse,
  fetchCollections,
  fetchCollectionWithChannels,
  fetchCollectionWithInfluencers,
  splitInfluencersToInAndOutOfNetwork,
} from '../../api-clients/imt-api-collections';
import CurrencyInput, {
  currencyPattern,
  currencyPatternWithMultipleDecimals,
} from '../currency-input/CurrencyInput';
import Selector from '../selector/Selector';
import {
  genders,
  countryCodesAndNamePairs,
  ageGroups,
  operatingSystems,
  deviceTypes,
} from '../../constants/demographics';
import {
  showErrorToast,
  showSuccessToast,
  CPMRule,
  DemographicFilter,
  CPMRuleWithDimensionFromCsvUpload,
  CPMRuleWithDimensionFromCsvUploadT,
  demographicFilters,
  DemographicFilterItem,
} from '../../helpers';
import {
  channelToPricedOffer,
  channelToConditionalOffer,
  ViewEstimateRange,
  limitOffersToSum,
  OfferProps,
} from '../../offerCalculator';

import type { OfferCreateRequest, OffereeToCommissionRateObject } from '../../helpers';
import type { MetaItem } from './PreviewTable';
import CampaignSelector, { CampaignSelectorMulti } from '../campaign-selector/CampaignSelector';
import AlertBox from '../alert-box/AlertBox';
import { CsvUpload } from '../csv-upload/CsvUpload';
import { sortBy } from 'lodash';
import { PreparedOffers } from './BulkOfferCreation';

import CampaignWarnings from './CampaignWarnings';
import axios from 'axios';

export type FailedToParse = string[];

const noWhitespaceValidation = (value?: string | null) =>
  typeof value === 'string' && value === value.trim();

const submitDataBase = {
  campaign_id: t.string,
  collection_id: t.string,
  excluded_collection_ids: t.array(t.string),
  cpm_multiplier: ViewEstimateRange,
  max_price: t.string,
  min_price: t.string,
  demographics: t.array(CPMRule),
  demographic_filters: t.array(DemographicFilter),
  expires: t.string,
  video_publishing_date_start: t.string,
  video_publishing_date_end: t.string,
  cpm_cap: t.string,
  cpm_min: t.string,
  sum_limit: t.union([t.string, t.null]),
  round_prices: t.union([t.boolean, t.null]),
  batch_commission_rate: t.union([t.number, t.null]),
  batch_name: t.union([t.string, t.null]),
  deliverable_type: t.union([t.literal('youtube_video'), t.literal('multiple_youtube_videos')]),
  number_of_videos: t.union([t.number, t.null]),
  min_days_between_deadlines: t.union([t.number, t.null]),
  target_campaign_ids: t.union([t.array(t.string), t.null]),
};

const submitDataCpiFields = {
  is_cpi: t.boolean,
  cpi: t.string,
  actions_threshold: t.union([t.number, t.null]),
  min_fee_factor: t.number,
  max_cap_factor: t.number,
};

export const SubmitDataDecoder = t.intersection([
  t.type(submitDataBase, 'FormSubmitData'),
  t.partial(submitDataCpiFields),
]);

const PartialSubmitDataDecoder = t.partial(
  { ...submitDataBase, ...submitDataCpiFields },
  'PartialFormSubmitData'
);

type SubmitData = t.TypeOf<typeof SubmitDataDecoder>;
type PartialSubmitData = t.TypeOf<typeof PartialSubmitDataDecoder>;

function useFormStateInUrl() {
  // We store the form state in "#" aka hash part of the URL
  // to avoid (real) issues with URL length. If we'd use
  // SearchParams, the full URL gets sent to the server, and
  // with _long_ urls (e.g. hundreds of demographic filters)
  // it gets a little unwieldly
  const navigate = useNavigate();
  const location = useLocation();

  const base = '#form=';

  const getFormDataFromUrl = (): PartialSubmitData | null => {
    if (location.hash.startsWith(base)) {
      const maybeTemplate = JSON.parse(decodeURI(location.hash.replace(base, '')));
      const template = PartialSubmitDataDecoder.decode(maybeTemplate);
      if (isRight(template)) {
        return template.right;
      } else {
        throw new Error(`Invalid form data in URL: ${PathReporter.report(template)}`);
      }
    } else {
      return null;
    }
  };

  const setFormDataToUrl = (newData: PartialSubmitData) => {
    navigate(`${base}${JSON.stringify(newData)}`);
  };

  return { get: getFormDataFromUrl, set: setFormDataToUrl };
}

export async function getChannelsForOffers(
  collection_id: string,
  excluded_collection_ids: string[]
): Promise<CollectionResponse> {
  let collectionResponse;
  try {
    collectionResponse = await fetchCollectionWithInfluencers(collection_id);
  } catch (e) {
    let message = 'Unexpected error occured';
    if (axios.isAxiosError(e)) {
      message = e.response?.data.error.message;
      if (message === 'Something went wrong') {
        message = `Failed to get collection with id ${collection_id} due to an unspecified reason.`;
      }
    }
    showErrorToast('Failed to get channels for offers', message);
    throw e;
  }

  let excludedCollectionResponse;
  try {
    excludedCollectionResponse = await Promise.all(
      excluded_collection_ids.map(fetchCollectionWithChannels)
    );
  } catch (e) {
    let message = 'Unexpected error occured';
    if (axios.isAxiosError(e)) {
      message = e.response?.data.error.message;
      if (message === 'Something went wrong') {
        message = `Failed to get collection with id ${collection_id} due to an unspecified reason.`;
      }
    }
    showErrorToast('Failed to get excluded channels for offers', message);
    throw e;
  }

  const channelIdsToExclude = excludedCollectionResponse.flatMap((c) => {
    return c.channels;
  });

  const ret = collectionResponse.influencers.filter((influencer) => {
    if (!Array.isArray(influencer.youtubeChannels)) {
      return false;
    }
    return channelIdsToExclude.indexOf(influencer.youtubeChannels[0]?.id) < 0;
  });
  return { influencers: ret, name: collectionResponse.name, id: collectionResponse.id };
}

export type Price = { [key: string]: MetaItem };

const prepareOffers = async (data: SubmitData): Promise<PreparedOffers> => {
  let batchCommissionRate = data.batch_commission_rate;
  if (batchCommissionRate !== null && isNaN(batchCommissionRate)) {
    batchCommissionRate = null;
  }
  const errorTitle = 'Error: could not create offers';
  const { campaign_id } = data;

  // fetch budget id for campgaign
  let budgetId: BudgetLock | null | undefined;
  if (campaign_id) {
    try {
      budgetId = await fetchBudgetId(campaign_id);
    } catch (e) {
      let message = 'Unexpected error occured';
      if (axios.isAxiosError(e)) {
        message = e.response?.data.error.message;
        if (message === 'Something went wrong') {
          message = `Failed to get budget for campaign with id ${campaign_id} due to an unspecified reason.`;
        }
      }
      showErrorToast(errorTitle, message);
      throw e;
    }
  }
  console.debug({ data, budgetId }, 'Data gathered from form');

  const channelsForOffers = await getChannelsForOffers(
    data.collection_id,
    data.excluded_collection_ids
  );

  const { youtubeChannels, outOfNetworkYoutubeChannels, failedToParse, detailsPerChannel } =
    splitInfluencersToInAndOutOfNetwork(channelsForOffers);

  console.debug('Youtube channels from collection:', youtubeChannels);
  console.debug('OON youtube channels from collection:', outOfNetworkYoutubeChannels);

  // fetch campaign
  let campaignResponse;
  try {
    campaignResponse = await fetchCampaign(campaign_id);
  } catch (e) {
    let message = 'Unexpected error occured';
    if (axios.isAxiosError(e)) {
      message = e.response?.data.error.message;
      if (message === 'Something went wrong') {
        message = `Failed to get campaign with id ${campaign_id} due to an unspecified reason.`;
      }
    }
    showErrorToast(errorTitle, message);
    throw e;
  }
  const { defaultPromotionType } = campaignResponse;

  if (!defaultPromotionType) {
    throw new Error('Unable to find default promotion type for campaign!');
  }

  // if all API calls went well, start with offer creation!

  const cpmRulesWithDimension = data.demographics.map((cpmRule) => {
    const dimensionAndVal = cpmRule.name?.split('.');
    return {
      dimension: dimensionAndVal[0],
      name: dimensionAndVal[1],
      cpm_per_percentage: cpmRule.cpmPerPercentage,
    };
  });

  const minPrice = parseFloat(data.min_price) || undefined;
  const maxPrice = parseFloat(data.max_price) || undefined;
  const minCpm = parseFloat(data.cpm_min) || undefined;
  const cpmCap = parseFloat(data.cpm_cap) || undefined;

  const deliverable =
    data.deliverable_type === 'youtube_video'
      ? {
          type: 'youtube_video' as const,
          description: '_',
          format: defaultPromotionType,
          tracking_period: campaignResponse.trackingPeriod,
          deadline_range_start: data.video_publishing_date_start,
          deadline_range_end: data.video_publishing_date_end,
        }
      : {
          type: 'multiple_youtube_videos' as const,
          description: '_',
          format: defaultPromotionType,
          tracking_period: campaignResponse.trackingPeriod,
          deadline_range_start: data.video_publishing_date_start,
          deadline_range_end: data.video_publishing_date_end,
          number_of_videos: data.number_of_videos || 1,
          min_days_between_deadlines: data.min_days_between_deadlines || 2,
          target_campaign_ids: data.target_campaign_ids || [],
        };

  const offerRules: OfferProps = {
    cpmRules: data.demographics,
    priceFilters: [{ minPrice, maxPrice, minCpm }],
    roundPrice: data.round_prices,
    defaultCommissionRate: batchCommissionRate || campaignResponse.commissionRate,
    demographicFilters: data.demographic_filters,
    viewEstimateRange: data.cpm_multiplier,
    cpmCap,
    expires: data.expires,
    campaignId: data.campaign_id,
    budgetId: budgetId ? budgetId.id : undefined,
    deliverable: deliverable,
    cpi: data.is_cpi && typeof data.cpi === 'string' ? data.cpi : null,
    minFeeFactor:
      data.is_cpi && typeof data.min_fee_factor === 'number' && data.min_fee_factor > 0
        ? data.min_fee_factor
        : null,
    maxCapFactor:
      data.is_cpi && typeof data.max_cap_factor === 'number' && data.max_cap_factor > 0
        ? data.max_cap_factor
        : null,
    actionsThreshold:
      data.is_cpi && typeof data.actions_threshold === 'number' ? data.actions_threshold : 0,
    offerParams: {
      ...data,
    },
  };

  const offersWithPricing = youtubeChannels.map(channelToPricedOffer(offerRules));

  // Conditional offers don't support CPI currently
  const conditionalOffers = data.is_cpi
    ? []
    : outOfNetworkYoutubeChannels.map(
        channelToConditionalOffer({ ...offerRules, cpmRules: cpmRulesWithDimension })
      );

  if (data.batch_name) {
    for (const co of conditionalOffers) {
      if (!co.meta) {
        co.meta = {};
      }
      co.meta.batch_name = data.batch_name;
    }
  }

  let pricedOffers = offersWithPricing
    .map(({ offer }) => offer)
    .filter((offer): offer is OfferCreateRequest => !!offer);

  if (data.sum_limit) {
    pricedOffers = limitOffersToSum(data.sum_limit, pricedOffers);
  }

  if (data.batch_name) {
    for (const o of pricedOffers) {
      if (!o.meta) {
        o.meta = {};
      }
      o.meta.batch_name = data.batch_name;
    }
  }

  const prices = offersWithPricing.reduce<Price>(
    (acc, o) => ({ ...acc, [o.pricing.channelId]: o.pricing }),
    {}
  );

  const offerCommissionRates = offersWithPricing.reduce<Record<string, number | null>>(
    (acc, o) => ({ ...acc, [o.pricing.channelId]: o.variableCommission }),
    {}
  );

  const channelIds: string[] = youtubeChannels
    .map((c) => c.id)
    .concat(outOfNetworkYoutubeChannels.map((c) => c.id));

  const commissionRateObject = channelIds.reduce<OffereeToCommissionRateObject>(
    (acc, id) => ({ ...acc, [id]: offerCommissionRates[id] || batchCommissionRate }),
    {}
  );

  const preparedOffers: PreparedOffers = {
    commissionRates: commissionRateObject,
    prices,
    offers: pricedOffers,
    conditionalOffers,
    failedToParse,
    detailsPerChannel,
  };
  return preparedOffers;
};

const AsyncCollectionSelector = ({
  children,
}: {
  children: (collections: Collection[]) => JSX.Element | JSX.Element;
}) => (
  <Async promiseFn={fetchCollections}>
    <Async.Pending>
      <C.FormControl>
        <C.FormLabel htmlFor="collection">Collection</C.FormLabel>
        <C.InputGroup>
          <C.Input name="collection" disabled placeholder="Loading..." />
          <C.InputRightElement children={<C.Spinner />} />
        </C.InputGroup>
      </C.FormControl>
    </Async.Pending>
    <Async.Fulfilled>{children}</Async.Fulfilled>
    <Async.Rejected>
      {(error) => (
        <AlertBox>
          {`Failed to fetch collections${error.message ? `: [${error.message}].` : '.'}`}
        </AlertBox>
      )}
    </Async.Rejected>
  </Async>
);

const defaultRules = { required: true, validate: noWhitespaceValidation };

const isDateRangeValid = (start: string, end: string): boolean =>
  !start || !end || new Date(start) <= new Date(end);

const toSubmitData = (baseData: Record<string, any>): SubmitData => {
  // data we get from the form is kinda wonky, and not fully conforming to the
  // SubmitData shape. Specifically demographic filters are not aligned
  const data = { ...baseData };

  data.demographic_filters = (data.demographic_filters || [])
    .filter((f: any) => f.names && (f.min_threshold || f.max_threshold))
    .map((f: any) => ({
      dimension: f.dimension,
      names: f.names,
      min_threshold: f.min_threshold ? +f.min_threshold : undefined,
      max_threshold: f.max_threshold ? +f.max_threshold : undefined,
    }));

  const decodedData = SubmitDataDecoder.decode(data);
  if (!isRight(decodedData)) {
    console.error('Invalid data in the form:', PathReporter.report(decodedData));
    throw new Error(`Invalid data in the form: ${PathReporter.report(decodedData)}`);
  }
  return decodedData.right;
};

export const BulkOfferForm = ({
  setPreparedOffers,
  onFormSubmitStatus,
  onFormSubmitError,
}: {
  setPreparedOffers: React.Dispatch<React.SetStateAction<PreparedOffers>>;
  onFormSubmitStatus: React.Dispatch<React.SetStateAction<string>>;
  onFormSubmitError: React.Dispatch<React.SetStateAction<Error | undefined>>;
}) => {
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [isSettingCpmRulesFromCsv, setIsSettingCpmRulesFromCsv] = React.useState(false);
  const { isLoading, run, status, error } = useAsync({
    deferFn: onFormSubmit,
  });

  const { get: getFormDataFromUrl, set: setFormDataToUrl } = useFormStateInUrl();
  const { confirm, Dialog } = useConfirmDialog();

  React.useEffect(() => {
    onFormSubmitStatus(status);
    onFormSubmitError(error);
  }, [status, error, onFormSubmitStatus, onFormSubmitError]);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    trigger,
    watch,
  } = useForm<SubmitData>({
    mode: 'onBlur',
    defaultValues: {
      campaign_id: '',
      collection_id: '',
      excluded_collection_ids: [],
      round_prices: true,
      cpm_multiplier: 'youtube7d',
      cpm_cap: '',
      cpm_min: '',
      max_price: '',
      min_price: '100',
      demographics: [],
      demographic_filters: [],
      video_publishing_date_start: '',
      video_publishing_date_end: '',
      sum_limit: null,
      deliverable_type: 'youtube_video',
      number_of_videos: 1,
      min_days_between_deadlines: 2,
      target_campaign_ids: [],
    },
  });

  const {
    fields,
    append,
    remove,
    replace: setDemographics,
  } = useFieldArray({ control, name: 'demographics' });
  const {
    fields: fieldsDemographicFilters,
    append: appendDemographicFilter,
    remove: removeDemographicFilter,
    replace: setDemographicsFilters,
  } = useFieldArray({ control, name: 'demographic_filters' });

  React.useEffect(() => {
    // formState is a Proxy [0][1], so we need to react to it via
    // useEffect.
    //
    // Check GitHub report [2] for more details
    //
    // [0] https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy
    // [1] https://www.keithcirkel.co.uk/metaprogramming-in-es6-part-3-proxies/
    // [2] https://github.com/react-hook-form/react-hook-form/issues/3750
    setIsFormValid(isValid);
  }, [isValid]);

  const hasErrors = Object.keys(errors).length > 0;
  if (hasErrors) {
    console.error('Error log: Form has errors:', errors);
  }

  const watchVideoPublishingDateStart = watch('video_publishing_date_start');
  const watchVideoPublishingDateEnd = watch('video_publishing_date_end');
  const watchDeliverableType = watch('deliverable_type');
  const watchIsCpi = watch('is_cpi');

  const debouncedFormDataToUrlUpdate = debounce((value) => {
    setFormDataToUrl(toSubmitData(value));
  }, 1000);

  React.useEffect(() => {
    const subscription = watch(debouncedFormDataToUrlUpdate);
    return () => subscription.unsubscribe();
    // We really, really want to only run this on the first render
    // to avoid performance penalty
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // only update from URL on the initial load to not negatively
    // affect performance)
    try {
      const data = getFormDataFromUrl();
      if (data) {
        setFormData(data);
        showSuccessToast('Updated form data from URL', null);
      }
    } catch (e) {
      console.error(e, 'Failed to update form data from URL');
      showErrorToast(
        e instanceof Error ? e.message : 'Unexpected error while updating form data from url',
        null
      );
    }
    // We really, really want to only run this on the first render
    // If we'd pass formDataFromUrl as a dep, we'd end up running
    // this hook always when the form (and the url) changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFormData = (data: PartialSubmitData): void => {
    Object.entries(data).forEach(([key, value]) => {
      if (value !== undefined) {
        if (key === 'demographics') {
          setDemographics(data.demographics || []);
        } else if (key === 'demographic_filters') {
          setDemographicsFilters(data.demographic_filters || []);
        } else {
          setValue(key as keyof typeof data, value, { shouldDirty: true });
        }
      }
    });
  };

  // Sadly react-async types don't allow us to define the input in a somewhat saner way here,
  // but maybe that will change in https://github.com/async-library/react-async/pull/247.
  // So here we tell that we receive a `SubmitData[]`, whereas really calling `run(some, args, to, thisRun)`
  // would result in onFormSubmit receiving `[some, args, to, thisRun]` array.
  // ...so here to avoid confusion about how many SubmitDatas we receive, we destructure first argument.
  async function onFormSubmit([baseData]: Record<string, any>[]) {
    const data = toSubmitData(baseData);
    const preparedOffers = await prepareOffers(data);
    setPreparedOffers(preparedOffers);
  }

  return (
    <C.Box p={[0, 6]}>
      <form
        onSubmit={handleSubmit((data) => {
          run(data);
        })}>
        <C.Stack spacing={8}>
          <C.FormControl maxW="md">
            <AsyncCollectionSelector>
              {(collections: Collection[]) => {
                if (!collections) {
                  return <AlertBox>No collections found</AlertBox>;
                }
                const sortedCollections = sortBy(collections, (c) => c.id).reverse();
                return (
                  <C.Stack spacing={8}>
                    <C.FormControl isInvalid={!!errors.collection_id}>
                      <C.FormLabel>Collection</C.FormLabel>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <DropdownSelect<Collection>
                            limitItems={30}
                            inputPlaceholder="Select collection..."
                            onChange={(i) => {
                              const id = i?.id ? String(i.id) : '';
                              onChange(id);
                            }}
                            items={sortedCollections}
                            initialItem={collections.find((c) => {
                              return c.id === parseInt(value);
                            })}
                            itemToLabel={(c) => `[${c.id}] ${c.name}`}
                          />
                        )}
                        control={control}
                        rules={{
                          ...defaultRules,
                          pattern: { value: /\d+/, message: 'Collection id should be a number' },
                        }}
                        name="collection_id"
                      />
                      <C.FormErrorMessage>
                        {errors.collection_id && errors.collection_id.message}
                      </C.FormErrorMessage>
                    </C.FormControl>

                    <C.FormControl isInvalid={!!errors.collection_id}>
                      <C.FormLabel>Excluded Collections</C.FormLabel>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <DropdownSelectMulti<Collection>
                            limitItems={30}
                            inputPlaceholder="Select collections..."
                            onChange={(newValue) => {
                              if (newValue) {
                                onChange(
                                  newValue.map((v: Collection) => {
                                    return v.id + '';
                                  })
                                );
                              }
                            }}
                            items={sortedCollections}
                            initialItems={collections.filter((c) => {
                              return value.indexOf(c.id + '') > -1;
                            })}
                            itemToLabel={(c) => `[${c.id}] ${c.name}`}
                          />
                        )}
                        control={control}
                        name="excluded_collection_ids"
                      />
                      <C.FormErrorMessage>
                        {errors.excluded_collection_ids &&
                          errors.excluded_collection_ids
                            .map((e) => {
                              return e.message;
                            })
                            .join(', ')}
                      </C.FormErrorMessage>
                      <C.FormHelperText>
                        Channels that are in these collections are excluded from offers being
                        created
                      </C.FormHelperText>
                    </C.FormControl>
                  </C.Stack>
                );
              }}
            </AsyncCollectionSelector>
          </C.FormControl>
          <C.Divider />
          <C.Stack spacing={8}>
            <C.Box w={['auto', 'md']}>
              <CampaignSelector<SubmitData>
                name="campaign_id"
                errors={errors?.campaign_id}
                required={true}
                control={control}
              />
              <C.Box mt={1}>
                <CampaignWarnings campaignId={getValues('campaign_id')} />
              </C.Box>
            </C.Box>
            <C.FormControl>
              <C.FormLabel>Publishing window</C.FormLabel>
              <C.Stack direction={['column', 'row']} spacing={2} alignItems={['start']}>
                <C.FormControl isInvalid={!!errors.video_publishing_date_start} maxW="xs">
                  <C.Input
                    placeholder="YYYY-MM-DD"
                    data-testid="video_publishing_date_start"
                    type="date"
                    {...register('video_publishing_date_start', {
                      ...defaultRules,
                      validate: (value) => {
                        if (isDateRangeValid(value, watchVideoPublishingDateEnd)) {
                          clearErrors('video_publishing_date_end');
                          clearErrors('video_publishing_date_start');
                          return true;
                        } else {
                          return 'Start date should be less or same than end date';
                        }
                      },
                    })}
                  />
                  <C.FormErrorMessage>
                    {errors &&
                      errors.video_publishing_date_start &&
                      errors.video_publishing_date_start.message}
                  </C.FormErrorMessage>
                </C.FormControl>
                <C.Text pt={2} display={['none', 'block']}>
                  -
                </C.Text>
                <C.FormControl isInvalid={!!errors.video_publishing_date_end} maxW="xs">
                  <C.Input
                    placeholder="YYYY-MM-DD"
                    data-testid="video_publishing_date_end"
                    type="date"
                    {...register('video_publishing_date_end', {
                      validate: (value) => {
                        if (isDateRangeValid(watchVideoPublishingDateStart, value)) {
                          clearErrors('video_publishing_date_end');
                          clearErrors('video_publishing_date_start');
                          return true;
                        } else {
                          return 'End date should be greater or same than start date';
                        }
                      },
                    })}
                  />
                  <C.FormErrorMessage>
                    {errors &&
                      errors.video_publishing_date_end &&
                      errors.video_publishing_date_end.message}
                  </C.FormErrorMessage>
                </C.FormControl>
              </C.Stack>
              <C.FormHelperText>
                Creators can pick their publishing date from this range. If you want a single
                publishing date, set these dates to be the same.
              </C.FormHelperText>
            </C.FormControl>
            <C.FormControl>
              <C.HStack align="center">
                <Controller
                  render={({ field: { onChange, value } }) => {
                    return (
                      <C.Switch
                        isChecked={value === 'multiple_youtube_videos'}
                        onChange={(e) => {
                          onChange(e.target.checked ? 'multiple_youtube_videos' : 'youtube_video');
                        }}
                      />
                    );
                  }}
                  control={control}
                  name="deliverable_type"
                />
                <C.FormLabel>More than 1 video</C.FormLabel>
              </C.HStack>
              <C.FormHelperText>
                Creator should include the promotion in more than 1 videos
              </C.FormHelperText>
            </C.FormControl>
            {watchDeliverableType === 'multiple_youtube_videos' && (
              <>
                <C.FormControl>
                  <C.FormLabel>Number of videos</C.FormLabel>
                  <C.Input
                    type="number"
                    {...register('number_of_videos', { valueAsNumber: true })}
                  />
                </C.FormControl>
                <C.FormControl>
                  <C.FormLabel>Campaigns for resulting deals</C.FormLabel>
                  <Controller
                    render={({ field: { onChange, value } }) => {
                      return (
                        <CampaignSelectorMulti
                          initialSelectedCampaignIds={value || []}
                          onSetSelectedCampaigns={(campaigns) => {
                            onChange(
                              (campaigns || []).map((c) => {
                                return String(c.id);
                              })
                            );
                          }}
                        />
                      );
                    }}
                    control={control}
                    name="target_campaign_ids"
                  />
                  {(getValues('target_campaign_ids') || []).length !==
                    getValues('number_of_videos') && (
                    <C.Alert status="error" mt={2}>
                      <C.AlertDescription>
                        Number of campaigns and number of videos must match
                      </C.AlertDescription>
                    </C.Alert>
                  )}
                </C.FormControl>
                <C.FormControl>
                  <C.FormLabel>Minimum days between videos</C.FormLabel>
                  <C.Input
                    type="number"
                    {...register('min_days_between_deadlines', { valueAsNumber: true })}
                  />
                </C.FormControl>
              </>
            )}
            <C.FormControl>
              <C.Box w={['auto', 'md']}>
                <C.FormLabel>Expiration date</C.FormLabel>
                <C.Input
                  {...register('expires', { ...defaultRules })}
                  placeholder="YYYY-MM-DD"
                  type="date"
                />
              </C.Box>
              <C.FormHelperText>
                Date when all pending offers should be automatically revoked
              </C.FormHelperText>
            </C.FormControl>
          </C.Stack>
          <C.Divider />
          <C.Stack spacing={2}>
            {fields.length === 0 && <C.FormLabel as="legend">CPM Rules</C.FormLabel>}
            {fields.map((field, index) => {
              const error = errors.demographics && errors.demographics[index];
              return (
                <C.Stack key={field.id + '-' + index} direction={'row'} spacing={2}>
                  <C.FormControl maxW="xs">
                    {index === 0 && <C.FormLabel as="legend">Demographic</C.FormLabel>}
                    <Selector
                      {...register(`demographics.${index}.name` as const, { ...defaultRules })}
                      defaultValue="gender.female"
                      data-testid={`demographics.${index}.name`}
                      options={[
                        { groupKey: 'gender', groupLabel: 'Gender', options: genders },
                        {
                          groupKey: 'country',
                          groupLabel: 'Country',
                          options: countryCodesAndNamePairs,
                        },
                        {
                          groupKey: 'deviceType',
                          groupLabel: 'Device type',
                          options: deviceTypes,
                        },
                        { groupKey: 'ageGroup', groupLabel: 'Age group', options: ageGroups },
                        {
                          groupKey: 'operatingSystem',
                          groupLabel: 'Operating system',
                          options: operatingSystems,
                        },
                      ]}
                    />
                  </C.FormControl>
                  <C.FormControl isInvalid={!!(error && error.cpmPerPercentage)} maxW="xs">
                    {index === 0 && <C.FormLabel as="legend">CPM per %</C.FormLabel>}
                    <C.HStack spacing={[2, 4]}>
                      <CurrencyInput
                        data-testid={`demographics.${index}.cpmPerPercentage`}
                        {...register(`demographics.${index}.cpmPerPercentage` as const, {
                          ...defaultRules,
                          pattern: currencyPatternWithMultipleDecimals,
                        })}
                      />
                      {/* IconButton as sister to input in HStack here, so that button is always in line with input regardless of label or error message */}
                      <C.IconButton
                        size="sm"
                        colorScheme="gray"
                        aria-label="Remove CPM rule"
                        icon={<DeleteIcon />}
                        onClick={() => remove(index)}
                      />
                    </C.HStack>
                    <C.FormErrorMessage>
                      {error && error.cpmPerPercentage && error.cpmPerPercentage.message}
                    </C.FormErrorMessage>
                  </C.FormControl>
                </C.Stack>
              );
            })}
          </C.Stack>

          <C.HStack>
            <C.Button
              size="sm"
              onClick={() => append({ name: 'gender.female', cpmPerPercentage: '' })}>
              Add new CPM rule
            </C.Button>
            <CsvUpload<CPMRuleWithDimensionFromCsvUploadT>
              codec={CPMRuleWithDimensionFromCsvUpload}
              onData={(d) => {
                setIsSettingCpmRulesFromCsv(true);
                // Pushing the actual setValue to the end of the eventLoop, otherwise
                // React doesn't have time to update csv button state :|
                setTimeout(() => {
                  const newDemos = d.map((i) => ({
                    name: `${i.dimension}.${i.name}`,
                    cpmPerPercentage: String(i.cpm_per_percentage),
                  }));

                  setValue('demographics', newDemos, { shouldDirty: true });
                  // For some reason passing `shouldValidate: true` in the above
                  // doesn't trigger validation for every field :|
                  // So triggering it manually here.
                  trigger('demographics');
                  setIsSettingCpmRulesFromCsv(false);
                }, 0);
              }}
              size="sm"
              isLoading={isSettingCpmRulesFromCsv}
              loadingText="Setting values from csv..."
            />
            <C.Button
              size="sm"
              variant="outline"
              onClick={() => {
                confirm({
                  headerText: 'Are you sure?',
                  bodyText: `You are about to clear all CPM rules`,
                  cancelText: 'Abort mission',
                  confirmText: 'Clear rules',
                  confirmColorScheme: 'red',
                }).then(() => {
                  setDemographics([]);
                });
              }}>
              Clear all CPM Rules
            </C.Button>
          </C.HStack>
          <CpmRulesFromCsvAccordion />
          <C.FormControl>
            <C.FormLabel>CPM views multiplier</C.FormLabel>
            <Controller
              render={({ field }) => (
                <C.RadioGroup {...field}>
                  <C.Stack>
                    {/* this radio button here is mostly just for legacy reasons,
                    informing users what view forecast method is used. There used to
                    be a few options to select from. After _some_ time, this can be removed
                    */}
                    <C.Radio value="youtube7d" isDisabled>
                      YouTube 7-day median views estimate
                      <C.Tooltip label="We always use 7d forecasts">
                        <QuestionIcon ml={1} />
                      </C.Tooltip>
                    </C.Radio>
                  </C.Stack>
                </C.RadioGroup>
              )}
              control={control}
              rules={defaultRules}
              name="cpm_multiplier"
              defaultValue="youtube7d"
            />
          </C.FormControl>
          <C.FormControl isInvalid={!!errors.cpm_cap} maxW="xs">
            <C.FormLabel>CPM Cap</C.FormLabel>
            <CurrencyInput
              {...register('cpm_cap', {
                validate: noWhitespaceValidation,
                pattern: currencyPattern,
              })}
            />
            <C.FormHelperText>
              CPM used to calculate price per offer will be capped to this value
            </C.FormHelperText>
            <C.FormErrorMessage>{errors.cpm_cap && errors.cpm_cap.message}</C.FormErrorMessage>
          </C.FormControl>
          <C.FormControl>
            <C.HStack align="center">
              <Controller
                render={({ field: { onChange, value } }) => {
                  return (
                    <C.Switch
                      isChecked={!!value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                    />
                  );
                }}
                control={control}
                name="round_prices"
              />
              <C.FormLabel>Round prices</C.FormLabel>
            </C.HStack>
            <C.FormHelperText>
              Round down creators prices and slightly up the advertiser prices, increasing
              commission rate. Only applied to priced offers!
            </C.FormHelperText>
          </C.FormControl>

          <C.Divider />
          <C.Box>
            <C.Heading as="h2" size="md">
              Constant CPI
            </C.Heading>
            <C.Text color="gray.500">
              Create CPI offers. Min. fee and max. cap are based on fixed fees calculated for each
              channel.
            </C.Text>
          </C.Box>

          <C.FormControl>
            <C.HStack align="center">
              <Controller
                render={({ field: { onChange, value } }) => {
                  return (
                    <C.Switch
                      isChecked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                    />
                  );
                }}
                control={control}
                name="is_cpi"
              />
              <C.FormLabel>Send CPI offers</C.FormLabel>
            </C.HStack>
            <C.FormHelperText>
              <C.Text>If this is enabled, we will send CPI offers instead of fixed fee ones</C.Text>
              <C.HStack color="yellow.500">
                <C.Icon as={WarningTwoIcon} />
                <C.Text>CPI offers are currently not counted against budget lock</C.Text>
              </C.HStack>
            </C.FormHelperText>
          </C.FormControl>

          {watchIsCpi && (
            <>
              <C.Stack direction={['column', 'row']} spacing={[5, 2]}>
                <C.FormControl maxW="xs" isInvalid={!!errors.min_fee_factor}>
                  <C.FormLabel as="legend">Min fee factor</C.FormLabel>
                  <C.Input
                    type="number"
                    step="0.01"
                    {...register('min_fee_factor', {
                      valueAsNumber: true,
                      onBlur: () => trigger(['min_fee_factor', 'max_cap_factor']),
                      validate: (value) => {
                        if (typeof value === 'undefined' || isNaN(value)) {
                          return true;
                        }

                        if (
                          value > 0 &&
                          value < (getValues('max_cap_factor') || Number.MAX_SAFE_INTEGER)
                        ) {
                          return true;
                        } else {
                          return 'Should be smaller than max. cap factor';
                        }
                      },
                    })}
                  />
                  <C.FormErrorMessage>
                    {errors.min_fee_factor && errors.min_fee_factor.message}
                  </C.FormErrorMessage>
                  <C.FormHelperText>
                    Minimum fee part of CPI offer will be calculated by multiplying fixed fee by
                    this number
                  </C.FormHelperText>
                </C.FormControl>
                <C.FormControl maxW="xs" isInvalid={!!errors.max_cap_factor}>
                  <C.FormLabel as="legend">Max cap factor</C.FormLabel>
                  <C.Input
                    type="number"
                    step="0.01"
                    {...register('max_cap_factor', {
                      valueAsNumber: true,
                      onBlur: () => trigger(['min_fee_factor', 'max_cap_factor']),
                      validate: (value) => {
                        if (typeof value === 'undefined' || isNaN(value)) {
                          return true;
                        }

                        if (value > (getValues('min_fee_factor') || 0)) {
                          return true;
                        } else {
                          return 'Should be larger than min. fee factor';
                        }
                      },
                    })}
                  />
                  <C.FormErrorMessage>
                    {errors.max_cap_factor && errors.max_cap_factor.message}
                  </C.FormErrorMessage>
                  <C.FormHelperText>
                    CPI offer price cap will be calculated by multiplying fixed fee by this number
                  </C.FormHelperText>
                </C.FormControl>
              </C.Stack>

              <C.Stack direction={['column', 'row']} spacing={[5, 2]}>
                <C.FormControl isInvalid={!!errors.cpi} isDisabled={!watchIsCpi} maxW="xs">
                  <C.FormLabel>CPI</C.FormLabel>
                  <CurrencyInput
                    {...register('cpi', {
                      required: watchIsCpi,
                      validate: noWhitespaceValidation,
                      pattern: currencyPattern,
                    })}
                  />
                  <C.FormErrorMessage>{errors.cpi && errors.cpi.message}</C.FormErrorMessage>
                  <C.FormHelperText>Every offer will have this constant CPI</C.FormHelperText>
                </C.FormControl>
                <C.FormControl
                  isInvalid={!!errors.actions_threshold}
                  isDisabled={!watchIsCpi}
                  maxW="xs">
                  <C.FormLabel>Actions threshold</C.FormLabel>
                  <C.Input
                    type="number"
                    step="1"
                    {...register('actions_threshold', {
                      valueAsNumber: true,
                    })}
                  />
                  <C.FormHelperText>
                    How many installs should be registered before CPI part of the pricing is paid
                  </C.FormHelperText>
                  <C.FormErrorMessage>
                    {errors.actions_threshold && errors.actions_threshold.message}
                  </C.FormErrorMessage>
                </C.FormControl>
              </C.Stack>
            </>
          )}

          <C.Divider />

          <C.Heading as="h2" size="md">
            Filters
          </C.Heading>
          <C.Text mt="0!important" color="gray.500">
            Offers that don't satisfy these filter rules will be excluded from the list
          </C.Text>
          <C.Stack direction={['column', 'row']} spacing={[5, 2]}>
            <C.FormControl isInvalid={!!errors.min_price} maxW="xs">
              <C.FormLabel>Min price per offer</C.FormLabel>
              <CurrencyInput
                {...register('min_price', { ...defaultRules, pattern: currencyPattern })}
              />
              <C.FormErrorMessage>
                {errors.min_price && errors.min_price.message}
              </C.FormErrorMessage>
            </C.FormControl>
            <C.FormControl isInvalid={!!errors.max_price} maxW="xs">
              <C.FormLabel>Max price per offer</C.FormLabel>
              <CurrencyInput
                {...register('max_price', {
                  validate: noWhitespaceValidation,
                  pattern: currencyPattern,
                })}
              />
              <C.FormErrorMessage>
                {errors.max_price && errors.max_price.message}
              </C.FormErrorMessage>
            </C.FormControl>
          </C.Stack>
          <C.FormControl isInvalid={!!errors.cpm_min} maxW="xs">
            <C.FormLabel>Minimum CPM</C.FormLabel>
            <CurrencyInput
              {...register('cpm_min', {
                validate: noWhitespaceValidation,
                pattern: currencyPattern,
              })}
            />
            <C.FormErrorMessage>{errors.cpm_min && errors.cpm_min.message}</C.FormErrorMessage>
          </C.FormControl>
          <C.Stack spacing={2}>
            {fieldsDemographicFilters.length === 0 && (
              <C.FormLabel as="legend">Demographics</C.FormLabel>
            )}
            {fieldsDemographicFilters.map((filter, index) => {
              return (
                <C.Stack direction="row" spacing={2} key={filter.dimension + '-' + index}>
                  <C.FormControl>
                    {index === 0 && <C.FormLabel as="legend">Demographics</C.FormLabel>}
                    <Controller
                      render={({ field: { onChange, value } }) => {
                        const initialItems = value
                          ? (value as string[]).map((item: string) => {
                              return { id: item, label: item };
                            })
                          : [];
                        return (
                          <DropdownSelectMulti<DemographicFilterItem>
                            inputPlaceholder={demographicFilters[filter.dimension].inputPlaceholder}
                            onChange={(items) => {
                              onChange(items?.map((item: DemographicFilterItem) => item.id));
                            }}
                            items={demographicFilters[filter.dimension].items}
                            initialItems={initialItems}
                            itemToLabel={(item: DemographicFilterItem) => item.label}
                          />
                        );
                      }}
                      control={control}
                      name={`demographic_filters.${index}.names` as const}
                    />
                    {index === fieldsDemographicFilters.length - 1 && (
                      <C.FormHelperText>
                        The sum of selections per filter will be used to test against the min and/or
                        max threshold(s).
                      </C.FormHelperText>
                    )}
                  </C.FormControl>
                  <C.FormControl maxW={120}>
                    {index === 0 && <C.FormLabel as="legend">Min threshold</C.FormLabel>}
                    <C.InputGroup>
                      <C.NumberInput step={5} min={0} max={100}>
                        <C.NumberInputField
                          {...register(`demographic_filters.${index}.min_threshold` as const)}
                        />
                      </C.NumberInput>
                      <C.InputRightElement children="%" />
                    </C.InputGroup>
                  </C.FormControl>
                  <C.FormControl maxW={160}>
                    {index === 0 && <C.FormLabel as="legend">Max threshold</C.FormLabel>}
                    <C.HStack spacing={2}>
                      <C.InputGroup>
                        <C.NumberInput step={5} min={0} max={100}>
                          <C.NumberInputField
                            {...register(`demographic_filters.${index}.max_threshold` as const)}
                          />
                        </C.NumberInput>
                        <C.InputRightElement children="%" />
                      </C.InputGroup>
                      {/* IconButton as sister to input group in HStack here, so that button is always in line with input regardless of label */}
                      <C.IconButton
                        size="sm"
                        aria-label="Remove demographic filter"
                        icon={<DeleteIcon />}
                        onClick={() => removeDemographicFilter(index)}
                      />
                    </C.HStack>
                  </C.FormControl>
                </C.Stack>
              );
            })}
            <C.Box>
              <C.Menu>
                <C.MenuButton as={C.Button} size="sm" rightIcon={<ChevronDownIcon />}>
                  Add demographic filter
                </C.MenuButton>
                <C.MenuList zIndex="dropdown">
                  <C.MenuItem onClick={() => appendDemographicFilter({ dimension: 'gender' })}>
                    Gender
                  </C.MenuItem>
                  <C.MenuItem onClick={() => appendDemographicFilter({ dimension: 'country' })}>
                    Country
                  </C.MenuItem>
                  <C.MenuItem onClick={() => appendDemographicFilter({ dimension: 'ageGroup' })}>
                    Age group
                  </C.MenuItem>
                  <C.MenuItem
                    onClick={() => appendDemographicFilter({ dimension: 'operatingSystem' })}>
                    Operating system
                  </C.MenuItem>
                  <C.MenuItem onClick={() => appendDemographicFilter({ dimension: 'deviceType' })}>
                    Device type
                  </C.MenuItem>
                </C.MenuList>
              </C.Menu>
            </C.Box>
          </C.Stack>
          <C.FormControl maxW="xs" isInvalid={!!errors.sum_limit}>
            <C.FormLabel as="legend">Limit total priced offers sum</C.FormLabel>
            <CurrencyInput
              {...register('sum_limit', {
                pattern: currencyPattern,
              })}
              placeholder="Leave blank to not limit"
            />
            <C.FormErrorMessage>{errors.sum_limit && errors.sum_limit.message}</C.FormErrorMessage>
            <C.FormHelperText>
              A random selection of priced offers will be chosen if the sum of priced offers is
              higher than this limit.
            </C.FormHelperText>
          </C.FormControl>
          <C.FormControl maxW="s" isInvalid={!!errors.batch_commission_rate}>
            <C.FormLabel as="legend">Batch commission rate</C.FormLabel>
            <C.Input
              type="number"
              step="0.01"
              {...register('batch_commission_rate', {
                valueAsNumber: true,
                validate: (value: number | null) => {
                  return value === null || isNaN(value) || (value > 0 && value < 1);
                },
              })}
              placeholder="Leave blank to default to campaign commission rate"
            />
            <C.FormErrorMessage>
              {errors.batch_commission_rate && errors.batch_commission_rate.message}
            </C.FormErrorMessage>
            <C.FormHelperText>
              A manually set commission rate for offers and conditional offers in this batch. Must
              be a decimal number between 0 and 1, for example 0.35. This number will override the
              campaign default commission rate for all offers and conditional offers in this batch.
              Only use this if you know what you are doing.
            </C.FormHelperText>
          </C.FormControl>
          <C.FormControl maxW="s">
            <C.FormLabel as="legend">Offer batch name</C.FormLabel>
            <C.Input
              {...register('batch_name')}
              placeholder="Leave blank to not have a batch name"
            />
            <C.FormHelperText>
              You can specify a batch name for offers sent. It can be used to do identify offer
              batches and do analytics on performance of different kinds of bulk offer conditions.
            </C.FormHelperText>
          </C.FormControl>
          <C.Box>
            <C.Button
              colorScheme="cyan"
              type="submit"
              disabled={!isFormValid || isLoading}
              isLoading={isLoading}
              loadingText="Calculating previews...">
              Preview offers
            </C.Button>
          </C.Box>
        </C.Stack>
      </form>
      <Dialog />
    </C.Box>
  );
};
