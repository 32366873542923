import * as C from '@chakra-ui/react';
import { AgreementStatus, cancelSettledDeal } from '../../api-clients/imt-api-agreements';
import { ModalBase } from '@sharkpunch/idun';
import { useAsyncCallback } from 'react-async-hook';
import { showErrorToast } from '../../helpers';
import AlertBox from '../alert-box/AlertBox';

const CancelDealModal = ({
  agreementId,
  isOpen,
  onClose,
  onNewStatus,
}: {
  agreementId: number;
  isOpen: boolean;
  onClose: () => void;
  onNewStatus: (status: AgreementStatus) => void;
}) => {
  const doCancelDeal = useAsyncCallback(async () => cancelSettledDeal(agreementId), {
    onSuccess: (result) => {
      onNewStatus(result.status);
      onClose();
    },
    onError: (error) => {
      if (error.message) {
        showErrorToast('Error: Failed to cancel deal', error.message);
      }
    },
  });

  return (
    <ModalBase header="Cancel settled deal" onClose={onClose} isOpen={isOpen}>
      <C.Stack spacing="var(--space-md)">
        <C.Text>
          All parties involved in this deal won't get notified, so make sure they're aware. Unless
          there is a good reason, canceling a settled deal should be your last option.
        </C.Text>
        <AlertBox>
          This is something that's very hard to reverse, so please make sure that this is the only
          possible solution, and that you know what you're doing.
        </AlertBox>
        <C.HStack justifyContent="flex-end">
          <C.Button onClick={onClose}>Abort</C.Button>
          <C.Button
            onClick={() => doCancelDeal.execute()}
            isLoading={doCancelDeal.loading}
            colorScheme="cyan">
            Cancel deal
          </C.Button>
        </C.HStack>
      </C.Stack>
    </ModalBase>
  );
};

export default CancelDealModal;
