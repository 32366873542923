import * as C from '@chakra-ui/react';
import { contentSubmissionStatuses } from '../content-submission/ContentSubmissionStatus';
import { alterValueInFilters, Filters } from '../../helpers';
import DateRangeFilter from './DateRangeFilter';

const status = 'content_submission.status';
const deadline = 'content_submission.deadline';

const statusesArray = Object.entries(contentSubmissionStatuses);

function ContentReviewFilters({
  filters,
  onSetFilters,
}: {
  filters: Filters[];
  onSetFilters: (filters: Filters[]) => void;
}) {
  const cstFilter = filters.find((f) => f.id === status)?.value || [];
  const deadlineF = filters.find((f) => f.id === deadline)?.value[0],
    deadlineStartF = filters.find((f) => f.id === `${deadline}[0]`)?.value[0],
    deadlineEndF = filters.find((f) => f.id === `${deadline}[1]`)?.value[0];

  const activeFiltersCount =
    cstFilter?.length || 0 + (deadlineF || deadlineStartF || deadlineEndF ? 1 : 0);

  return (
    <C.Menu closeOnSelect={false}>
      <C.MenuButton
        as={C.Button}
        size="sm"
        rightIcon={
          activeFiltersCount ? (
            <C.Tag size="sm" borderRadius="full" variant="solid" colorScheme="cyan">
              {activeFiltersCount}
            </C.Tag>
          ) : (
            <></>
          )
        }>
        Content review
      </C.MenuButton>
      <C.MenuList>
        <C.MenuOptionGroup
          title="Status is one of"
          value={cstFilter}
          type="checkbox"
          onChange={(selectedValues) => {
            const nextFilters = alterValueInFilters(filters, status, [...selectedValues]);
            onSetFilters(nextFilters);
          }}>
          {statusesArray.map(([key, label]) => (
            <C.MenuItemOption value={key} key={key}>
              {label}
            </C.MenuItemOption>
          ))}
        </C.MenuOptionGroup>

        <C.MenuDivider />
        <C.MenuGroup title="Draft deadline is">
          <C.Box px="var(--space-sm)">
            <DateRangeFilter filterId={deadline} filters={filters} onSetFilters={onSetFilters} />
          </C.Box>
        </C.MenuGroup>
      </C.MenuList>
    </C.Menu>
  );
}

export default ContentReviewFilters;
