import * as C from '@chakra-ui/react';
import { CalendarIcon, ArrowUpIcon, ArrowDownIcon } from '@chakra-ui/icons';
import { useSearchParams } from 'react-router-dom';

import { SortBy, sortByToOrder, orderToSortBy } from '../../helpers';

function orderToIcon([order]: SortBy[]) {
  if (!order) {
    return null;
  }

  switch (true) {
    case order.id === 'deal.deadline': {
      return (
        <>
          <CalendarIcon /> {order.isSortedDesc ? <ArrowDownIcon /> : <ArrowUpIcon />}
        </>
      );
    }
  }
}

function SortByButton() {
  const [searchParams, setSearchParams] = useSearchParams();

  const order = orderToSortBy(searchParams.get('order') || '');

  return (
    <C.Menu>
      <C.MenuButton as={C.Button} size="sm">
        <C.HStack>
          <C.Text>Sort by</C.Text> {order && orderToIcon(order)}
        </C.HStack>
      </C.MenuButton>
      <C.MenuList>
        <C.MenuOptionGroup
          value={
            !order.length
              ? 'default'
              : order[0]?.isSortedDesc
              ? 'deal.deadline.desc'
              : 'deal.deadline.asc'
          }
          type="radio">
          <C.MenuItemOption
            value="default"
            onClick={() => {
              const currentSearchParams = Object.fromEntries(searchParams.entries());

              delete currentSearchParams['order'];
              setSearchParams(currentSearchParams);
            }}>
            Newest message (default)
          </C.MenuItemOption>
          <C.MenuDivider />
          <C.MenuItem isDisabled opacity="1!important" fontWeight="bold" fontSize="sm">
            Publishing date
          </C.MenuItem>
          <C.MenuItemOption
            value="deal.deadline.desc"
            onClick={() => {
              searchParams.set(
                'order',
                sortByToOrder([{ id: 'deal.deadline', isSortedDesc: true }]).join(',')
              );
              setSearchParams(searchParams);
            }}>
            Newest first
          </C.MenuItemOption>
          <C.MenuItemOption
            value="deal.deadline.asc"
            onClick={() => {
              searchParams.set(
                'order',
                sortByToOrder([{ id: 'deal.deadline', isSortedDesc: false }]).join(',')
              );
              setSearchParams(searchParams);
            }}>
            Oldest first
          </C.MenuItemOption>
        </C.MenuOptionGroup>
      </C.MenuList>
    </C.Menu>
  );
}

export default SortByButton;
