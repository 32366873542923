import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as Sentry from '@sentry/react';

import { SENTRY_DSN } from './config';
import { theme } from '@sharkpunch/idun';
import { ColorModeScript } from '@chakra-ui/color-mode';
import { ExtraErrorData } from '@sentry/integrations';
import { extendTheme } from '@chakra-ui/react';

const extendedTheme = extendTheme(theme);

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new ExtraErrorData({ depth: 9 })],
  normalizeDepth: 10,
});

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

// temporarily not useing StrictMode
// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-strict-mode
root.render(
  <React.Fragment>
    <ColorModeScript initialColorMode={extendedTheme.config.initialColorMode} />
    <App />
  </React.Fragment>
);
