import * as C from '@chakra-ui/react';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  SearchIcon,
} from '@chakra-ui/icons';
import { UsePaginationInstanceProps, UsePaginationState } from 'react-table';

interface TablePaginatorType<D extends object = {}>
  extends C.HTMLChakraProps<'div'>,
    UsePaginationState<D>,
    Omit<UsePaginationInstanceProps<D>, 'page'> {}

const TablePaginator = (props: TablePaginatorType) => {
  const {
    pageIndex,
    pageSize,
    pageCount,
    pageOptions,
    canNextPage,
    canPreviousPage,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    ...styles
  } = props;

  return (
    <C.HStack spacing={[2, 4]} alignItems="flex-end" {...styles}>
      <C.IconButton
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
        aria-label="First page"
        size="sm"
        colorScheme="gray"
        icon={<ArrowLeftIcon />}
      />
      <C.IconButton
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
        aria-label="Previous page"
        size="sm"
        colorScheme="gray"
        icon={<ChevronLeftIcon />}
      />
      <span>
        Page{' '}
        <strong>
          {pageIndex + 1} of {pageOptions.length}
        </strong>
      </span>
      <C.IconButton
        onClick={() => nextPage()}
        disabled={!canNextPage}
        aria-label="Next page"
        size="sm"
        colorScheme="gray"
        icon={<ChevronRightIcon />}
      />
      <C.IconButton
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
        aria-label="Last page"
        size="sm"
        colorScheme="gray"
        icon={<ArrowRightIcon />}
      />
      <C.InputGroup width="20" size="sm">
        <C.Input
          placeholder="Go to page"
          defaultValue={pageIndex + 1}
          type="number"
          onBlur={(e) => {
            const target = e.target as HTMLInputElement;
            const page = target.value ? Number(target.value) - 1 : 0;
            gotoPage(page);
          }}
          colorScheme="cyan"
        />
        <C.InputRightElement children={<SearchIcon />} />
      </C.InputGroup>
      <C.Select
        width={100}
        size="sm"
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}>
        {[25, 50, 100].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </C.Select>
    </C.HStack>
  );
};

export default TablePaginator;
