import * as C from '@chakra-ui/react';
import { useAsync } from 'react-async-hook';
import AlertBox from '../alert-box/AlertBox';

import { CampaignFromSingleEndpoint, fetchCampaign } from '../../api-clients/imt-api-campaigns';

const getCampaignById = async (id: string) => {
  return id ? fetchCampaign(id) : null;
};

const getWarnings = (
  campaign: CampaignFromSingleEndpoint
): { label: string; description: string }[] => {
  const ret: { label: string; description: string }[] = [];
  if (campaign.attributionPartner === 'none') {
    ret.push({
      label: 'No attribution',
      description:
        'Most campaigns should have attribution configured. This may or may not be a problem.',
    });
  }
  if (!campaign.visible) {
    ret.push({
      label: 'Campaign is hidden',
      description: "Creators won't be able to access offers send for this campaign!",
    });
  }
  return ret;
};

const CampaignWarnings = (props: { campaignId: string }) => {
  const { result, error, loading } = useAsync(getCampaignById, [props.campaignId]);
  if (!result && !error && !loading) {
    return <></>;
  } else if (loading) {
    return <C.Spinner size="sm" />;
  } else if (error) {
    return <AlertBox>{error.message}</AlertBox>;
  } else if (result) {
    const warnings = getWarnings(result);
    if (warnings.length) {
      return (
        <C.Wrap>
          {warnings.map((w) => (
            <C.Tooltip key={w.label} label={w.description}>
              <C.Tag colorScheme="red">{w.label}</C.Tag>
            </C.Tooltip>
          ))}
        </C.Wrap>
      );
    } else {
      return <></>;
    }
  } else {
    return (
      <AlertBox status="warning">
        Something unexpected happened when checking campaign configuration
      </AlertBox>
    );
  }
};

export default CampaignWarnings;
