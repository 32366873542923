import * as Sentry from '@sentry/react';
import * as t from 'io-ts';
import { isRight } from 'fp-ts/Either';
import { imtApi } from './imt-api';

const TeamDecoder = t.exact(
  t.type({
    name: t.string,
    id: t.number,
  }),
  'Team'
);

export type Team = t.TypeOf<typeof TeamDecoder>;

export async function fetchTeams(): Promise<Team[]> {
  const url = `/teams`;
  const res = await imtApi.get(url);
  const resData = res.data.data;

  if (resData === null || !Array.isArray(resData)) {
    console.error('Error log: IMT API response was not an array of objects', resData);
    throw new Error('Failed to parse teams from IMT API response!');
  }

  const teams = resData.map((t) => {
    const ret = TeamDecoder.decode(t);
    if (!isRight(ret)) {
      console.error('Error log: Invalid team in response object:', ret);

      const err = new Error('Invalid team in list of teams');
      Sentry.captureException(err, { extra: t });
      throw err;
    }
    return ret.right;
  });
  return teams;
}
