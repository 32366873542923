import React from 'react';
import _ from 'lodash';
import type { AxiosError } from 'axios';
import {
  ConditionalOfferCreateRequest,
  OfferCreateRequest,
  OffereeToCommissionRateObject,
  SendConditionalOfferPayload,
  SendOfferPayload,
} from './helpers';

import { sendConditionalOffer } from './api-clients/imt-api-conditional-offers';
import { sendOffer } from './api-clients/imt-api-campaigns';
import { v4 as uuid } from 'uuid';

function getReason(e: AxiosError) {
  const requestId = e.response?.headers['request-id'] || null;
  if (e.response?.status === 404) {
    return 'The creator or the campaign was not found.';
  }
  if (e.response?.status === 409) {
    return 'An active offer already seems to exist for this campaign id and this channel.';
  }
  return `Could not create offer. [${
    e.message || 'Unspecified reason'
  }] Request ID: [${requestId}]`;
}

export type FailedOffer = {
  channelId: string;
  reason: string;
};

export function useSendOffers() {
  const [succeeded, setSucceeded] = React.useState<number>(0);
  const [failed, setFailed] = React.useState<FailedOffer[]>([]);
  const [totalOffersToSendCount, setTotalOffersToSendCount] = React.useState<number>(0);

  async function triggerSendOffers(
    offers: OfferCreateRequest[],
    conditionalOffers: ConditionalOfferCreateRequest[] = [],
    commissionRates: OffereeToCommissionRateObject
  ) {
    setFailed([]);
    setSucceeded(0);
    let succeededCount = 0;
    setTotalOffersToSendCount(offers.length + conditionalOffers.length);

    // Attaching all offers a batch identifier into `meta`.
    // This can be used to easily do analytics on offer groups.
    // For example splitting a campaign into different offer batches
    // to see retrospectively how e.g. different pricing models
    // performed in terms of allocation speed and efficiency
    const batchIdentifier = uuid();

    offers.forEach((offer) => {
      const offerPayload: SendOfferPayload = { offer, commission_rate: null };
      const variableCommissionRate = commissionRates[offer.offeree.platform_id];
      if (variableCommissionRate) {
        offerPayload.commission_rate = variableCommissionRate;
      }

      if (!offer.meta) {
        offer.meta = { batch_id: batchIdentifier };
      } else {
        offer.meta.batch_id = batchIdentifier;
      }

      sendOffer(offerPayload)
        .then(() => {
          succeededCount = succeededCount + 1;
        })
        .catch((e: AxiosError) => {
          const reason = getReason(e);
          setFailed((old) => [...old, { channelId: offer.offeree.platform_id, reason }]);
        })
        .finally(() => {
          // in finally so counting insn't interrupted by hook due to forEach
          setSucceeded(succeededCount);
        });
    });

    const handleConditionalOffer = async (conditionalOffer: ConditionalOfferCreateRequest) => {
      const conditionalOfferPayload: SendConditionalOfferPayload = {
        conditional_offer: conditionalOffer,
        commission_rate: null,
      };
      const variableCommissionRate = commissionRates[conditionalOffer.offeree.platform_id];
      if (variableCommissionRate) {
        conditionalOfferPayload.commission_rate = variableCommissionRate;
      }

      if (!conditionalOffer.meta) {
        conditionalOffer.meta = { batch_id: batchIdentifier };
      } else {
        conditionalOffer.meta.batch_id = batchIdentifier;
      }

      return sendConditionalOffer(conditionalOfferPayload)
        .then(() => {
          succeededCount = succeededCount + 1;
        })
        .catch((e: AxiosError) => {
          const reason = getReason(e);
          setFailed((old) => [...old, { channelId: conditionalOffer.offeree.platform_id, reason }]);
        })
        .finally(() => {
          // in finally so counting insn't interrupted by hook due to forEach
          setSucceeded(succeededCount);
        });
    };

    const batchSize = 5;
    const batches = _.chunk(conditionalOffers, batchSize);

    const sendConditionalOffersInBatches = async () => {
      for (const batch of batches) {
        await Promise.all(batch.map(handleConditionalOffer));
      }
    };

    sendConditionalOffersInBatches();
  }

  return {
    succeeded,
    failed,
    totalOffersToSendCount,
    triggerSendOffers,
  };
}
