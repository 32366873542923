import React from 'react';
import * as C from '@chakra-ui/react';
import AlertBox from '../alert-box/AlertBox';
import { Card, CardBody, EmptyState } from '@sharkpunch/idun';
import { useParams, useSearchParams } from 'react-router-dom';
import { useUserState } from '../../stores/UserStore';
import { fetchDeals } from '../../api-clients/imt-api-deals';
import {
  filtersToPostgrestFormat,
  postgrestFormatToFilters,
  orderToSortBy,
  isOutOfRangeError,
  Filters as FiltersT,
} from '../../helpers';
import DealComponent from './Deal';
import DealsList from './DealsList';
import Filters from './Filters';
import { useAsync } from 'react-async-hook';

const DealsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  function onSetFilters(filters: FiltersT[]) {
    if (!filters.length) {
      // This is a reset action
      setSearchParams({});
      return;
    }

    const nextFilters = filtersToPostgrestFormat(filters);
    const s = new URLSearchParams(searchParams);

    // Add / update everything that's set by new filters
    for (const [id, value] of Object.entries(nextFilters)) {
      s.set(id, value);
    }

    // If anything was removed by filtersToPostgrestFormat --
    // also remove it from search url params
    for (const { id } of filters) {
      if (!nextFilters[id]) {
        s.delete(id);
      }
    }

    // Don't set any search params if the result is what
    // we already have in url -- otherwise we would have
    // duplicate entries in browser history.
    // This handler _might_ end up being called multiple
    // times because this can be passed to inputs /
    // dropdowns or selectors, which then trigger
    // `onChange` every time they receive new value
    searchParams.sort();
    s.sort();
    if (searchParams.toString() !== s.toString()) {
      setSearchParams(s);
    }
  }

  const dealsOffset = parseInt(searchParams.get('offset') || '0');
  const offsetStepSize = parseInt(searchParams.get('limit') || '50');

  const { id: dealId } = useParams();

  const {
    execute,
    result: deals,
    error,
    loading,
  } = useAsync(
    async (offset: number, limit: number, search: URLSearchParams) =>
      fetchDeals({
        offset: offset,
        limit: limit,
        filters: filtersToPostgrestFormat(postgrestFormatToFilters(search)),
        order: orderToSortBy(search.get('order') || ''),
      }),
    [dealsOffset, offsetStepSize, searchParams],
    {
      // Don't reset results when we fetch new ones
      setLoading: (state) => ({ ...state, loading: true }),
      // Reset offset back to 0 if we paged away too hard
      onError: (e) => {
        if (isOutOfRangeError(e)) {
          onSetFilters([{ id: 'offset', value: ['0'], customPrefix: '' }]);
        }
      },
    }
  );

  // The only semi-legal reason to use useEffect -- we want to setup an
  // interval to refresh deals list every 30 seconds
  React.useEffect(() => {
    const messagesInterval = setInterval(() => {
      if (!loading) {
        execute(dealsOffset, offsetStepSize, searchParams);
      }
    }, 30000);
    return () => clearInterval(messagesInterval);
  }, [execute, dealsOffset, offsetStepSize, searchParams, loading]);

  const { user: admin } = useUserState();

  function getPrevDeals() {
    const nextOffset = dealsOffset < offsetStepSize ? 0 : dealsOffset - offsetStepSize;
    onSetFilters([{ id: 'offset', value: [nextOffset.toString()], customPrefix: '' }]);
  }

  function getNextDeals() {
    onSetFilters([
      { id: 'offset', value: [(dealsOffset + offsetStepSize).toString()], customPrefix: '' },
    ]);
  }

  let content;

  if (!isOutOfRangeError(error) && error?.message) {
    content = <AlertBox>{`Failed fetching deals: [${error.message}]`}</AlertBox>;
  } else if (deals && admin) {
    content = (
      <DealsList
        deals={deals}
        getPrevDeals={getPrevDeals}
        getNextDeals={getNextDeals}
        dealsOffset={dealsOffset}
        offsetStepSize={offsetStepSize}
        loadingDeals={loading}
      />
    );
  } else if (loading) {
    content = <C.Divider />;
  } else if (!deals?.length) {
    content = (
      <CardBody>
        <EmptyState title="No deals found" description="Seems like no one created any yet." />
      </CardBody>
    );
  } else {
    content = (
      <CardBody>
        <EmptyState title="Something unexpected went wrong" />
      </CardBody>
    );
  }

  return (
    <C.Container maxW="85rem" h="calc(100vh - 4rem)">
      {/* container has full height minus top menu */}
      <C.Grid
        templateColumns="repeat(12, 1fr)"
        gap="var(--space-md)"
        h="100%"
        py="var(--page-top-spacing)">
        <C.GridItem colSpan={dealId ? { base: 5, xl: 4 } : 12} h="100%" overflow="hidden">
          <Card height="100%" overflow="hidden" display="flex" flexDirection="column">
            <C.Box pb="var(--space-sm)">
              <Filters
                isLoading={loading}
                filters={postgrestFormatToFilters(searchParams)}
                onSetFilters={onSetFilters}
              />
            </C.Box>
            {content}
          </Card>
        </C.GridItem>
        {dealId ? (
          <C.GridItem colSpan={{ base: 7, xl: 8 }} h="100%" overflow="hidden">
            <DealComponent />
          </C.GridItem>
        ) : null}
      </C.Grid>
    </C.Container>
  );
};

export default DealsPage;
