import * as t from 'io-ts';
import type { AxiosResponse } from 'axios';
import chunk from 'lodash/chunk';
import { isRight } from 'fp-ts/Either';
import { PathReporter } from 'io-ts/PathReporter';
import { PromiseFn } from 'react-async';
import { imtApi } from './imt-api';
import { ContentSubmissionParticipantDecoder, DisplayDataDecoder } from '../helpers';

// API docs: https://content-submission-api.matchmade.tv/docs
const ContentSubmissionStatusDecoder = t.keyof(
  {
    pending: null,
    request_for_changes: null,
    submitted: null,
    approved: null,
    canceled: null,
  },
  'ContentSubmissionTaskStatus'
);

const UserInputDecoder = t.type(
  {
    description: t.string,
    is_required: t.boolean,
    user_input_type: t.string,
    boolean_value: t.union([t.boolean, t.null]),
  },
  'User Input'
);

const ContentSubmissionDecoder = t.intersection(
  [
    t.type({
      id: t.string,
      created: t.string,
      updated: t.string,
      content_format: t.string,
    }),
    t.partial({
      // this is what currently API returns, and it's based on `content_format`
      url: t.union([t.string, t.null]),
      submitted: t.union([t.string, t.null]),
    }),
  ],
  'Content Submission'
);

const ContentSubmissionTaskDecoder = t.type(
  {
    id: t.string,
    created: t.string,
    updated: t.string,
    expected_content_submitter: ContentSubmissionParticipantDecoder,
    user_inputs: t.array(UserInputDecoder),
    content_submission: ContentSubmissionDecoder,
    status: ContentSubmissionStatusDecoder,
    reviewed_by: t.union([ContentSubmissionParticipantDecoder, t.null]),
    status_comment: t.union([t.string, t.null]),
    cover_image_url: t.string,
    briefing: t.string,
    meta: t.union([t.null, t.UnknownRecord]),
    deadline: t.union([t.null, t.string]),
  },
  'Content Submission Task'
);

export type ContentSubmissionTask = t.TypeOf<typeof ContentSubmissionTaskDecoder>;

export const getContentSubmissionTask: PromiseFn<ContentSubmissionTask> = async ({ id }) => {
  const res = await imtApi.get<void, { data: { data: ContentSubmissionTask } }>(
    `/admin/content-submission-tasks/v1/content-submission-task/${id}`
  );
  const decoded = ContentSubmissionTaskDecoder.decode(res.data.data);

  if (!isRight(decoded)) {
    console.error('Error log: Invalid content submission task:', PathReporter.report(decoded));
    throw new Error('Fetched invalid content submission task');
  }

  return res.data.data;
};

export const getContentSubmissionTaskNew = async (contentSubmissionTaskId: string) => {
  const res = await imtApi.get<void, { data: { data: ContentSubmissionTask } }>(
    `/admin/content-submission-tasks/v1/content-submission-task/${contentSubmissionTaskId}`
  );
  const decoded = ContentSubmissionTaskDecoder.decode(res.data.data);

  if (!isRight(decoded)) {
    console.error('Error log: Invalid content submission task:', PathReporter.report(decoded));
    throw new Error('Fetched invalid content submission task');
  }

  return res.data.data;
};

const getContentSubmissionTasks = async (params: URLSearchParams) => {
  const res = await imtApi.get<void, AxiosResponse<{ data: ContentSubmissionTask[] }>>(
    `/admin/content-submission-tasks/v1/content-submission-task`,
    { params }
  );
  const decoded = t.array(ContentSubmissionTaskDecoder).decode(res.data.data);

  if (!isRight(decoded)) {
    console.error('Error log: Invalid content submission task:', PathReporter.report(decoded));
    throw new Error('Fetched invalid content submission task');
  }

  return { data: res.data.data, total: parseInt(res.headers['content-range'].split('/')[1]) };
};

export const approveContentSubmissionTask = async ({
  id,
  admin_id,
  status_comment,
}: {
  id: string;
  admin_id: number;
  status_comment: string | null;
}): Promise<ContentSubmissionTask> => {
  const res = await imtApi.put(
    `/admin/content-submission-tasks/v1/content-submission-task/${id}/approve`,
    {
      reviewed_by: { platform: 'matchmade', platform_id: String(admin_id) },
      status_comment: status_comment,
    }
  );
  const decoded = ContentSubmissionTaskDecoder.decode(res.data.data);
  if (isRight(decoded)) {
    return decoded.right;
  }

  console.error('Error log: Invalid content submission task:', PathReporter.report(decoded));
  throw new Error('Invalid content submission task returned after approving');
};

export const requestChangesForContentSubmissionTask = async ({
  id,
  status_comment,
  admin_id,
}: {
  id: string;
  status_comment: string;
  admin_id: number;
}) => {
  const res = await imtApi.put(
    `/admin/content-submission-tasks/v1/content-submission-task/${id}/request-for-changes`,
    { status_comment, reviewed_by: { platform: 'matchmade', platform_id: String(admin_id) } }
  );

  const decoded = ContentSubmissionTaskDecoder.decode(res.data.data);
  if (isRight(decoded)) {
    return decoded.right;
  }

  console.error('Error log: Invalid content submission task:', PathReporter.report(decoded));
  throw new Error('Invalid content submission task returned after approving');
};

export const getDisplayDataForTask = async (task: ContentSubmissionTask) => {
  const { platform: submitterPlatform, platform_id: submitterPlatformId } =
    task.expected_content_submitter;
  try {
    const contentSubmitterData = await imtApi.get(
      `/accounts/${submitterPlatform}/${submitterPlatformId}`
    );

    if (contentSubmitterData.data.data) {
      const decoded = DisplayDataDecoder.decode(contentSubmitterData.data.data);
      if (!isRight(decoded)) {
        console.error(
          `Error log: Invalid account of type [${submitterPlatform}] with id [${submitterPlatformId}]`,
          PathReporter.report(decoded)
        );
      } else {
        task.expected_content_submitter.display_data = decoded.right;
      }
    }
  } catch (e) {
    task.expected_content_submitter.display_data = {
      avatarUrl: '',
      name: 'Channel not found',
    };
    console.error('Error log: Failed to get channel account', e);
  }

  if (!task.reviewed_by) {
    return;
  }

  const { platform: reviewerPlatform, platform_id: reviewerPlatformId } = task.reviewed_by;
  try {
    const reviewerData = await imtApi.get(`/accounts/${reviewerPlatform}/${reviewerPlatformId}`);

    if (reviewerData.data.data) {
      const decodedReviewerData = DisplayDataDecoder.decode(reviewerData.data.data);
      if (!isRight(decodedReviewerData)) {
        console.error(
          `Error log: Invalid reviewer [${reviewerPlatform}] with id [${reviewerPlatformId}]`,
          PathReporter.report(decodedReviewerData)
        );
      } else {
        task.reviewed_by.display_data = decodedReviewerData.right;
      }
    }
  } catch (e) {
    task.reviewed_by.display_data = {
      avatarUrl: '',
      name: 'Admin not found',
    };
    console.error('Error log: Failed to get admin account', e);
  }
  return task;
};

export const getContentSubmissionTasksWithDisplayData: PromiseFn<{
  data: ContentSubmissionTask[];
  total: number;
}> = async (params) => {
  const { data: tasks, total } = await getContentSubmissionTasks(params.searchParams);

  const batches = chunk(tasks, 5);

  for (const batch of batches) {
    await Promise.all(batch.map(async (r) => getDisplayDataForTask(r)));
  }

  return { data: tasks, total };
};
