import React from 'react';
import * as C from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { useAsyncCallback } from 'react-async-hook';
import { setDealTags } from '../../api-clients/imt-api-agreements';
import { showErrorToast } from '../../helpers';
import DealTagsSelect from './DealTagsSelect';
import { isEqual } from 'lodash';

const AddDealTagPopover = ({
  onClose,
  setNewTags,
  isOpen,
  dealTags,
  agreementId,
  children,
}: {
  onClose: () => void;
  setNewTags: (tags: string[]) => void;
  isOpen: boolean;
  dealTags: string[];
  agreementId: number;
  children: React.ReactNode;
}) => {
  const [updatedTags, setUpdatedTags] = React.useState(dealTags);

  const addTag = useAsyncCallback(async (tags) => setDealTags(tags, agreementId), {
    onSuccess: (result) => {
      // send the API response with new tags back to parent component
      setNewTags(result.dealTags);
    },
    onError: (error) => {
      if (error.message) {
        showErrorToast('Error: Failed to add tag', error.message);
      }
    },
  });

  React.useEffect(() => {
    // update tags here when they have changes outside of the component after initial render
    setUpdatedTags(dealTags);
  }, [dealTags, setUpdatedTags]);

  return (
    <C.Popover onClose={onClose} isOpen={isOpen}>
      <C.PopoverTrigger>{children}</C.PopoverTrigger>
      <C.Portal>
        <C.PopoverContent minW="30rem">
          <C.PopoverArrow />
          <C.PopoverBody p="var(--space-sm)">
            <C.Stack spacing="var(--space-sm)">
              <C.Heading size="sm" as="h4">
                Deal is tagged with:
              </C.Heading>
              <DealTagsSelect
                allowItemCreate
                initialTags={updatedTags.map((tag) => ({ tag: tag, count: 0 }))}
                setChosenTags={(tags) => {
                  const stringTags = tags?.map((tag) => tag.tag);
                  if (!isEqual(stringTags, updatedTags)) {
                    addTag.execute(stringTags || []);
                  }
                }}
              />
            </C.Stack>
          </C.PopoverBody>
        </C.PopoverContent>
      </C.Portal>
    </C.Popover>
  );
};

function DealTags({ dealTags, agreementId }: { dealTags: string[]; agreementId: number }) {
  const { isOpen: isOpenAdd, onClose: onCloseAdd, onOpen: onOpenAdd } = C.useDisclosure();
  const [tags, setTags] = React.useState(dealTags);

  React.useEffect(() => {
    setTags(dealTags);
  }, [dealTags]);

  return (
    <C.HStack>
      <C.WrapItem>
        <AddDealTagPopover
          isOpen={isOpenAdd}
          onClose={onCloseAdd}
          setNewTags={setTags}
          dealTags={tags}
          agreementId={agreementId}>
          <C.Tag as={C.Button} height="auto" rightIcon={<EditIcon />} onClick={onOpenAdd}>
            Edit tags
          </C.Tag>
        </AddDealTagPopover>
      </C.WrapItem>
      <C.Wrap overflow="visible">
        {tags.map((tag, index) => (
          <C.WrapItem key={index}>
            <C.Tag>
              <C.TagLabel>{tag}</C.TagLabel>
            </C.Tag>
          </C.WrapItem>
        ))}
      </C.Wrap>
    </C.HStack>
  );
}

export default DealTags;
