import * as C from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-async';
import AlertBox from '../alert-box/AlertBox';
import { Card, CardBody, CardHeader, EmptyState, PageHeader } from '@sharkpunch/idun';
import {
  ContentSubmissionTask,
  getContentSubmissionTask,
} from '../../api-clients/imt-api-content-submission';
import ContentSubmissionStatusBadge from './ContentSubmissionStatus';
import { useUserState } from '../../stores/UserStore';

import ContentSubmissionReview from './ContentSubmissionReview';

import ChakraMarkdown from '../chakra-markdown/ChakraMarkdown';

const ContentSubmissionDetails = ({
  contentSubmissionTask,
  approveTask,
  requestForChanges,
}: {
  contentSubmissionTask: ContentSubmissionTask;
  approveTask: (newTaskData: ContentSubmissionTask) => {};
  requestForChanges: (newTaskData: ContentSubmissionTask) => {};
}) => {
  return (
    <C.Stack spacing="var(--space-md)">
      <C.Grid templateColumns="repeat(3, 1fr)" gap="var(--space-md)">
        <C.GridItem colSpan={[3, 3, 2]}>
          <Card>
            <CardHeader>
              <C.Heading size="md">Content</C.Heading>
            </CardHeader>
            <CardBody>
              <ContentSubmissionReview
                contentSubmissionTask={contentSubmissionTask}
                approveTask={approveTask}
                requestForChanges={requestForChanges}
              />
            </CardBody>
          </Card>
        </C.GridItem>

        <C.GridItem colSpan={[3, 3, 1]}>
          <Card>
            <CardHeader>
              <C.Heading size="md">Details</C.Heading>
            </CardHeader>
            <CardBody>
              <C.Stack spacing="var(--space-md)">
                <C.FormControl>
                  <C.FormLabel>Submission status</C.FormLabel>
                  <ContentSubmissionStatusBadge status={contentSubmissionTask.status} />
                </C.FormControl>

                <C.FormControl>
                  <C.FormLabel>Status comment</C.FormLabel>
                  {contentSubmissionTask.status_comment ? (
                    <ChakraMarkdown content={contentSubmissionTask.status_comment} />
                  ) : (
                    <C.Text color="grey">No status comment</C.Text>
                  )}
                </C.FormControl>
              </C.Stack>
            </CardBody>
          </Card>
        </C.GridItem>
      </C.Grid>
    </C.Stack>
  );
};

const ContentSubmissionPage = () => {
  let { id } = useParams<{ id: string }>();
  const { data, error, isPending, setData } = useAsync(getContentSubmissionTask, { id });
  const { user: admin } = useUserState();

  let content;
  if (isPending) {
    content = (
      <C.Box pr={5} pl={5} pt={40} pb={40}>
        <C.Progress size="xs" isIndeterminate />
      </C.Box>
    );
  } else if (error) {
    content = <AlertBox>{`Failed fetching content submission task: [${error.message}]`}</AlertBox>;
  } else if (data && id && admin) {
    content = (
      <ContentSubmissionDetails
        contentSubmissionTask={data}
        approveTask={setData}
        requestForChanges={setData}
      />
    );
  } else {
    content = (
      <EmptyState
        title="No content submission task found"
        description="Seems like no one created any yet."
      />
    );
  }
  return (
    <C.Container maxW="container.xl" pt="var(--page-top-spacing)" pb="var(--page-bottom-spacing)">
      <PageHeader title="Content submission task" backRoute="/content-submissions" />
      {content}
    </C.Container>
  );
};

export default ContentSubmissionPage;
