import * as C from '@chakra-ui/react';
import { useAsyncCallback } from 'react-async-hook';
import { useForm, Controller } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import { showErrorToast } from '../../helpers';
import { setPublishingDate } from '../../api-clients/imt-api-agreements';
import { ChakraDateInput } from './DateRangeFilter';

import 'react-datepicker/dist/react-datepicker.css';
import './chakra-react-datepicker.css';

function DealPublishingDate({ deadline, agreementId }: { deadline: string; agreementId: number }) {
  const { isOpen, onOpen, onClose } = C.useDisclosure();
  const [updatedDeadline, setUpdatedDeadline] = useState(new Date(deadline));

  const { handleSubmit, setValue, control } = useForm<{ newDeadline: string }>({
    mode: 'onChange',
  });

  const saveDeadline = useAsyncCallback(
    async (newDeadline: string) => setPublishingDate(newDeadline, agreementId),
    {
      onSuccess: (result) => {
        setUpdatedDeadline(new Date(result.deadline));
        onClose();
      },
      onError: (error) => {
        if (error.message) {
          showErrorToast('Error: Failed to change publishing date', error.message);
        }
      },
    }
  );

  const onSaveDeadline = (data: { newDeadline: string }) => {
    saveDeadline.execute(format(new Date(data.newDeadline), 'yyyy-MM-dd'));
  };

  useEffect(() => {
    // set the deadline that we display whenever the deadline prop changes,
    // since we set this manually we need to watch for this change in prop and not the other props
    setUpdatedDeadline(new Date(deadline));
  }, [deadline]);

  useEffect(() => {
    setValue('newDeadline', format(new Date(deadline), 'yyyy-MM-dd'));
  }, [deadline, setValue]);

  const isLight = C.useColorMode().colorMode === 'light';
  return (
    <C.Popover trigger="click" id="change_deadline_popover" onClose={onClose} isOpen={isOpen}>
      <C.PopoverTrigger>
        <C.Button onClick={onOpen} size="sm" variant="ghost" fontSize="md">
          <C.Text fontWeight="normal" mr="1">
            Publishing date
          </C.Text>
          <C.Tag>
            <C.TagLabel fontWeight="bold">{format(updatedDeadline, 'dd.MM.yyyy')}</C.TagLabel>
          </C.Tag>
        </C.Button>
      </C.PopoverTrigger>
      <C.Portal>
        <C.PopoverContent maxWidth="17rem">
          <C.PopoverArrow />
          <C.PopoverHeader fontWeight="bold" border="0">
            Change publishing date
          </C.PopoverHeader>
          <C.PopoverBody>
            <form onSubmit={handleSubmit(onSaveDeadline)}>
              <C.HStack>
                <C.Box flex="1">
                  <Controller
                    name="newDeadline"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <C.ButtonGroup className={isLight ? 'light-theme' : 'dark-theme'}>
                        <DatePicker
                          onChange={onChange}
                          selected={new Date(value)}
                          dateFormat="dd.MM.yyyy"
                          calendarStartDay={1}
                          customInput={<ChakraDateInput minW="10rem" />}
                        />
                      </C.ButtonGroup>
                    )}
                  />
                </C.Box>
                <C.Button type="submit" isLoading={saveDeadline.loading} colorScheme="cyan">
                  Save
                </C.Button>
              </C.HStack>
            </form>
          </C.PopoverBody>
        </C.PopoverContent>
      </C.Portal>
    </C.Popover>
  );
}

export default DealPublishingDate;
