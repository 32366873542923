import { imtApi } from './imt-api';
import { PricedOffer, PricedOfferDecoder } from '../helpers';
import { decodeResponseArray } from '../api-helpers';

const offerUrl = `/admin/offers`;

export async function revokePricedOffer(
  id: string,
  hashId: string,
  revokeReason: string
): Promise<unknown> {
  const url = `${offerUrl}/${id}/revoke`;

  const res = await imtApi.put(url, { hashId, revokeReason });
  // returns data object so that data.status can be used later
  return res.data;
}

export async function getPricedOffersByChannelId(channelId: string): Promise<PricedOffer[]> {
  const url = `/admin/offers/v1/offers/youtube/${channelId}`;
  const res = await imtApi.get(url);
  return decodeResponseArray(res, PricedOfferDecoder);
}
