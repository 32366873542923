import * as t from 'io-ts';
import { isRight } from 'fp-ts/Either';
import { PathReporter } from 'io-ts/PathReporter';
import axios, { AxiosResponse } from 'axios';
import { ApiResponseDecodingError } from './errors';
import { notEmpty } from './helpers';

const hasHeaders = (thing: unknown): thing is { headers: Record<string, string> } =>
  thing !== null &&
  typeof thing === 'object' &&
  !!(thing as { headers: Record<string, string> }).headers;

export function getRequestId(errorOrResponse: unknown) {
  if (axios.isAxiosError(errorOrResponse)) {
    return errorOrResponse.response?.headers['request-id'] || null;
  }
  if (hasHeaders(errorOrResponse)) {
    return errorOrResponse.headers['request-id'] || null;
  }
  return null;
}

export function decodeResponse<ResponseType>(
  response: AxiosResponse<{ data: ResponseType }, ResponseType>,
  decoder?: t.Type<ResponseType>
): ResponseType {
  const resData = response.data.data;
  const requestId = getRequestId(response);

  if (resData === null) {
    console.error(`Error log: API response was empty. Request id: ${requestId}.`);
    throw new ApiResponseDecodingError(`API response was empty. Request id: ${requestId}.`, {
      requestId,
      report: null,
    });
  }

  if (decoder) {
    const decoded = decoder.decode(resData);
    if (!isRight(decoded)) {
      const report = PathReporter.report(decoded);
      console.error('Error log: Invalid object in response:', { item: resData, report });
      throw new ApiResponseDecodingError(`Invalid object in response. Request id: ${requestId}.`, {
        report,
        requestId,
      });
    }
    return decoded.right;
  } else {
    return resData;
  }
}

export function decodeResponseArray<ResponseType>(
  response: AxiosResponse<{ data: ResponseType[] }, ResponseType[]>,
  decoder: t.Type<ResponseType>,
  discardInvalid?: boolean
): ResponseType[] {
  const resData = response.data.data;
  const requestId = getRequestId(response);

  if (resData === null) {
    console.error(`Error log: API response was empty. Request id: ${requestId}`, resData);
    throw new ApiResponseDecodingError(`API response was empty. Request id: ${requestId}.`, {
      requestId,
      report: null,
    });
  }

  if (!Array.isArray(resData)) {
    console.error(`Error log: API response was not an array. Request id: ${requestId}`, resData);
    throw new ApiResponseDecodingError(`API response was not an array. Request id: ${requestId}.`, {
      requestId,
      report: null,
    });
  }

  const decoded = resData.map((item) => {
    const decoded = decoder.decode(item);
    if (!isRight(decoded)) {
      const report = PathReporter.report(decoded);
      console.error('Error log: Invalid object in response:', { report, item });
      if (!discardInvalid) {
        throw new ApiResponseDecodingError(
          `Invalid object in response. Request id: ${requestId}.`,
          {
            report,
            requestId,
          }
        );
      }
    }
    return isRight(decoded) ? decoded.right : null;
  });
  return decoded.filter(notEmpty);
}
