import { isEmpty } from 'lodash';
import {
  DetailsPerChannel,
  isBuyoutDeliverable,
  isInstagramDeliverable,
  isYoutubeVideoDeliverable,
  OfferCreateRequest,
  OffereeToCommissionRateObject,
} from '../../helpers';
import { format } from 'date-fns';
import Papa from 'papaparse';
import { Button, ButtonGroup, HStack, Text } from '@chakra-ui/react';

export const CsvDownload = (props: {
  pricedOffersSelected: OfferCreateRequest[];
  pricedOffersChecked: OfferCreateRequest[];
  commissionRates: OffereeToCommissionRateObject;
  detailsPerChannel: DetailsPerChannel;
}) => {
  const { pricedOffersSelected, pricedOffersChecked, commissionRates, detailsPerChannel } = props;

  function downloadCsv(offers: OfferCreateRequest[]): void {
    // create csv rows with cols
    const rows = offers.map((offer) => {
      const channelId = offer.offeree.platform_id;
      const feeInCents = parseFloat(offer.pricing.price) * 100;
      const deliverable = offer.deliverables[0];

      if (
        !isYoutubeVideoDeliverable(deliverable) &&
        !isInstagramDeliverable(deliverable) &&
        !isBuyoutDeliverable(deliverable)
      ) {
        console.error('Unexpected deliverable encountered', deliverable);
        throw new Error('Unexpected deliverable type');
      }

      const commissionRate = commissionRates ? commissionRates[offer.offeree.platform_id] : '';
      return {
        campaign_id: offer.offeror.platform_id,
        influencer_id: detailsPerChannel[channelId].influencerId,
        channel_id: channelId,
        promotion_type: isYoutubeVideoDeliverable(deliverable) ? deliverable.format : '',
        creator_fixed_fee_cents: feeInCents.toFixed(2),
        // Not entirely sure whether we want to send double deadlines for
        // buyout, this depends on the campaign API, which currently
        // doesn't know how to create buyout deliverables
        video_publishing_date_start:
          isYoutubeVideoDeliverable(deliverable) || isInstagramDeliverable(deliverable)
            ? deliverable.deadline_range_start
            : deliverable.deadline,
        video_publishing_date_end:
          isYoutubeVideoDeliverable(deliverable) || isInstagramDeliverable(deliverable)
            ? deliverable.deadline_range_end
            : deliverable.deadline,
        budget_id: offer.budget_id,
        variable_commission_rate: commissionRate,
        cpm: offer.meta?.cpm || '',
        cpm_multiplier: offer.meta?.cpm_multiplier || '',
        meta: (offer.meta && JSON.stringify(offer.meta)) || '',
      };
    });

    const csv = Papa.unparse(rows);
    const csvContent = 'data:text/csv;charset=utf-8,' + csv;
    const today = format(new Date(), 'yyyy-MM-dd');

    // create a hidden <a> and set its download attribute in order to be able to name download file
    let link = document.createElement('a');
    link.setAttribute('href', encodeURI(csvContent));
    link.setAttribute(
      'download',
      today + '_campaign-' + pricedOffersSelected[0].offeror.platform_id + '_priced-offers.csv'
    );
    document.body.appendChild(link); // required for FF

    link.click();
  }

  return (
    <HStack>
      <Text fontSize="sm">Download CSV:</Text>
      <ButtonGroup size="sm" isAttached variant="outline">
        <Button
          disabled={!pricedOffersSelected.length || isEmpty(detailsPerChannel)}
          onClick={() => downloadCsv(pricedOffersSelected)}>
          All
        </Button>
        <Button
          disabled={!pricedOffersChecked.length || isEmpty(detailsPerChannel)}
          onClick={() => downloadCsv(pricedOffersChecked)}>
          Selected
        </Button>
      </ButtonGroup>
    </HStack>
  );
};
