import React from 'react';
import * as C from '@chakra-ui/react';

import { useSendOffers } from '../../useSendOffers';
import { PreviewTable } from './PreviewTable';
import { PreviewSummary } from './PreviewSummary';
import { BulkOfferForm } from './BulkOfferForm';
import { BuyoutOfferForm } from './BuyoutOfferForm';
import { CsvUploadPage } from './CsvUploadPage';
import { SentOffersInformationBox } from './SentOffersInformationBox';
import type {
  OfferCreateRequest,
  ConditionalOfferCreateRequest,
  DetailsPerChannel,
  OffereeToCommissionRateObject,
} from '../../helpers';
import type { FailedToParse, Price } from './BulkOfferForm';

import { useConfirmDialog } from '@sharkpunch/idun';
import AlertBox from '../alert-box/AlertBox';

export type PreparedOffers = {
  offers: OfferCreateRequest[];
  prices: Price;
  conditionalOffers: ConditionalOfferCreateRequest[];
  commissionRates: OffereeToCommissionRateObject;
  failedToParse: FailedToParse;
  detailsPerChannel: DetailsPerChannel;
};

const defaultPreparedOffers = {
  offers: [],
  prices: {},
  conditionalOffers: [],
  commissionRates: {},
  failedToParse: [],
  detailsPerChannel: {},
};

function OfferSender(props: {
  preparedOffers: PreparedOffers;
  pricedOffersSelected: OfferCreateRequest[];
}) {
  const { preparedOffers, pricedOffersSelected } = props;

  const { succeeded, failed, totalOffersToSendCount, triggerSendOffers } = useSendOffers();

  const { isOpen, onClose, onToggle } = C.useDisclosure();
  const { confirm, Dialog } = useConfirmDialog();

  const totalOffersLength = pricedOffersSelected.length + preparedOffers.conditionalOffers.length;

  return (
    <>
      {preparedOffers.conditionalOffers?.length > 0 && (
        <C.Button
          colorScheme="cyan"
          mr={5}
          onClick={() => {
            confirm({
              headerText: 'Are you sure?',
              bodyText: `You are about to send out ${pricedOffersSelected.length} priced offers and create ${preparedOffers.conditionalOffers.length} conditional offers.`,
              cancelText: 'Abort mission',
              confirmText: 'Send offers',
              confirmColorScheme: 'cyan',
            }).then(() => {
              triggerSendOffers(
                pricedOffersSelected,
                preparedOffers.conditionalOffers,
                preparedOffers.commissionRates
              );
              onToggle();
            });
          }}>
          {totalOffersLength > 0 ? `Send all ${totalOffersLength} offers` : 'Send all offers'}
        </C.Button>
      )}
      <C.Button
        colorScheme="cyan"
        disabled={!pricedOffersSelected.length}
        onClick={() => {
          confirm({
            headerText: 'Are you sure?',
            bodyText: `You are about to send out ${pricedOffersSelected.length} offers.`,
            cancelText: 'Abort mission',
            confirmText: 'Send offers',
            confirmColorScheme: 'cyan',
          }).then(() => {
            triggerSendOffers(pricedOffersSelected, undefined, preparedOffers.commissionRates);
            onToggle();
          });
        }}>
        {pricedOffersSelected.length > 0
          ? `Send ${pricedOffersSelected.length} priced offers`
          : 'Send priced offers'}
      </C.Button>
      <SentOffersInformationBox
        succeeded={succeeded}
        failed={failed}
        isOpen={isOpen}
        onClose={onClose}
        totalOffersCount={totalOffersToSendCount}
        conditionalOffersCount={preparedOffers.conditionalOffers.length}
      />
      <Dialog />
    </>
  );
}

export default function BulkOfferCreation() {
  const [preparedOffers, setPreparedOffers] = React.useState<PreparedOffers>(defaultPreparedOffers);
  const [pricedOffersSelected, setPricedOffersSelected] = React.useState<OfferCreateRequest[]>([]);
  const [formSubmitStatus, setFormSubmitStatus] = React.useState<string>('');
  const [formSubmitError, setFormSubmitError] = React.useState<Error | undefined>(undefined);

  const totalOffersLength = pricedOffersSelected.length + preparedOffers.conditionalOffers.length;

  return (
    <>
      <C.Box py={10}>
        <C.Container maxW="container.lg">
          <C.Tabs isFitted isLazy variant="enclosed-colored" layerStyle="base">
            <C.TabList>
              <C.Tab>👩‍🔬 Offers generator</C.Tab>
              <C.Tab>💸 Buyout offers generator</C.Tab>
              <C.Tab>😎 CSV Upload</C.Tab>
            </C.TabList>
            <C.TabPanels>
              <C.TabPanel>
                <BulkOfferForm
                  setPreparedOffers={setPreparedOffers}
                  onFormSubmitStatus={setFormSubmitStatus}
                  onFormSubmitError={setFormSubmitError}
                />
              </C.TabPanel>
              <C.TabPanel>
                <BuyoutOfferForm setPreparedOffers={setPreparedOffers} />
              </C.TabPanel>
              <C.TabPanel>
                <CsvUploadPage setPreparedOffers={setPreparedOffers} />
              </C.TabPanel>
            </C.TabPanels>
          </C.Tabs>
        </C.Container>
      </C.Box>
      <C.Box layerStyle="base" p={10}>
        <C.Flex>
          <C.Heading as="h2" size="md">
            Preview of offers to send
          </C.Heading>
          <C.Spacer />
          <OfferSender
            preparedOffers={preparedOffers}
            pricedOffersSelected={pricedOffersSelected}
          />
        </C.Flex>
        <PreviewSummary
          preparedOffers={preparedOffers}
          pricedOffersSelected={pricedOffersSelected}
        />
        <C.Box pb={10}>
          <PreviewTable
            preparedOffers={preparedOffers}
            onPricedOffersSelected={setPricedOffersSelected}
          />
          {formSubmitStatus === 'fulfilled' && !formSubmitError && totalOffersLength === 0 && (
            <AlertBox status="info">
              {`The current filters and rules didn't result in any offers to preview, try to adjust some filters and/or rules or choose a different collection.`}
            </AlertBox>
          )}
        </C.Box>
      </C.Box>
    </>
  );
}
