import { useForm } from 'react-hook-form';
import * as C from '@chakra-ui/react';
import { useAsync } from 'react-async';
import { chunk } from 'lodash';
import { EmptyState, Card, CardBody, PageHeader, ModalBase } from '@sharkpunch/idun';
import AlertBox from '../alert-box/AlertBox';
import { isOutOfRangeError, showSuccessToast, showErrorToast } from '../../helpers';
import {
  BatchOutreach,
  BatchOutreachStats,
  ConditionalOfferIdResult,
  createOutreachBatch,
  createOutreachNotification,
  createOutreachTask,
  fetchBatches,
  fetchBatchStats,
  fetchUnsentConditionalOfferIdsByCampaignId,
} from '../../api-clients/imt-api-outreaches';
import BatchOutreachListingTable from './BatchOutreachListingTable';
import CampaignSelector from '../campaign-selector/CampaignSelector';

import { CREATOR_APP_URL } from '../../config';

type FormData = { notification_template: string; campaign_id: string; batch_name: string };
type SubmitData = {
  notification_template: Object;
  campaign_id: string;
  batch_name: string;
  conditional_offer_ids: ConditionalOfferIdResult;
};

const urlRegex =
  /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)/g;

const NewOutreachTaskModal = ({
  onClose,
  onSubmit,
  isOpen,
}: {
  onClose: () => void;
  onSubmit: (data: SubmitData) => void;
  isOpen: boolean;
}) => {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onChange',
  });

  const {
    setData,
    data: conditional_offer_ids = [],
    run: fetchConditionalOfferIds,
    isPending,
  } = useAsync<ConditionalOfferIdResult>({
    deferFn: async (campaign_id: any) => {
      setValue('campaign_id', campaign_id);
      return fetchUnsentConditionalOfferIdsByCampaignId(campaign_id);
    },
  });

  const onSubmitHandler = (data: FormData) => {
    let parsedNotification;
    try {
      parsedNotification = JSON.parse(data.notification_template);
    } catch (e) {
      console.error(
        'Error log: Failed to parse notification template:',
        data.notification_template,
        e
      );
      showErrorToast(
        'Error',
        'Invalid notification template! You can use the notification tool to check and make sure it is valid.'
      );
      return;
    }
    onSubmit({
      ...data,
      conditional_offer_ids,
      notification_template: parsedNotification,
    });
    setData([]);
    setValue('campaign_id', '');
    onClose();
  };

  const values = getValues();

  const watchedNotificationTemplate = watch('notification_template');

  let wrongTemplate: string | null = null;

  if (watchedNotificationTemplate && watchedNotificationTemplate.trim() !== '') {
    try {
      JSON.parse(watchedNotificationTemplate);
    } catch {
      wrongTemplate = 'Template should be a valid JSON';
    }

    const urls = watchedNotificationTemplate.match(urlRegex);
    if (!urls) {
      wrongTemplate = 'Template expected to have urls';
    } else {
      const creatorAppHostName = new URL(CREATOR_APP_URL).hostname;
      let invalidHostnames = [];
      for (const urlString of urls) {
        const url = new URL(urlString);
        if (!url.hostname.includes(creatorAppHostName)) {
          invalidHostnames.push(url.hostname);
        }
      }
      if (invalidHostnames.length) {
        wrongTemplate = `All urls expected to point to ${creatorAppHostName}, found url that points to ${invalidHostnames.join(
          ', '
        )}. You still can use this template if you think this is correct.`;
      }
    }
  }

  return (
    <ModalBase
      header="Create outreach batch"
      onClose={() => {
        setData([]);
        setValue('campaign_id', '');
        onClose();
      }}
      isOpen={isOpen}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <C.FormControl>
          <C.SimpleGrid spacing="var(--space-sm)" mb="var(--space-md)">
            <C.Box>
              <CampaignSelector
                errors={errors.campaign_id}
                control={control}
                required={true}
                name="campaign_id"
                onChangeProp={fetchConditionalOfferIds}
              />
              {conditional_offer_ids.length === 0 &&
                values.campaign_id &&
                values.campaign_id !== '' &&
                !isPending && (
                  <C.Alert
                    status="success"
                    variant="subtle"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    padding={10}
                    borderRadius="md"
                    mt={4}>
                    <C.AlertIcon boxSize="40px" mr={0} />
                    <C.AlertTitle mt={4} mb={1} fontSize="lg">
                      {`Outreach already done`}
                    </C.AlertTitle>
                    <C.AlertDescription>
                      <C.Text fontSize="xs">
                        All the conditional offers in this campaign have already been outreached
                        for.
                      </C.Text>
                    </C.AlertDescription>
                  </C.Alert>
                )}
              {conditional_offer_ids.length > 0 && (
                <C.Alert
                  status="warning"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  padding={10}
                  borderRadius="md"
                  mt={4}>
                  <C.AlertIcon boxSize="40px" mr={0} />
                  <C.AlertTitle mt={4} mb={1} fontSize="lg">
                    {`Going to send outreach for ${conditional_offer_ids.length} conditional offers`}
                  </C.AlertTitle>
                  <C.AlertDescription>
                    <C.Text fontSize="xs">
                      {`There are ${conditional_offer_ids.length} conditional offers in the campaign for which outreach hasn't been done yet. Those conditional offers will be part of this batch to be created.`}
                    </C.Text>
                  </C.AlertDescription>
                </C.Alert>
              )}
            </C.Box>
            <C.FormControl>
              <C.FormLabel>Notification template</C.FormLabel>
              <C.Textarea rows={8} {...register('notification_template')} />
              <C.FormHelperText>
                This notification template will be used as a basis for all outreach notifications
                sent to creators.
              </C.FormHelperText>
              {wrongTemplate && <C.Box color="yellow.500">{wrongTemplate}</C.Box>}
              <C.LinkBox>
                <a
                  href="https://notifications.matchmade.tv/preview?template=%7B%22title%22%3A%22This+is+the+subject+line+for+notification%22%2C%22content%22%3A%22Hey%2C+this+is+the+leading+copy+of+the+notification.+You+can+type+in+Markdown+here.%5Cn%5CnAnother+paragraph.%5Cn%5Cn-+List+element%5Cn-+%5BAnother+List%5D%28https%3A%2F%2Fexample.com%29%22%2C%22cover_image%22%3A%22https%3A%2F%2Fpicsum.photos%2F900%2F400%22%2C%22blocks%22%3A%5B%7B%22type%22%3A%22highlight%22%2C%22content%22%3A%22%23+Markdown%5Cn%5Cn%23%23+Does+work%5Cn+%23%23%23+Here%5Cn+Lorem+ipsum+dolor%22%2C%22image%22%3A%22https%3A%2F%2Fpicsum.photos%2F200%22%7D%2C%7B%22type%22%3A%22text%22%2C%22content%22%3A%22You+can+mix+highlighted+and+normal+blocks%22%2C%22image%22%3A%22https%3A%2F%2Fpicsum.photos%2F200%22%7D%2C%7B%22type%22%3A%22highlight%22%2C%22content%22%3A%22Another+highlight+block%5Cn-+Another+list+foo+faa+lorem+ipsum%5Cn-+Lorem+ipsum+dolor+sit+omet+nomen+this+is+a+bit+longer+text+what+happens+here%22%7D%5D%2C%22action_label%22%3A%22Main+call+to+action%22%2C%22action_uri%22%3A%22https%3A%2F%2Fcreators.matchmade.tv%2Fauthenticate%3Frole%3Dinfluencer%22%2C%22notification_type%22%3A%22new_campaign_available%22%2C%22user_id%22%3A123%7D"
                  target="_blank"
                  rel="noreferrer">
                  Click here to open the notification template tool in a new tab
                </a>
              </C.LinkBox>
            </C.FormControl>
            <C.Box>
              <C.FormLabel>Batch name</C.FormLabel>
              <C.Input
                placeholder=""
                type="text"
                {...register('batch_name', {
                  validate: (value) =>
                    (typeof value === 'string' && value.length > 0) || 'Batch name cannot be empty',
                })}
              />
            </C.Box>
          </C.SimpleGrid>
        </C.FormControl>
        <C.HStack spacing={3} mt={8} justifyContent="flex-end">
          <C.Button
            onClick={() => {
              setData([]);
              setValue('campaign_id', '');
              onClose();
            }}>
            Cancel
          </C.Button>
          <C.Button isDisabled={isPending} colorScheme="cyan" type="submit">
            Create
          </C.Button>
        </C.HStack>
      </form>
    </ModalBase>
  );
};

const onSendOutreachTask = async (params: SubmitData) => {
  try {
    const batch = await createOutreachBatch(params.batch_name);
    const notificationTemplate = await createOutreachNotification(params.notification_template);
    await createOutreachTask({
      batch_id: batch.id,
      notification_template_id: notificationTemplate.id,
      conditional_offer_ids: params.conditional_offer_ids,
    });
    showSuccessToast('Success', 'Outreach batch successfully created.');
  } catch (e) {
    const message = e instanceof Error ? e.message : 'Unknown error';
    console.error(e, 'Error log: Failed to create outreact');
    showErrorToast('Error', `Something went wrong trying to create the outreach: ${message}`);
  }
};

const BatchOutreachListing = () => {
  const outreachTaskModal = C.useDisclosure();
  const { data: stats = [], run: fetchStats } = useAsync<BatchOutreachStats[]>({
    deferFn: async (params) => {
      const batches = [...params[0]];
      const groups = chunk(batches, 5);
      let stats: BatchOutreachStats[] = [];

      for (const batchesPerGroup of groups) {
        const res = await Promise.all(
          batchesPerGroup.map((batchOutreach) => fetchBatchStats(batchOutreach.id))
        );
        stats = stats.concat(res);
      }

      return stats;
    },
  });

  const {
    data: batches = [],
    isLoading,
    error,
  } = useAsync<BatchOutreach[]>({
    promiseFn: fetchBatches,
    onResolve: fetchStats,
  });

  let content;
  if (error && !isOutOfRangeError(error)) {
    content = <AlertBox>{`Failed to fetch outreach batches: ${error.message}`}</AlertBox>;
  } else if (isLoading && !batches) {
    // Initial load -- we have no data, don't display table yet
    content = <C.Progress size="xs" isIndeterminate />;
  } else if (batches) {
    content = <BatchOutreachListingTable batches={batches} stats={stats} />;
  } else if (!error && !isLoading) {
    content = (
      <EmptyState
        title="No outreach batches"
        description="Seems like there is nothing to do here"
      />
    );
  }

  return (
    <C.Container maxW="container.xl" my={10}>
      <C.Box display="flex" flexDirection="row" justifyContent="space-between">
        <PageHeader title="Outreach batches" />
        <C.Button onClick={outreachTaskModal.onOpen} colorScheme="cyan">
          New outreach batch
        </C.Button>
      </C.Box>
      <Card>
        <CardBody>
          <C.Stack spacing={8}>{content}</C.Stack>
        </CardBody>
      </Card>
      <NewOutreachTaskModal
        isOpen={outreachTaskModal.isOpen}
        onSubmit={onSendOutreachTask}
        onClose={outreachTaskModal.onClose}
      />
    </C.Container>
  );
};

export default BatchOutreachListing;
