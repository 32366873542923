import * as React from 'react';
import * as C from '@chakra-ui/react';
import { format } from 'date-fns';
import { Column, useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import { Link } from 'react-router-dom';

import type { SortBy } from '../../helpers';

import { CAMPAIGN_APP_URL } from '../../config';

import ReactTableBase from '../react-table/ReactTableBase';
import {
  ContentSubmissionTask,
  getDisplayDataForTask,
} from '../../api-clients/imt-api-content-submission';

import { YoutubeChannelDetails, AdminDetails } from '../bulk-offer/ChannelDetails';
import ContentSubmissionStatusBadge from './ContentSubmissionStatus';

import ContentSubmissionReview from './ContentSubmissionReview';
import { findIndex } from 'lodash';

import PropertyFilters from './PropertyFilters';

type TableRow = {
  status: string;
  created: string;
  deadline: string | null;
};

export function ReviewDrawer({
  contentSubmissionTask,
  isOpen,
  onClose,
  approveTask,
  requestForChanges,
}: {
  contentSubmissionTask: ContentSubmissionTask;
  isOpen: boolean;
  onClose: () => void;
  approveTask: (newTaskData: ContentSubmissionTask) => void;
  requestForChanges: (newTaskData: ContentSubmissionTask) => void;
}) {
  return (
    <C.Drawer
      placement="left"
      size="lg"
      onClose={onClose}
      isOpen={isOpen}
      blockScrollOnMount={false}>
      <C.DrawerOverlay />
      <C.DrawerContent>
        <C.DrawerHeader borderBottomWidth="1px">Review content</C.DrawerHeader>
        <C.DrawerBody>
          <ContentSubmissionReview
            contentSubmissionTask={contentSubmissionTask}
            approveTask={approveTask}
            requestForChanges={requestForChanges}
          />
        </C.DrawerBody>
      </C.DrawerContent>
    </C.Drawer>
  );
}

export type Filter = { id: string; value: string[] };

const ContentSubmissionListingTable = ({
  contentSubmissionTasks,
  setContentSubmissionTasks,
  pageIndex,
  setPageIndex,
  pageSize,
  setPageSize,
  total,

  sortBy,
  setSortBy,

  filters,
  setFilters,

  isLoading,
}: {
  contentSubmissionTasks: ContentSubmissionTask[];
  setContentSubmissionTasks: (data: { data: ContentSubmissionTask[]; total: number }) => void;
  pageIndex: number;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  total: number;
  sortBy: SortBy[];
  setSortBy: React.Dispatch<React.SetStateAction<SortBy[]>>;
  filters: Filter[];
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>;

  isLoading: boolean;
}) => {
  const setNewData = React.useCallback(
    async (newTaskData: ContentSubmissionTask) => {
      const changedTaskIndex = findIndex(
        contentSubmissionTasks,
        (task) => task.id === newTaskData.id
      );
      const fullTaskData = await getDisplayDataForTask(newTaskData);
      const newTasks = [...contentSubmissionTasks];
      newTasks[changedTaskIndex] = fullTaskData || newTaskData;
      setContentSubmissionTasks({ data: newTasks, total });
    },
    [contentSubmissionTasks, setContentSubmissionTasks, total]
  );

  const columns = React.useMemo(
    () =>
      [
        {
          Header: 'Campaign',
          accessor: (row: ContentSubmissionTask) => row.meta?.campaign_id,
          id: 'meta.campaign_id' as const,
          sortType: 'basic',
          disableFilters: true,
          Cell: ({ value, row }: { value?: number; row: { original: ContentSubmissionTask } }) =>
            value ? (
              <C.Link isExternal href={`${CAMPAIGN_APP_URL}/admin/campaigns/${value}`}>
                {row.original?.meta?.campaign_name
                  ? String(row.original.meta.campaign_name)
                  : value}
              </C.Link>
            ) : (
              '-'
            ),
        },
        {
          Header: 'Channel',
          accessor: (row: ContentSubmissionTask) => row.expected_content_submitter.platform_id,
          id: 'expected_content_submitter_platform_id' as const,
          sortType: 'basic',
          disableFilters: true,
          Cell: ({ value, row }: { value: string; row: { original: ContentSubmissionTask } }) =>
            value && row.original.expected_content_submitter?.display_data ? (
              <YoutubeChannelDetails
                id={value}
                name={row.original.expected_content_submitter.display_data.name}
                avatarUrl={row.original.expected_content_submitter.display_data.avatarUrl}
              />
            ) : (
              <div>{value}</div>
            ),
        },
        {
          Header: 'Status',
          accessor: 'status' as const,
          disableFilters: true,
          sortType: 'basic',
          Cell: ({ value }: { value: string }) => <ContentSubmissionStatusBadge status={value} />,
        },
        {
          Header: 'Created',
          accessor: 'created' as const,
          sortType: 'basic',
          disableFilters: true,
          Cell: ({ value }: { value: string }) => format(Date.parse(value), 'do MMM yyyy'),
        },
        {
          Header: 'Deadline',
          accessor: 'deadline' as const,
          sortType: 'basic',
          disableFilters: true,
          Cell: ({ value }: { value: string | null }) =>
            value ? format(Date.parse(value), 'do MMM yyyy') : '-',
        },
        {
          Header: 'Reviewer',
          accessor: (row: ContentSubmissionTask) => row.reviewed_by?.display_data?.name,
          id: 'reviewed_by_platform_id' as const, // This probably should be name as well?
          sortType: 'basic',
          disableFilters: true,
          Cell: ({ value, row }: { value?: string; row: { original: ContentSubmissionTask } }) =>
            value ? (
              <AdminDetails
                name={value}
                avatarUrl={row.original.reviewed_by?.display_data?.avatarUrl || ''}
              />
            ) : (
              <div>{value || '-'}</div>
            ),
        },
        {
          Header: 'Actions',
          accessor: 'id' as const,
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ value, row }: { value: string; row: { original: ContentSubmissionTask } }) => {
            const { isOpen, onOpen, onClose } = C.useDisclosure();
            const status = row.original.status;

            return (
              <C.HStack>
                <ReviewDrawer
                  isOpen={isOpen}
                  onClose={onClose}
                  contentSubmissionTask={row.original}
                  approveTask={setNewData}
                  requestForChanges={setNewData}
                />
                <C.Button
                  size="xs"
                  colorScheme="teal"
                  onClick={onOpen}
                  variant={status === 'submitted' ? 'solid' : 'ghost'}>
                  Review
                </C.Button>
                <C.Button as={Link} to={`/content-submissions/${value}`} size="xs">
                  View
                </C.Button>
              </C.HStack>
            );
          },
        },
      ] as Column<TableRow>[],
    [setNewData]
  );
  const pageCount = total ? Math.ceil(total / pageSize) : 0;

  const tableInstance = useTable(
    {
      columns,
      data: contentSubmissionTasks,
      defaultColumn: {
        // This could be a default filter, but we don't use that anywhere now so just return empty
        // (`defaultColumn.Filter` is required when using col filters)
        Filter: <></>,
      },
      autoResetPage: false,
      // Pagination-related props. Since we fetch data from the server,
      // tell react-table that we need to do manual pagination
      pageCount,
      manualPagination: true,
      initialState: {
        pageIndex,
        pageSize,
        sortBy: sortBy?.map((rule) => ({ id: rule.id, desc: rule.isSortedDesc })),
        filters,
      },
      manualSortBy: true,
      manualFilters: true,
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const {
    state: { pageIndex: statePageIndex, pageSize: statePageSize, sortBy: stateSortBy },
  } = tableInstance;

  // We need to transmit page-related changes to the parent
  // component, so it fetches more data, changes the sorting
  // and so on.
  React.useEffect(() => {
    setPageIndex(statePageIndex);
  }, [setPageIndex, statePageIndex]);
  React.useEffect(() => {
    setPageSize(statePageSize);
  }, [setPageSize, statePageSize]);
  React.useEffect(() => {
    setSortBy(
      stateSortBy.map((i) => ({
        id: i.id,
        isSorted: typeof i.desc !== 'undefined',
        isSortedDesc: i.desc,
      }))
    );
  }, [stateSortBy, setSortBy]);

  return (
    <>
      <PropertyFilters filters={filters} setFilters={setFilters} />
      <ReactTableBase
        tableInstance={tableInstance}
        isLoading={isLoading}
        withColumnFilters
        withFilterButton
      />
    </>
  );
};

export default ContentSubmissionListingTable;
