import * as C from '@chakra-ui/react';
import { alterValueInFilters, Filters } from '../../helpers';
import { CampaignSelectorMulti, TeamSelectorMulti } from '../campaign-selector/CampaignSelector';

const campaignSearchUrlKey = 'deal.campaign_id';
const teamSearchUrlKey = 'team.id';

const DealsCampaignFilter = ({
  filters,
  onSetFilters,
}: {
  filters: Filters[];
  onSetFilters: (filters: Filters[]) => void;
}) => {
  const campaignF = filters.find((f) => f.id === campaignSearchUrlKey)?.value;
  const teamF = filters.find((f) => f.id === teamSearchUrlKey)?.value;

  const activeFiltersCount = (campaignF?.length || 0) + (teamF?.length || 0);

  return (
    <C.Menu closeOnSelect={false}>
      <C.MenuButton
        as={C.Button}
        size="sm"
        rightIcon={
          activeFiltersCount ? (
            <C.Tag size="sm" borderRadius="full" variant="solid" colorScheme="cyan">
              {activeFiltersCount}
            </C.Tag>
          ) : (
            <></>
          )
        }>
        Campaigns
      </C.MenuButton>
      <C.MenuList maxW="25rem">
        <C.MenuGroup title="Campaigns belong to">
          <C.Box px="var(--space-sm)">
            <TeamSelectorMulti
              initialSelectedTeamIds={filters.find((f) => f.id === teamSearchUrlKey)?.value || []}
              onSetSelectedTeams={(teams) => {
                const teamIds = teams?.map((t) => t.id.toString()) || [];
                onSetFilters(alterValueInFilters(filters, teamSearchUrlKey, teamIds));
              }}
            />
          </C.Box>
        </C.MenuGroup>

        <C.MenuDivider />
        <C.MenuGroup title="Campaign is one of">
          <C.Box px="var(--space-sm)">
            <CampaignSelectorMulti
              initialSelectedCampaignIds={
                filters.find((f) => f.id === campaignSearchUrlKey)?.value || []
              }
              onSetSelectedCampaigns={(campaigns) => {
                const campaignIds = campaigns?.map((c) => c.id.toString()) || [];
                onSetFilters(alterValueInFilters(filters, campaignSearchUrlKey, campaignIds));
              }}
            />
          </C.Box>
        </C.MenuGroup>
      </C.MenuList>
    </C.Menu>
  );
};

export default DealsCampaignFilter;
