import * as C from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import { alterValueInFilters, Filters } from '../../helpers';
import DateRangeFilter from './DateRangeFilter';

const published = 'content.published';
const submitted = 'content.submitted';
const status = 'content.status';

function VideoFilters({
  filters,
  onSetFilters,
}: {
  filters: Filters[];
  onSetFilters: (filters: Filters[]) => void;
}) {
  function onChange(filterId: string, newValue: string[] | null) {
    let nextFilters;

    if (newValue && newValue[0] === 'notPublished') {
      nextFilters = alterValueInFilters(filters, filterId, []);
      nextFilters = alterValueInFilters(
        nextFilters,
        'or',
        ['(content__status.is.null,content__status.not.eq.published)'],
        ''
      );
    } else {
      nextFilters = alterValueInFilters(filters, 'or', []);
      nextFilters = alterValueInFilters(nextFilters, filterId, newValue ? newValue : []);
    }

    onSetFilters(nextFilters);
  }

  const publishingDateF = filters.find((f) => f.id === published)?.value[0],
    publishingDateStartF = filters.find((f) => f.id === `${published}[0]`)?.value[0],
    publishingDateEndF = filters.find((f) => f.id === `${published}[1]`)?.value[0];

  const submittedDateF = filters.find((f) => f.id === submitted)?.value[0],
    submittedDateStartF = filters.find((f) => f.id === `${submitted}[0]`)?.value[0],
    submittedDateEndF = filters.find((f) => f.id === `${submitted}[1]`)?.value[0];

  const publishedF = filters.find((f) => f.id === status)?.value[0];
  const notPublishedF = filters.find(
    (f) =>
      f.id === 'or' && f.value[0] === '(content__status.is.null,content__status.not.eq.published)'
  )?.value[0];

  const activeFiltersCount =
    0 +
    (publishingDateF || publishingDateStartF || publishingDateEndF ? 1 : 0) +
    (submittedDateF || submittedDateStartF || submittedDateEndF ? 1 : 0) +
    (publishedF || notPublishedF ? 1 : 0);

  const publishedValue = notPublishedF ? 'notPublished' : publishedF || 'publishedAny';

  return (
    <C.Menu closeOnSelect={false}>
      <C.MenuButton
        as={C.Button}
        size="sm"
        rightIcon={
          activeFiltersCount ? (
            <C.Tag size="sm" borderRadius="full" variant="solid" colorScheme="cyan">
              {activeFiltersCount}
            </C.Tag>
          ) : (
            <></>
          )
        }>
        Content
      </C.MenuButton>
      <C.MenuList>
        <C.MenuOptionGroup title="Public and not public" value={publishedValue} type="radio">
          <C.MenuItemOption
            value="publishedAny"
            onClick={() => {
              onChange(status, null);
            }}>
            Any
          </C.MenuItemOption>
          <C.MenuItemOption value="published" onClick={() => onChange(status, ['published'])}>
            Public
          </C.MenuItemOption>
          <C.MenuItemOption value="notPublished" onClick={() => onChange(status, ['notPublished'])}>
            Not public
          </C.MenuItemOption>
        </C.MenuOptionGroup>

        <C.MenuDivider />
        <C.MenuGroup title="Video published in">
          <C.Box px="3">
            <DateRangeFilter filterId={published} onSetFilters={onSetFilters} filters={filters} />
          </C.Box>
        </C.MenuGroup>

        <C.MenuDivider />
        <C.MenuGroup title="Video submitted in">
          <C.Box px="3">
            <DateRangeFilter filterId={submitted} onSetFilters={onSetFilters} filters={filters} />
          </C.Box>
        </C.MenuGroup>
      </C.MenuList>
    </C.Menu>
  );
}

export default VideoFilters;
