import * as t from 'io-ts';
import * as Sentry from '@sentry/react';
import { isRight } from 'fp-ts/Either';
import { PathReporter } from 'io-ts/PathReporter';
import {
  genders,
  countries,
  ageGroups,
  operatingSystems,
  deviceTypes,
} from '../constants/demographics';
import { DetailsPerChannel, getChannelInfo } from '../helpers';
import { imtApi } from './imt-api';

const CollectionDecoder = t.exact(
  t.type({
    id: t.number,
    name: t.string,
  }),
  'Collection'
);

export type Collection = t.TypeOf<typeof CollectionDecoder>;

const DemographicsDecoder = t.partial(
  {
    gender: t.array(
      t.type({
        name: t.keyof(
          genders.reduce((acc, c) => {
            return { ...acc, [c]: null };
          }, {} as { [key: string]: null })
        ),
        value: t.number,
      }),
      'Gender type'
    ),
    country: t.array(
      t.type({
        name: t.keyof(
          countries.reduce((acc, c) => {
            return { ...acc, [c]: null };
          }, {} as { [key: string]: null })
        ),
        value: t.number,
      }),
      'Country type'
    ),
    ageGroup: t.array(
      t.type({
        name: t.keyof(
          ageGroups.reduce((acc, c) => {
            return { ...acc, [c]: null };
          }, {} as { [key: string]: null })
        ),
        value: t.number,
      }),
      'Age group type'
    ),
    operatingSystem: t.array(
      t.type({
        name: t.keyof(
          operatingSystems.reduce((acc, c) => {
            return { ...acc, [c]: null };
          }, {} as { [key: string]: null })
        ),
        value: t.number,
      }),
      'OS type'
    ),
    deviceType: t.array(
      t.type({
        name: t.keyof(
          deviceTypes.reduce((acc, c) => {
            return { ...acc, [c]: null };
          }, {} as { [key: string]: null })
        ),
        value: t.number,
      }),
      'DeviceType'
    ),
  },
  'Demographics'
);

const ViewShape = t.type(
  {
    high: t.number,
    low: t.number,
    median: t.number,
  },
  'ViewShape'
);

const Last30dStats = t.type(
  {
    averageCommentCount: t.number,
    averageDislikeCount: t.number,
    averageEngagementRatio: t.number,
    averageLikeCount: t.number,
    averageViewCount: t.number,
    gamingVideoPercentage: t.number,
    id: t.string,
    medianViewCount: t.number,
    totalCommentCount: t.number,
    totalDislikeCount: t.number,
    totalLikeCount: t.number,
    totalVideoCount: t.number,
    totalViewCount: t.number,
  },
  'Last30dStats'
);

const Estimates = t.type(
  {
    views: t.type(
      {
        youtube7d: ViewShape,
        youtube14d: ViewShape,
        youtube30d: ViewShape,
      },
      'Estimated views'
    ),
  },
  'Estimates'
);

const UnkownYoutubeChannel = t.intersection(
  [
    t.type({
      id: t.string,
      name: t.string,
      avatarUrl: t.union([t.null, t.string]),
      country: t.union([t.string, t.null]),
      estimatedLanguage: t.union([t.string, t.null]),
      subscriberCount: t.union([t.number, t.null]),
      estimates: t.partial({
        views: t.type(
          {
            youtube7d: ViewShape,
            youtube14d: ViewShape,
            youtube30d: ViewShape,
          },
          'Estimated views'
        ),
      }),
    }),
    t.partial({
      demographics: t.union([DemographicsDecoder, t.null]),
      last30dStats: t.union([Last30dStats, t.null]),
    }),
  ],
  'UnknownYoutubeChannel'
);

const InNetworkYoutubeChannelDecoder = t.exact(
  t.type({
    id: t.string,
    name: t.string,
    avatarUrl: t.union([t.null, t.string]),
    estimates: Estimates,
    demographics: DemographicsDecoder,
    last30dStats: Last30dStats,
  }),
  'InNetworkYoutubeChannelDecoder'
);

const YoutubeChannelOutOfNetworkDecoder = t.exact(
  t.type({
    id: t.string,
    name: t.string,
    avatarUrl: t.union([t.null, t.string]),
    // OON channel can have estimates: {}, but we don't want to create offer for them anyway,
    // so forcing type Esitmates will filter out channels without estimates in decoding
    estimates: Estimates,
    demographics: t.union([DemographicsDecoder, t.null]),
  }),
  'YoutubeChannelOutOfNetworkDecoder'
);

const CollectionResponseDecoder = t.type(
  {
    id: t.number,
    name: t.string,
    influencers: t.array(
      t.union([
        t.type(
          {
            id: t.number,
            isOutOfNetworkInfluencer: t.literal(false),
            youtubeChannels: t.array(UnkownYoutubeChannel),
          },
          'In-network creator'
        ),
        t.type(
          {
            id: t.null,
            isOutOfNetworkInfluencer: t.literal(true),
            youtubeChannels: t.array(UnkownYoutubeChannel),
          },
          'Out-of-network creator'
        ),
      ])
    ),
  },
  'Collections response'
);

const CollectionResponseWithChannelsDecoder = t.type(
  {
    name: t.string,
    channels: t.array(t.string),
  },
  'Collections response with channels'
);

export type Demographics = t.TypeOf<typeof DemographicsDecoder>;
export type InNetworkYoutubeChannel = t.TypeOf<typeof InNetworkYoutubeChannelDecoder>;
export type YoutubeChannelOutOfNetwork = t.TypeOf<typeof YoutubeChannelOutOfNetworkDecoder>;
export type CollectionResponse = t.TypeOf<typeof CollectionResponseDecoder>;
export type CollectionResponseWithChannels = t.TypeOf<typeof CollectionResponseWithChannelsDecoder>;

type CollectionObject = {
  youtubeChannels: InNetworkYoutubeChannel[];
  outOfNetworkYoutubeChannels: YoutubeChannelOutOfNetwork[];
  failedToParse: string[];
  detailsPerChannel: DetailsPerChannel;
};

export async function fetchCollections(): Promise<Collection[]> {
  const url = `/collections`;
  const res = await imtApi.get(url);
  const resData = res.data.data;

  if (resData === null || !Array.isArray(resData)) {
    console.error('Error log: IMT API response was not an array of objects', resData);
    throw new Error('IMT API response was not an array of objects');
  }

  const collections = resData.map((c) => {
    const ret = CollectionDecoder.decode(c);
    if (!isRight(ret)) {
      const report = PathReporter.report(c);
      console.error('Error log: Invalid collection in response object:', report);

      const err = new Error('Invalid collection');
      Sentry.captureException(err, { extra: { report } });
      throw err;
    }
    return ret.right;
  });

  return collections;
}

const logInvalidChannel = (
  channel: YoutubeChannelOutOfNetwork,
  validation: t.Validation<InNetworkYoutubeChannel | YoutubeChannelOutOfNetwork>,
  channelId: string
) => {
  const report = PathReporter.report(validation);
  console.warn('Invalid youtube channel in collection:', { report, channel, channelId });

  Sentry.captureException(new Error('Invalid youtube channel in collection'), {
    extra: { channel, channelId, report },
  });
};

export function splitInfluencersToInAndOutOfNetwork(data: CollectionResponse): CollectionObject {
  const youtubeChannels = data.influencers.reduce<CollectionObject>(
    (acc, cur) => {
      if (cur.isOutOfNetworkInfluencer) {
        // OON is decoded separately because these youtube channels might not have demographics
        const decoded = YoutubeChannelOutOfNetworkDecoder.decode(cur.youtubeChannels[0]);
        const channel = cur.youtubeChannels[0] as YoutubeChannelOutOfNetwork;
        const channelId = channel.id;
        if (!isRight(decoded)) {
          logInvalidChannel(channel, decoded, channelId);

          const failedIdentifyer = `[OON] ${channelId}`;

          return {
            ...acc,
            failedToParse: [...acc.failedToParse, failedIdentifyer], // OON doesn't have id so just pass 'OON influencer'
          };
        }
        acc.detailsPerChannel[channelId] = getChannelInfo('OON', channel);

        return {
          ...acc,
          outOfNetworkYoutubeChannels: [...acc.outOfNetworkYoutubeChannels, decoded.right],
          detailsPerChannel: acc.detailsPerChannel,
        };
      } else {
        // in-network channels

        const decoded = InNetworkYoutubeChannelDecoder.decode(cur.youtubeChannels[0]);

        const channel = cur.youtubeChannels[0] as InNetworkYoutubeChannel;
        const channelId = channel.id;
        if (!isRight(decoded)) {
          logInvalidChannel(channel, decoded, channelId);

          const failedIdentifyer = `[${cur.id.toString()}] ${channelId}`;

          return {
            ...acc,
            failedToParse: [...acc.failedToParse, failedIdentifyer],
          };
        }
        acc.detailsPerChannel[channelId] = getChannelInfo(cur.id.toString(), channel);
        return {
          ...acc,
          youtubeChannels: [...acc.youtubeChannels, decoded.right],
          detailsPerChannel: acc.detailsPerChannel,
        };
      }
    },
    {
      youtubeChannels: [],
      outOfNetworkYoutubeChannels: [],
      failedToParse: [],
      detailsPerChannel: {},
    }
  );

  return youtubeChannels;
}

export async function fetchCollectionWithInfluencers(id: string): Promise<CollectionResponse> {
  const url = `/collections/${id}?include=influencers`;
  const res = await imtApi.get(url);
  const resData = res.data.data;

  if (resData === null) {
    console.error('Error log: IMT API response was not a collection', resData);
    throw new Error('IMT API response was not a collection');
  }

  const decodedResponse = CollectionResponseDecoder.decode(resData);
  if (!isRight(decodedResponse)) {
    const report = PathReporter.report(decodedResponse);
    console.error('Error log: Invalid collection in response object:', report);

    const err = new Error(`Invalid collection ${report}`);
    Sentry.captureException(err, { extra: { report } });
    throw err;
  }

  return decodedResponse.right;
}

export async function fetchCollectionWithChannels(
  id: string
): Promise<CollectionResponseWithChannels> {
  const url = `/collections/${id}?include=channels`;
  const res = await imtApi.get(url);
  const resData = res.data.data;

  if (resData === null) {
    console.error('Error log: IMT API response was not a collection', resData);
    throw new Error('IMT API response was not a collection');
  }

  const decodedResponse = CollectionResponseWithChannelsDecoder.decode(resData);
  if (!isRight(decodedResponse)) {
    const report = PathReporter.report(decodedResponse);
    console.error('Error log: Invalid collection in response object:', report);

    const err = new Error('Invalid collection');
    Sentry.captureException(err, { extra: { report } });
    throw err;
  }

  return decodedResponse.right;
}

export async function createCollection(newCollection: {
  name: string;
  youtubeChannelIds: string[];
}): Promise<CollectionResponseWithChannels> {
  const res = await imtApi.post('/collections', newCollection);
  const resData = res.data.data;

  if (resData === null) {
    console.error('Error log: IMT API response was not a collection', resData);
    throw new Error('IMT API response was not a collection');
  }

  const decodedResponse = CollectionResponseWithChannelsDecoder.decode(resData);
  if (!isRight(decodedResponse)) {
    const report = PathReporter.report(decodedResponse);
    console.error('Error log: Invalid collection in response object:', report);

    const err = new Error('Invalid collection');
    Sentry.captureException(err, { extra: { report } });
    throw err;
  }

  return decodedResponse.right;
}
