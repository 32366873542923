import React from 'react';
import { createContainer } from 'react-tracked';
import { UserObject } from '../api-clients/imt-user';

export enum UserActionKind {
  SetUser = 'SET_USER',
}

type UserAction = {
  type: UserActionKind.SetUser;
  payload: { user: UserObject };
};

type State = {
  user: UserObject | null;
};

const Reducer = (state: State, action: UserAction) => {
  switch (action.type) {
    case UserActionKind.SetUser:
      const user = action.payload.user;
      return {
        ...state,
        user,
      };
    default:
      return state;
  }
};

const defaultInitialState = {
  user: null,
};

export const {
  Provider: UserStoreProvider,
  useTrackedState: useUserState,
  useUpdate: useUserDispatch,
} = createContainer(
  ({ initialState, children }: { initialState?: State; children?: React.ReactNode }) => {
    // allow setting an initial state when initializing store (mostly just for tests atm)
    const mergedInitialState = { ...defaultInitialState, ...initialState };
    return React.useReducer(Reducer, mergedInitialState);
  }
);
