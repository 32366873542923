import * as C from '@chakra-ui/react';
import { useAsync } from 'react-async-hook';
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form';
import { Campaign, fetchCampaigns } from '../../api-clients/imt-api-campaigns';
import { fetchTeams, Team } from '../../api-clients/imt-api-teams';
import AlertBox from '../alert-box/AlertBox';
import { DropdownSelect, DropdownSelectMulti } from '@sharkpunch/idun';
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';

export const CampaignSelectorMulti = ({
  initialSelectedCampaignIds,
  onSetSelectedCampaigns,
}: {
  initialSelectedCampaignIds?: string[];
  onSetSelectedCampaigns: (campaigns: Campaign[] | undefined) => void;
}) => {
  const [init, setInit] = useState<Campaign[]>([]);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);

  const doFetchCampaigns = useAsync(fetchCampaigns, [], {
    // sort campaigns by id, but reversed so that latest campaign is on top
    onSuccess: (result) => setCampaigns(result.sort((a, b) => b.id - a.id)),
  });

  useEffect(() => {
    if (initialSelectedCampaignIds && campaigns) {
      const newInit = initialSelectedCampaignIds.map((campaignId) => {
        const campaign = campaigns.find((c) => c.id === parseInt(campaignId));
        return (
          campaign || {
            id: parseInt(campaignId),
            name: campaignId,
            archived: false,
            contentPlatforms: ['youtube'],
          }
        );
      });
      setInit(newInit);
    }
  }, [initialSelectedCampaignIds, campaigns]);

  return (
    <C.Box>
      {doFetchCampaigns.loading ? (
        <C.Spinner />
      ) : doFetchCampaigns.result ? (
        <DropdownSelectMulti<Campaign>
          limitItems={30}
          initialItems={init}
          inputPlaceholder="Select campaigns..."
          onChange={onSetSelectedCampaigns}
          items={doFetchCampaigns.result}
          itemToLabel={(item) => `[${item.id}] ${item.name}`}
        />
      ) : (
        'No campaigns found'
      )}
    </C.Box>
  );
};

export const TeamSelectorMulti = ({
  initialSelectedTeamIds,
  onSetSelectedTeams,
}: {
  initialSelectedTeamIds?: string[];
  onSetSelectedTeams: (team: Team[] | undefined) => void;
}) => {
  const [init, setInit] = useState<Team[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);

  const doFetchTeams = useAsync(fetchTeams, [], {
    // sort teams by id, but reversed so that latest team is on top
    onSuccess: (result) => setTeams(result.sort((a, b) => b.id - a.id)),
  });

  useEffect(() => {
    if (initialSelectedTeamIds && teams) {
      const newInit = initialSelectedTeamIds.map((teamId) => {
        const team = teams.find((t) => t.id === parseInt(teamId));
        return team || { id: parseInt(teamId), name: teamId };
      });
      setInit(newInit);
    }
  }, [initialSelectedTeamIds, teams]);

  return (
    <C.Box>
      {doFetchTeams.loading ? (
        <C.Spinner />
      ) : doFetchTeams.result ? (
        <DropdownSelectMulti<Team>
          limitItems={10}
          initialItems={init}
          inputPlaceholder="Select advertiser..."
          onChange={onSetSelectedTeams}
          items={doFetchTeams.result.sort((a, b) => b.id - a.id)}
          itemToLabel={(item) => `${item.name}`}
        />
      ) : (
        'No advertisers found'
      )}
    </C.Box>
  );
};

const CampaignSelector = <FormFieldsTypes extends FieldValues>({
  name,
  errors,
  control,
  required,
  setCampaigns,
  onChangeProp,
  setSelectedPlatform,
  hideLabel,
}: {
  name: Path<FormFieldsTypes>;
  errors: FieldError | undefined;
  control: Control<FormFieldsTypes>;
  required: boolean;
  setCampaigns?: (campaigns: Campaign[]) => void;
  onChangeProp?: Function;
  hideLabel?: boolean;
  setSelectedPlatform?: (platform: 'twitch' | 'youtube') => void;
}) => {
  const {
    result: campaigns,
    loading,
    error,
  } = useAsync(fetchCampaigns, [], {
    onSuccess: (result) => {
      if (setCampaigns) {
        setCampaigns(result);
      }
    },
  });

  let content;
  if (loading) {
    content = (
      <C.FormControl>
        {!hideLabel && <C.FormLabel htmlFor="video_publishing_date_start">Campaign</C.FormLabel>}
        <C.InputGroup>
          <C.Input name="campaign" disabled placeholder="Loading..." />
          <C.InputRightElement children={<C.Spinner />} />
        </C.InputGroup>
      </C.FormControl>
    );
  } else if (error) {
    content = (
      <AlertBox>
        {`Failed to fetch campaigns${error.message ? `: [${error.message}].` : '.'}`}
      </AlertBox>
    );
  } else if (campaigns) {
    const items = sortBy(campaigns, (c) => {
      return c.id;
    })
      .filter((c) => !c.archived)
      .reverse();
    content = (
      <C.FormControl isInvalid={!!errors}>
        {!hideLabel && <C.FormLabel>Campaign</C.FormLabel>}
        <Controller
          render={({ field: { onChange, value } }) => (
            <DropdownSelect<Campaign>
              limitItems={30}
              inputPlaceholder="Select campaign..."
              onChange={(i) => {
                const id = i?.id ? String(i.id) : '';
                if (onChangeProp) {
                  onChangeProp(id);
                } else {
                  onChange(id);
                }
                const platform = i?.contentPlatforms[0];
                if (
                  setSelectedPlatform &&
                  platform &&
                  (platform === 'youtube' || platform === 'twitch')
                ) {
                  setSelectedPlatform(platform);
                }
              }}
              items={items}
              initialItem={campaigns.find((c) => {
                return c.id === parseInt(value);
              })}
              itemToLabel={(c) => `[${c.id}][${c.contentPlatforms[0]}] ${c.name}`}
            />
          )}
          control={control}
          rules={{
            required,
            pattern: { value: /\d+/, message: 'Campaign id should be a number' },
          }}
          name={name}
        />
        {errors?.message && <C.FormErrorMessage>{errors.message}</C.FormErrorMessage>}
      </C.FormControl>
    );
  } else {
    content = <AlertBox>No campaigns found</AlertBox>;
  }

  return content;
};

export default CampaignSelector;
