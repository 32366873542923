import * as C from '@chakra-ui/react';
import ReactPlayer from 'react-player/youtube';
import { EmptyState, ModalBase } from '@sharkpunch/idun';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import {
  approveContentSubmissionTask,
  ContentSubmissionTask,
  requestChangesForContentSubmissionTask,
} from '../../api-clients/imt-api-content-submission';
import { getYoutubeId, showErrorToast, showSuccessToast } from '../../helpers';
import {
  CheckCircleIcon,
  CheckIcon,
  CopyIcon,
  ExternalLinkIcon,
  TimeIcon,
  WarningIcon,
} from '@chakra-ui/icons';
import { useUserState } from '../../stores/UserStore';
import { CREATOR_APP_URL } from '../../config';

type FormData = { status_comment: string };

// Arbitrary limit, mostly to account for the fact that
// the change request comments get sent as chat messages
// in campaign-api, and those chat messages have length
// limit, and sending too long comments fails silently
// there. Also for the creators it's nicer if the
// comments they get are concise and not too long
const CHANGE_REQUEST_MAX_LENGTH = 1000;

const RequestChangesModal = ({
  onClose,
  onSubmit,
  isOpen,
}: {
  onClose: () => void;
  onSubmit: (data: FormData) => void;
  isOpen: boolean;
}) => {
  const {
    watch,
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      status_comment: '',
    },
  });

  const watchStatusComment = watch('status_comment');

  const onSubmitReason = (data: FormData) => {
    onSubmit(data);
    onClose();
  };

  return (
    <ModalBase header="Request changes to content" onClose={onClose} isOpen={isOpen}>
      <C.Text pb="var(--space-sm)" fontSize="sm">
        A short introduction will be prepended to this message, so you can limit your message to
        only include the desired changes you want to request from the creator.
      </C.Text>
      <form onSubmit={handleSubmit(onSubmitReason)}>
        <C.FormControl isInvalid={!!errors.status_comment}>
          <C.FormLabel>Description of required changes</C.FormLabel>
          <C.Textarea
            placeholder="What should the creator change?"
            {...register('status_comment', {
              required: true,
              maxLength: CHANGE_REQUEST_MAX_LENGTH,
            })}
          />
          {errors.status_comment && errors.status_comment.type === 'maxLength' ? (
            <C.FormErrorMessage>
              {watchStatusComment.length} / {CHANGE_REQUEST_MAX_LENGTH} (too long)
            </C.FormErrorMessage>
          ) : (
            <C.FormHelperText>
              {watchStatusComment.length} / {CHANGE_REQUEST_MAX_LENGTH}
            </C.FormHelperText>
          )}
        </C.FormControl>
        <C.HStack spacing={3} mt={8} justifyContent="flex-end">
          <C.Button onClick={onClose}>Cancel</C.Button>
          <C.Button disabled={!isValid} colorScheme="cyan" type="submit">
            Request changes
          </C.Button>
        </C.HStack>
      </form>
    </ModalBase>
  );
};

const ApproveModal = ({
  onClose,
  onApprove,
  isOpen,
}: {
  onClose: () => void;
  onApprove: () => void;
  isOpen: boolean;
}) => {
  return (
    <ModalBase header="Approve content" onClose={onClose} isOpen={isOpen}>
      <C.Text>
        An automatic approval message will be sent to the creator. You can modify this message under
        Edit campaign.
      </C.Text>
      <C.Text my={3}>
        Note that no message will be sent if there is no automatic approval message set up.
      </C.Text>
      <C.HStack spacing={3} justifyContent="flex-end">
        <C.Button onClick={onClose}>Cancel</C.Button>
        <C.Button
          colorScheme="cyan"
          onClick={() => {
            onApprove();
            onClose();
          }}>
          Approve
        </C.Button>
      </C.HStack>
    </ModalBase>
  );
};

function ContentSubmissionReview({
  contentSubmissionTask,
  approveTask,
  requestForChanges,
}: {
  contentSubmissionTask: ContentSubmissionTask;
  approveTask: (newTaskData: ContentSubmissionTask) => void;
  requestForChanges: (newTaskData: ContentSubmissionTask) => void;
}) {
  const latestSubmission = contentSubmissionTask.content_submission;
  const { hasCopied, onCopy } = C.useClipboard(latestSubmission.url || '');
  const { user: admin } = useUserState();

  const requestChangesModal = C.useDisclosure();
  const approveModal = C.useDisclosure();

  const doRequestChanges = async (data: { status_comment: string }) => {
    try {
      const newData = await requestChangesForContentSubmissionTask({
        id: contentSubmissionTask.id || '',
        status_comment: data.status_comment,
        admin_id: admin?.token.accountId || 0,
      });
      requestForChanges(newData);
      showSuccessToast('Success', 'Request for changes to content submission have been saved.');
    } catch {
      showErrorToast('Error', 'Request for changes to content submission task failed. Try again?');
    }
  };

  const doApprove = async () => {
    if (!admin?.token.accountId) {
      showErrorToast(
        'Failed to approve content',
        'Seems like there is something wrong with your login data, please log out and log in and try again.'
      );
      throw new Error('User accountId was not found');
    }
    try {
      const newData = await approveContentSubmissionTask({
        id: contentSubmissionTask.id,
        admin_id: admin.token.accountId,
        status_comment: null,
      });
      approveTask(newData);
      showSuccessToast('Success', 'Content submission task has been approved.');
    } catch {
      showErrorToast('Error', 'Content submission task failed to approve. Try again?');
    }
  };

  return (
    <C.Box>
      {latestSubmission?.url ? (
        <C.Stack spacing="var(--space-md)">
          {latestSubmission.submitted && (
            <C.Text>
              Submitted on {format(Date.parse(latestSubmission.submitted), 'do MMM yyyy, HH:mm')}
            </C.Text>
          )}

          {getYoutubeId(latestSubmission.url) && (
            <ReactPlayer
              url={latestSubmission.url}
              controls
              width="100%"
              config={{
                playerVars: { origin: window.location.origin + '/' },
              }}
            />
          )}

          <C.FormControl>
            <C.FormLabel>Submitted URL</C.FormLabel>
            <C.HStack>
              <C.Link
                isExternal
                href={latestSubmission.url}
                target="_blank"
                title="Open in new tab"
                aria-label="Open link in new tab">
                {latestSubmission.url}
                <ExternalLinkIcon mx="1" />
              </C.Link>
              <C.IconButton
                colorScheme={hasCopied ? 'green' : 'gray'}
                onClick={onCopy}
                icon={<CopyIcon />}
                title="Copy URL to clipboard"
                aria-label="Copy URL to clipboard"
                size="sm"
              />
            </C.HStack>
          </C.FormControl>

          <C.FormControl>
            <C.FormLabel>Creator checklist</C.FormLabel>
            {contentSubmissionTask.user_inputs.map((input, i) => {
              if (input.user_input_type === 'boolean') {
                return (
                  <C.HStack alignItems="flex-start" key={i}>
                    {input.boolean_value ? (
                      <CheckCircleIcon mt="1" color="green" />
                    ) : (
                      <WarningIcon mt="1" color="gray" />
                    )}
                    <C.Text layerStyle="textDark" opacity={input.boolean_value ? 1 : 0.5}>
                      {input.description}
                    </C.Text>
                  </C.HStack>
                );
              }
              return null;
            })}
          </C.FormControl>
          <C.HStack justifyContent="flex-end">
            <C.Button
              isDisabled={
                contentSubmissionTask.status === 'approved' ||
                contentSubmissionTask.status === 'request_for_changes'
              }
              leftIcon={
                contentSubmissionTask.status === 'request_for_changes' ? <TimeIcon /> : undefined
              }
              onClick={requestChangesModal.onOpen}>
              {contentSubmissionTask.status === 'request_for_changes'
                ? 'Changes requested'
                : 'Request changes'}
            </C.Button>
            <C.Button
              onClick={approveModal.onOpen}
              leftIcon={contentSubmissionTask.status === 'approved' ? <CheckIcon /> : undefined}
              colorScheme="cyan"
              isDisabled={
                contentSubmissionTask.status === 'approved' ||
                contentSubmissionTask.status === 'request_for_changes'
              }>
              {contentSubmissionTask.status === 'approved' ? 'Approved' : 'Approve'}
            </C.Button>
            <RequestChangesModal
              isOpen={requestChangesModal.isOpen}
              onSubmit={doRequestChanges}
              onClose={requestChangesModal.onClose}
            />
            <ApproveModal
              isOpen={approveModal.isOpen}
              onApprove={doApprove}
              onClose={approveModal.onClose}
            />
          </C.HStack>
        </C.Stack>
      ) : (
        <EmptyState
          title="No draft yet"
          description="Wait for the creator to submit the draft, or remind them in the chat."
        />
      )}
      <C.Tooltip
        label="When creator fails to submit a draft in the portal themselves, admins can follow this link
          to do it for them. Please use with caution and don't share this link, this opens the same
          page that creators use."
        hasArrow
        placement="bottom-start">
        <C.Box mt="20">
          <C.Text>Submit content as admin:</C.Text>
          <C.Link
            href={`${CREATOR_APP_URL}/content-submission/${contentSubmissionTask.id}`}
            isExternal>
            Open creator's content submission portal <ExternalLinkIcon mx="1" />
          </C.Link>
        </C.Box>
      </C.Tooltip>
    </C.Box>
  );
}

export default ContentSubmissionReview;
