import React from 'react';
import _ from 'lodash';
import * as C from '@chakra-ui/react';
import { DropdownSelectMulti } from '@sharkpunch/idun';
import { CountryGroup } from './Collections';
import {
  CountryCodeAndNamePair,
  countryCodesAndNamePairs,
  countryCodesAndNames,
} from '../../constants/demographics';

const defaultCountryGroup = {
  name: '',
  countries: [],
};

export const CountryGroupSelector = ({
  selectedCountryGroups,
  setSelectedCountryGroups,
}: {
  selectedCountryGroups: CountryGroup[];
  setSelectedCountryGroups: React.Dispatch<React.SetStateAction<CountryGroup[]>>;
}) => {
  const [currentCountryGroup, setCurrentCountryGroup] =
    React.useState<CountryGroup>(defaultCountryGroup);

  const availableCountriesToSelect = React.useMemo(() => {
    const selected = selectedCountryGroups
      .map((c) => c.countries)
      .flat()
      .concat(currentCountryGroup.countries);
    return countryCodesAndNamePairs.filter((c) => {
      return !selected.includes(c.id);
    });
  }, [currentCountryGroup, selectedCountryGroups]);

  const handleNewCountryInput = (selected?: CountryCodeAndNamePair[]) => {
    if (!selected) {
      return;
    }
    const newCountryGroup: CountryGroup = _.cloneDeep(currentCountryGroup);
    newCountryGroup.countries = selected.map((s) => s.id);
    setCurrentCountryGroup(newCountryGroup);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const current = _.cloneDeep(currentCountryGroup);
    current.name = e.target.value;
    setCurrentCountryGroup(current);
  };

  return (
    <C.Box>
      {selectedCountryGroups.map((c) => (
        <C.Tag p="4" mt="4" mr="2" key={c.name} size="lg" colorScheme="cyan" borderRadius="md">
          <C.Stack>
            <C.Text as="b" fontSize="20">
              {c.name}
            </C.Text>
            <C.Text fontSize="10">{c.countries.join(', ')}</C.Text>
          </C.Stack>
          <C.TagCloseButton
            onClick={() => {
              const selected = _.cloneDeep(selectedCountryGroups);
              _.remove(selected, (s) => _.isEqual(s.countries.sort(), c.countries.sort()));
              setSelectedCountryGroups(selected);
            }}
          />
        </C.Tag>
      ))}
      <C.Stack mt="4">
        <C.Box mb="1" w="lg">
          <DropdownSelectMulti<CountryCodeAndNamePair>
            limitItems={30}
            initialItems={currentCountryGroup.countries.map((c) => {
              const country = countryCodesAndNames[c as keyof typeof countryCodesAndNames];
              return { id: c, label: country };
            })}
            inputPlaceholder="Select countries..."
            onChange={handleNewCountryInput}
            items={availableCountriesToSelect}
            itemToLabel={(item) => `[${item.id}] ${item.label}`}
          />
        </C.Box>
        <C.Input
          mb="1"
          w="lg"
          type="text"
          placeholder="Country group name"
          onChange={handleInputChange}
          value={currentCountryGroup.name}
        />
        <C.Button
          w="xs"
          onClick={() => {
            const currentCountryGroups = _.cloneDeep(selectedCountryGroups);
            setSelectedCountryGroups(currentCountryGroups.concat(currentCountryGroup));
            setCurrentCountryGroup(defaultCountryGroup);
          }}>
          Add group
        </C.Button>
      </C.Stack>
    </C.Box>
  );
};
