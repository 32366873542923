import * as C from '@chakra-ui/react';
import { FailedOffer } from '../../useSendOffers';

export function RevokeOffersInfoModal(props: {
  succeeded: number;
  failed: FailedOffer[];
  totalOffersCount: number;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { succeeded, failed, totalOffersCount, isOpen, onClose } = props;

  const isAllSucceeded = succeeded === totalOffersCount;
  const isSomeFailed = succeeded + failed.length === totalOffersCount && failed.length > 0;
  const isRevokingCompleted = isAllSucceeded || isSomeFailed;
  const alertTitle = isAllSucceeded
    ? 'Offers successfully revoked'
    : 'Some offers failed to be revoked';
  const alertStatus = isAllSucceeded ? 'success' : 'warning';
  return (
    <>
      <C.Modal isOpen={isOpen} onClose={onClose}>
        <C.ModalOverlay />
        <C.ModalContent minWidth="lg">
          <C.ModalHeader>Revoking offers</C.ModalHeader>
          <C.ModalCloseButton />
          <C.ModalBody>
            <C.Progress
              size="md"
              min={0}
              max={totalOffersCount}
              value={succeeded + failed.length}
            />
            Currently revoked offers: {`${succeeded + failed.length} out of ${totalOffersCount}`}
            {isRevokingCompleted && (
              <C.Alert
                status={alertStatus}
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                padding={10}
                borderRadius="md"
                mt={4}>
                <C.AlertIcon boxSize="40px" mr={0} />
                <C.AlertTitle mt={4} mb={1} fontSize="lg">
                  {alertTitle}
                </C.AlertTitle>

                {isSomeFailed && (
                  <C.Accordion allowToggle mt={4}>
                    <C.AccordionItem>
                      <h2>
                        <C.AccordionButton>
                          <C.Box flex="1" textAlign="left" minWidth="sm">
                            Click here to see which offers to revoked and why
                          </C.Box>
                          <C.AccordionIcon />
                        </C.AccordionButton>
                      </h2>
                      <C.AccordionPanel>
                        <C.Table>
                          <C.TableCaption>Failed to revoke: {failed.length} offers</C.TableCaption>
                          <C.Thead>
                            <C.Tr>
                              <C.Th>Channel id</C.Th>
                              <C.Th>Failure reason</C.Th>
                            </C.Tr>
                          </C.Thead>
                          <C.Tbody>
                            {failed.map((f) => (
                              <C.Tr key={f.channelId}>
                                <C.Td>
                                  <C.Text fontSize="xs">{f.channelId}</C.Text>
                                </C.Td>
                                <C.Td>
                                  <C.Text fontSize="xs">{f.reason}</C.Text>
                                </C.Td>
                              </C.Tr>
                            ))}
                          </C.Tbody>
                        </C.Table>
                      </C.AccordionPanel>
                    </C.AccordionItem>
                  </C.Accordion>
                )}
              </C.Alert>
            )}
          </C.ModalBody>

          <C.ModalFooter>
            <C.Button colorScheme="blue" onClick={onClose}>
              Close
            </C.Button>
          </C.ModalFooter>
        </C.ModalContent>
      </C.Modal>
    </>
  );
}
