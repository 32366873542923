import * as C from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

function ChakraMarkdown({ content }: { content: string }) {
  return (
    <ReactMarkdown
      children={content}
      remarkPlugins={[remarkGfm]}
      components={{
        // whiteSpace="pre-wrap" helps to handle cases, where the content contains
        // a single line-break, which by default markdown just omits.
        p: ({ node, ...props }) => <C.Text mb={4} whiteSpace="pre-wrap" {...props} />,
        a: ({ node, ...props }) => <C.Link target="_blank" {...props} />,
        h1: ({ node, ...props }) => <C.Heading mb={2} as="h1" size="xl" {...props} />,
        h2: ({ node, ...props }) => <C.Heading mb={2} as="h2" size="lg" {...props} />,
        h3: ({ node, ...props }) => <C.Heading mb={2} as="h3" size="md" {...props} />,
        h4: ({ node, ...props }) => <C.Heading mb={2} as="h4" size="sm" {...props} />,
        h5: ({ node, ...props }) => <C.Heading mb={2} as="h5" size="xs" {...props} />,
        // Need to exclude ordered prop, because it has special meaning
        // in Chakra elements, which isn't compatible with react-markdown
        // (and we don't care about it anyway)
        // https://github.com/remarkjs/remark/discussions/873
        ul: ({ node, ordered, ...props }) => (
          <C.UnorderedList sx={{ listStylePosition: 'outside' }} mb={4} {...props} />
        ),
        ol: ({ node, ordered, ...props }) => (
          <C.OrderedList sx={{ listStylePosition: 'outside' }} mb={4} {...props} />
        ),
        li: ({ node, ordered, ...props }) => <C.ListItem {...props} />,
      }}
    />
  );
}

export default ChakraMarkdown;
