import * as Sentry from '@sentry/react';
import { isRight } from 'fp-ts/Either';
import * as t from 'io-ts';
import { PathReporter } from 'io-ts/PathReporter';
import { imtApi } from './imt-api';

const adminUrl = `/accounts/list-admins`;

const AdminAccount = t.type(
  {
    id: t.number,
    displayName: t.string,
    avatarUrl: t.union([t.string, t.null]),
    email: t.string,
  },
  'Admin account'
);

type AdminAccountType = t.TypeOf<typeof AdminAccount>;

export async function fetchAdmins(): Promise<AdminAccountType[]> {
  const res = await imtApi.get(adminUrl);
  const admins = res.data.data;

  if (!Array.isArray(admins)) {
    throw new Error("List admins API did not return anything or didn't return an array");
  }
  const adminsDecoded = admins.map((a) => {
    const ret = AdminAccount.decode(a);
    if (!isRight(ret)) {
      console.error('Error log: Invalid admin in list of admins:', PathReporter.report(ret));
      const err = new Error('Invalid admin in list of admins');
      Sentry.captureException(err, { extra: { leftObject: ret.left } });
      throw err;
    }
    return ret.right;
  });

  return adminsDecoded.sort((a, b) => (a.displayName < b.displayName ? -1 : 1));
}
