import * as t from 'io-ts';
import { isRight } from 'fp-ts/Either';
import { PathReporter } from 'io-ts/PathReporter';
import { imtApi } from './imt-api';

const outreachUrl = `/admin/outreaches`;

const BatchOutreachDecoder = t.exact(
  t.type({
    id: t.string,
    name: t.string,
    created: t.string,
  }),
  'Batch outreach'
);

const BatchOutreachStatsDecoder = t.exact(
  t.type({
    batch_outreach_id: t.string,
    total_count: t.number,
    completed_count: t.number,
    fetch_email_failed_count: t.number,
    send_notification_failed_count: t.number,
    no_email_found_count: t.number,
  }),
  'Batch outreach stats'
);

const ConditionalOfferIdResultDecoder = t.array(t.string, 'ConditionalOfferIdResultDecoder');
export type ConditionalOfferIdResult = t.TypeOf<typeof ConditionalOfferIdResultDecoder>;

const NotificationTemplateDecoder = t.type(
  {
    id: t.string,
  },
  'Notification template'
);
type NotificationTemplate = t.TypeOf<typeof NotificationTemplateDecoder>;

export type BatchOutreach = t.TypeOf<typeof BatchOutreachDecoder>;
export type BatchOutreachStats = t.TypeOf<typeof BatchOutreachStatsDecoder>;

export async function fetchBatches(): Promise<BatchOutreach[]> {
  const url = `${outreachUrl}/v1/batches`;
  const res = await imtApi.get(url);
  const resData = res.data.data;

  if (resData === null || !Array.isArray(resData)) {
    console.error('Error log: IMT API response was not an array of objects', resData);
    throw new Error('IMT API response was not an array of objects');
  }

  const batches = resData.map((c) => {
    const decoded = BatchOutreachDecoder.decode(c);
    if (!isRight(decoded)) {
      console.error(
        'Error log: Invalid batch outreach in response object:',
        PathReporter.report(decoded)
      );
      throw new Error('Invalid batch outreach');
    }
    return decoded.right;
  });

  return batches;
}

export async function fetchBatchStats(id: string): Promise<BatchOutreachStats> {
  const url = `${outreachUrl}/v1/batches/${id}/stats`;
  const res = await imtApi.get(url);
  const resData = res.data.data;

  if (resData === null) {
    console.error('Error log: IMT API response did not contain stats', resData);
    throw new Error('IMT API response did not contain stats');
  }

  const decoded = BatchOutreachStatsDecoder.decode(resData);
  if (!isRight(decoded)) {
    console.error(
      'Error log: Invalid batch outreach stats in response object:',
      PathReporter.report(decoded)
    );
    throw new Error('Invalid batch outreach stats');
  }
  const stats = decoded.right;

  return stats;
}

export async function fetchUnsentConditionalOfferIdsByCampaignId(
  campaign_id: string
): Promise<ConditionalOfferIdResult> {
  const url = `${outreachUrl}/v1/outreach-tasks/unsent-outreach-tasks/${campaign_id}`;
  const res = await imtApi.get(url);
  const resData = res.data.data;

  if (resData === null) {
    console.error('Error log: IMT API response did not contain conditional offer ids', resData);
    throw new Error('IMT API response did not contain conditional offer ids');
  }

  const decoded = ConditionalOfferIdResultDecoder.decode(resData);
  if (!isRight(decoded)) {
    console.error(
      'Error log: Invalid conditional offer id list in unsent outreach response object:',
      PathReporter.report(decoded)
    );
    throw new Error('Invalid conditional offer id list in unsent outreach response');
  }
  const ids = decoded.right;

  return ids;
}

export async function createOutreachBatch(batch_name: string): Promise<BatchOutreach> {
  const url = `${outreachUrl}/v1/batches`;
  const res = await imtApi.post(url, { name: batch_name });
  const resData = res.data.data;

  if (resData === null) {
    console.error('Error log: IMT API response did not contain batch data', resData);
    throw new Error('IMT API response did not contain batch data');
  }

  const decoded = BatchOutreachDecoder.decode(resData);
  if (!isRight(decoded)) {
    console.error(
      'Error log: Invalid batch info in outreach batch response object:',
      PathReporter.report(decoded)
    );
    throw new Error('Invalid batch info in outreach response');
  }
  const batch = decoded.right;

  return batch;
}

export async function createOutreachNotification(
  notification: Object
): Promise<NotificationTemplate> {
  const url = `${outreachUrl}/v1/notification-templates`;
  const res = await imtApi.post(url, { value: notification });
  const resData = res.data.data;

  if (resData === null) {
    console.error(
      'Error log: IMT API response did not contain notification template data',
      resData
    );
    throw new Error('IMT API response did not contain notification template data');
  }

  const decoded = NotificationTemplateDecoder.decode(resData);
  if (!isRight(decoded)) {
    console.error(
      'Error log: Invalid data in outreach notification object:',
      PathReporter.report(decoded)
    );
    throw new Error('Invalid data in outreach notification response');
  }
  const notificationId = decoded.right;

  return notificationId;
}

export async function createOutreachTask(params: {
  batch_id: string;
  notification_template_id: string;
  conditional_offer_ids: ConditionalOfferIdResult;
}): Promise<void> {
  const { batch_id, notification_template_id, conditional_offer_ids } = params;
  const url = `${outreachUrl}/v1/batches/${batch_id}/outreach-tasks`;
  await imtApi.post(url, { notification_template_id, conditional_offer_ids });
  return;
}
