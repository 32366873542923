// This utility calculates CPM based on channels demographics.
// It is intended to operate on an array of `YoutubeChannels` objects
// that we usually get as part of IMT "influencer" object.
// Each youtube channel should contain `demographics` prop, where
// each of the topLevelDemographicsProps is an array of
// { name: 'SomeLabel', value: SomeInt },
// and estimates prop, that should carry views estimates for
// 7, 14 and 30 days (the so-called "old new estimates").
//
// Another part of the equation would be a list of shaping rules.
// One kind of those rules could be CPMRule, which define
// the cpm per percentage of relevant demographics prop.
// For instance:
// [
//   { key: 'country.US', cpmPerPercentage: 0.1 },
//   { key: 'gender.female', cpmPerPercentage: 0.25 },
// ]
//
// Another kind of rule could be post-processing filter, to define max cap
// or lower boundary for the offers we generate:
// [
//  { minPrice: 100 } // exclude offers below $100
// ]
//
// Returned price is in dollars (since that's what Njord expects).
//
// Loosely based on https://github.com/SharkPunch/bulk-cpm-tool-proto/blob/master/src/App.tsx
import * as t from 'io-ts';
import curry from 'lodash/curry';

import {
  Deliverable,
  OfferCreateRequest,
  ConditionalOfferCreateRequest,
  CPMRuleT,
  CPMRuleWithDimensionT,
  DemographicFilterT,
} from './helpers';
import type {
  Demographics,
  InNetworkYoutubeChannel,
  YoutubeChannelOutOfNetwork,
} from './api-clients/imt-api-collections';
import type { MetaItem } from './components/bulk-offer/PreviewTable';
import shuffle from 'lodash/shuffle';
import sumBy from 'lodash/sumBy';
import { SubmitDataDecoder } from './components/bulk-offer/BulkOfferForm';

type BaseOfferProps = {
  viewEstimateRange: ViewEstimateRangeT;
  cpmRules: CPMRuleT[];
  priceFilters: PostProcessingRuleT[];
  roundPrice: boolean | null;
  defaultCommissionRate: number;
  demographicFilters?: DemographicFilterT[];
  cpmCap?: number;
  expires: string;
  campaignId: string;
  budgetId?: string;
  offerParams: t.TypeOf<typeof SubmitDataDecoder>;
  deliverable: Deliverable;
};

type CpiOfferProps = BaseOfferProps & {
  cpi: string | null;
  minFeeFactor: number | null;
  maxCapFactor: number | null;
  actionsThreshold: number;
};

export type OfferProps = BaseOfferProps | CpiOfferProps;

type ConditionalOfferProps = {
  viewEstimateRange: ViewEstimateRangeT;
  cpmRules: CPMRuleWithDimensionT[];
  priceFilters: PostProcessingRuleT[];
  demographicFilters?: DemographicFilterT[];
  cpmCap?: number;
  expires: string;
  campaignId: string;
  budgetId?: string;
  offerParams: t.TypeOf<typeof SubmitDataDecoder>;
  deliverable: Deliverable;
};

export const filterByDemographics = (
  filters: DemographicFilterT[],
  channelDemographics: Demographics
): boolean => {
  return filters.some((filter) => {
    const count = filter.names.reduce((c, filterName) => {
      const channelDemographic = channelDemographics[filter.dimension]?.find(
        (d) => d.name === filterName
      );
      if (channelDemographic) {
        // count all values of included filters
        c = c + channelDemographic.value;
      }
      return c;
    }, 0);
    return !(count >= (filter.min_threshold || 0) && count <= (filter.max_threshold || 100));
  });
};

const calculateFixedFee = ({
  channel,
  viewEstimateRange,
  cpmRules,
  cpmCap,
}: {
  channel: InNetworkYoutubeChannel;
  viewEstimateRange: ViewEstimateRangeT;
  cpmRules: CPMRuleT[];
  cpmCap?: number;
}) => {
  const demographics = flattenDemographics(channel.demographics);
  const views = channel.estimates.views[viewEstimateRange].median;

  const cpm = Math.min(
    cpmRules
      // price each matching demographics percentage by cpm rules.
      .map((r) => demographics[r.name] * parseFloat(r.cpmPerPercentage))
      // add all that was priced
      .reduce((a, b) => a + (b || 0), 0),
    cpmCap || Number.MAX_SAFE_INTEGER
  );

  const price =
    cpm *
    // multiply by estimated views / 1000 (CPM aka cost per thousand) to get fixed fee price
    (views / 1000);
  return { price, cpm: String(cpm), views: views.toFixed(2) };
};

export function roundDownPrice(price: number): number {
  // non-scientific "sensible" rounding thresholds where
  // the round-down shouldn't matter too much for the creators
  if (price < 300) {
    return Math.floor(price / 5) * 5;
  } else if (price < 800) {
    return Math.floor(price / 25) * 25;
  } else if (price < 1300) {
    return Math.floor(price / 50) * 50;
  } else {
    return Math.floor(price / 100) * 100;
  }
}

export function calculateCommissionRate(payout: number, targetAdvertiserFee: number): number {
  return (targetAdvertiserFee - payout) / targetAdvertiserFee;
}

const hasCpiProps = (o: OfferProps): o is CpiOfferProps => !!(o as unknown as CpiOfferProps).cpi;

// This creates an offer from "offer & pricing rules", that can be then used
// either as mapper to convert a list of channels, or as usual function to convert
// one channel to an offer.
// Returns null in case channel doesn't pass any of the filtering rules.
//
// Some examples on how to use this can be found in ./offerCalculator.test.ts
const createPricedOfferForChannel = (
  props: OfferProps,
  channel: InNetworkYoutubeChannel
): { offer: OfferCreateRequest | null; pricing: MetaItem; variableCommission: number | null } => {
  const {
    viewEstimateRange,
    demographicFilters,
    cpmRules,
    roundPrice,
    defaultCommissionRate,
    priceFilters,
    cpmCap,
    expires,
    campaignId,
    budgetId,
    offerParams,
    deliverable,
  } = props;

  let cpi, minFeeFactor, maxCapFactor, actionsThreshold;

  if (hasCpiProps(props)) {
    cpi = props.cpi;
    minFeeFactor = props.minFeeFactor;
    maxCapFactor = props.maxCapFactor;
    actionsThreshold = props.actionsThreshold;
  }

  const fixedFee = calculateFixedFee({
    channel,
    viewEstimateRange,
    cpmRules,
    cpmCap,
  });

  let variableCommission = null;
  if (roundPrice) {
    const newPrice = roundDownPrice(fixedFee.price);
    variableCommission = calculateCommissionRate(
      newPrice,
      // round the advertiser price up to nearest $10
      Math.ceil(fixedFee.price / (1 - defaultCommissionRate) / 10) * 10
    );
    fixedFee.price = newPrice;
  }

  const pricing = {
    price: fixedFee.price.toFixed(2),
    views: fixedFee.views,
    cpm: fixedFee.cpm,
    channelId: channel.id,
    estimateToAverageOffset:
      (channel.estimates.views.youtube30d.median - channel.last30dStats.averageViewCount) /
      channel.last30dStats.averageViewCount,
  };

  if (
    priceFilters.some(
      (rule) =>
        (rule.minPrice && fixedFee.price < rule.minPrice) ||
        (rule.maxPrice && fixedFee.price > rule.maxPrice) ||
        (rule.minCpm && parseFloat(fixedFee.cpm) < rule.minCpm)
    )
  ) {
    console.debug('Excluding: ', channel.name, fixedFee.price.toFixed(2));
    return { offer: null, pricing, variableCommission: null };
  }

  if (demographicFilters && filterByDemographics(demographicFilters, channel.demographics)) {
    console.debug('Excluding due to demographic filter: ', channel.name);
    return { offer: null, pricing, variableCommission: null };
  }

  const pricingParams = cpi
    ? {
        type: 'cpi' as const,
        currency: 'USD',
        price: Number(cpi).toFixed(2),
        min_fee:
          minFeeFactor && minFeeFactor > 0 ? (fixedFee.price * minFeeFactor).toFixed(2) : null,
        max_cap:
          maxCapFactor && maxCapFactor > 0 ? (fixedFee.price * maxCapFactor).toFixed(2) : null,
        actions_threshold: actionsThreshold || 0,
      }
    : {
        type: 'fixed_fee' as const,
        currency: 'USD',
        price: fixedFee.price.toFixed(2),
      };

  const offer: OfferCreateRequest = {
    expires: expires,
    title: '_',
    description: '_',
    allow_counter_offers: false,
    offeror: {
      platform: 'campaign',
      platform_id: campaignId,
    },
    offeree: {
      platform: 'youtube',
      platform_id: channel.id,
    },
    status: {
      offeror_status: 'accepted',
      offeror_reason: 'no_reason',
      offeree_status: 'pending',
      offeree_reason: 'no_reason',
    },
    pricing: pricingParams,
    deliverables: [deliverable],
    meta: {
      cpm: parseFloat(fixedFee.cpm),
      demographics: channel.demographics,
      cpm_multiplier: channel.estimates.views[viewEstimateRange].median,
      offer_params: offerParams,
    },
  };
  if (budgetId) {
    offer.budget_id = budgetId;
  }
  return { offer, pricing, variableCommission };
};

/* This simply returns a conditional offer object for OON channels.
Conditional offers don't include a price at this point in time, so nopricingcalculations
here, simply return pricing conditions.
Also conditional offers are not filtered at this point, so just return filtering conditions. */
const createConditionalOfferForChannel = (
  {
    viewEstimateRange,
    cpmRules,
    priceFilters,
    demographicFilters,
    cpmCap,
    expires,
    campaignId,
    budgetId,
    offerParams,
    deliverable,
  }: ConditionalOfferProps,
  channel: YoutubeChannelOutOfNetwork
): ConditionalOfferCreateRequest => {
  const offer: ConditionalOfferCreateRequest = {
    expires: expires,
    title: '_',
    description: '_',
    allow_counter_offers: false,
    offeror: {
      platform: 'campaign',
      platform_id: campaignId,
    },
    offeree: {
      platform: 'youtube',
      platform_id: channel.id,
    },
    deliverables: [deliverable],
    conditions: {
      pricing: {
        cpm_rules: cpmRules,
        cpm_multiplier: channel.estimates.views[viewEstimateRange].median,
        pricing_type: 'fixed_fee',
        currency: 'USD',
        cpm_cap: cpmCap?.toString(),
      },
      price_filters: {
        min_price: priceFilters[0].minPrice?.toString(),
        max_price: priceFilters[0].maxPrice?.toString(),
        min_cpm: priceFilters[0].minCpm?.toString(),
      },
      demographic_filters: demographicFilters,
    },
    meta: {
      offer_params: offerParams,
    },
  };
  if (budgetId) {
    offer.budget_id = budgetId;
  }
  return offer;
};

// Conveniently wrap function with lodash's `curry` [0], so we can
// call this either like
// `const offer = channelToPricedOffer(rules, channel)`,
// or
// `const offers = channels.map(channelToPricedOffer(rules))`
//
// [0] https://lodash.com/docs/4.17.15#curry
export const channelToPricedOffer = curry(createPricedOfferForChannel);
export const channelToConditionalOffer = curry(createConditionalOfferForChannel);

const topLevelDemographicsProps: Array<keyof Demographics> = [
  'country',
  'gender',
  'operatingSystem',
  'ageGroup',
  'deviceType',
];
const flattenDemographics = (demographics: Demographics): Record<string, number> =>
  topLevelDemographicsProps.reduce((acc, baseKey) => {
    const vals = demographics[baseKey] || [];
    if (typeof vals === 'string') {
      return acc;
    }

    return {
      ...acc,
      ...vals.reduce((newProps, value) => {
        newProps[`${baseKey}.${value.name}`] = value.value;
        return newProps;
      }, {} as Record<string, number>),
    };
  }, {});

export const PostProcessingRule = t.partial({
  minPrice: t.number,
  maxPrice: t.number,
  minCpm: t.number,
});
type PostProcessingRuleT = t.TypeOf<typeof PostProcessingRule>;

// The part of `estimates.views` object to be used during offer price
// calculation.
export const ViewEstimateRange = t.keyof({
  youtube7d: null,
  youtube14d: null,
  youtube30d: null,
});
type ViewEstimateRangeT = t.TypeOf<typeof ViewEstimateRange>;

export const limitOffersToSum = (limit: string, offers: OfferCreateRequest[]) => {
  const totalSum = sumBy(offers, (o) => parseFloat(o.pricing.price));
  // don't filter out offers if the sum is not over the limit
  if (totalSum <= +limit) {
    return offers;
  }

  // shuffle the offers to randomly try to fill the limit with offers
  const filteredOffers = shuffle(offers).reduce((acc: OfferCreateRequest[], offer) => {
    const sumSoFar = sumBy(acc, (o) => parseFloat(o.pricing.price));
    // if we are still below limit, try to add an offer
    if (sumSoFar < +limit) {
      const offerPrice = parseFloat(offer.pricing.price);
      // only add offer if the sum so far plus the offer price is still below the limit
      if (sumSoFar + offerPrice < +limit) {
        acc.push(offer);
      }
    }
    return acc;
  }, []);
  return filteredOffers;
};
