import * as C from '@chakra-ui/react';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { useForm } from 'react-hook-form';
import {
  approveDeal,
  resetPaymentMethod,
  getPayoutTask,
  getInvoiceItems,
  PayoutTask,
  InvoiceItem,
} from '../../api-clients/imt-api-agreements';
import { format, isAfter, add } from 'date-fns';
import { ModalBase, CurrencyInput } from '@sharkpunch/idun';
import AlertBox from '../alert-box/AlertBox';
import { currencyFormatter, getDealMonetaryValues, showErrorToast } from '../../helpers';
import { getRequestId } from '../../api-helpers';
import { Deal } from '../../api-clients/imt-api-deals';

function ApproveDeal({
  agreementId,
  payoutAmount,
  invoiceItemAmount,
  onDealApproved,
}: {
  agreementId: number;
  payoutAmount: string;
  invoiceItemAmount: string;
  onDealApproved: () => void;
}) {
  const { isOpen, onOpen, onClose } = C.useDisclosure();

  const { register, handleSubmit, getValues } = useForm<{
    payoutAmount: string;
    invoiceItemAmount: string;
    revenueRecognitionDate: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      payoutAmount,
      invoiceItemAmount,
      revenueRecognitionDate: format(new Date(), 'yyyy-MM-dd'),
    },
  });

  const { error, result, loading, execute } = useAsyncCallback(
    async () =>
      approveDeal(
        getValues('payoutAmount'),
        getValues('invoiceItemAmount'),
        getValues('revenueRecognitionDate'),
        agreementId
      ),
    {
      onSuccess: (result) => {
        if (result?.payout || result?.invoiceItem) {
          onDealApproved();
        }
        onClose();
      },
    }
  );

  let errorBlock;
  if (error || result?.payoutError || result?.invoiceItemError) {
    const requestId = getRequestId(error) || result?.requestId;
    const message = error?.message || result?.payoutError || result?.invoiceItemError;
    errorBlock = (
      <C.Box key={requestId}>
        <C.Alert status="error">
          <C.Show above="sm">
            <C.AlertIcon />
          </C.Show>
          <C.Stack spacing={0}>
            <C.AlertTitle>{message}</C.AlertTitle>
            <C.AlertDescription>
              Try reloading the page or contact @fixer if the issue persists.
            </C.AlertDescription>
            {requestId && (
              <C.Text fontSize="xs" opacity={0.5}>
                Error id: {requestId}
              </C.Text>
            )}
          </C.Stack>
        </C.Alert>
      </C.Box>
    );
  }

  return (
    <>
      <ModalBase header="Are you sure?" onClose={onClose} isOpen={isOpen}>
        <C.Text>
          By confirming that the is deal done, we invoice the advertiser for{' '}
          <strong>{currencyFormatter(getValues('invoiceItemAmount'))}</strong> and pay{' '}
          <strong>{currencyFormatter(getValues('payoutAmount'))}</strong> to the creator or agency.
          This can't be changed later on!
        </C.Text>
        <C.Flex justifyContent="flex-end">
          <C.Button onClick={handleSubmit(() => execute())} isLoading={loading} colorScheme="cyan">
            Deal done
          </C.Button>
        </C.Flex>
      </ModalBase>
      <C.Stack spacing="var(--space-sm)">
        <C.Text>
          Confirm that the deal has been executed, and we can invoice the advertiser and pay the
          creator or agency the sums below.
        </C.Text>
        <AlertBox status="info">
          Make sure the amounts are correct. This can't be changed later on!
        </AlertBox>
        {errorBlock}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onOpen();
          }}>
          <C.HStack mb="3">
            <C.FormControl>
              <C.FormLabel>Invoice to advertiser:</C.FormLabel>
              <CurrencyInput
                {...register('invoiceItemAmount', {
                  required: 'This is required.',
                })}></CurrencyInput>
            </C.FormControl>
            <C.FormControl>
              <C.FormLabel>Payout to creator:</C.FormLabel>
              <CurrencyInput
                {...register('payoutAmount', {
                  required: 'This is required.',
                })}></CurrencyInput>
            </C.FormControl>
            <C.FormControl>
              <C.FormLabel>Revenue recognition</C.FormLabel>
              <C.Input
                placeholder="YYYY-MM-DD"
                data-testid="revenueRecognitionDate"
                type="date"
                {...register('revenueRecognitionDate', { required: 'This is required.' })}
              />
            </C.FormControl>
          </C.HStack>
          <C.Flex justifyContent="flex-end">
            <C.Button type="submit" colorScheme="cyan">
              Deal done
            </C.Button>
          </C.Flex>
        </form>
      </C.Stack>
    </>
  );
}

// Statuses that we allow to reset payment for
// https://github.com/SharkPunch/freyja-api/blob/main/src/api/v1/payoutTask.ts#L589-L602
const resettableStatuses = ['payment_info_added', 'payment_info_invalid', 'payment_failed'];

function PayoutTaskDetails({
  payout,
  agreementId,
  onPaymentMethodReset,
}: {
  payout: PayoutTask;
  agreementId: number;
  onPaymentMethodReset: () => void;
}) {
  const statusToText = {
    payment_info_pending: 'Waiting for payment info',
    payment_info_added: 'Payment info added',
    payment_info_confirmed: 'Payment info confirmed',
    payment_info_invalid: 'Invalid payment info',
    paid: 'Payment done',
    payment_failed: 'Payment failed',
  };

  const { isOpen, onOpen, onClose } = C.useDisclosure();

  const { loading, execute } = useAsyncCallback(async () => resetPaymentMethod(agreementId), {
    onSuccess: () => {
      onPaymentMethodReset();
      onClose();
    },
    onError: (error) => {
      if (error.message) {
        showErrorToast('Error: Failed to reset payment method', error.message);
      }
    },
  });

  return (
    <>
      <ModalBase header="Are you sure?" onClose={onClose} isOpen={isOpen}>
        <C.Text>
          Resetting the payment method causes issues if we have already made the payment. Before
          resetting, make sure it is OK to do so.
        </C.Text>
        <C.Flex justifyContent="flex-end">
          <C.Button onClick={() => execute()} isLoading={loading} colorScheme="cyan">
            Reset payment method
          </C.Button>
        </C.Flex>
      </ModalBase>
      <C.Box>
        <C.Text>
          Payout <strong>{currencyFormatter(payout.amount)}</strong> has been created
        </C.Text>
        <C.Text>Status: {statusToText[payout.status] || 'Unknown state'}</C.Text>
        <C.Center>
          <C.Link href={payout.url} isExternal rel="noopener noreferrer">
            Check details
          </C.Link>
        </C.Center>
        {resettableStatuses.includes(payout.status) && (
          <C.Center pt="3">
            <C.Button onClick={onOpen} size="sm">
              Reset payment method
            </C.Button>
          </C.Center>
        )}
      </C.Box>
    </>
  );
}

function InvoiceItemsDetails({ items }: { items: InvoiceItem[] }) {
  const statusToText = {
    pending: 'Invoice item has been created',
    invoiced: 'Invoice item has been sent',
    paid: 'Invoice item has been paid',
  };

  return (
    <C.Box>
      <C.Heading as="h3" size="sm">
        {' '}
        Invoice items:
      </C.Heading>
      {items.map((invoiceItem, index) => (
        <C.Box key={`${invoiceItem.id}_${index}`} pt="2">
          <C.Text>
            Item#{index + 1}: <strong>{currencyFormatter(invoiceItem.amount)}</strong>
          </C.Text>
          <C.Text>Status: {statusToText[invoiceItem.status] || 'Unknown status'}</C.Text>
        </C.Box>
      ))}
    </C.Box>
  );
}

function PayoutInfoModal({
  deal,
  isOpen,
  onClose,
}: {
  deal: Deal;
  isOpen: boolean;
  onClose: () => void;
}) {
  const dealId = deal.deal.id;
  const hasPublishedContent = !!deal.content;

  const { payoutAmount, invoiceItemAmount } = getDealMonetaryValues(deal);

  const {
    result: resultPayoutTask,
    loading: payoutTaskLoading,
    execute: payoutExecute,
  } = useAsync(getPayoutTask, [dealId]);
  const {
    result: resultInvoiceItems,
    loading: invoiceItemsLoading,
    execute: invoiceItemsExecute,
  } = useAsync(getInvoiceItems, [dealId]);

  let content;
  if (
    resultPayoutTask ||
    (resultInvoiceItems && resultInvoiceItems.length > 0) ||
    payoutTaskLoading ||
    invoiceItemsLoading
  ) {
    content = (
      <C.HStack justifyContent="space-around" alignItems="baseline">
        {invoiceItemsLoading ? (
          <C.Spinner />
        ) : resultInvoiceItems && resultInvoiceItems.length > 0 ? (
          <InvoiceItemsDetails items={resultInvoiceItems} />
        ) : (
          <C.Text>Invoice Item doesn't exist yet</C.Text>
        )}
        {payoutTaskLoading ? (
          <C.Spinner />
        ) : resultPayoutTask ? (
          <PayoutTaskDetails
            payout={resultPayoutTask}
            agreementId={dealId}
            onPaymentMethodReset={() => {
              payoutExecute(dealId);
            }}
          />
        ) : (
          <C.Text>Payout doesn't exist yet</C.Text>
        )}
      </C.HStack>
    );
  } else if (!hasPublishedContent && deal.deliverable.type === 'youtube_video') {
    content = (
      <AlertBox status="info">
        Can't create payout or invoice item because the video hasn't been published yet. If needed
        you can manually link published video by clicking "No published content" button above
        messages list.
      </AlertBox>
    );
  } else if (
    deal.deliverable.type === 'youtube_video' &&
    deal.pricing.type === 'cost_per_action' &&
    deal.content &&
    deal.content.published &&
    isAfter(
      add(new Date(deal.content.published), { days: deal.deliverable.tracking_period }),
      new Date()
    )
  ) {
    content = (
      <AlertBox status="info">
        Can't create payout or invoice item because this is a CPA deal and tracking period is not
        over yet. You can create payout & invoice for this deal after{' '}
        {format(
          add(new Date(deal.content.published), { days: deal.deliverable.tracking_period }),
          'dd MMM yyyy'
        )}
      </AlertBox>
    );
  } else if (
    deal.deliverable.type === 'buyout' &&
    deal.content_submission_task?.status !== 'approved'
  ) {
    content = (
      <AlertBox status="info">
        Licensing deal content submission hasn't submitted by creator or approved by us yet. If
        needed, you can manually submit content on behalf of creator via content submission portal.
      </AlertBox>
    );
  } else if (!payoutAmount || !invoiceItemAmount) {
    content = (
      <AlertBox status="warning">
        Missing payout or invoice item amount. Either this deal pricing is something else than fixed
        fee, or something else unexpected happened. Contact @fixer.
      </AlertBox>
    );
  } else {
    content = (
      <ApproveDeal
        agreementId={dealId}
        payoutAmount={payoutAmount}
        invoiceItemAmount={invoiceItemAmount}
        onDealApproved={() => {
          payoutExecute(dealId);
          invoiceItemsExecute(dealId);
        }}
      />
    );
  }

  return (
    <ModalBase header="Payout and Invoice Item information" onClose={onClose} isOpen={isOpen}>
      {content}
    </ModalBase>
  );
}

export default PayoutInfoModal;
