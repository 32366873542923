import React from 'react';
import {
  currencyFormatter,
  getDeadlines,
  isYoutubeVideoDeliverable,
  PricedOffer,
} from '../../helpers';
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  UseRowSelectRowProps,
  useSortBy,
  useTable,
} from 'react-table';
import IndeterminateCheckbox from '../react-table/IndeterminateCheckbox';
import ReactTableBase from '../react-table/ReactTableBase';
import * as C from '@chakra-ui/react';
import AlertBox from '../alert-box/AlertBox';
import { DeleteIcon } from '@chakra-ui/icons';
import { isEmpty } from 'lodash';
import { useRevokeOffers } from '../../useRevokeOffers';
import { useForm } from 'react-hook-form';
import { RevokeOffersInfoModal } from './RevokeOffersInfoModal';
import { ModalBase } from '@sharkpunch/idun';

type OfferTableRow = {
  channelId: string;
  price: string;
  status?: string;
  promotionType: string;
  publishingDate: string;
};

const PricedOfferListingTable = ({
  pricedOffers,
  formSubmitError,
  onOffersRevoked,
}: {
  pricedOffers: PricedOffer[];
  formSubmitError: Error | undefined;
  onOffersRevoked: Function;
}) => {
  const { isOpen, onClose, onToggle } = C.useDisclosure();
  const { isOpen: isOpenForm, onClose: onCloseForm, onOpen: onOpenForm } = C.useDisclosure();
  const { succeeded, failed, totalOffersToRevokeCount, triggerRevokeOffers } = useRevokeOffers();

  const data = React.useMemo(() => {
    if (!pricedOffers) {
      return [];
    }
    return pricedOffers.map((offer) => {
      const channelId = offer.offeree.platform_id;
      const deliverable = offer.deliverables[0];

      return {
        channelId,
        price: offer.pricing.type === 'fixed_fee' ? offer.pricing.price : 'N/A',
        statusOfferee: offer.status.offeree_status,
        statusOfferor: offer.status.offeror_status,
        promotionType: isYoutubeVideoDeliverable(deliverable) ? deliverable.format : '',
        publishingDate: getDeadlines(deliverable),
      };
    });
  }, [pricedOffers]);

  const columns = React.useMemo(
    () =>
      [
        {
          Header: 'Channel id',
          accessor: 'channelId' as const,
          disableSortBy: true,
          Cell: ({ value }: { value: string }) => <C.Badge textTransform="none">{value}</C.Badge>,
        },
        {
          Header: 'Price',
          accessor: 'price' as const,
          Cell: ({ value }: { value: string }) =>
            value === 'N/A' ? value : currencyFormatter(value),
        },
        { Header: 'Offeree status', accessor: 'statusOfferee' as const },
        { Header: 'Offeror status', accessor: 'statusOfferor' as const },
        { Header: 'Promotion type', accessor: 'promotionType' as const, disableSortBy: true },
        { Header: 'Publishing date', accessor: 'publishingDate' as const, disableSortBy: true },
      ] as Column<OfferTableRow>[],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 50 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }: { row: UseRowSelectRowProps<OfferTableRow> }) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ]);
    }
  );

  const numbericCellColumnIds = ['price'];

  const {
    state: { selectedRowIds },
  } = tableInstance;

  const selectedIds = tableInstance.state.selectedRowIds;
  const offersToRevoke = pricedOffers.filter((offer, i) => selectedIds[i]);

  type FormData = { revokeReason: string };
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      revokeReason: '',
    },
  });

  const onSubmitReason = (data: FormData) => {
    // do the revoking
    triggerRevokeOffers({ pricedOffers: offersToRevoke, revokeReason: data.revokeReason }).then(
      () => onOffersRevoked()
    );
    // close revoke reason form modal
    onCloseForm();
    // open revoke progress modal
    onToggle();
  };

  const onConfirm = () => {
    handleSubmit(onSubmitReason)();
  };

  return (
    <>
      <C.Box>
        <C.Button
          disabled={isEmpty(selectedRowIds)}
          size="sm"
          leftIcon={<DeleteIcon />}
          onClick={onOpenForm}>
          Revoke selected
        </C.Button>
        <ReactTableBase
          tableInstance={tableInstance}
          numericCellColumnIds={numbericCellColumnIds}
          withGlobalFilter
        />
        {!formSubmitError && pricedOffers.length === 0 && (
          <AlertBox status="info">{`No priced offers were found for this campaign.`}</AlertBox>
        )}
      </C.Box>
      <ModalBase header="Are you sure?" onClose={onCloseForm} isOpen={isOpenForm}>
        <C.Text mb={6}>
          You are about to revoke {offersToRevoke.length} priced offers. Please write down a reason
          why these should be revoked before continuing.
        </C.Text>
        <form onSubmit={handleSubmit(onSubmitReason)}>
          <C.FormControl isInvalid={!!errors.revokeReason}>
            <C.FormLabel>Reason for revoking</C.FormLabel>
            <C.Input
              placeholder="Type a reason"
              {...register('revokeReason', { required: true })}
            />
          </C.FormControl>
          <C.HStack spacing={3} mt={8} justifyContent="flex-end">
            <C.Button onClick={onClose}>Cancel</C.Button>
            <C.Button onClick={onConfirm} disabled={!isValid} colorScheme="cyan" type="submit">
              Revoke offers
            </C.Button>
          </C.HStack>
        </form>
      </ModalBase>
      <RevokeOffersInfoModal
        succeeded={succeeded}
        failed={failed}
        isOpen={isOpen}
        onClose={onClose}
        totalOffersCount={totalOffersToRevokeCount}
      />
    </>
  );
};

export default PricedOfferListingTable;
