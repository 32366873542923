import axios from 'axios';
import { getAuthHeader } from './puppe';
import { AUTH_URL, IMT_API_URL, ROOT_URL } from '../config';
import { hasOwnProperty } from '../helpers';

export const LOGIN_URL = `${AUTH_URL}/token/v1/login/google`;
export const LOGOUT_URL = `${AUTH_URL}/token/v1/logout?next_url=${ROOT_URL}`;

const parseImtMeResponse = (res: unknown) => {
  const user =
    res &&
    typeof res === 'object' &&
    hasOwnProperty(res, 'data') &&
    res.data &&
    typeof res.data === 'object' &&
    res.data !== null &&
    hasOwnProperty(res.data, 'data') &&
    res.data.data;
  if (
    typeof user === 'object' &&
    user !== null &&
    hasOwnProperty(user, 'token') &&
    typeof user.token === 'object' &&
    user.token !== null &&
    hasOwnProperty(user.token, 'accountId') &&
    hasOwnProperty(user.token, 'role') &&
    typeof user.token.accountId === 'number' &&
    typeof user.token.role === 'string' &&
    hasOwnProperty(user, 'avatarUrl') &&
    hasOwnProperty(user, 'displayName') &&
    typeof user.avatarUrl === 'string' &&
    typeof user.displayName === 'string'
  ) {
    return {
      avatarUrl: user.avatarUrl,
      displayName: user.displayName,
      token: { accountId: user.token.accountId, role: user.token.role },
    };
  } else {
    throw new Error('Failed to authenticate!');
  }
};

export const fetchCurrentIMTUser = async (): Promise<UserObject> => {
  const res: unknown = await axios(`${IMT_API_URL}/api/me`, {
    headers: await getAuthHeader(),
  });
  return parseImtMeResponse(res);
};

export type UserObject = {
  avatarUrl: string;
  displayName: string;
  token: {
    accountId: number;
    role: string;
  };
};
