import * as t from 'io-ts';
import axios from 'axios';
import { imtApi } from './imt-api';
import { handleError, PromotionTypeDecoder, SortBy, UnknownEntityDecoder } from '../helpers';
import { decodeResponse, decodeResponseArray } from '../api-helpers';
import {
  AgreementStatusDecoder,
  PayoutTaskStatusDecoder,
  InvoiceItemDecoder,
} from './imt-api-agreements';

const ContentStatusDecoder = t.keyof(
  {
    published: null,
    in_review: null,
    deleted: null,
  },
  'Content status'
);

const DealPayoutTaskDecoder = t.exact(
  t.type(
    {
      id: t.string,
      amount: t.string,
      currency: t.string,
      display_info: t.union([t.string, t.null]),
      idempotency_key: t.string,
      reference_id: t.string,
      meta: t.union([t.null, t.UnknownRecord]),
      transaction_date: t.union([t.string, t.null]),
      expected_payout_date: t.union([t.string, t.null]),
      payout_date: t.union([t.string, t.null]),
      revenue_recognition_date: t.union([t.string, t.null]),
      status: PayoutTaskStatusDecoder,
    },
    'Payout task'
  )
);
export type PayoutTask = t.TypeOf<typeof DealPayoutTaskDecoder>;

const DeliverableYoutube = t.type({
  type: t.literal('youtube_video'),
  format: PromotionTypeDecoder,
  deadline: t.string,
  tracking_period: t.number,
});
const BuyoutDeliverableDecoder = t.type(
  {
    type: t.literal('buyout'),
    deadline: t.string,
    target_content_platform: t.literal('youtube'),
    target_content_id: t.string,
    target_name: t.union([t.null, t.string]),
    license_duration_days: t.number,
    license_terms: t.union([t.string, t.null]),
  },
  'Buyout deliverable'
);

const FixedFeePricingDecoder = t.type(
  {
    type: t.literal('fixed_fee'),
    price: t.string,
    currency: t.string,
    commission_rate: t.number,
  },
  'Fixed fee pricing'
);
export type FixedFeePricing = t.TypeOf<typeof FixedFeePricingDecoder>;

const CPAPricingDecoder = t.type(
  {
    type: t.literal('cost_per_action'),
    action: t.literal('install'),
    price: t.string,
    currency: t.string,
    min_fee: t.union([t.string, t.null]),
    max_cap: t.union([t.string, t.null]),
    actions_threshold: t.number,
    actions_count: t.number,
    commission_rate: t.number,
  },
  'CPA pricing'
);
export type CPAPricing = t.TypeOf<typeof CPAPricingDecoder>;

const DealDecoder = t.exact(
  t.type({
    content_submission_task: t.union(
      [
        t.exact(
          t.type({
            status: t.string,
          })
        ),
        t.null,
      ],
      'Content submission task'
    ),
    content: t.union(
      [
        t.null,
        t.exact(
          t.type({
            url: t.string,
            published: t.union([t.string, t.null]),
            status: ContentStatusDecoder,
            submitted: t.union([t.string, t.null]),
            text: t.union([t.string, t.null]),
          })
        ),
      ],
      'Content'
    ),
    deal: t.type(
      {
        id: t.number,
        tags: t.array(t.string),
        chat_closed: t.boolean,
        deadline: t.string,
        tracking_code: t.union([t.string, t.null]),
        status: AgreementStatusDecoder,
      },
      'Deal'
    ),
    pricing: t.union([FixedFeePricingDecoder, CPAPricingDecoder, UnknownEntityDecoder]),
    campaign: t.type({ name: t.string, id: t.number }, 'Campaign'),
    latest_message: t.union([
      t.null,
      t.type(
        {
          id: t.string,
          role: t.string,
          content: t.string,
          sender_id: t.number,
          sender_display_name: t.string,
          sender_avatar_url: t.string,
          created: t.string,
          viewed: t.union([t.string, t.null]),
          handled_by_id: t.union([t.number, t.null]),
        },
        'Latest message'
      ),
    ]),
    channel: t.type(
      {
        id: t.string,
        display_name: t.string,
        avatar_url: t.string,
      },
      'Channel'
    ),
    team: t.type(
      {
        id: t.number,
        name: t.string,
      },
      'Team'
    ),
    payout_task: t.union([t.null, DealPayoutTaskDecoder], 'Payout task'),
    invoice_items: t.array(InvoiceItemDecoder),
    deliverable: t.union(
      [DeliverableYoutube, BuyoutDeliverableDecoder, UnknownEntityDecoder],
      'Deliverable'
    ),
  }),
  'Deal'
);
export type Deal = t.TypeOf<typeof DealDecoder>;

const DealTagsDecoder = t.exact(
  t.type({
    tag: t.string,
    count: t.number,
  }),
  'Deal tags'
);
export type DealTags = t.TypeOf<typeof DealTagsDecoder>;

export async function fetchDeals(params?: {
  offset?: number;
  limit?: number;
  filters?: { [key: string]: string };
  order?: SortBy[];
}): Promise<Deal[]> {
  const { offset = 0, limit = 50, filters, order } = params || {};

  // always add offset and limit
  let url = `/admin/deals?offset=${offset}&limit=${limit}`;

  if (filters) {
    // apply filters if they exist
    const searchParams = new URLSearchParams(filters);
    url = `${url}&${searchParams}`;
  }

  if (order && order.length) {
    // apply sorting order if it exist
    url = `${url}&order=${order
      .filter((i) => i.id && typeof i.isSortedDesc !== 'undefined')
      .map((i) => `${i.id}.${i.isSortedDesc ? 'desc' : 'asc'}.nullslast`)}`;
  }

  const res = await imtApi.get(url).catch(handleError('Fetching deals'));
  const decoded = decodeResponseArray(res, DealDecoder);
  return decoded;
}

export async function fetchDealTags(): Promise<DealTags[]> {
  const url = `/admin/deal-tags/suggestions`;
  const res = await imtApi.get(url).catch(handleError('Fetching deal tags'));
  const decoded = decodeResponseArray<DealTags>(res, DealTagsDecoder);
  return decoded;
}

export async function setMessageHandledById(id: string) {
  const url = `/chats/${id}/handle`;
  const res = await imtApi.put(url).catch(handleError('Setting message as handled'));
  const decoded = decodeResponse(res);
  return decoded;
}

export async function deleteMessageHandledById(id: string) {
  const url = `/chats/${id}/handle`;

  let res;
  try {
    res = await imtApi.delete(url);
  } catch (e) {
    if (axios.isAxiosError(e)) {
      handleError('Setting message as unhandled');
    } else {
      console.error('Error log: Setting message as unhandled: Unknown error type:', e);
      throw new Error('Setting message as unhandled: Unknown error type');
    }
  }

  return res;
}
