import * as C from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { setMessageHandledById, deleteMessageHandledById } from '../../api-clients/imt-api-deals';
import { useAsyncCallback } from 'react-async-hook';
import { showErrorToast } from '../../helpers';
import { AgreementMessage } from '../../api-clients/imt-api-agreements';

const DealHandledButton = ({
  message,
  setMessage,
}: {
  message: AgreementMessage;
  setMessage: (message: AgreementMessage) => void;
}) => {
  const [handled, setHandled] = useState(message?.handledById ? true : false);

  const setMessageHandled = useAsyncCallback(async (id: string) => setMessageHandledById(id), {
    onSuccess: () => {
      setHandled(true);
      // set `handledById` to any number since the API doesn't return anything,
      // we should change this if we're going to use the real id for anything other than just "exists or not"
      setMessage({ ...message, handledById: 1 });
    },
    onError: (error) => {
      if (error.message) {
        showErrorToast('Error: Failed to mark deal as handled', error.message);
      }
    },
  });

  const setMessageUnhandled = useAsyncCallback(async (id: string) => deleteMessageHandledById(id), {
    onSuccess: () => {
      setHandled(false);
      setMessage({ ...message, handledById: null });
    },
    onError: (error) => {
      if (error.message) {
        showErrorToast('Error: Failed to mark deal as unhandled', error.message);
      }
    },
  });

  useEffect(() => {
    setHandled(message?.handledById ? true : false);
  }, [message?.handledById]);

  return (
    <>
      {!handled ? (
        <C.Button
          onClick={() => {
            setMessageHandled.execute(message.id);
          }}
          isLoading={setMessageHandled.loading}
          size="xs"
          colorScheme="red"
          variant="ghost">
          Mark as handled
        </C.Button>
      ) : (
        <C.Button
          onClick={() => {
            setMessageUnhandled.execute(message.id);
          }}
          isLoading={setMessageUnhandled.loading}
          size="xs"
          colorScheme="red"
          variant="ghost">
          Mark as unhandled
        </C.Button>
      )}
    </>
  );
};

export default DealHandledButton;
