import * as t from 'io-ts';
import {
  format,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  isWithinInterval,
  addDays,
  addSeconds,
  isValid,
} from 'date-fns';
import axios, { AxiosError } from 'axios';

import { createStandaloneToast } from '@chakra-ui/react';
import {
  Demographics,
  InNetworkYoutubeChannel,
  YoutubeChannelOutOfNetwork,
} from './api-clients/imt-api-collections';

import { AgreementStatus } from './api-clients/imt-api-agreements';

import {
  genders,
  countryCodesAndNamePairs,
  ageGroups,
  operatingSystems,
  deviceTypes,
} from './constants/demographics';
import { TRACKING_URL } from './config';
import { cloneDeep } from 'lodash';
import { SubmitDataDecoder } from './components/bulk-offer/BulkOfferForm';
import { CampaignsInfo } from './components/bulk-offer/PreviewTable';
import { InvalidCsvDataError } from './components/csv-upload/CsvUpload';
import { Role } from './api-clients/imt-api-accounts';
import { Deal } from './api-clients/imt-api-deals';

//github.com/gcanti/io-ts/blob/master/index.md#union-of-string-literals
const PlatformDecoder = t.keyof(
  { youtube: null, instagram: null, campaign: null, twitch: null },
  'Platform'
);
type Platform = t.TypeOf<typeof PlatformDecoder>;

const YoutubeDeliverableFormatDecoder = t.keyof(
  { dedicated: null, integration: null },
  'Youtube Deliverable Format'
);

const StatusTypeDecoder = t.keyof(
  {
    pending: null,
    accepted: null,
    revoked: null,
    rejected: null,
    cancelled: null,
  },
  'StatusType'
);
export type StatusType = t.TypeOf<typeof StatusTypeDecoder>;

export const ConditionalOfferStatusTypeDecoder = t.keyof(
  {
    pending: null,
    revoked: null,
    processed: null,
  },
  'ConditionalOfferStatus'
);

export const ParticipantDecoder = t.type(
  {
    platform: PlatformDecoder,
    platform_id: t.string,
  },
  'Participant'
);
type Participant = t.TypeOf<typeof ParticipantDecoder>;

export const DisplayDataDecoder = t.type({ avatarUrl: t.string, name: t.string }, 'Display data');

export const ContentSubmissionParticipantDecoder = t.intersection(
  [
    t.type(
      {
        platform: t.keyof({ youtube: null, matchmade: null }),
        platform_id: t.string,
      },
      'ContentSubmissionParticipant'
    ),
    t.partial(
      {
        display_data: DisplayDataDecoder,
      },
      'ContentSubmissionDisplayData'
    ),
  ],
  'ContentSubmissionParticipant'
);

const YoutubeDeliverableDecoder = t.type(
  {
    type: t.literal('youtube_video'),
    description: t.string,
    format: YoutubeDeliverableFormatDecoder,
    tracking_period: t.number,
    deadline_range_start: t.string,
    deadline_range_end: t.string,
  },
  'Youtube video deliverable'
);
type YoutubeDeliverable = t.TypeOf<typeof YoutubeDeliverableDecoder>;

const InstagramDeliverableDecoder = t.type(
  {
    type: t.literal('instagram_post'),
    description: t.string,
    tracking_period: t.number,
    deadline_range_start: t.string,
    deadline_range_end: t.string,
  },
  'Instagram post Deliverable'
);
type InstagramDeliverable = t.TypeOf<typeof InstagramDeliverableDecoder>;

const BuyoutDeliverableDecoder = t.type(
  {
    type: t.literal('buyout'),
    description: t.string,
    deadline: t.string,
    target_content_platform: t.union([t.literal('youtube'), t.literal('twitch')]),
    target_content_id: t.string,
    target_name: t.union([t.null, t.string]),
    license_duration_days: t.number,
    license_terms: t.union([t.string, t.null]),
  },
  'Buyout deliverable'
);
type BuyoutDeliverable = t.TypeOf<typeof BuyoutDeliverableDecoder>;

const MultipleYoutubeVideosDeliverableDecoder = t.type(
  {
    type: t.literal('multiple_youtube_videos'),
    number_of_videos: t.number,
    target_campaign_ids: t.array(t.string),
    min_days_between_deadlines: t.number,
    description: t.string,
    format: YoutubeDeliverableFormatDecoder,
    tracking_period: t.number,
    deadline_range_start: t.string,
    deadline_range_end: t.string,
  },
  'Youtube video deliverable'
);
type MultipleYoutubeVideoDeliverable = t.TypeOf<typeof MultipleYoutubeVideosDeliverableDecoder>;

export const DeliverableDecoder = t.union([
  YoutubeDeliverableDecoder,
  InstagramDeliverableDecoder,
  BuyoutDeliverableDecoder,
  MultipleYoutubeVideosDeliverableDecoder,
]);
export type Deliverable = t.TypeOf<typeof DeliverableDecoder>;

export const isYoutubeVideoDeliverable = (
  deliverable: Deliverable
): deliverable is YoutubeDeliverable => deliverable.type === 'youtube_video';
const isMultipleYoutubeVideoDeliverable = (
  deliverable: Deliverable
): deliverable is MultipleYoutubeVideoDeliverable => deliverable.type === 'multiple_youtube_videos';
export const isInstagramDeliverable = (
  deliverable: Deliverable
): deliverable is InstagramDeliverable => deliverable.type === 'instagram_post';
export const isBuyoutDeliverable = (deliverable: Deliverable): deliverable is BuyoutDeliverable =>
  deliverable.type === 'buyout';

export const UnknownEntityDecoder = t.type({ type: t.literal('unknown') }, 'Unknown entity');

const FixedFeePricingDecoder = t.type(
  {
    currency: t.string,
    type: t.literal('fixed_fee'),
    price: t.string,
  },
  'Fixed fee Pricing'
);
type FixedFeePricing = t.TypeOf<typeof FixedFeePricingDecoder>;

const CPIPricingDecoder = t.type(
  {
    type: t.literal('cpi'),
    currency: t.string,
    price: t.string,
    min_fee: t.union([t.string, t.null]),
    max_cap: t.union([t.string, t.null]),
    actions_threshold: t.number,
  },
  'CPI pricing'
);
type CPIPricing = t.TypeOf<typeof CPIPricingDecoder>;

const PricingDecoder = t.union(
  [FixedFeePricingDecoder, CPIPricingDecoder, UnknownEntityDecoder],
  'Pricing'
);
export type Pricing = t.TypeOf<typeof PricingDecoder>;

const OfferStatusDecoder = t.type(
  {
    offeror_status: StatusTypeDecoder,
    offeror_reason: t.string,
    offeree_status: StatusTypeDecoder,
    offeree_reason: t.string,
  },
  'OfferStatus'
);
export type OfferStatus = t.TypeOf<typeof OfferStatusDecoder>;

export const CPMRule = t.type(
  {
    name: t.string,
    cpmPerPercentage: t.string,
  },
  'CPMRule'
);
export type CPMRuleT = t.TypeOf<typeof CPMRule>;

export const CPMRuleWithDimension = t.type(
  {
    dimension: t.string,
    name: t.string,
    cpm_per_percentage: t.string,
  },
  'CPMRuleWithDimension'
);
export type CPMRuleWithDimensionT = t.TypeOf<typeof CPMRuleWithDimension>;

// It would be nice to have just one type for CPM rules,
// but this needs a bit too much refactoring for me to do
// on the last day before holidays.
// We need this because we use dynamic typing from Papaparse,
// https://github.com/SharkPunch/bulk-offer-webapp/blob/main/src/components/CsvUpload.tsx#L31,
// which parses strings-that-look-like-numbers as numbers (duh!),
// and then decoder from above fails.
//
// @someone plz fix ;(
export const CPMRuleWithDimensionFromCsvUpload = t.type(
  {
    dimension: t.string,
    name: t.string,
    cpm_per_percentage: t.number,
  },
  'CPMRuleWithDimensionFromCsvUpload'
);
export type CPMRuleWithDimensionFromCsvUploadT = t.TypeOf<typeof CPMRuleWithDimensionFromCsvUpload>;

export const DemographicFilter = t.intersection(
  [
    t.type({
      dimension: t.keyof({
        gender: null,
        country: null,
        ageGroup: null,
        deviceType: null,
        operatingSystem: null,
      }),
      names: t.array(t.string),
    }),
    t.partial({
      min_threshold: t.number,
      max_threshold: t.number,
    }),
  ],
  'DemographicFilter'
);
export type DemographicFilterT = t.TypeOf<typeof DemographicFilter>;

const PriceFilter = t.partial(
  {
    min_price: t.string,
    max_price: t.string,
    min_cpm: t.string,
  },
  'PriceFilter'
);

const OfferConditionPricing = t.type(
  {
    cpm_rules: t.array(t.union([CPMRuleWithDimension, t.UnknownRecord])),
    cpm_multiplier: t.number,
    pricing_type: t.literal('fixed_fee'),
    currency: t.string,
    cpm_cap: t.union([t.string, t.undefined]),
  },
  'Offer Condition Pricing'
);

const OfferConditions = t.intersection(
  [
    t.type({
      pricing: OfferConditionPricing,
      price_filters: PriceFilter,
    }),
    t.partial({
      demographic_filters: t.array(DemographicFilter),
    }),
  ],
  'Offer Conditions'
);
type OfferConditionsT = t.TypeOf<typeof OfferConditions>;

export const PricedOfferDecoder = t.type(
  {
    id: t.string,
    created: t.string,
    updated: t.string,
    expires: t.string,
    hash: t.string,
    title: t.string,
    description: t.string,
    allow_counter_offers: t.boolean,
    offeror: ParticipantDecoder,
    offeree: ParticipantDecoder,
    status: OfferStatusDecoder,
    pricing: PricingDecoder,
    deliverables: t.array(DeliverableDecoder),
    budget_id: t.union([t.string, t.null]),
  },
  'PricedOffer'
);
export type PricedOffer = t.TypeOf<typeof PricedOfferDecoder>;

type OfferCreateRequestMeta = {
  cpm?: number;
  demographics?: Demographics;
  cpm_multiplier?: number;
  batch_id?: string;
  batch_name?: string;
  offer_params?: t.TypeOf<typeof SubmitDataDecoder>;
};

// This is the specific payload Njord ultimately expects to create an offer
export type OfferCreateRequest = {
  expires: string;
  title: string;
  description: string;
  allow_counter_offers: boolean;
  offeror: Participant;
  offeree: Participant;
  status: OfferStatus;
  pricing: FixedFeePricing | CPIPricing;
  deliverables: Deliverable[];
  budget_id?: string;
  meta?: OfferCreateRequestMeta;
};

// This is the payload campaign API expects to save potential variable commission
// rate and then forward the actual offer request to Njord
export type SendOfferPayload = {
  offer: OfferCreateRequest;
  commission_rate: number | null;
};

// This is the specific payload Njord ultimately expects to create a conditional offer
export type ConditionalOfferCreateRequest = {
  expires: string;
  title: string;
  description: string;
  allow_counter_offers: boolean;
  offeror: Participant;
  offeree: Participant;
  deliverables: Deliverable[];
  budget_id?: string;
  conditions: OfferConditionsT;
  meta?: OfferCreateRequestMeta;
  commission_rate?: number;
};

// This is the payload campaign API expects to save potential variable commission
// rate and then forward the actual conditional offer request to Njord
export type SendConditionalOfferPayload = {
  conditional_offer: ConditionalOfferCreateRequest;
  commission_rate: number | null;
};

type ChannelDetails = {
  influencerId: string;
  channelName: string;
  channelAvatarUrl: string;
};

export type DetailsPerChannel = {
  [key: string]: ChannelDetails;
};

// Maps offeree_id to a commission rate
export type OffereeToCommissionRateObject = {
  [key: string]: number | null;
};

export const PromotionTypeDecoder = t.keyof(
  { dedicated: null, integration: null },
  'Promotion type'
);
export const OfferData = t.intersection(
  [
    t.type(
      {
        campaign_id: t.number,
        influencer_id: t.number,
        channel_id: t.string,
        promotion_type: PromotionTypeDecoder,
        creator_fixed_fee_cents: t.number,
        video_publishing_date_start: t.string,
        video_publishing_date_end: t.string,
        budget_id: t.union([t.null, t.string]),
        variable_commission_rate: t.union([t.number, t.null]),
      },
      'Offer data mandatory'
    ),
    t.partial({ meta: t.string }, 'Offer data optional'),
  ],
  'Offer Data'
);

export type OfferDataT = t.TypeOf<typeof OfferData>;

export function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop);
}

export function offerDataToOfferCreateRequest(
  offer: OfferDataT,
  campaignsInfo: CampaignsInfo
): OfferCreateRequest {
  if (!offer.variable_commission_rate && !campaignsInfo[offer.campaign_id]?.commissionRate) {
    throw new InvalidCsvDataError(
      `There was a problem fetching commission rates for an offer. To guarantee we don't send offers with wrong pricing, must prevent offers from being sent here. This shouldn't happen and you should contact the fixer. Offer: ${JSON.stringify(
        offer
      )}, campaignsInfo: ${JSON.stringify(campaignsInfo)}`
    );
  }
  const offerorPlatform: Platform = 'campaign';
  const offereePlatform: Platform = 'youtube';
  const status: OfferStatus = {
    offeror_status: 'accepted',
    offeror_reason: 'no_reason',
    offeree_status: 'pending',
    offeree_reason: 'no_reason',
  };
  const pricing: Pricing = {
    currency: 'USD',
    type: 'fixed_fee',
    price: '' + (offer.creator_fixed_fee_cents / 100).toFixed(2),
  };

  const [start, end] = [
    new Date(offer.video_publishing_date_start),
    new Date(offer.video_publishing_date_end),
  ];
  const isInvalidStartDate = isNaN(start.getTime());
  const isInvalidEndDate = isNaN(end.getTime());
  if (isInvalidStartDate && isInvalidEndDate) {
    const errorMessage = `Invalid publishing date range: ${
      isInvalidStartDate && offer.video_publishing_date_start
    }${isInvalidEndDate ? ` ${offer.video_publishing_date_end}` : ''}`;

    throw new InvalidCsvDataError(errorMessage);
  }

  const deliverables: Deliverable[] = [
    {
      type: 'youtube_video',
      description: '_',
      format: offer.promotion_type,
      tracking_period: campaignsInfo[offer.campaign_id]?.trackingPeriod || 7,
      deadline_range_start: offer.video_publishing_date_start,
      deadline_range_end: offer.video_publishing_date_end,
    },
  ];

  const offerCreationObject: OfferCreateRequest = {
    expires: offer.video_publishing_date_end,
    title: '_',
    description: '_',
    allow_counter_offers: false,
    offeror: {
      platform: offerorPlatform,
      platform_id: '' + offer.campaign_id,
    },
    offeree: {
      platform: offereePlatform,
      platform_id: offer.channel_id,
    },
    status,
    pricing,
    deliverables,
  };

  if (offer.budget_id) {
    offerCreationObject.budget_id = offer.budget_id;
  }

  if (offer.meta) {
    const parsed = JSON.parse(offer.meta);
    offerCreationObject.meta = parsed;
  }

  return offerCreationObject;
}

export function showErrorToast(title: string, description: string | null): void {
  const { toast } = createStandaloneToast();
  toast({
    title,
    description,
    status: 'error',
    duration: null,
    isClosable: true,
  });
}

export function showSuccessToast(title: string, description: string | null): void {
  const { toast } = createStandaloneToast();
  toast({
    title,
    description,
    status: 'success',
    duration: 5000,
    isClosable: true,
  });
}

export const currencyFormatter = (
  amount: number | string,
  locale: string = 'en-US', // 'en-US' voted most wanted locale (allow selecting in future?)
  currency: string = 'USD',
  minimumFractionDigits: number = 2,
  maximumFractionDigits: number = 2
): string => {
  let amountNumber = +amount;
  if (isNaN(amountNumber)) {
    return '-.--'; // still show something in UI that indicates an amount, but without value
  }
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(amountNumber);
};

export const numberFormatter = (
  amount: number | string,
  locale: string = 'en-US' // 'en-US' voted most wanted locale (allow selecting in future?)
): string => {
  let amountNumber = +amount;
  if (isNaN(amountNumber)) {
    return '-'; // still show something in UI that indicates an amount, but without value
  }
  return new Intl.NumberFormat(locale).format(amountNumber);
};

export function dateFormatter(date: string) {
  let formattedDate;
  // to get "less than a minute ago" / "in less than a minute"
  if (
    isWithinInterval(new Date(date), {
      start: addSeconds(new Date(), -60),
      end: addSeconds(new Date(), 60),
    })
  ) {
    formattedDate = formatDistanceToNow(new Date(date), {
      addSuffix: true,
    });
  }
  // to get "x hours" / "x days"
  else if (
    isWithinInterval(new Date(date), {
      start: addDays(new Date(), -7),
      end: addDays(new Date(), 7),
    })
  ) {
    formattedDate = formatDistanceToNowStrict(new Date(date), {
      addSuffix: true,
    });
  } else {
    formattedDate = format(new Date(date), 'MMM do yyyy');
  }
  return formattedDate;
}

// created object with channel information
// this can be expanded to hold info needed for things like avatar
export const getChannelInfo = (
  influencerId: string,
  channel?: InNetworkYoutubeChannel | YoutubeChannelOutOfNetwork
): ChannelDetails => ({
  influencerId,
  channelName: channel?.name || '',
  channelAvatarUrl: channel?.avatarUrl || '',
});

// returns string that is either a date range or single date based on the offer deliverable deadline range
export function getDeadlines(deliverable: Deliverable): string {
  if (
    isYoutubeVideoDeliverable(deliverable) ||
    isMultipleYoutubeVideoDeliverable(deliverable) ||
    isInstagramDeliverable(deliverable)
  ) {
    if (!deliverable?.deadline_range_start) {
      return 'N/A';
    }

    try {
      const deadlineRangeStart = format(
        Date.parse(deliverable.deadline_range_start),
        'do MMM yyyy'
      );
      const deadlineRangeEnd = format(Date.parse(deliverable.deadline_range_end), 'do MMM yyyy');

      // always show range start, and only show range end if it's not the same as range start
      return (
        deadlineRangeStart +
        (deadlineRangeEnd && deadlineRangeStart !== deadlineRangeEnd
          ? ' - ' + deadlineRangeEnd
          : '')
      );
    } catch (e) {
      console.warn(
        {
          e,
          deadline_range_start: deliverable.deadline_range_start,
          deadline_range_end: deliverable.deadline_range_end,
        },
        'Failed to parse deadline values'
      );
      return 'Invalid deadline';
    }
  }

  if (isBuyoutDeliverable(deliverable)) {
    try {
      const deadline = format(Date.parse(deliverable.deadline), 'do MMM yyyy');
      return deadline;
    } catch (e) {
      console.warn(
        {
          e,
          deadline: deliverable.deadline,
        },
        'Failed to parse deadline'
      );
      return 'Invalid deadline';
    }
  }

  console.error('Unsupported deliverable type', deliverable);
  return 'Unsupported deliverable';
}

export type DemographicFilterItem = { id: string; label: string };
export const demographicFilters: {
  [key: string]: {
    items: Array<DemographicFilterItem>;
    inputPlaceholder: string;
  };
} = {
  gender: {
    items: genders.map((item) => ({ id: item, label: item })),
    inputPlaceholder: 'Gender',
  },
  ageGroup: {
    items: ageGroups.map((item) => ({ id: item, label: item })),
    inputPlaceholder: 'Age groups',
  },
  country: {
    items: countryCodesAndNamePairs,
    inputPlaceholder: 'Countries',
  },
  operatingSystem: {
    items: operatingSystems.map((item) => ({ id: item, label: item })),
    inputPlaceholder: 'Operating systems',
  },
  deviceType: {
    items: deviceTypes.map((item) => ({ id: item, label: item })),
    inputPlaceholder: 'Device types',
  },
};

const youtubeRegExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

export const getYoutubeId = (url: string) => {
  const match = url.match(youtubeRegExp);
  return match && match[7].length === 11 ? match[7] : '';
};

const humanReadableStatuses = {
  waiting_for_approval: 'Creator sent offer',
  invited_to_join: 'Matchmade sent offer',
  publisher_set_cpi: 'Matchmade sent offer',
  influencer_set_cpi: 'Creator sent offer',
  influencer_declined: 'Creator declined offer',
  publisher_declined: 'Matchmade declined offer',
  influencer_withdrew: 'Creator withdrew offer',
  publisher_withdrew: 'Matchmade withdrew offer',
  auto_withdrew: 'Offer expired',
  cancelled: 'Offer cancelled',
  settled: 'Offer accepted',
};

export const getHumanReadableAgreementStatus = (status: AgreementStatus) =>
  humanReadableStatuses[status];

const humanReadableDeliverableLabels = {
  youtube_video: 'YouTube video',
  buyout: 'Licensing deal',
  unknown: 'Unknown',
};
export const getHumanReadableDeliverableLabel = (type: Deal['deliverable']['type']) =>
  humanReadableDeliverableLabels[type];

export const isOutOfRangeError = (error?: Error | OutOfRangeError) =>
  error instanceof OutOfRangeError || (axios.isAxiosError(error) && error.response?.status === 416);

export const getTrackingUrl = (trackingCode: string) => `${TRACKING_URL}/${trackingCode}`;

export const growElementByContent = (e: HTMLElement) => {
  e.style.height = 'inherit';
  e.style.height = `${e.scrollHeight}px`;
};

export type SortBy = { id: string; isSortedDesc?: boolean };
export type Filters = { id: string; value: string[]; customPrefix?: string };
// Convert filters to postgrest format.
// { id: 'status', value: ['accepted', 'pending'] } =>
//   status=in.(accepted,pending)
// { id: 'status', value: ['accepted'] } =>
//   status=eq.accepted
// { id: 'status', value: ['true']} =>
//   status=is.true
//
// These are used for listing pages, such as ContentSubmissionListing
export const filtersToPostgrestFormat = (filters: Filters[]): { [key: string]: string } =>
  filters.reduce((acc, item) => {
    const { id, value, customPrefix } = item;
    if (!value.length) {
      return acc;
    }

    let prefix;
    if (customPrefix || customPrefix === '') {
      prefix = customPrefix;
    } else if (isValid(new Date(value[0])) && (id.includes('[0]') || id.includes('[1]'))) {
      prefix = id.includes('[0]') ? 'gte.' : 'lte.';
    } else if (value.length > 1) {
      prefix = 'in.';
    } else if (['true', 'false', 'null', 'undefined'].includes(value[0])) {
      prefix = 'is.';
    } else {
      prefix = 'eq.';
    }

    // `ov.` is overlap, basically when we need to search
    // through a column of array type (like `text[]`), for
    // example deal tags:
    //
    // `?deal__tags=ov.{fast_response,great_vids}`
    //
    // [0] https://postgrest.org/en/stable/api.html#operators
    const searchValue =
      customPrefix && customPrefix === 'ov.'
        ? `{${item.value.join(',')}}`
        : value.length > 1
        ? `(${item.value.join(',')})`
        : value[0];
    return { ...acc, [item.id]: prefix + searchValue };
  }, {});

const specialSearchParams = ['order', 'limit', 'offset'];
// Convert URLSearchParams (which we expect to postgrest-like)
// to Filters that we can use in React-Table, for instance
// These are used for listing pages, such as ContentSubmissionListing
export const postgrestFormatToFilters = (searchParams: URLSearchParams): Filters[] => {
  const filters: Filters[] = [];
  for (const [key, searchValue] of searchParams) {
    if (specialSearchParams.includes(key)) {
      continue;
    }

    const prefix = searchValue.slice(0, searchValue.lastIndexOf('.'));
    const value = searchValue.slice(searchValue.lastIndexOf('.') + 1);

    let parsedValue: string[] = [],
      customPrefix: undefined | string;

    if (prefix === 'in') {
      parsedValue = value.replace(/[()]/g, '').split(',');
    } else if (prefix === 'ov') {
      parsedValue = value.replace(/[{}]/g, '').split(',');
      customPrefix = 'ov.';
    } else if (prefix === 'ilike') {
      parsedValue = [value];
      customPrefix = 'ilike.';
    } else if (prefix === 'eq' || prefix === 'is' || prefix === 'gte' || prefix === 'lte') {
      parsedValue = [value];
    } else if (key === 'or') {
      parsedValue = [searchValue];
      customPrefix = '';
    } else {
      customPrefix = prefix + '.';
      parsedValue = [value];
    }
    filters.push({ id: key, value: parsedValue, customPrefix });
  }
  return filters;
};

// This translates order coming from URLSearchParams
// to SortBy that can be used for react table.
//
// order=created.desc,campaign_id.asc =>
// [
//   { id: 'created', isSortedDesc: true },
//   { id: 'campaign_id', isSortedDesc: false }
// ]
export const orderToSortBy = (order: string): SortBy[] =>
  order
    .split(',')
    .map((orderItem) => {
      const parts = orderItem.split('.');
      let direction = parts.pop();
      if (direction !== 'asc' && direction !== 'desc') {
        direction = parts.pop() + '.' + direction;
      }
      const key = parts.join('.');
      return {
        id: key,
        isSortedDesc: direction.startsWith('desc'),
      };
    })
    .filter((i) => i.id);

// This translates SortBy from react table
// to order URLSearchParam
//
// Note that we always append `.nullslast`
// to make sure that items with missing
// data end up at the end of the list
//
// https://matchmade.slack.com/archives/C2NN9A5L3/p1658832215196959
//
// [
//   { id: 'created', isSortedDesc: true },
//   { id: 'campaign_id', isSortedDesc: false },
//   { id: 'this_was_sorted_but_not_anymore' }
// ] => order=created.desc.nullslast,campaign_id.asc.nullslast
export const sortByToOrder = (sortBy: SortBy[]): string[] =>
  sortBy
    // remove columns that aren't sorted at all
    .filter((i) => i.id && typeof i.isSortedDesc !== 'undefined')
    .map((item) => `${item.id}.${item.isSortedDesc ? 'desc' : 'asc'}.nullslast`);

export const alterValueInFilters = (
  filters: Filters[] = [],
  filterId: string,
  newValue: string[] = [],
  customPrefix?: string
): Filters[] => {
  const nextFilters = cloneDeep(filters);
  let existingIndex = filters.findIndex((f) => f.id === filterId);

  if (existingIndex < 0) {
    nextFilters.push({ id: filterId, value: newValue, customPrefix });
  } else {
    nextFilters[existingIndex].value = newValue;
    nextFilters[existingIndex].customPrefix = customPrefix;
  }

  return nextFilters;
};

class OutOfRangeError extends Error {}

export function handleError(errorPrefix: string) {
  return function (e: AxiosError) {
    if (e.response) {
      const requestId = e.response.headers['request-id'];
      console.error(`Error log: ${errorPrefix}: Failed with response`, e.response);
      const message = `${e.response.data.error.message}. Request id: ${requestId}`;

      const error = isOutOfRangeError(e) ? new OutOfRangeError(message) : new Error(message);
      throw error;
    } else if (e.request) {
      console.error(`Error log: ${errorPrefix}: Failed with no response`, e.request);
      throw new Error(`${errorPrefix}: Failed with no response`);
    } else {
      console.error(`Error log: ${errorPrefix}: Setting up request triggered an Error`);
      throw new Error(`${errorPrefix}: Setting up request triggered an error`);
    }
  };
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const YOUTUBE_HOSTS = ['youtube.com', 'www.youtube.com', 'gaming.youtube.com'];

// We shouldn't rely on particular channel id format
export const isYoutubeChannelLike = (maybeId: string) =>
  maybeId.startsWith('UC') && maybeId.length > 23;

export function getRoleLabel(role: Role) {
  switch (role) {
    case 'admin':
    case 'whitelabel_admin':
      return 'Admin';
    case 'influencer':
      return 'Creator';
    case 'influencer_manager':
      return 'Manager';
    case 'publisher':
      return 'Advertiser';
    default:
      return 'Unknown user role';
  }
}

export function calculateValueWithCommission(value: number, commissionRate: number) {
  return Math.round((value / (1 - commissionRate)) * 100) / 100;
}

export function getDealMonetaryValues(deal: Deal): {
  payoutAmount: string;
  formattedPayoutAmount: string;
  invoiceItemAmount: string;
  formattedInvoiceItemAmount: string;
} {
  switch (deal.pricing.type) {
    case 'fixed_fee': {
      const payoutAmount = deal.pricing.price;

      const formattedPayoutAmount = currencyFormatter(payoutAmount);

      const invoiceItemAmount = calculateValueWithCommission(
        Number(deal.pricing.price),
        deal.pricing.commission_rate
      ).toString();

      const formattedInvoiceItemAmount = currencyFormatter(invoiceItemAmount);

      return { payoutAmount, formattedPayoutAmount, invoiceItemAmount, formattedInvoiceItemAmount };
    }
    case 'cost_per_action': {
      if (deal.deliverable.type !== 'youtube_video') {
        const na = 'N/A';
        return {
          payoutAmount: na,
          formattedPayoutAmount: currencyFormatter(na),
          invoiceItemAmount: na,
          formattedInvoiceItemAmount: currencyFormatter(na),
        };
      }

      const pricing = deal.pricing;
      const actionCount = pricing.actions_count;

      const minFee = Number(pricing.min_fee) || 0;
      const maxCap = Number(pricing.max_cap) || Infinity;

      // Current price, calculated as min_fee + price * actions, but no more than max_cap
      // We also round this up to the nearest cent.
      const currentPrice =
        Math.round(
          Math.min(
            minFee +
              Number(pricing.price) *
                (actionCount > pricing.actions_threshold
                  ? actionCount - pricing.actions_threshold
                  : 0),
            maxCap
          ) * 100
        ) / 100;
      const currentPriceWithCommission = calculateValueWithCommission(
        currentPrice,
        pricing.commission_rate
      );

      return {
        payoutAmount: currentPrice.toString(),
        formattedPayoutAmount: currencyFormatter(currentPrice),
        invoiceItemAmount: currentPriceWithCommission.toString(),
        formattedInvoiceItemAmount: currencyFormatter(currentPriceWithCommission),
      };
    }
    default:
      const na = 'N/A';
      return {
        payoutAmount: na,
        formattedPayoutAmount: currencyFormatter(na),
        invoiceItemAmount: na,
        formattedInvoiceItemAmount: currencyFormatter(na),
      };
  }
}
