import * as C from '@chakra-ui/react';
import { putAgreement } from '../../api-clients/imt-api-agreements';
import { ModalBase } from '@sharkpunch/idun';
import { useAsyncCallback } from 'react-async-hook';
import { showErrorToast } from '../../helpers';

const ChatDisableModal = ({
  agreementId,
  isOpen,
  onClose,
  chatClosed,
  onNewChatClosed,
}: {
  agreementId: number;
  isOpen: boolean;
  onClose: () => void;
  chatClosed: boolean;
  onNewChatClosed: (chatClosed: boolean) => void;
}) => {
  const toggleClosed = useAsyncCallback(
    async () => putAgreement({ chatClosed: !chatClosed }, agreementId),
    {
      onSuccess: (result) => {
        onNewChatClosed(result.chatClosed);
        onClose();
      },
      onError: (error) => {
        if (error?.message) {
          showErrorToast(
            `Error: ${chatClosed ? 'Failed to enable chat' : 'Failed to disable chat'}`,
            error.message
          );
        }
      },
    }
  );

  const enableText = 'Are you sure you want to enable chat?';
  const disableText = 'Are you sure you want to disable chat?';
  return (
    <ModalBase
      header={chatClosed ? enableText : disableText}
      onClose={onClose}
      isOpen={isOpen}
      size="lg">
      <C.HStack justifyContent="space-between">
        <C.Text>{`You can still choose to ${
          chatClosed ? 'disable' : 'enable'
        } the chat again`}</C.Text>
        <C.Button
          onClick={() => toggleClosed.execute()}
          type="button"
          isLoading={toggleClosed.loading}
          colorScheme="cyan">
          Confirm
        </C.Button>
      </C.HStack>
    </ModalBase>
  );
};

export default ChatDisableModal;
