import React from 'react';
import * as C from '@chakra-ui/react';
import { useAsync } from 'react-async';
import { useForm } from 'react-hook-form';
import {
  ConditionalOfferResponse,
  fetchConditionalOffers,
  fetchPricedOffers,
} from '../../api-clients/imt-api-campaigns';
import CampaignSelector from '../campaign-selector/CampaignSelector';
import ConditionalOfferListingTable from './ConditionalOfferListingTable';
import { PricedOffer, showErrorToast } from '../../helpers';
import PricedOfferListingTable from './PricedOfferListingTable';
import { Card, CardBody } from '@sharkpunch/idun';
import axios from 'axios';

type SubmitData = {
  campaign_id: string;
};

const OfferListing = () => {
  const [conditionalOffers, setConditionalOffers] = React.useState<ConditionalOfferResponse[]>([]);
  const [pricedOffers, setPricedOffers] = React.useState<PricedOffer[]>([]);
  const [tabIndex, setTabIndex] = React.useState(0);
  const { isPending, run, status, error } = useAsync({ deferFn: onFormSubmit });

  const {
    formState: { errors },
    handleSubmit,
    control,
    getValues,
  } = useForm<SubmitData>({
    mode: 'onBlur',
    defaultValues: { campaign_id: '' },
  });

  async function getOffers(campaignId: string) {
    try {
      const res = await fetchConditionalOffers(campaignId);
      // hard-coded filtering out of revoked offers, atm we don't have a reason to show these in list
      // (could be user-controlled filter if it becomes neccesary)
      const excludeRevoked = res.filter((offer) => offer.status !== 'revoked');
      setConditionalOffers(excludeRevoked);
    } catch (e) {
      let message = 'Unexpected error occured';
      if (axios.isAxiosError(e)) {
        message = e.response?.data.error.message;
        if (message === 'Something went wrong') {
          // say something a bit more friendly if message is too generic
          message = 'Conditonal offers could not be fetched due to an unspecified reason.';
        }
      }
      showErrorToast('Error', message);
      throw e;
    }

    try {
      const res = await fetchPricedOffers(campaignId);
      // hard-coded filtering out of revoked offers, atm we don't have a reason to show these in list
      // (could be user-controlled filter if it becomes neccesary)
      const excludeRevoked = res.filter((offer) => offer.status.offeror_status !== 'revoked');
      setPricedOffers(excludeRevoked);
    } catch (e) {
      let message = 'Unexpected error occured';
      if (axios.isAxiosError(e)) {
        message = e.response?.data.error.message;
        if (message === 'Something went wrong') {
          // say something a bit more friendly if message is too generic
          message = 'Priced offers could not be fetched due to an unspecified reason.';
        }
      }
      showErrorToast('Error', message);
      throw e;
    }
  }

  async function onFormSubmit([data]: SubmitData[]) {
    const campaignId = data.campaign_id;
    await getOffers(campaignId);
  }

  function onOffersRevoked() {
    const campaignId = getValues('campaign_id');
    if (campaignId) {
      getOffers(campaignId);
    }
  }
  // use useCallback hook so that setTabByOffers is memoized (only called when
  // data and conditionalOffers props change)
  const setTabByOffers = React.useCallback(() => {
    // set to conditional offers tab if there are conditional offers and no priced offers
    setTabIndex(pricedOffers.length === 0 && conditionalOffers.length > 0 ? 1 : 0);
  }, [pricedOffers, conditionalOffers]);

  React.useEffect(() => {
    setTabByOffers();
  }, [setTabByOffers]);

  // needed to still be able to change tabs manually and controlled
  function handleTabsChange(index: number): void {
    setTabIndex(index);
  }

  return (
    <>
      <C.Container maxW="container.lg" my={10}>
        <Card>
          <CardBody>
            <C.Stack spacing={8}>
              <C.Box>
                <C.Heading as="h2" size="md">
                  List offers
                </C.Heading>
                <C.Text>
                  If there are existing offers in the selected campaign they will be listed below.
                </C.Text>
              </C.Box>
              <form
                onSubmit={handleSubmit((data) => {
                  run(data);
                })}>
                <C.HStack spacing={[2, 4]} alignItems="flex-end">
                  <CampaignSelector<SubmitData>
                    errors={errors.campaign_id}
                    control={control}
                    required={true}
                    name="campaign_id"
                  />
                  <C.Button
                    colorScheme="cyan"
                    type="submit"
                    disabled={isPending}
                    isLoading={isPending}>
                    Get offers
                  </C.Button>
                </C.HStack>
              </form>
            </C.Stack>
          </CardBody>
        </Card>
      </C.Container>

      {status === 'fulfilled' && (
        <C.Box layerStyle="base" p={10}>
          <C.Stack spacing={8}>
            <C.Heading as="h2" size="md">
              Offers in campaign
            </C.Heading>

            <C.Tabs index={tabIndex} onChange={handleTabsChange}>
              <C.TabList>
                <C.Tab>Priced offers ({pricedOffers.length})</C.Tab>
                <C.Tab>Conditional offers ({conditionalOffers.length})</C.Tab>
              </C.TabList>
              <C.TabPanels>
                <C.TabPanel px="0">
                  <PricedOfferListingTable
                    pricedOffers={pricedOffers}
                    formSubmitError={error}
                    onOffersRevoked={onOffersRevoked}
                  />
                </C.TabPanel>
                <C.TabPanel px="0">
                  <ConditionalOfferListingTable
                    conditionalOffers={conditionalOffers}
                    onOffersRevoked={onOffersRevoked}
                    formSubmitError={error}
                  />
                </C.TabPanel>
              </C.TabPanels>
            </C.Tabs>
          </C.Stack>
        </C.Box>
      )}
    </>
  );
};

export default OfferListing;
