import * as C from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import 'react-datepicker/dist/react-datepicker.css';
import { alterValueInFilters, Filters } from '../../helpers';
import DateRangeFilter from './DateRangeFilter';
import { DealTags } from '../../api-clients/imt-api-deals';
import { SearchIcon } from '@chakra-ui/icons';
import DealTagsSelect from './DealTagsSelect';

const tags = 'deal.tags';
const deadline = 'deal.deadline';
const dealId = 'deal.id';
const channelName = 'channel.display_name';
const channelId = 'channel.id';

function DealFilters({
  filters,
  onSetFilters,
}: {
  filters: Filters[];
  onSetFilters: (filters: Filters[]) => void;
}) {
  const onChange = debounce((filterId: string, newValue: string) => {
    let nextFilters;
    if (filterId === channelName) {
      nextFilters = alterValueInFilters(
        filters,
        filterId,
        newValue ? [`%${newValue}%`] : [],
        'ilike.'
      );
    } else {
      nextFilters = alterValueInFilters(filters, filterId, newValue ? [newValue] : []);
    }

    onSetFilters(nextFilters);
  }, 300);

  const tagsFilter = filters.find((f) => f.id === tags && f.customPrefix === 'ov.');
  const noTags = filters.find((f) => f.id === tags && f.customPrefix !== 'ov.')?.value[0]
    ? true
    : false;
  const deadlineF = filters.find((f) => f.id === deadline)?.value[0],
    deadlineStartF = filters.find((f) => f.id === `${deadline}[0]`)?.value[0],
    deadlineEndF = filters.find((f) => f.id === `${deadline}[1]`)?.value[0];
  const defaultDealId = filters.find((f) => f.id === dealId)?.value[0].replace(/%/, '') || '';
  const defaultChannelName =
    filters.find((f) => f.id === channelName)?.value[0].replace(/%/, '') || '';
  const defaultChannelId = filters.find((f) => f.id === channelId)?.value[0].replace(/%/, '') || '';

  const activeFiltersCount = [
    noTags,
    deadlineF || deadlineStartF || deadlineEndF,
    defaultDealId,
    defaultChannelName,
    defaultChannelId,
  ].reduce((acc, cur) => (cur ? acc + 1 : acc), tagsFilter?.value.length || 0);

  return (
    <C.Menu closeOnSelect={false} autoSelect={false}>
      <C.MenuButton
        as={C.Button}
        size="sm"
        rightIcon={
          activeFiltersCount ? (
            <C.Tag size="sm" borderRadius="full" variant="solid" colorScheme="cyan">
              {activeFiltersCount}
            </C.Tag>
          ) : (
            <></>
          )
        }>
        Deal
      </C.MenuButton>
      <C.MenuList maxW="25rem">
        <C.MenuGroup title="Publishing date is">
          <C.Box px="var(--space-sm)">
            <DateRangeFilter filterId={deadline} filters={filters} onSetFilters={onSetFilters} />
          </C.Box>
        </C.MenuGroup>

        <C.MenuDivider />
        <C.MenuGroup title="Deal id">
          <C.Stack px="var(--space-sm)">
            <C.InputGroup size="sm">
              <C.InputLeftElement
                zIndex={0}
                pointerEvents="none"
                children={<SearchIcon color="gray.400" />}
              />
              <C.Input
                defaultValue={defaultDealId}
                onChange={(e) => onChange(dealId, e.target.value)}
                placeholder="Must be an exact value"
              />
            </C.InputGroup>
          </C.Stack>
        </C.MenuGroup>

        <C.MenuDivider />
        <C.MenuGroup title="Deal is with">
          <C.Stack spacing={1} px="var(--space-sm)">
            <C.InputGroup size="sm">
              <C.InputLeftElement
                zIndex={0}
                pointerEvents="none"
                children={<SearchIcon color="gray.400" />}
              />
              <C.Input
                defaultValue={defaultChannelName}
                onChange={(e) => onChange(channelName, e.target.value)}
                placeholder="Channel name"
              />
            </C.InputGroup>
            <C.InputGroup size="sm">
              <C.InputLeftElement
                zIndex={0}
                pointerEvents="none"
                children={<SearchIcon color="gray.400" />}
              />
              <C.Input
                defaultValue={defaultChannelId}
                onChange={(e) => onChange(channelId, e.target.value)}
                placeholder="Channel id"
              />
            </C.InputGroup>
          </C.Stack>
        </C.MenuGroup>

        <C.MenuDivider />
        <C.MenuOptionGroup title="Deal is tagged with" value={noTags?.toString()} type="checkbox">
          <C.Box px="var(--space-sm)" pb={2}>
            <DealTagsSelect
              initialTags={tagsFilter?.value.map((t) => ({ tag: t, count: 0 }))}
              setChosenTags={(chosenTags?: DealTags[]) => {
                const tagArray = chosenTags?.map((dealTag) => dealTag.tag);
                let nextFilters = alterValueInFilters(filters, tags, tagArray, 'ov.');
                if (!nextFilters[0].value.length && noTags) {
                  nextFilters = [{ id: tags, value: ['{}'], customPrefix: 'eq.' }];
                }
                onSetFilters(nextFilters);
              }}
            />
          </C.Box>
          <C.MenuItemOption
            value="true"
            onClick={() => {
              let nextFilters;
              if (noTags) {
                nextFilters = alterValueInFilters(filters, tags, []);
              } else {
                nextFilters = alterValueInFilters(filters, tags, ['{}'], 'eq.');
              }
              onSetFilters(nextFilters);
            }}>
            No tags
          </C.MenuItemOption>
        </C.MenuOptionGroup>
      </C.MenuList>
    </C.Menu>
  );
}

export default DealFilters;
