import * as React from 'react';
import * as C from '@chakra-ui/react';
import { EmptyState, Card, CardBody, PageHeader } from '@sharkpunch/idun';
import { useAsync } from 'react-async';
import {
  isOutOfRangeError,
  SortBy,
  filtersToPostgrestFormat,
  postgrestFormatToFilters,
  orderToSortBy,
  sortByToOrder,
} from '../../helpers';
import {
  ContentSubmissionTask,
  getContentSubmissionTasksWithDisplayData,
} from '../../api-clients/imt-api-content-submission';

import { useSearchParams } from 'react-router-dom';

import AlertBox from '../alert-box/AlertBox';
import ContentSubmissionListingTable from './ContentSubmissionListingTable';

const defaultPageSize = 25;
const defaultPageIndex = 0;

const ContentSubmissionListing = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const limit = parseInt(searchParams.get('limit') || String(defaultPageSize), 10),
    offset = parseInt(searchParams.get('offset') || String(defaultPageIndex), 10),
    order = searchParams.get('order') || 'created.desc';

  const [pageSize, setPageSize] = React.useState(limit);
  const [pageIndex, setPageIndex] = React.useState(Math.ceil(offset / limit));

  // Convert order search param to react-table-ish sorting rule.
  // So
  // 'created.asc&reviewed_by_platform_id.desc'
  // will turn into
  // [
  //   { id: 'created', isSortedDesc: false },
  //   { id: 'reviewed_by_platform_id', isSortedDesc: true }
  // ]
  const [sortBy, setSortBy] = React.useState<SortBy[]>(orderToSortBy(order));

  const [filters, setFilters] = React.useState(postgrestFormatToFilters(searchParams));

  React.useEffect(() => {
    const currentSearchParams = Object.fromEntries(searchParams.entries());

    const newFilters = filtersToPostgrestFormat(filters);

    for (const searchKey in currentSearchParams) {
      if (!newFilters[searchKey]) {
        // We used to filter by this item, but not anymore --
        // remove it from search params;
        delete currentSearchParams[searchKey];
      }
    }

    const offset = (pageSize * pageIndex || 0).toString();
    const limit = (pageSize || 25).toString();

    // Convert react-table sorting rule to `order` param:
    const order = sortByToOrder(
      sortBy.map((item) => ({
        ...item,
        id: item.id
          // these are mapped to computed columns, so
          // we need to replace middle dots to underscore
          // https://github.com/SharkPunch/bragi-api/commits/e9b1291e31c2fb73c44f3a0ecef7c67a47a72d6a
          .replace('meta.campaign_id', 'meta->campaign_id')
          .replace('reviewed_by.platform_id', 'reviewed_by_platform_id')
          .replace(
            'expected_content_submitter.platform_id',
            'expected_content_submitter_platform_id'
          ),
      }))
    );

    setSearchParams({
      ...currentSearchParams,
      ...newFilters,
      offset,
      limit,
      order,
    });
  }, [pageSize, pageIndex, sortBy, searchParams, setSearchParams, filters]);

  const { isPending, data, error, setData } = useAsync<{
    data: ContentSubmissionTask[];
    total: number;
  }>({
    promiseFn: getContentSubmissionTasksWithDisplayData,
    searchParams,
    watchFn: (prevProps, nextProps) =>
      prevProps.searchParams.toString() !== nextProps.searchParams.toString(),
  });

  React.useEffect(() => {
    if (isOutOfRangeError(error)) {
      setPageIndex(defaultPageIndex);
    }
  }, [error]);

  let content;
  if (error && !isOutOfRangeError(error)) {
    content = <AlertBox>{`Failed to fetch content submission tasks: ${error.message}`}</AlertBox>;
  } else if (isPending && !data) {
    // Initial load -- we have no data, don't display table yet
    content = <C.Progress size="xs" isIndeterminate />;
  } else if (data) {
    const { data: tasks, total } = data || {};
    content = (
      <ContentSubmissionListingTable
        contentSubmissionTasks={tasks}
        isLoading={isPending}
        setContentSubmissionTasks={setData}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total={total}
        sortBy={sortBy}
        setSortBy={setSortBy}
        filters={filters}
        setFilters={setFilters}
      />
    );
  } else if (!error && !isPending) {
    content = (
      <EmptyState
        title="No content submissions to review"
        description="Seems like there is nothing to do here"
      />
    );
  }

  return (
    <C.Container maxW="container.xl" my={10}>
      <PageHeader title="Content submissions" />
      <Card>
        <CardBody>
          <C.Stack spacing={8}>{content}</C.Stack>
        </CardBody>
      </Card>
    </C.Container>
  );
};

export default ContentSubmissionListing;
