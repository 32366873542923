import * as C from '@chakra-ui/react';
import { Card, CardBody, CardHeader } from '@sharkpunch/idun';
import {
  calculateValueWithCommission,
  currencyFormatter,
  filtersToPostgrestFormat,
  getDealMonetaryValues,
  getHumanReadableAgreementStatus,
  getHumanReadableDeliverableLabel,
  getTrackingUrl,
  numberFormatter,
} from '../../helpers';
import { CAMPAIGN_APP_URL, ROOT_URL } from '../../config';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import { useAsync } from 'react-async-hook';

import { CPAPricing, Deal, fetchDeals } from '../../api-clients/imt-api-deals';

import DealPublishingDate from './DealPublishingDate';
import DealContentReview from './DealContentReview';
import DealTags from './DealTags';
import ChatDisableModal from './ChatDisableModal';
import PayoutInfoModal from './ApproveDeal';
import CancelDealModal from './CancelDealModal';

import {
  CheckIcon,
  ChevronDownIcon,
  CopyIcon,
  NotAllowedIcon,
  ChatIcon,
  ExternalLinkIcon,
  CloseIcon,
} from '@chakra-ui/icons';
import DealPublishedContent from './DealPublishedContent';
import { getRequestId } from '../../api-helpers';
import { AgreementStatus } from '../../api-clients/imt-api-agreements';
import ChakraMarkdown from '../chakra-markdown/ChakraMarkdown';
import { MdTextSnippet } from 'react-icons/md';
import { addDays, format, isAfter } from 'date-fns';

const PricingInfo = ({ deal }: { deal: Deal }) => {
  switch (deal.pricing.type) {
    case 'fixed_fee':
      return <FixedFeePricingInfo deal={deal} />;
    case 'cost_per_action':
      return <CPAPricingInfo deal={deal} pricing={deal.pricing} />;
    case 'unknown':
    default:
      console.error(`Unexpected pricing type [${deal.pricing}]`, deal);
      return <C.Text>Unknown pricing type {deal.pricing.type}. Contact @fixer.</C.Text>;
  }
};

const CPAPricingInfo = ({ deal, pricing }: { deal: Deal; pricing: CPAPricing }) => {
  const { formattedPayoutAmount, formattedInvoiceItemAmount } = getDealMonetaryValues(deal);
  const deliverable = deal.deliverable;

  const content = deal.content;

  const trackingPeriod = deliverable.type === 'youtube_video' ? deliverable.tracking_period : null;
  const isStillTrackingActions = !!(
    content &&
    content.published &&
    isAfter(addDays(new Date(content.published), trackingPeriod || 0), new Date())
  );

  const trackingPeriodEnd =
    (content && trackingPeriod && addDays(new Date(content.published || ''), trackingPeriod)) ||
    null;

  return (
    <C.Stack
      direction="row"
      flexWrap="wrap"
      spacing={2}
      align="center"
      overflow="visible"
      justify="flex-start">
      <C.Popover>
        <C.PopoverTrigger>
          <C.Box ml={-3}>
            <C.Button size="sm" variant="ghost" fontSize="md">
              <C.Text fontWeight="normal">
                {isStillTrackingActions ? 'Estimated payout' : 'Payout'}:{' '}
                <strong>{formattedPayoutAmount}</strong> /{' '}
                {isStillTrackingActions ? 'Estimated cost' : 'Cost'}:{' '}
                <strong>{formattedInvoiceItemAmount}</strong>
              </C.Text>
            </C.Button>
          </C.Box>
        </C.PopoverTrigger>

        <C.Portal>
          <C.PopoverContent>
            <C.PopoverArrow />
            <C.PopoverBody>
              <C.UnorderedList>
                <C.ListItem>
                  CPA: <strong>{currencyFormatter(pricing.price)}</strong> /{' '}
                  <strong>
                    {currencyFormatter(
                      calculateValueWithCommission(Number(pricing.price), pricing.commission_rate)
                    )}
                  </strong>
                </C.ListItem>
                <C.ListItem>
                  Min. fee: <strong>{currencyFormatter(Number(pricing.min_fee))}</strong> /{' '}
                  <strong>
                    {currencyFormatter(
                      calculateValueWithCommission(Number(pricing.min_fee), pricing.commission_rate)
                    )}
                  </strong>
                </C.ListItem>
                <C.ListItem>
                  Max. cap: <strong>{currencyFormatter(Number(pricing.max_cap))}</strong> /{' '}
                  <strong>
                    {currencyFormatter(
                      calculateValueWithCommission(Number(pricing.max_cap), pricing.commission_rate)
                    )}
                  </strong>
                </C.ListItem>

                <C.ListItem>
                  Installs theshold: <strong>{numberFormatter(pricing.actions_threshold)}</strong>
                </C.ListItem>
                <C.ListItem>
                  Current installs count: <strong>{numberFormatter(pricing.actions_count)}</strong>
                </C.ListItem>
              </C.UnorderedList>
            </C.PopoverBody>
          </C.PopoverContent>
        </C.Portal>
      </C.Popover>
      {trackingPeriod ? (
        <ElemWithBulletPoint>
          <PaddedBox>
            {trackingPeriodEnd ? (
              <>
                Tracking period ends on: <strong>{format(trackingPeriodEnd, 'dd.MM.yyyy')}</strong>
              </>
            ) : (
              <>
                Tracking period is <strong>{trackingPeriod}</strong> days after the video is
                published
              </>
            )}
          </PaddedBox>
        </ElemWithBulletPoint>
      ) : null}
    </C.Stack>
  );
};

const FixedFeePricingInfo = ({ deal }: { deal: Deal }) => {
  const { formattedPayoutAmount, formattedInvoiceItemAmount } = getDealMonetaryValues(deal);

  return (
    <C.Stack
      direction="row"
      flexWrap="wrap"
      spacing={2}
      align="center"
      overflow="visible"
      justify="flex-start">
      <C.Tooltip label="Estimated payout to creator" hasArrow openDelay={200}>
        <C.Text>
          Payout: <strong>{formattedPayoutAmount}</strong> /
        </C.Text>
      </C.Tooltip>
      <C.Tooltip label="Estimated cost for advertiser" hasArrow openDelay={200}>
        <C.Text>
          Cost: <strong>{formattedInvoiceItemAmount}</strong>
        </C.Text>
      </C.Tooltip>
    </C.Stack>
  );
};

const DeliverableInfo = ({ deal }: { deal: Deal }) => {
  switch (deal.deliverable.type) {
    case 'youtube_video':
      return <YoutubeDeliverableInfo deal={deal} />;
    case 'buyout':
      return <BuyoutDeliverableInfo deal={deal} />;
    case 'unknown':
      return <C.Text>Unknown deliverable type. Contact @fixer.</C.Text>;
    default:
      console.error(`Unexpected deliverable type [${deal.deliverable}]`, deal);
      return null;
  }
};

// Some helper elements for horizontal lists.
// Lists have clickable components / buttons that have default padding,
// and also plain text -- for the latter we can wrap them with
// this box that has the same padding as buttons do
const PaddedBox = ({ children }: { children: React.ReactNode }) => (
  <C.Box pl={2} pr={2}>
    {children}
  </C.Box>
);
// We want to have bullet items next to items in the lists, but
// wrapping sometimes moves those bullets to the next line; to avoid
// that, we just group elements with bullet points together
const ElemWithBulletPoint = ({ children }: { children: React.ReactNode }) => (
  <C.HStack>
    <C.Box>&bull;</C.Box>
    {children}
  </C.HStack>
);

const YoutubeDeliverableInfo = ({ deal }: { deal: Deal }) => {
  const deliverable = deal.deliverable;
  if (deliverable.type !== 'youtube_video') {
    console.error({ deal }, 'Invalid deliverable passed to YoutubeDeliverableInfo');
    throw new Error('Invalid deliverable passed to YoutubeDeliverableInfo');
  }

  return (
    <C.Stack
      direction="row"
      flexWrap="wrap"
      spacing={2}
      align="center"
      overflow="visible"
      justify="flex-start">
      <C.Box ml={-3}>
        <DealPublishingDate deadline={deal.deal.deadline} agreementId={deal.deal.id} />
      </C.Box>
      <ElemWithBulletPoint>
        <DealContentReview agreementId={deal.deal.id} />
      </ElemWithBulletPoint>
      <ElemWithBulletPoint>
        <DealPublishedContent
          dealItem={
            deal.content
              ? {
                  contentText: deal.content.text || '',
                  contentUrl: deal.content.url,
                  status: deal.content.status,
                }
              : null
          }
          agreementId={deal.deal.id}
        />
      </ElemWithBulletPoint>
      <ElemWithBulletPoint>
        <PaddedBox>
          <C.Text>
            Format: <strong>{deliverable.format}</strong>
          </C.Text>
        </PaddedBox>
      </ElemWithBulletPoint>
      <ElemWithBulletPoint>
        <PaddedBox>
          <DealTags dealTags={deal.deal.tags} agreementId={deal.deal.id} />
        </PaddedBox>
      </ElemWithBulletPoint>
    </C.Stack>
  );
};

const BuyoutDeliverableInfo = ({ deal }: { deal: Deal }) => {
  const deliverable = deal.deliverable;
  if (deliverable.type !== 'buyout') {
    console.error({ deal }, 'Invalid deliverable passed to BuyoutDeliverableInfo');
    throw new Error('Invalid deliverable passed to BuyoutDeliverableInfo');
  }

  return (
    <C.Stack
      direction="row"
      flexWrap="wrap"
      spacing={2}
      align="center"
      overflow="visible"
      justify="flex-start">
      <C.Box ml={-3}>
        <DealPublishingDate deadline={deliverable.deadline} agreementId={deal.deal.id} />
      </C.Box>
      <ElemWithBulletPoint>
        <PaddedBox>
          <C.Text>
            License duration: <strong>{deliverable.license_duration_days} days</strong>
          </C.Text>
        </PaddedBox>
      </ElemWithBulletPoint>
      <ElemWithBulletPoint>
        {deliverable.license_terms ? (
          <C.Popover>
            <C.PopoverTrigger>
              <C.Button leftIcon={<MdTextSnippet />} size="sm" variant="ghost" fontSize="md">
                <C.Text fontWeight="normal">Licensing terms</C.Text>
              </C.Button>
            </C.PopoverTrigger>
            <C.Portal>
              <C.PopoverContent>
                <C.PopoverArrow />
                <C.PopoverBody>
                  <ChakraMarkdown content={deliverable.license_terms} />
                </C.PopoverBody>
              </C.PopoverContent>
            </C.Portal>
          </C.Popover>
        ) : (
          <C.Text>No licensing terms</C.Text>
        )}
      </ElemWithBulletPoint>
      <ElemWithBulletPoint>
        <C.Box className="ellipsis" maxW="100%" pl={2} pr={2}>
          Content:{' '}
          <C.Link isExternal href={`https://youtube.com/watch?v=${deliverable.target_content_id}`}>
            {deliverable.target_name}
          </C.Link>
        </C.Box>
      </ElemWithBulletPoint>
      <ElemWithBulletPoint>
        <DealContentReview agreementId={deal.deal.id} />
      </ElemWithBulletPoint>
      <ElemWithBulletPoint>
        <DealTags dealTags={deal.deal.tags} agreementId={deal.deal.id} />
      </ElemWithBulletPoint>
    </C.Stack>
  );
};

const DealInfo = ({
  deal,
  onNewChatClosed,
  onNewStatus,
}: {
  deal: Deal;
  onNewChatClosed: (newChatClosed: boolean) => void;
  onNewStatus: (status: AgreementStatus) => void;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const trackingUrl = deal.deal.tracking_code ? getTrackingUrl(deal.deal.tracking_code) : '';
  const { hasCopied, onCopy } = C.useClipboard(trackingUrl);

  const {
    result: activeChannelDeals,
    loading,
    error,
  } = useAsync(
    () =>
      fetchDeals({
        filters: filtersToPostgrestFormat([
          { id: 'channel.id', value: [deal.channel.id] },
          { id: 'deal.chat_closed', value: ['false'] },
        ]),
      }),
    [deal.channel.id]
  );

  const {
    isOpen: isOpenDisable,
    onOpen: onOpenDisable,
    onClose: onCloseDisable,
  } = C.useDisclosure();
  const {
    isOpen: isOpenApprove,
    onOpen: onOpenApprove,
    onClose: onCloseApprove,
  } = C.useDisclosure();
  const cancelDealModal = C.useDisclosure();

  if (loading) {
    return (
      <C.Box p={10}>
        <C.Progress size="xs" isIndeterminate />
      </C.Box>
    );
  }

  if (error) {
    const requestId = getRequestId(error);
    return (
      <C.Box key={requestId}>
        <C.Alert status="error">
          <C.Show above="sm">
            <C.AlertIcon />
          </C.Show>
          <C.Stack spacing={0}>
            <C.AlertTitle>{error.message}</C.AlertTitle>
            <C.AlertDescription>
              Try reloading the page or contact @fixer if the issue persists.
            </C.AlertDescription>
            {requestId && (
              <C.Text fontSize="xs" opacity={0.5}>
                Error id: {requestId}
              </C.Text>
            )}
          </C.Stack>
        </C.Alert>
      </C.Box>
    );
  }

  const activeChannelDealsCount = activeChannelDeals?.length || 0;

  return (
    <>
      <ChatDisableModal
        agreementId={deal.deal.id}
        isOpen={isOpenDisable}
        onClose={onCloseDisable}
        chatClosed={deal.deal.chat_closed}
        onNewChatClosed={onNewChatClosed}
      />

      <PayoutInfoModal deal={deal} isOpen={isOpenApprove} onClose={onCloseApprove} />

      <CancelDealModal
        agreementId={deal.deal.id}
        isOpen={cancelDealModal.isOpen}
        onClose={cancelDealModal.onClose}
        onNewStatus={onNewStatus}
      />
      <Card>
        <CardHeader>
          <C.HStack>
            <C.Avatar
              alignSelf="flex-start"
              src={deal.channel.avatar_url || undefined}
              w={10}
              h={10}
            />
            <C.Box>
              <C.HStack>
                <C.Heading as="h3" size="md">
                  {deal.channel.display_name}
                </C.Heading>
                <span>&bull;</span>
                <C.Text>Deal ID {deal.deal.id}</C.Text>
                <span>&bull;</span>
                <C.Text>{getHumanReadableDeliverableLabel(deal.deliverable.type)}</C.Text>
                <span>&bull;</span>
                <C.Text>{getHumanReadableAgreementStatus(deal.deal.status)}</C.Text>
              </C.HStack>
              <C.HStack spacing="1">
                {activeChannelDealsCount !== null ? (
                  <C.Text>{activeChannelDealsCount}</C.Text>
                ) : (
                  <C.Spinner size="xs" />
                )}
                <C.Text>{activeChannelDealsCount === 1 ? 'active deal' : 'active deals'}</C.Text>
                {activeChannelDealsCount && (
                  <C.Link
                    href={`${ROOT_URL}/deals/?channel.id=eq.${deal.channel.id}&deal.chat_closed=is.false`}
                    target="_blank"
                    size="sm">
                    view
                  </C.Link>
                )}
              </C.HStack>
            </C.Box>
          </C.HStack>
          <C.HStack>
            <C.Menu>
              <C.MenuButton as={C.Button} rightIcon={<ChevronDownIcon />}>
                <C.Text noOfLines={1}>Deal options</C.Text>
              </C.MenuButton>
              <C.MenuList>
                <C.MenuItem
                  icon={<CheckIcon color="green" />}
                  onClick={onOpenApprove}
                  closeOnSelect={false}>
                  Payout info
                </C.MenuItem>
                <C.MenuItem
                  icon={
                    deal.deal.chat_closed ? (
                      <ChatIcon color="green" />
                    ) : (
                      <NotAllowedIcon color="red" />
                    )
                  }
                  onClick={onOpenDisable}
                  closeOnSelect={false}>
                  {deal.deal.chat_closed ? 'Enable chat' : 'Disable chat'}
                </C.MenuItem>
                {deal.deal.status === 'settled' && (
                  <C.MenuItem
                    icon={<CloseIcon color="red" w="0.6rem" h="0.6rem" />}
                    onClick={cancelDealModal.onOpen}>
                    Cancel deal
                  </C.MenuItem>
                )}
                <C.MenuDivider />
                <C.MenuGroup
                  title={trackingUrl || 'No tracking link'}
                  style={{ fontVariant: 'none' }}>
                  <C.MenuItem
                    isDisabled={!deal.deal.tracking_code}
                    icon={hasCopied ? <CheckIcon color="green" /> : <CopyIcon />}
                    onClick={onCopy}
                    closeOnSelect={false}>
                    Copy tracking link
                  </C.MenuItem>
                </C.MenuGroup>
                <C.MenuDivider />
                <C.MenuGroup title={`[${deal.campaign.id}] ${deal.campaign.name}`}>
                  <C.MenuItem
                    as="a"
                    icon={<ExternalLinkIcon />}
                    href={`${CAMPAIGN_APP_URL}/admin/campaigns/${deal.campaign.id}`}
                    target="_blank">
                    View campaign
                  </C.MenuItem>
                  <C.MenuItem
                    as="a"
                    icon={<ExternalLinkIcon />}
                    href={`${ROOT_URL}/deals/?deal.campaign_id=eq.${deal.campaign.id}`}
                    target="_blank">
                    View all deals in campaign
                  </C.MenuItem>
                </C.MenuGroup>
                <C.MenuDivider />
                <C.MenuItem
                  as="a"
                  icon={<ExternalLinkIcon />}
                  href={`https://www.youtube.com/channel/${deal.channel.id}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  {deal.channel.display_name}
                </C.MenuItem>
              </C.MenuList>
            </C.Menu>
            <C.IconButton
              size="sm"
              borderRadius="full"
              aria-label="Close deal view"
              icon={<CloseIcon w={3} h={3} />}
              onClick={() => {
                navigate({ pathname: '/deals', search: `?${createSearchParams(searchParams)}` });
              }}
            />
          </C.HStack>
        </CardHeader>
        <CardBody>
          <PricingInfo deal={deal} />
          <DeliverableInfo deal={deal} />
        </CardBody>
      </Card>
    </>
  );
};

export default DealInfo;
