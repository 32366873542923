import React, { useEffect, useState, useReducer, useCallback } from 'react';
import * as C from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';

import groupBy from 'lodash/groupBy';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useRowSelect,
  UseRowSelectRowProps,
  Column,
} from 'react-table';
import ReactTableBase from '../react-table/ReactTableBase';
import IndeterminateCheckbox from '../react-table/IndeterminateCheckbox';

import flatten from 'lodash/flatten';
import memoize from 'lodash/memoize';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import without from 'lodash/without';
import filter from 'lodash/filter';
import countBy from 'lodash/countBy';
import _ from 'lodash';

import { useAsync, useAsyncCallback } from 'react-async-hook';

import { Card, DropdownSelectMulti } from '@sharkpunch/idun';

import { showSuccessToast, showErrorToast } from '../../helpers';

import {
  CollectionResponseWithChannels,
  fetchCollections,
  fetchCollectionWithInfluencers,
  fetchCollectionWithChannels,
  createCollection,
  Demographics,
} from '../../api-clients/imt-api-collections';

import { filtersToPostgrestFormat } from '../../helpers';
import { fetchDeals } from '../../api-clients/imt-api-deals';
import { CampaignSelectorMulti } from '../campaign-selector/CampaignSelector';
import {
  CountryCodeAndNamePair,
  LanguageCodeAndNamePair,
  countryCodesAndNamePairs,
  countryCodesAndNames,
  languageCodesAndNamePairs,
  languages,
  genders,
  ageGroups,
  operatingSystems,
  deviceTypes,
} from '../../constants/demographics';
import { CountryGroupSelector } from './CountryGroupSelector';
import { RuleGroup, RuleListItem } from './RuleGroup';
import { Item, Operator, evaluateRule } from './Rule';

const demographicsAsRuleItems: Item[] = [
  {
    groupKey: 'gender',
    groupLabel: 'Gender',
    options: genders.map((i) => {
      return { id: i, label: i };
    }),
  },
  {
    groupKey: 'country',
    groupLabel: 'Countries',
    options: countryCodesAndNamePairs,
  },
  {
    groupKey: 'deviceType',
    groupLabel: 'Device types',
    options: deviceTypes.map((i) => {
      return { id: i, label: i };
    }),
  },
  {
    groupKey: 'ageGroup',
    groupLabel: 'Age groups',
    options: ageGroups.map((i) => {
      return { id: i, label: i };
    }),
  },
  {
    groupKey: 'operatingSystem',
    groupLabel: 'Operating systems',
    options: operatingSystems.map((i) => {
      return { id: i, label: i };
    }),
  },
];

type Channel = {
  id: string;
  networkStatus: string;
  country: string | null;
  estimatedLanguage: string | null;
  viewEstimate: number | null;
  demographics?: Demographics | null;
};

export type Collection = {
  id?: number;
  name: string;
  channels: Channel[];
};

type ValidatedSelectedOption = { id: string; groupKey: string };

type ValidatedSelectedRuleParams = {
  target: ValidatedSelectedOption;
  operator: Operator;
  ruleValue: number;
};

type ValidatedRuleListItem = {
  id: string;
  params: ValidatedSelectedRuleParams;
};

const validateRuleListItemsOrThrow = (ruleListItems: RuleListItem[]): ValidatedRuleListItem[] => {
  const validated: ValidatedRuleListItem[] = [];
  for (const r of ruleListItems) {
    if (
      r.params?.target &&
      r.params?.operator &&
      r.params?.ruleValue &&
      r.params?.target.groupKey &&
      !isNaN(parseFloat(r.params?.ruleValue))
    ) {
      validated.push({
        id: r.id,
        params: {
          target: { id: r.params.target.id, groupKey: r.params.target.groupKey },
          operator: r.params.operator,
          ruleValue: parseFloat(r.params.ruleValue),
        },
      });
    } else {
      throw new Error(
        'Invalid or incomplete data in list of in-network filtering rules. Finish the rules to proceed.'
      );
    }
  }
  return validated;
};

const memoizedFetchCollectionWithInfluencers = memoize(
  async (collectionId): Promise<Collection> => {
    const collection = await fetchCollectionWithInfluencers(collectionId);
    return {
      name: collection.name,
      channels: collection.influencers.map((influencer) => {
        const channel = influencer.youtubeChannels[0];
        const channelObject: Channel = {
          id: channel.id,
          networkStatus: influencer.isOutOfNetworkInfluencer ? 'OON' : 'IN',
          country: channel.country || 'Unknown country',
          estimatedLanguage: channel.estimatedLanguage || 'Unknown language',
          viewEstimate: channel.estimates.views?.youtube7d.median || null,
        };
        if (!influencer.isOutOfNetworkInfluencer) {
          channelObject.demographics = channel.demographics || null;
        }
        return channelObject || null;
      }),
    };
  }
);
const memoizedFetchCollectionWithChannels = memoize(fetchCollectionWithChannels);
const memoizedFetchDeals = memoize(async (campaignId: number) => {
  return await fetchDeals({
    filters: filtersToPostgrestFormat([{ id: 'deal__campaign_id', value: [String(campaignId)] }]),
  });
});

type CollectionCombinationRules = {
  initialCollections: Collection[];
  internalId: number;
  includedCollectionIds: number[];
  includedCampaignIds: number[];
  excludedCollectionIds: number[];
  excludedCampaignIds: number[];
  excludedCountries: string[];
  excludedLanguages: string[];
  excludeByChannelCount: number;
  inNetworkFilters: { filters: RuleListItem[]; discardOonChannels: boolean };
  mergeIncludedCollections: { value: boolean; name: string };
  removeCollectionOverlap: boolean;
  countrySplits: CountryGroup[];
  valueSplits: string[];
  namePostfix: string;
  batchSplit: string;
};

enum CountrySplitOptions {
  NONE = 'country-split-none',
  INDIVIDUAL = 'country-split-individual',
  ENGLISH_SPEAKING = 'country-split-english-speaking',
  WESTERN_EUROPE = 'country-split-western-europe',
  USER_GROUPS = 'country-split-user-groups',
}

const englishSpeakingCountries = ['US', 'GB', 'IE', 'CA', 'AU', 'NZ'];
const westernEuropeanCountries = [
  'FI',
  'SE',
  'NO',
  'DK',
  'IS',
  'NL',
  'DE',
  'BE',
  'AT',
  'CH',
  'FR',
  'IT',
  'ES',
  'GB',
  'IE',
  'PL',
];

export type CountryGroup = {
  name: string;
  countries: string[];
};

export const individualCountryGroups = countryCodesAndNamePairs.map((c) => {
  return { name: c.label, countries: [c.id] };
});
export const englishSpeakingCountryGroups = [
  { name: 'English speaking countries', countries: englishSpeakingCountries },
];
export const westernEuropeanCountryGroups = [
  { name: 'Western European countries', countries: westernEuropeanCountries },
];

type Action =
  | { type: 'includeCollections'; payload: { collectionIds: number[] } }
  | { type: 'includeCampaignChannels'; payload: { campaignIds: number[] } }
  | { type: 'mergeIncludedCollections'; payload: { value: boolean; name: string } }
  | { type: 'removeCollectionOverlap'; payload: boolean }
  | { type: 'excludeCollections'; payload: { collectionIds: number[] } }
  | { type: 'excludeCampaignChannels'; payload: { campaignIds: number[] } }
  | { type: 'excludeCountries'; payload: { excludedCountries: string[] } }
  | { type: 'excludeLanguages'; payload: { excludedLanguages: string[] } }
  | { type: 'excludeByChannelCount'; payload: number }
  | { type: 'addNamePostfix'; payload: { namePostfix: string } }
  | { type: 'addCountrySplit'; payload: CountryGroup[] }
  | { type: 'addValueSplit'; payload: { values: string[] } }
  | { type: 'addBatchSplit'; payload: { batchSplit: string } }
  | { type: 'inNetworkFilters'; payload: { filters: RuleListItem[]; discardOonChannels: boolean } };

function reducer(state: CollectionCombinationRules, action: Action) {
  switch (action.type) {
    case 'includeCollections':
      return {
        ...state,
        includedCollectionIds: action.payload.collectionIds,
        internalId: state.internalId + 1,
      };
    case 'includeCampaignChannels':
      return {
        ...state,
        includedCampaignIds: action.payload.campaignIds,
        internalId: state.internalId + 1,
      };
    case 'mergeIncludedCollections':
      return {
        ...state,
        mergeIncludedCollections: action.payload,
        internalId: state.internalId + 1,
      };
    case 'removeCollectionOverlap':
      return {
        ...state,
        removeCollectionOverlap: action.payload,
        internalId: state.internalId + 1,
      };
    case 'excludeCollections':
      return {
        ...state,
        excludedCollectionIds: action.payload.collectionIds,
        internalId: state.internalId + 1,
      };
    case 'excludeCampaignChannels':
      return {
        ...state,
        excludedCampaignIds: action.payload.campaignIds,
        internalId: state.internalId + 1,
      };
    case 'excludeCountries':
      return {
        ...state,
        excludedCountries: action.payload.excludedCountries,
        internalId: state.internalId + 1,
      };
    case 'excludeLanguages':
      return {
        ...state,
        excludedLanguages: action.payload.excludedLanguages,
        internalId: state.internalId + 1,
      };
    case 'excludeByChannelCount':
      return {
        ...state,
        excludeByChannelCount: action.payload,
        internalId: state.internalId + 1,
      };
    case 'addNamePostfix':
      return {
        ...state,
        namePostfix: action.payload.namePostfix,
        internalId: state.internalId + 1,
      };
    case 'addCountrySplit':
      return {
        ...state,
        countrySplits: action.payload,
        internalId: state.internalId + 1,
      };
    case 'addValueSplit':
      return {
        ...state,
        valueSplits: action.payload.values,
        internalId: state.internalId + 1,
      };
    case 'addBatchSplit':
      return { ...state, batchSplit: action.payload.batchSplit, internalId: state.internalId + 1 };
    case 'inNetworkFilters':
      return {
        ...state,
        inNetworkFilters: action.payload,
        internalId: state.internalId + 1,
      };
    default:
      throw new Error('Unexpected action');
  }
}

async function excludeChannelsFromCollections(channelsToExclude: string[], acc: Collection[]) {
  return acc.map((c) => {
    return {
      ...c,
      channels: c.channels.filter((channel) => {
        return channelsToExclude.indexOf(channel.id) < 0;
      }),
    };
  });
}

async function addCollectionIds(collectionIds: number[], acc: Collection[]): Promise<Collection[]> {
  const collections = await Promise.all(
    collectionIds.map(String).map(memoizedFetchCollectionWithInfluencers)
  );
  return [...acc, ...collections];
}

async function excludedCollectionIds(
  collectionIds: number[],
  acc: Collection[]
): Promise<Collection[]> {
  const collections = await Promise.all(
    collectionIds.map(String).map(memoizedFetchCollectionWithChannels)
  );
  const idsToExclude = collections.flatMap((c) => {
    return c.channels;
  });
  return await excludeChannelsFromCollections(idsToExclude, acc);
}

async function excludeCampaignChannels(
  campaignIds: number[],
  acc: Collection[]
): Promise<Collection[]> {
  const deals = await Promise.all(campaignIds.map(memoizedFetchDeals));
  const channelIds = deals.flat().map((d) => d.channel.id);
  return await excludeChannelsFromCollections(channelIds, acc);
}

function leastRepresentedBatchIndex(insertionOrder: number[], batchSizes: number[]): number {
  const batchRepresentations = _.countBy(insertionOrder);

  // Manually add batch key with value `0` for batches that don't have
  // any indices yet in insertionOrder array (hence countBy doesn't count them)
  for (let i = 0; i < batchSizes.length; i++) {
    if (!batchRepresentations[i]) {
      batchRepresentations[i] = 0;
    }
  }

  // Get the index of the batch which has least channels relative
  // to the size of the batch at this point
  const res = _.minBy(
    Object.keys(batchRepresentations),
    (index) => batchRepresentations[index] * batchSizes[Number(index)]
  );
  return Number(res) || 0;
}

async function splitCollectionIntoBatches(
  batchSizes: number[],
  collection: Collection
): Promise<Collection[]> {
  const channels = _.sortBy(collection.channels, (c) => -(c.viewEstimate || 0));

  // 1. We have `n` batches. Let each batch be represented by an index in an array.
  // This array will store to which batch should the sorted channels be pushed to in order.
  const insertionOrder: number[] = [];

  // 2. For every channel, see which batch is least represented (has lowest proportion
  // of channels relative to it's batch size), and add that to the insertionOrder array.
  for (let i = 0; i < channels.length; i++) {
    const nextBatchIndex = leastRepresentedBatchIndex(insertionOrder, batchSizes);
    insertionOrder.push(nextBatchIndex);
  }

  // 3. Iterate integers from 0 to length of the channels, and push a channel from each index
  // in the channels array the batch corresponding the index in the insertionOrder array
  const slices: Channel[][] = batchSizes.map((b) => []);
  for (let i = 0; i < channels.length; i++) {
    const batchIndex = insertionOrder[i];
    const channel = channels[i];
    slices[batchIndex].push(channel);
  }

  const seenByBatchSize: Record<number, number> = {};
  return slices.map((slice, index) => {
    const batchSize = batchSizes[index];
    const batchesWithSameSize = batchSizes.filter((s) => s === batchSize).length;
    let namePostFix = '';
    if (batchesWithSameSize > 1) {
      if (!seenByBatchSize[batchSize]) {
        seenByBatchSize[batchSize] = 0;
      }
      seenByBatchSize[batchSize] += 1;
      namePostFix = ' v' + seenByBatchSize[batchSize];
    }

    return {
      name: `${collection.name} - Split ${batchSizes[index] * 100}%${namePostFix}`,
      channels: slice,
    };
  });
}

const isNearlyOne = (n: number): boolean => {
  return n >= 0.99999 && n <= 1.000001;
};

async function batchSplit(batchSizes: number[], acc: Collection[]): Promise<Collection[]> {
  const totalSlices = _.sum(batchSizes);
  if (!isNearlyOne(totalSlices)) {
    throw new Error(
      `Slices must add to exactly "1", ${JSON.stringify(batchSizes)} adds to ${totalSlices}`
    );
  }
  const batches = await Promise.all(
    acc.map((c) => {
      return splitCollectionIntoBatches(batchSizes, c);
    })
  );
  return flatten(batches);
}

async function valueSplit(values: string[], acc: Collection[]): Promise<Collection[]> {
  let ret = [...acc];
  values.forEach((value) => {
    ret = ret.flatMap((collection) => {
      const grouped = groupBy(collection.channels, (channel) => {
        return value in channel ? channel[value as keyof Channel] : 'Unknown';
      });
      return Object.entries(grouped).map(([key, channels]) => {
        return { name: `${collection.name} - ${key}`, channels: channels };
      });
    });
  });
  return ret;
}

async function mergeCollections(name: string, acc: Collection[]): Promise<Collection[]> {
  return [
    {
      name: name,
      channels: acc.flatMap((a) => {
        return a.channels;
      }),
    },
  ];
}

async function findDuplicates(acc: Collection[]): Promise<Channel[]> {
  const channels = acc.map((c) => c.channels).flat();
  const channelCounts = countBy(channels, (c) => c.id);
  return uniqBy(
    filter(channels, (c) => channelCounts[c.id] > 1),
    (c) => c.id
  );
}

async function levelOrderInsertChannelsToCollections(
  acc: Collection[],
  channels: Channel[]
): Promise<Collection[]> {
  for (const c of channels) {
    acc = sortBy(acc, (c) => c.channels.length);
    acc[0].channels.push(c);
  }
  return acc;
}

async function removeCollectionOverlap(acc: Collection[]): Promise<Collection[]> {
  // Goal of this function is to retain only one occurrence of each channel among all the
  // accumulated collections, while having the remaining collections be as evenly sized as possible.
  //
  // From efficiency angle a naive, but as a tradeoff, somewhat readable solution:
  //
  // 1. find duplicate channels
  const duplicates = await findDuplicates(acc);
  if (!duplicates.length) {
    return acc;
  }

  // 2. remove duplicates from all collections
  let ret = await excludeChannelsFromCollections(
    duplicates.map((c) => c.id),
    acc
  );

  // 3. add duplicates one by one to the smallest collection at each point in time
  return await levelOrderInsertChannelsToCollections(ret, duplicates);
}

async function postfixNames(postfix: string, acc: Collection[]): Promise<Collection[]> {
  return acc.map((c) => {
    return { ...c, name: `${c.name} - ${postfix}` };
  });
}

async function splitByCountryGroups(
  acc: Collection[],
  groups: CountryGroup[]
): Promise<Collection[]> {
  if (groups.length === 0) {
    return acc;
  }
  const ret = await Promise.all(
    acc.map((collection) => {
      let collections = [];
      const channels = _.cloneDeep(collection.channels);
      for (const group of groups) {
        const groupChannels = _.remove(
          channels,
          (c) => c.country && group.countries.includes(c.country)
        );
        if (groupChannels.length) {
          collections.push({ name: `${collection.name} - ${group.name}`, channels: groupChannels });
        }
      }
      collections.push({ name: `${collection.name} - other countries`, channels });
      return collections;
    })
  );
  return ret.flat();
}

async function excludeChannelsByProperty(
  acc: Collection[],
  property: keyof Channel,
  values: string[]
): Promise<Collection[]> {
  const channelIdsToRemove = acc
    .map((c) => c.channels)
    .flat()
    .filter((c) => {
      const value = c[property];
      return value && values.includes(String(value));
    })
    .map((c) => c.id);
  return await excludeChannelsFromCollections(channelIdsToRemove, acc);
}

const evaluateAllRulesForChannel = (
  filters: ValidatedRuleListItem[],
  channel: Channel
): boolean => {
  if (!channel.demographics) {
    return false;
  }
  for (const filter of filters) {
    const groupKey = filter.params.target.groupKey;
    const targetKey = filter.params.target.id;
    const specificDemographic = channel.demographics[groupKey as keyof typeof channel.demographics];
    if (!specificDemographic) {
      return false;
    }
    const targetValue = specificDemographic.find((d) => d.name === targetKey)?.value;
    if (!targetValue) {
      return false;
    }
    const targetValueAsDecimalFraction = targetValue / 100;
    if (
      !evaluateRule(targetValueAsDecimalFraction, filter.params.ruleValue, filter.params.operator)
    ) {
      return false;
    }
  }
  return true;
};

async function applyInNetworkFilters(
  acc: Collection[],
  filters: RuleListItem[],
  discardOonChannels: Boolean
): Promise<Collection[]> {
  const validatedInNetworkFilters: ValidatedRuleListItem[] = validateRuleListItemsOrThrow(filters);
  const channelIdsToRemove = [];
  for (const collection of acc) {
    for (const channel of collection.channels) {
      if (channel.networkStatus === 'OON') {
        if (discardOonChannels) {
          channelIdsToRemove.push(channel.id);
        }
        continue;
      }
      if (!evaluateAllRulesForChannel(validatedInNetworkFilters, channel)) {
        channelIdsToRemove.push(channel.id);
      }
    }
  }
  return await excludeChannelsFromCollections(channelIdsToRemove, acc);
}

async function removeDuplicatesWithinEachCollection(acc: Collection[]) {
  return acc.map((collection) => {
    const uniqueChannels = _.uniqBy(collection.channels, 'id');
    collection.channels = uniqueChannels;
    return collection;
  });
}

async function processCollectionsAfterOperation(acc: Collection[]) {
  acc = await removeDuplicatesWithinEachCollection(acc);
  return acc;
}

async function excludeCollectionsWithLessThanNChannels(
  acc: Collection[],
  n: number
): Promise<Collection[]> {
  return acc.filter((c) => c.channels.length >= n);
}

export async function stateToCollections(
  collectionCombinationRules: CollectionCombinationRules
): Promise<Collection[]> {
  let result: Collection[] = collectionCombinationRules.initialCollections;

  if (collectionCombinationRules.includedCollectionIds) {
    result = await addCollectionIds(collectionCombinationRules.includedCollectionIds, result);
    result = await processCollectionsAfterOperation(result);
  }

  if (collectionCombinationRules.includedCampaignIds) {
    // TODO: Implement adding channels to collection by campaign ids
  }

  if (collectionCombinationRules.mergeIncludedCollections.value) {
    result = await mergeCollections(
      collectionCombinationRules.mergeIncludedCollections.name,
      result
    );
    result = await processCollectionsAfterOperation(result);
  }

  if (collectionCombinationRules.removeCollectionOverlap) {
    result = await removeCollectionOverlap(result);
    result = await processCollectionsAfterOperation(result);
  }

  if (collectionCombinationRules.countrySplits) {
    result = await splitByCountryGroups(result, collectionCombinationRules.countrySplits);
    result = await processCollectionsAfterOperation(result);
  }

  if (collectionCombinationRules.valueSplits.length > 0) {
    result = await valueSplit(collectionCombinationRules.valueSplits, result);
    result = await processCollectionsAfterOperation(result);
  }

  if (collectionCombinationRules.batchSplit !== '[]') {
    const splits = JSON.parse(collectionCombinationRules.batchSplit);
    result = await batchSplit(splits, result);
    result = await processCollectionsAfterOperation(result);
  }

  if (
    collectionCombinationRules.inNetworkFilters.filters &&
    collectionCombinationRules.inNetworkFilters.filters.length > 0
  ) {
    result = await applyInNetworkFilters(
      result,
      collectionCombinationRules.inNetworkFilters.filters,
      collectionCombinationRules.inNetworkFilters.discardOonChannels
    );
    result = await processCollectionsAfterOperation(result);
  }

  if (collectionCombinationRules.excludedCollectionIds) {
    result = await excludedCollectionIds(collectionCombinationRules.excludedCollectionIds, result);
    result = await processCollectionsAfterOperation(result);
  }

  if (
    collectionCombinationRules.excludedCampaignIds &&
    collectionCombinationRules.excludedCampaignIds.length > 0
  ) {
    result = await excludeCampaignChannels(collectionCombinationRules.excludedCampaignIds, result);
    result = await processCollectionsAfterOperation(result);
  }

  if (
    collectionCombinationRules.excludedCountries &&
    collectionCombinationRules.excludedCountries.length > 0
  ) {
    result = await excludeChannelsByProperty(
      result,
      'country',
      collectionCombinationRules.excludedCountries
    );
    result = await processCollectionsAfterOperation(result);
  }

  if (
    collectionCombinationRules.excludedLanguages &&
    collectionCombinationRules.excludedLanguages.length > 0
  ) {
    result = await excludeChannelsByProperty(
      result,
      'estimatedLanguage',
      collectionCombinationRules.excludedLanguages
    );
    result = await processCollectionsAfterOperation(result);
  }

  if (collectionCombinationRules.excludeByChannelCount > -1) {
    result = await excludeCollectionsWithLessThanNChannels(
      result,
      collectionCombinationRules.excludeByChannelCount
    );
  }

  if (collectionCombinationRules.namePostfix) {
    result = await postfixNames(collectionCombinationRules.namePostfix, result);
    result = await processCollectionsAfterOperation(result);
  }

  return result;
}

const NewCollectionsList = (props: {
  collectionCombinationRules: CollectionCombinationRules;
  createCollections: (collections: Collection[]) => Promise<CollectionResponseWithChannels[]>;
}) => {
  const { result, error, loading } = useAsync(async () => {
    return stateToCollections(props.collectionCombinationRules);
  }, [props.collectionCombinationRules.internalId]);

  type Data = { name: string; channelCount: number; totalViewEstimate: number };

  const data: Data[] = React.useMemo(() => {
    return (result || []).map((c) => {
      return {
        name: c.name,
        channelCount: c.channels.length,
        totalViewEstimate: _.sumBy(c.channels, (channel) => channel.viewEstimate || 0),
      };
    });
  }, [result]);

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Collection name',
        accessor: 'name' as const,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ value }: { value: string }) => (
          // Use whiteSpace: 'unset' to force line break for long collection names
          <C.Badge style={{ whiteSpace: 'unset' }} width="lg" textTransform="none">
            {value}
          </C.Badge>
        ),
      },
      {
        Header: 'Channel count',
        accessor: 'channelCount' as const,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ value }: { value: number }) => value,
      },
      {
        Header: 'Total views estimated',
        accessor: 'totalViewEstimate' as const,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ value }: { value: number }) => value,
      },
    ] as Column<Data>[];
  }, []);

  const tableInstance = useTable(
    { data, columns },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }: { row: UseRowSelectRowProps<Data> }) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ]);
    }
  );

  const getCheckedCollections = () => {
    const selectedIds = tableInstance.state.selectedRowIds;
    return (result || []).filter((collection, i) => selectedIds[i]);
  };

  const [collectionsCreated, setCollectionsCreated] = useState(false);
  useEffect(() => {
    setCollectionsCreated(false);
  }, [props.collectionCombinationRules.internalId]);

  const collectionCreation = useAsyncCallback(props.createCollections);

  if (error) {
    return <C.Alert status="error">{error.message}</C.Alert>;
  } else if (loading) {
    return (
      <C.Wrap>
        <C.Spinner /> <span>Loading...</span>
      </C.Wrap>
    );
  } else if (result && result.length) {
    return (
      <C.Stack>
        <ReactTableBase tableInstance={tableInstance} />
        {collectionsCreated ? (
          <C.Alert status="success">These collections are created</C.Alert>
        ) : (
          <C.Button
            colorScheme="cyan"
            onClick={() => {
              collectionCreation.execute(getCheckedCollections()).then((collections) => {
                setCollectionsCreated(true);
              });
            }}
            isLoading={collectionCreation.loading}>
            Create {getCheckedCollections().length} collections
          </C.Button>
        )}
      </C.Stack>
    );
  } else if (result && !result.length) {
    return <C.Alert status="info">No collections selected</C.Alert>;
  } else {
    return <C.Alert status="error">Something truly unexpected happened</C.Alert>;
  }
};

const CollectionsPage = () => {
  const [showCountryGroupSelector, setShowCountryGroupSelector] = React.useState<boolean>(false);
  const [selectedCountryGroups, setSelectedCountryGroups] = React.useState<CountryGroup[]>([]);
  const [showInNetworkFilters, setShowInNetworkFilters] = React.useState<boolean>(false);
  const [discardOonChannels, setDiscardOonChannels] = React.useState<boolean>(false);
  const collectionsFetching = useAsync(fetchCollections, []);

  useEffect(() => {
    dispatch({
      type: 'addCountrySplit',
      payload: selectedCountryGroups,
    });
  }, [selectedCountryGroups]);

  useEffect(() => {
    if (!showCountryGroupSelector) {
      setSelectedCountryGroups([]);
    }
  }, [showCountryGroupSelector]);

  const createCollections = useAsyncCallback(async (collections: Collection[]) => {
    try {
      const results = await Promise.all(
        collections
          .map((r) => {
            return {
              name: r.name,
              youtubeChannelIds: r.channels.map((c) => {
                return c.id;
              }),
            };
          })
          .map(createCollection)
      );
      showSuccessToast('Success', `Created ${results.length} new collections`);
      collectionsFetching.execute();
      return results;
    } catch (e) {
      showErrorToast(
        'Error when creating collections',
        e instanceof Error ? e.message : 'Unknown error'
      );
      return [];
    }
  });

  const [collectionCombinationRules, dispatch] = useReducer(reducer, {
    initialCollections: [],
    internalId: 0,
    includedCollectionIds: [],
    includedCampaignIds: [],
    mergeIncludedCollections: { value: false, name: '' },
    excludedCollectionIds: [],
    excludedCampaignIds: [],
    excludedCountries: [],
    excludedLanguages: [],
    excludeByChannelCount: 0,
    inNetworkFilters: { filters: [], discardOonChannels: false },
    removeCollectionOverlap: false,
    countrySplits: [],
    valueSplits: [],
    namePostfix: '',
    batchSplit: '[]',
  });

  const handleRuleGroupChange = useCallback(
    (params: RuleListItem[]) => {
      dispatch({
        type: 'inNetworkFilters',
        payload: { filters: params, discardOonChannels },
      });
    },
    [discardOonChannels]
  );

  const possibleSplitValues: number[][] = [
    [],
    [0.2, 0.8],
    [0.2, 0.2, 0.6],
    [0.5, 0.5],
    [0.3, 0.7],
    [0.25, 0.25, 0.25, 0.25],
  ];

  return (
    <C.Container maxW="container.xl" my={10}>
      {/* container has full height minus top menu */}
      <C.Heading mb={8}>Collection Combiner 3000</C.Heading>
      <Card p={8}>
        <C.Stack spacing={8}>
          <C.Box>
            <C.Heading size="l">Setup</C.Heading>
          </C.Box>
          <C.FormControl>
            <C.FormLabel>Select collections</C.FormLabel>
            {!createCollections.loading ? (
              <DropdownSelectMulti<{ id: number; name: string }>
                limitItems={60}
                inputPlaceholder="Select collections..."
                onChange={(newValue) => {
                  const collectionIds = (newValue || []).map((c) => {
                    return Number(c.id);
                  });
                  if (!isEqual(collectionIds, collectionCombinationRules.includedCollectionIds)) {
                    dispatch({
                      type: 'includeCollections',
                      payload: {
                        collectionIds: collectionIds,
                      },
                    });
                  }
                }}
                initialItems={collectionCombinationRules.includedCollectionIds.map((c: number) => {
                  const collection = find(collectionsFetching.result, (r) => {
                    return r.id === c;
                  });
                  return { id: c, name: collection?.name || '' };
                })}
                items={collectionsFetching.result || []}
                itemToLabel={(c) => `[${c.id}] ${c.name}`}
              />
            ) : (
              <C.Wrap>
                {collectionCombinationRules.includedCollectionIds.map((c: number) => {
                  const collection = find(collectionsFetching.result, (r) => {
                    return r.id === c;
                  });
                  return (
                    <C.Tag key={c}>
                      [{`${c}`}] {`${collection?.name || ''}`}
                    </C.Tag>
                  );
                })}
              </C.Wrap>
            )}
          </C.FormControl>

          <C.FormControl>
            <C.Stack>
              <C.HStack align="center">
                <C.Switch
                  onChange={(e) => {
                    dispatch({
                      type: 'mergeIncludedCollections',
                      payload: {
                        value: e.target.checked,
                        name: collectionCombinationRules.mergeIncludedCollections.name,
                      },
                    });
                  }}
                  isDisabled={createCollections.loading}
                />
                <C.FormLabel>Combine collections</C.FormLabel>
              </C.HStack>
              {collectionCombinationRules.mergeIncludedCollections.value && (
                <C.Input
                  isDisabled={createCollections.loading}
                  type="text"
                  placeholder="Collection name"
                  defaultValue={collectionCombinationRules.mergeIncludedCollections.name}
                  onBlur={(e) => {
                    dispatch({
                      type: 'mergeIncludedCollections',
                      payload: {
                        value: collectionCombinationRules.mergeIncludedCollections.value,
                        name: e.target.value,
                      },
                    });
                  }}
                />
              )}
            </C.Stack>
          </C.FormControl>

          <C.FormControl>
            <C.Stack>
              <C.HStack align="center">
                <C.Switch
                  onChange={(e) => {
                    dispatch({
                      type: 'removeCollectionOverlap',
                      payload: e.target.checked,
                    });
                  }}
                  isDisabled={createCollections.loading}
                />
                <C.FormLabel>Remove collection overlap</C.FormLabel>
              </C.HStack>
            </C.Stack>
          </C.FormControl>

          <C.FormControl>
            <C.FormLabel>
              Only include collections that have at least this many channels:
            </C.FormLabel>
            <C.NumberInput
              width="xs"
              onChange={(n) => {
                const parsed = parseInt(n);
                dispatch({
                  type: 'excludeByChannelCount',
                  payload: !isNaN(parsed) ? parsed : 0,
                });
              }}
              step={1}
              defaultValue={0}
              min={0}>
              <C.NumberInputField />
              <C.NumberInputStepper>
                <C.NumberIncrementStepper />
                <C.NumberDecrementStepper />
              </C.NumberInputStepper>
            </C.NumberInput>
          </C.FormControl>

          <C.FormControl>
            <C.FormLabel>Split into batches</C.FormLabel>
            <C.RadioGroup
              isDisabled={createCollections.loading}
              onChange={(newValue) => {
                dispatch({ type: 'addBatchSplit', payload: { batchSplit: newValue } });
              }}
              value={collectionCombinationRules.batchSplit}>
              <C.Stack>
                {possibleSplitValues.map((splitValues) => {
                  return (
                    <C.Radio value={JSON.stringify(splitValues)} key={JSON.stringify(splitValues)}>
                      {splitValues
                        .map((s) => {
                          return `${s * 100}%`;
                        })
                        .join(', ') || 'No split'}
                    </C.Radio>
                  );
                })}
              </C.Stack>
            </C.RadioGroup>
          </C.FormControl>

          <C.FormControl>
            <C.FormLabel>Split by countries</C.FormLabel>
            <C.Stack>
              <C.RadioGroup
                isDisabled={createCollections.loading}
                onChange={(newValue) => {
                  let payload: CountryGroup[] = [];
                  switch (newValue) {
                    case CountrySplitOptions.NONE:
                      setShowCountryGroupSelector(false);
                      payload = [];
                      break;
                    case CountrySplitOptions.INDIVIDUAL:
                      setShowCountryGroupSelector(false);
                      payload = individualCountryGroups;
                      break;
                    case CountrySplitOptions.ENGLISH_SPEAKING:
                      setShowCountryGroupSelector(false);
                      payload = englishSpeakingCountryGroups;
                      break;
                    case CountrySplitOptions.WESTERN_EUROPE:
                      setShowCountryGroupSelector(false);
                      payload = westernEuropeanCountryGroups;
                      break;
                    case CountrySplitOptions.USER_GROUPS:
                      setShowCountryGroupSelector(true);
                      payload = [];
                      break;
                    default:
                      setShowCountryGroupSelector(false);
                      payload = [];
                      break;
                  }
                  dispatch({
                    type: 'addCountrySplit',
                    payload,
                  });
                }}>
                <C.Stack>
                  <C.Radio value={CountrySplitOptions.NONE} key={CountrySplitOptions.NONE}>
                    Do not split by countries
                  </C.Radio>
                  <C.Radio
                    value={CountrySplitOptions.INDIVIDUAL}
                    key={CountrySplitOptions.INDIVIDUAL}>
                    Split collections by individual countries
                  </C.Radio>
                  <C.Radio
                    value={CountrySplitOptions.ENGLISH_SPEAKING}
                    key={CountrySplitOptions.ENGLISH_SPEAKING}>
                    Split collections between English speaking and rest of the world
                  </C.Radio>
                  <C.Radio
                    value={CountrySplitOptions.WESTERN_EUROPE}
                    key={CountrySplitOptions.WESTERN_EUROPE}>
                    Split collections between Western Europe and rest of the world{' '}
                    <C.Tooltip label="Definition: FI, SE, NO, DK, IS, NL, DE, BE, AT, CH, FR, IT, ES, GB, IE + PL">
                      <InfoIcon />
                    </C.Tooltip>
                  </C.Radio>
                  <C.Radio
                    value={CountrySplitOptions.USER_GROUPS}
                    key={CountrySplitOptions.USER_GROUPS}>
                    Select country split groups yourself
                  </C.Radio>
                </C.Stack>
              </C.RadioGroup>
              {showCountryGroupSelector && (
                <CountryGroupSelector
                  selectedCountryGroups={selectedCountryGroups}
                  setSelectedCountryGroups={setSelectedCountryGroups}
                />
              )}
            </C.Stack>
          </C.FormControl>

          <C.FormControl>
            <C.FormLabel>Split by value</C.FormLabel>
            <C.Stack>
              <C.HStack align="center">
                <C.Switch
                  onChange={(e) => {
                    dispatch({
                      type: 'addValueSplit',
                      payload: {
                        values: e.target.checked
                          ? uniq([...collectionCombinationRules.valueSplits, 'networkStatus'])
                          : without(collectionCombinationRules.valueSplits, 'networkStatus'),
                      },
                    });
                  }}
                  isDisabled={createCollections.loading}
                />
                <C.FormLabel>In and Out-of-network</C.FormLabel>
              </C.HStack>
              <C.HStack align="center">
                <C.Switch
                  onChange={(e) => {
                    dispatch({
                      type: 'addValueSplit',
                      payload: {
                        values: e.target.checked
                          ? uniq([...collectionCombinationRules.valueSplits, 'estimatedLanguage'])
                          : without(collectionCombinationRules.valueSplits, 'estimatedLanguage'),
                      },
                    });
                  }}
                  isDisabled={createCollections.loading}
                />
                <C.FormLabel>Language</C.FormLabel>
              </C.HStack>
            </C.Stack>
          </C.FormControl>

          <C.Heading size="l" mb={8}>
            Filters
          </C.Heading>

          <C.FormControl>
            <C.Stack>
              <C.HStack mb={4} align="center">
                <C.Switch
                  onChange={(e) => {
                    setShowInNetworkFilters(e.target.checked);
                    dispatch({
                      type: 'inNetworkFilters',
                      payload: { filters: [], discardOonChannels },
                    });
                  }}
                  isDisabled={createCollections.loading}
                />
                <C.FormLabel>Filter in-network channels by demographics</C.FormLabel>
              </C.HStack>
              {showInNetworkFilters && (
                <C.Stack>
                  <C.HStack mb={4} align="center">
                    <C.Switch
                      onChange={(e) => {
                        setDiscardOonChannels(e.target.checked);
                      }}
                      isDisabled={createCollections.loading}
                    />
                    <C.FormLabel>
                      Discard OON channels from collections{' '}
                      <C.Tooltip label="We do not have demographics for OON channels. Hence you case by case you might or might not want to discard OON channels from the collections to which you apply demographic filters. You must have at least one active in-network filtering rule for this toggle to have an effect.">
                        <InfoIcon />
                      </C.Tooltip>
                    </C.FormLabel>
                  </C.HStack>
                  <RuleGroup
                    targetItems={demographicsAsRuleItems}
                    onChange={handleRuleGroupChange}
                  />
                </C.Stack>
              )}
            </C.Stack>
          </C.FormControl>

          <C.FormControl>
            <C.FormLabel>Exclude collections</C.FormLabel>
            {!createCollections.loading ? (
              <DropdownSelectMulti<{ id: number; name: string }>
                limitItems={60}
                inputPlaceholder="Select collections..."
                onChange={(newValue) => {
                  const collectionIds = (newValue || []).map((c) => {
                    return Number(c.id);
                  });
                  if (!isEqual(collectionIds, collectionCombinationRules.excludedCollectionIds)) {
                    dispatch({
                      type: 'excludeCollections',
                      payload: {
                        collectionIds: collectionIds,
                      },
                    });
                  }
                }}
                initialItems={collectionCombinationRules.excludedCollectionIds.map((c: number) => {
                  const collection = find(collectionsFetching.result, (r) => {
                    return r.id === c;
                  });
                  return { id: c, name: collection?.name || '' };
                })}
                items={collectionsFetching.result || []}
                itemToLabel={(c) => `[${c.id}] ${c.name}`}
              />
            ) : (
              <C.Wrap>
                {collectionCombinationRules.excludedCollectionIds.map((c: number) => {
                  const collection = find(collectionsFetching.result, (r) => {
                    return r.id === c;
                  });
                  return (
                    <C.Tag key={c}>
                      [{`${c}`}] {`${collection?.name || ''}`}
                    </C.Tag>
                  );
                })}
              </C.Wrap>
            )}
          </C.FormControl>

          <C.FormControl>
            <C.FormLabel>Exclude channels from campaigns</C.FormLabel>
            <CampaignSelectorMulti
              initialSelectedCampaignIds={collectionCombinationRules.excludedCampaignIds.map((id) =>
                String(id)
              )}
              onSetSelectedCampaigns={(campaigns) => {
                const campaignIds = (campaigns || []).map((c) => {
                  return c.id;
                });
                if (!isEqual(campaignIds, collectionCombinationRules.excludedCampaignIds)) {
                  dispatch({
                    type: 'excludeCampaignChannels',
                    payload: {
                      campaignIds,
                    },
                  });
                }
              }}
            />
          </C.FormControl>

          <C.FormControl>
            <C.FormLabel>Exclude channels from countries</C.FormLabel>
            <DropdownSelectMulti<CountryCodeAndNamePair>
              limitItems={60}
              inputPlaceholder="Select countries..."
              onChange={(newValue) => {
                const countries = (newValue || []).map((c) => {
                  return c.id;
                });
                if (!isEqual(countries, collectionCombinationRules.excludedCountries)) {
                  dispatch({
                    type: 'excludeCountries',
                    payload: {
                      excludedCountries: countries,
                    },
                  });
                }
              }}
              initialItems={collectionCombinationRules.excludedCountries.map((c: string) => {
                const country = countryCodesAndNames[c as keyof typeof countryCodesAndNames];
                return { id: c, label: country || '' };
              })}
              items={countryCodesAndNamePairs}
              itemToLabel={(c) => `[${c.id}] ${c.label}`}
            />
          </C.FormControl>

          <C.FormControl>
            <C.FormLabel>Exclude channels by languages</C.FormLabel>
            <DropdownSelectMulti<LanguageCodeAndNamePair>
              limitItems={60}
              inputPlaceholder="Select languages..."
              onChange={(newValue) => {
                const languages = (newValue || []).map((c) => {
                  return c.id;
                });
                if (!isEqual(languages, collectionCombinationRules.excludedLanguages)) {
                  dispatch({
                    type: 'excludeLanguages',
                    payload: {
                      excludedLanguages: languages,
                    },
                  });
                }
              }}
              initialItems={collectionCombinationRules.excludedLanguages.map((c: string) => {
                const language = languages[c as keyof typeof languages];
                return { id: c, label: language || '' };
              })}
              items={languageCodesAndNamePairs}
              itemToLabel={(c) => `[${c.id}] ${c.label}`}
            />
          </C.FormControl>

          <C.FormControl>
            <C.FormLabel>Name postfix</C.FormLabel>
            <C.Input
              isDisabled={createCollections.loading}
              type="text"
              onBlur={(e) => {
                dispatch({ type: 'addNamePostfix', payload: { namePostfix: e.target.value } });
              }}
            />
          </C.FormControl>

          <C.Box>
            <C.Heading size="l" mb={8}>
              Results
            </C.Heading>
            <NewCollectionsList
              collectionCombinationRules={collectionCombinationRules}
              createCollections={createCollections.execute}
            />
          </C.Box>
        </C.Stack>
      </Card>
    </C.Container>
  );
};

export default CollectionsPage;
