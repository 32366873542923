import * as C from '@chakra-ui/react';
import { dateFormatter } from '../../helpers';
import { format } from 'date-fns';

interface PropsDateText extends C.PropsOf<typeof C.Text> {
  date: string;
}

const DateText = ({ date, ...rest }: PropsDateText) => {
  const hoverDate = format(new Date(date), 'MMM do yyyy k:mm');
  return (
    <C.Tooltip label={hoverDate} openDelay={500} hasArrow>
      <C.Text {...rest}>{dateFormatter(date)}</C.Text>
    </C.Tooltip>
  );
};

export default DateText;
