import sumBy from 'lodash/sumBy';
import * as C from '@chakra-ui/react';

import type { OfferCreateRequest } from '../../helpers';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { currencyFormatter } from '../../helpers';
import { PreparedOffers } from './BulkOfferCreation';

export const PreviewSummary = (props: {
  pricedOffersSelected: OfferCreateRequest[];
  preparedOffers: PreparedOffers;
}) => {
  const { preparedOffers, pricedOffersSelected } = props;
  const { offers: pricedOffers, conditionalOffers, failedToParse } = preparedOffers;
  const totalOffersLength = pricedOffersSelected?.length + conditionalOffers?.length;
  const totalSum = sumBy(pricedOffersSelected, (o: OfferCreateRequest) =>
    parseFloat(
      o.pricing.type === 'fixed_fee'
        ? o.pricing.price
        : o.pricing.type === 'cpi'
        ? o.pricing.min_fee || '0'
        : '0'
    )
  );
  const unselectedLength = pricedOffers.length - pricedOffersSelected.length;

  const hasCpiOffers = pricedOffers.some((o) => o.pricing.type === 'cpi');

  return (
    <C.Wrap my={8}>
      <C.WrapItem>
        <C.Stat mr={10} minW={['100%', 'xs']}>
          <C.StatLabel>Total sum of offers (in creator payouts)</C.StatLabel>
          <C.StatNumber data-testid="offers-sum">{currencyFormatter(totalSum)}</C.StatNumber>
          {(conditionalOffers.length > 0 || hasCpiOffers) && (
            <C.StatHelpText>
              Excluding conditional offers. Only min guarantee of CPI offers is counted in.
            </C.StatHelpText>
          )}
        </C.Stat>
      </C.WrapItem>
      <C.WrapItem>
        <C.Stat mr={10} minW={['100%', 'xs']}>
          <C.StatLabel>Total number of offers</C.StatLabel>
          <C.StatNumber data-testid="offers-count">{totalOffersLength}</C.StatNumber>
          {unselectedLength > 0 && (
            <C.StatHelpText>
              {unselectedLength} {unselectedLength > 1 ? 'are' : 'is'} removed
            </C.StatHelpText>
          )}
        </C.Stat>
      </C.WrapItem>
      {conditionalOffers?.length > 0 && (
        <C.WrapItem>
          <C.Stat mr={10} minW={['100%', 'xs']}>
            <C.StatLabel>Conditional offers </C.StatLabel>
            <C.StatNumber data-testid="conditional-offers-count">
              {conditionalOffers.length}
            </C.StatNumber>
            <C.StatHelpText>
              {`${conditionalOffers.length}/${totalOffersLength} offers are conditional`}
              <C.Tooltip
                hasArrow
                placement="right"
                label='Conditional offers are for out-of-network creators that will be turned into priced ("normal") offers if they decide to sign up to Matchmade (and still satify to any set rules and filters).
                All other offers are priced ("normal") offers sent to in-network creators.'>
                <QuestionOutlineIcon ml={1} />
              </C.Tooltip>
            </C.StatHelpText>
          </C.Stat>
        </C.WrapItem>
      )}
      {failedToParse.length > 0 && (
        <C.WrapItem>
          <C.Stat minW={['100%', 'xs']}>
            <C.StatLabel>
              Number of creators for which we couldn’t calculate an offer because of missing data
            </C.StatLabel>
            <C.StatNumber color="red" data-testid="offers-failed-count">
              {failedToParse.length}
            </C.StatNumber>
            <C.StatHelpText>
              Offers will not be sent to influencers with{' '}
              <strong>[influencer-id] channel-id</strong>:
              <C.Wrap>
                {failedToParse.map((item) => (
                  <C.WrapItem key={item}>
                    <C.Badge textTransform="none">{item}</C.Badge>
                  </C.WrapItem>
                ))}
              </C.Wrap>
            </C.StatHelpText>
          </C.Stat>
        </C.WrapItem>
      )}
    </C.Wrap>
  );
};
