import React from 'react';
import * as C from '@chakra-ui/react';
import { format } from 'date-fns';
import { ConditionalOfferResponse } from '../../api-clients/imt-api-campaigns';
import { getDeadlines, isYoutubeVideoDeliverable } from '../../helpers';
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  UseRowSelectRowProps,
  useSortBy,
  useTable,
} from 'react-table';
import IndeterminateCheckbox from '../react-table/IndeterminateCheckbox';
import { useConfirmDialog } from '@sharkpunch/idun';
import ReactTableBase from '../react-table/ReactTableBase';
import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { isEmpty } from 'lodash';
import { useRevokeOffers } from '../../useRevokeOffers';
import { RevokeOffersInfoModal } from './RevokeOffersInfoModal';
import AlertBox from '../alert-box/AlertBox';

type OfferTableRow = {
  campaignId?: string;
  channelId: string;
  status: string;
  promotionType: string;
  publishingDate: string;
};

const OfferListingTable = ({
  conditionalOffers,
  onOffersRevoked,
  formSubmitError,
}: {
  conditionalOffers: Array<ConditionalOfferResponse>;
  onOffersRevoked: Function;
  formSubmitError: Error | undefined;
}) => {
  const { confirm, Dialog } = useConfirmDialog();
  const { isOpen, onClose, onToggle } = useDisclosure();
  const { succeeded, failed, totalOffersToRevokeCount, triggerRevokeOffers } = useRevokeOffers();

  const data = React.useMemo(() => {
    if (!conditionalOffers) {
      return [];
    }
    return conditionalOffers.map((offer) => {
      const channelId = offer.offeree.platform_id;
      const deliverable = offer.deliverables[0];

      return {
        channelId,
        status: offer.status,
        promotionType: isYoutubeVideoDeliverable(deliverable) ? deliverable.format : '',
        publishingDate: getDeadlines(offer.deliverables[0]),
        expirationDate: format(Date.parse(offer.expires), 'do MMM yyyy'),
      };
    });
  }, [conditionalOffers]);

  const columns = React.useMemo(
    () =>
      [
        {
          Header: 'Channel id',
          accessor: 'channelId' as const,
          disableSortBy: true,
          Cell: ({ value }: { value: string }) => <C.Badge textTransform="none">{value}</C.Badge>,
        },
        { Header: 'Offer status', accessor: 'status' as const },
        { Header: 'Promotion type', accessor: 'promotionType' as const, disableSortBy: true },
        { Header: 'Publishing date', accessor: 'publishingDate' as const, disableSortBy: true },
        { Header: 'Expiration date', accessor: 'expirationDate' as const, disableSortBy: true },
      ] as Column<OfferTableRow>[],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 50 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }: { row: UseRowSelectRowProps<OfferTableRow> }) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ]);
    }
  );

  const {
    state: { selectedRowIds },
  } = tableInstance;

  const revokeOffers = () => {
    const selectedIds = tableInstance.state.selectedRowIds;
    const offersToRevoke = conditionalOffers.filter((offer, i) => selectedIds[i]);

    confirm({
      headerText: 'Are you sure?',
      bodyText: `You are about to revoke ${offersToRevoke.length} conditional offers.`,
      cancelText: 'Abort mission',
      confirmText: 'Revoke offers',
      confirmColorScheme: 'cyan',
    }).then(() => {
      triggerRevokeOffers({ conditionalOffers: offersToRevoke }).then(() => onOffersRevoked());
      onToggle();
    });
  };

  return (
    <Box>
      <Button
        disabled={isEmpty(selectedRowIds)}
        size="sm"
        leftIcon={<DeleteIcon />}
        onClick={revokeOffers}>
        Revoke selected
      </Button>
      <ReactTableBase tableInstance={tableInstance} withGlobalFilter />
      {!formSubmitError && conditionalOffers.length === 0 && (
        <AlertBox status="info">{`No conditional offers were found for this campaign.`}</AlertBox>
      )}
      <Dialog />
      <RevokeOffersInfoModal
        succeeded={succeeded}
        failed={failed}
        isOpen={isOpen}
        onClose={onClose}
        totalOffersCount={totalOffersToRevokeCount}
      />
    </Box>
  );
};

export default OfferListingTable;
