import * as C from '@chakra-ui/react';

import {
  OfferDataT,
  OfferData,
  DetailsPerChannel,
  offerDataToOfferCreateRequest,
  ConditionalOfferCreateRequest,
} from '../../helpers';
import { CsvUpload } from '../csv-upload/CsvUpload';

import type { OffereeToCommissionRateObject } from '../../helpers';
import { getChannelInfo } from '../../helpers';
import { PreparedOffers } from './BulkOfferCreation';
import { getCampaignsInfo } from './PreviewTable';

const parseCommissionRates = (d: OfferDataT[]): OffereeToCommissionRateObject => {
  const commissionRates: OffereeToCommissionRateObject = {};
  d.forEach((o) => {
    commissionRates[o.channel_id] = o.variable_commission_rate || null;
  });
  return commissionRates;
};

export function CsvUploadPage({
  setPreparedOffers,
}: {
  setPreparedOffers: React.Dispatch<React.SetStateAction<PreparedOffers>>;
}) {
  const { colorMode } = C.useColorMode();
  const columns = [
    {
      col: 'campaign_id',
      type: 'number',
      example: '1234',
    },
    {
      col: 'influencer_id',
      type: 'number',
      example: '1234',
    },
    {
      col: 'channel_id',
      type: 'text',
      example: 'UC1yk1Q8LOUUss8BPueRhvjh',
    },
    {
      col: 'promotion_type',
      type: 'dedicated or integration',
      example: 'dedicated',
    },
    {
      col: 'creator_fixed_fee_cents',
      type: 'number (creator price in cents, without commission)',
      example: '12345',
    },
    {
      col: 'video_publishing_date_start',
      type: 'date (YYYY-MM-DD)',
      example: '2021-12-21',
    },
    {
      col: 'video_publishing_date_end',
      type: 'date (YYYY-MM-DD) (same as start date if not a range)',
      example: '2021-12-28',
    },
    {
      col: 'budget_id',
      type: "text or empty ('')",
      example: 'someId1',
    },
    {
      col: 'variable_commission_rate',
      type: 'number (optional, leave empty to default to campaign commission rate)',
      example: '0.1',
    },
    {
      col: 'meta',
      type: "JSON field of metadata. Put empty object {} if you don't want to include metadata for the offers.",
      example: '{ "batch_name": "test_batch_name" }',
    },
  ];
  return (
    <C.Stack spacing={8} p={6}>
      <C.Text>This app takes a bulk offer CSV with the following columns:</C.Text>
      <C.Box
        backgroundColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}
        padding={4}
        fontFamily="monospace"
        data-testid="csv-columns-overview">
        {columns.map((column) => (
          <C.Text key={column.col}>{column.col}</C.Text>
        ))}
      </C.Box>
      <C.Accordion allowToggle>
        <C.AccordionItem>
          <C.AccordionButton>
            <C.Box flex="1" textAlign="left">
              Click here to view accepted formatting for the CSV file
            </C.Box>
            <C.AccordionIcon />
          </C.AccordionButton>
          <C.AccordionPanel>
            <C.Table data-testid="csv-columns-help">
              <C.Thead>
                <C.Tr>
                  <C.Th>Column</C.Th>
                  <C.Th>Format</C.Th>
                  <C.Th>Example</C.Th>
                </C.Tr>
              </C.Thead>
              <C.Tbody>
                {columns.map((column) => (
                  <C.Tr key={column.col}>
                    <C.Td>
                      <C.Text fontFamily="monospace">{column.col}</C.Text>
                    </C.Td>
                    <C.Td>
                      <C.Text>{column.type}</C.Text>
                    </C.Td>
                    <C.Td>
                      <C.Code>{column.example}</C.Code>
                    </C.Td>
                  </C.Tr>
                ))}
              </C.Tbody>
            </C.Table>
            <C.Text mt={10}>Example file:</C.Text>
            <C.Box backgroundColor="gray.100" padding={4} fontFamily="monospace">
              <C.Text>
                campaign_id,influencer_id,channel_id,promotion_type,creator_fixed_fee_cents,video_publishing_date_start,video_publishing_date_end,budget_id,variable_commission_rate,meta
              </C.Text>
              <C.Text>
                {`1816,224,UC1yk1Q8LOUUss8BPueRhvjh,dedicated,12345,2020-12-22,2020-12-22,someId1,0.3,{}`}
              </C.Text>
              <C.Text>{`1816,123,UWwyi4mA4zCuXDhVwLxwtylA,integration,4321,2020-11-28,2020-11-28,,0.9,{ "batch_name": "secret_batch_123" }`}</C.Text>
            </C.Box>
          </C.AccordionPanel>
        </C.AccordionItem>
      </C.Accordion>
      <C.Text>
        The CSV is then shown in the table below, and one can bulk send all offers with the "Send
        offers" button.
      </C.Text>
      <CsvUpload
        codec={OfferData}
        onData={async (d: OfferDataT[]) => {
          const campaignsInfo = await getCampaignsInfo(d.map((o) => String(o.campaign_id)));
          const offersToBeSent = d.map((o) => offerDataToOfferCreateRequest(o, campaignsInfo));
          const commissionRates = parseCommissionRates(d);
          const conditionalOffers: ConditionalOfferCreateRequest[] = [];
          const detailsPerChannel = d.reduce((acc, data) => {
            acc[data.channel_id] = getChannelInfo(data.influencer_id.toString());
            return { ...acc };
          }, {} as DetailsPerChannel);
          setPreparedOffers({
            offers: offersToBeSent,
            conditionalOffers,
            commissionRates,
            detailsPerChannel,
            prices: {},
            failedToParse: [],
          });
        }}
        colorScheme="cyan"
      />
    </C.Stack>
  );
}
