import * as t from 'io-ts';
import { imtApi } from './imt-api';
import { decodeResponseArray } from '../api-helpers';

const LinkedConnectionDecoder = t.exact(
  t.type({
    platform: t.literal('youtube'),
    platform_id: t.string,
    display_name: t.string,
    avatar_url: t.union([t.string, t.null]),
  }),
  'Connection'
);

export type LinkedConnection = t.TypeOf<typeof LinkedConnectionDecoder>;

export async function getLinkedConnectionsById(id: string): Promise<LinkedConnection[]> {
  const url = `/v2/admin/linked-connections/${id}`;
  const res = await imtApi.get(url);
  return decodeResponseArray(res, LinkedConnectionDecoder);
}
