import React from 'react';
import * as C from '@chakra-ui/react';
import { DropdownSelectMulti } from '@sharkpunch/idun';
import { DealTags, fetchDealTags } from '../../api-clients/imt-api-deals';
import { useAsync } from 'react-async-hook';

const DealTagsSelect = ({
  setChosenTags,
  initialTags,
  allowItemCreate,
}: {
  setChosenTags: (dealTags?: DealTags[]) => void;
  initialTags?: DealTags[];
  allowItemCreate?: boolean;
}) => {
  const [init, setInit] = React.useState<DealTags[]>();
  const [dealTags, setDealTags] = React.useState<DealTags[]>([]);

  const doFetchDealTags = useAsync(fetchDealTags, [], {
    onSuccess: (result) => setDealTags(result),
  });

  // change array to string so that useEffect doesn't think it sees changes that don't exist
  const initialTagsString = React.useMemo(() => JSON.stringify(initialTags), [initialTags]);

  React.useEffect(() => {
    const parsed = initialTagsString ? JSON.parse(initialTagsString) : [];
    if (parsed && dealTags) {
      // we need to set the same `count` so that DropdownSelectMulti knows which
      // tags are already selected, otherwise the items are different and they won't match
      const newInit = parsed.map((tag: DealTags) => {
        const completeTag = dealTags.find((t) => t.tag === tag.tag);
        return completeTag || { tag: tag.tag, count: 0 };
      });
      setInit(newInit);
    }
  }, [initialTagsString, dealTags]);

  return (
    <C.Box>
      {doFetchDealTags.loading ? (
        <C.Spinner />
      ) : doFetchDealTags.result ? (
        <DropdownSelectMulti<DealTags>
          allowItemCreate={allowItemCreate}
          createNewItem={(value) => ({ tag: value, count: 0 })}
          initialItems={init}
          inputPlaceholder="Select tags"
          onChange={setChosenTags}
          items={dealTags}
          itemToLabel={(item) => item.tag}
        />
      ) : (
        'No tags found'
      )}
    </C.Box>
  );
};

export default DealTagsSelect;
