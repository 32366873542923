import * as C from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import { alterValueInFilters, Filters } from '../../helpers';

const statusesArray = Object.entries({
  payment_info_pending: 'Payment info pending',
  payment_info_added: 'Payment info added',
  payment_info_confirmed: 'Payment info confirmed',
  payment_info_invalid: 'Payment info invalid',
  paid: 'Paid',
  payment_failed: 'Payment failed',
});
const payout = 'payout_task__id';
const status = 'payout_task.status';

function PayoutFilters({
  filters,
  onSetFilters,
}: {
  filters: Filters[];
  onSetFilters: (filters: Filters[]) => void;
}) {
  function onChange(filterId: string, newValue: string[] | null, customPrefix?: string) {
    const nextFilters = alterValueInFilters(
      filters,
      filterId,
      newValue ? newValue : [],
      customPrefix
    );
    onSetFilters(nextFilters);
  }

  const triggeredF = filters.find((f) => f.id === payout && f.customPrefix)?.value.length;
  const notTriggeredF = filters.find((f) => f.id === payout && !f.customPrefix)?.value.length;
  const statusF = filters.find((f) => f.id === status)?.value;

  const activeFiltersCount = statusF?.length || 0 + (triggeredF || notTriggeredF ? 1 : 0);

  const payoutTriggeredValue = triggeredF
    ? 'triggered'
    : notTriggeredF
    ? 'notTriggered'
    : 'triggeredAny';

  const payoutStatusValue = statusF || [];

  return (
    <C.Menu closeOnSelect={false}>
      <C.MenuButton
        as={C.Button}
        size="sm"
        rightIcon={
          activeFiltersCount ? (
            <C.Tag size="sm" borderRadius="full" variant="solid" colorScheme="cyan">
              {activeFiltersCount}
            </C.Tag>
          ) : (
            <></>
          )
        }>
        Payout
      </C.MenuButton>
      <C.MenuList>
        <C.MenuOptionGroup
          title="Triggered and untriggered"
          value={payoutTriggeredValue}
          type="radio">
          <C.MenuItemOption
            value="triggeredAny"
            isDisabled={!!filters.find((f) => f.id === status)?.value.length}
            onClick={() => {
              onChange(payout, null);
            }}>
            Any
          </C.MenuItemOption>
          <C.MenuItemOption
            value="triggered"
            isDisabled={!!filters.find((f) => f.id === status)?.value.length}
            onClick={() => onChange(payout, ['null'], 'not.is.')}>
            Triggered
          </C.MenuItemOption>
          <C.MenuItemOption
            value="notTriggered"
            isDisabled={!!filters.find((f) => f.id === status)?.value.length}
            onClick={() => onChange(payout, ['null'])}>
            Not yet triggered
          </C.MenuItemOption>
        </C.MenuOptionGroup>

        <C.MenuDivider />
        <C.MenuOptionGroup
          title="Status is one of"
          value={payoutStatusValue}
          type="checkbox"
          onChange={(selectedValues) => {
            let nextFilters = alterValueInFilters(filters, status, [...selectedValues]);
            if (nextFilters.length) {
              nextFilters = alterValueInFilters(nextFilters, payout, ['null'], 'not.is.');
            }
            onSetFilters(nextFilters);
          }}>
          {statusesArray.map(([key, label]) => (
            <C.MenuItemOption value={key} key={key}>
              {label}
            </C.MenuItemOption>
          ))}
        </C.MenuOptionGroup>
      </C.MenuList>
    </C.Menu>
  );
}

export default PayoutFilters;
