import React from 'react';
import * as C from '@chakra-ui/react';
import { Select } from '@chakra-ui/react';

type Option = { id: string; label: string };
export type SelectedRuleParams = {
  target: SelectedOption | null;
  operator: Operator | null;
  ruleValue: string | null;
};

type SelectedOption = { id: string; groupKey: string | null };
export type Item = { groupLabel: string | null; groupKey: string | null; options: Option[] };
type Props = {
  targetItems: Item[];
  onChange: (params: SelectedRuleParams) => void;
  selectedParams: SelectedRuleParams;
};

export type Operator =
  | { id: 'eq'; name: 'equals' }
  | { id: 'gte'; name: 'is greater than or equal to' }
  | { id: 'gt'; name: 'is greater than' }
  | { id: 'lte'; name: 'is less than or equal to' }
  | { id: 'lt'; name: 'is less than' };

export const operators: Operator[] = [
  { id: 'eq', name: 'equals' },
  { id: 'gte', name: 'is greater than or equal to' },
  { id: 'gt', name: 'is greater than' },
  { id: 'lte', name: 'is less than or equal to' },
  { id: 'lt', name: 'is less than' },
];

export const evaluateRule = (
  targetValue: number,
  ruleValue: number,
  operator: Operator
): boolean => {
  switch (operator.id) {
    case 'eq':
      return targetValue === ruleValue;
    case 'gte':
      return targetValue >= ruleValue;
    case 'gt':
      return targetValue > ruleValue;
    case 'lte':
      return targetValue <= ruleValue;
    case 'lt':
      return targetValue < ruleValue;
    default:
      return false;
  }
};

export const Rule = (props: Props) => {
  const { targetItems = [], onChange, selectedParams } = props;

  return (
    <C.HStack>
      <C.Box width="xs">
        <Select
          value={selectedParams.target ? JSON.stringify(selectedParams.target) : ''}
          onChange={(i) => {
            const value = JSON.parse(i.target.value);
            if (value) {
              onChange({
                ...selectedParams,
                target: value,
              });
            } else {
              throw new Error(
                `Failed to parse target value, this should never happen. Value: ${i.target.value}. Please ping fixer.`
              );
            }
          }}>
          {targetItems.map(({ groupLabel, groupKey, options }) => (
            <optgroup label={groupLabel || ''} key={groupKey || options[0].id}>
              {options.map((option) => {
                return (
                  <option key={option.label} value={JSON.stringify({ groupKey, id: option.id })}>
                    {option.label}
                  </option>
                );
              })}
            </optgroup>
          ))}
        </Select>
      </C.Box>
      <C.Box width="xs">
        <Select
          value={selectedParams?.operator?.id || undefined}
          textAlign="center"
          onChange={(i: any) => {
            const id = i.target.value;
            const operator = operators.find((o) => o.id === id);
            if (operator) {
              onChange({
                ...selectedParams,
                operator,
              });
            }
          }}>
          {operators.map((o) => {
            return (
              <option key={o.id} value={o.id}>
                {o.name}
              </option>
            );
          })}
        </Select>
      </C.Box>
      <C.Box width={32}>
        <C.NumberInput
          precision={2}
          value={selectedParams.ruleValue || undefined}
          min={0}
          max={1}
          onChange={(i) => {
            onChange({
              ...selectedParams,
              ruleValue: i,
            });
          }}>
          <C.NumberInputField />
        </C.NumberInput>
      </C.Box>
    </C.HStack>
  );
};
