import * as C from '@chakra-ui/react';
import { CardHeader } from '@sharkpunch/idun';

import DealsCampaignFilter from './DealCampaignFilter';
import DealFilters from './DealFilters';
import ChatFilters from './ChatFilters';
import ContentReviewFilters from './ContentReviewFilters';
import VideoFilters from './VideoFilters';
import PayoutFilters from './PayoutFilters';
import SortBy from './SortBy';

import { Filters as FiltersT } from '../../helpers';

const Filters = ({
  isLoading,
  filters,
  onSetFilters,
}: {
  isLoading: boolean;
  filters: FiltersT[];
  onSetFilters: (filters: FiltersT[]) => void;
}) => (
  <CardHeader alignItems="flex-start">
    <C.Wrap overflow="visible">
      <C.WrapItem>
        <DealsCampaignFilter onSetFilters={onSetFilters} filters={filters} />
      </C.WrapItem>
      <C.WrapItem>
        <DealFilters onSetFilters={onSetFilters} filters={filters} />
      </C.WrapItem>
      <C.WrapItem>
        <ChatFilters onSetFilters={onSetFilters} filters={filters} />
      </C.WrapItem>
      <C.WrapItem>
        <ContentReviewFilters onSetFilters={onSetFilters} filters={filters} />
      </C.WrapItem>
      <C.WrapItem>
        <VideoFilters onSetFilters={onSetFilters} filters={filters} />
      </C.WrapItem>
      <C.WrapItem>
        <PayoutFilters onSetFilters={onSetFilters} filters={filters} />
      </C.WrapItem>
      <C.WrapItem>
        <SortBy />
      </C.WrapItem>
      {isLoading && (
        <C.WrapItem>
          <C.CircularProgress isIndeterminate size="var(--chakra-fontSizes-3xl)" color="cyan.500" />
        </C.WrapItem>
      )}
    </C.Wrap>
    <C.Flex>
      <C.Button
        variant="link"
        isDisabled={filters.length ? false : true}
        onClick={() => onSetFilters([])}
        colorScheme="cyan"
        size="sm">
        Clear
      </C.Button>
    </C.Flex>
  </CardHeader>
);

export default Filters;
