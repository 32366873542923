import * as t from 'io-ts';
import { imtApi } from './imt-api';
import { decodeResponse, decodeResponseArray } from '../api-helpers';

export const RoleDecoder = t.keyof(
  {
    influencer: null,
    influencer_manager: null,
    publisher: null,
    whitelabel_admin: null,
    admin: null,
  },
  'Role'
);
export type Role = t.TypeOf<typeof RoleDecoder>;

const AccountDecoder = t.exact(
  t.type({
    id: t.number,
    email: t.string,
    contactEmail: t.union([t.string, t.null]),
    displayName: t.string,
    avatarUrl: t.union([t.null, t.string]),
    role: t.union([RoleDecoder, t.null]),
  }),
  'Account'
);

export type Account = t.TypeOf<typeof AccountDecoder>;

export async function fetchAccountsByEmail(email: string): Promise<Account[]> {
  const url = `/admin/accounts?email=${encodeURIComponent(email)}`;
  const res = await imtApi.get(url);
  return decodeResponseArray(res, AccountDecoder);
}

export async function fetchAccountsByYoutubeChannelId(id: string): Promise<Account[]> {
  const res = await imtApi.get(`/admin/accounts?youtube_channel_id=${id}`);
  return decodeResponseArray(res, AccountDecoder);
}

export async function fetchAccountsByYoutubeHandle(handle: string): Promise<Account[]> {
  const res = await imtApi.get(`/admin/accounts?youtube_channel_handle=${handle}`);
  return decodeResponseArray(res, AccountDecoder);
}

export async function getAccountByIdOrThrow(id: string): Promise<Account> {
  const url = `/admin/accounts/${id}`;
  const res = await imtApi.get(url);
  return decodeResponse(res, AccountDecoder);
}
export async function fetchAccountsByDisplayName(displayName: string): Promise<Account[]> {
  const url = `/admin/accounts?display_name=${displayName}`;
  const res = await imtApi.get(url);
  return decodeResponseArray(res, AccountDecoder);
}

const AccountIdAndNameDecoder = t.type(
  {
    id: t.number,
    displayName: t.string,
  },
  'AccountIdAndName'
);

export type AccountIdAndName = t.TypeOf<typeof AccountIdAndNameDecoder>;

export async function getAllAccountNameAndIdPairs(): Promise<AccountIdAndName[]> {
  const url = `/admin/accounts/select-fields?fields=id,display_name`;
  const res = await imtApi.get(url);
  return decodeResponseArray(res, AccountIdAndNameDecoder);
}

const getChannelsByIdMimirPQL = (channelIds: string[]) => ({
  op: 'query',
  expression: {
    type: 'youtube_channel',
    select: [{ op: 'obj', expression: { type: 'youtube_channel' } }],
    where: [
      {
        op: 'in',
        expression: {
          haystack: {
            op: 'constant_list',
            expression: {
              values: channelIds,
            },
          },
          needle: {
            op: 'field',
            expression: {
              field: 'id',
            },
          },
        },
      },
    ],
  },
});

const getTwitchChannelsByIdMimirPQL = (channelIds: string[]) => ({
  op: 'query',
  expression: {
    type: 'twitch_channel',
    select: [{ op: 'obj', expression: { type: 'twitch_channel' } }],
    where: [
      {
        op: 'in',
        expression: {
          haystack: {
            op: 'constant_list',
            expression: {
              values: channelIds,
            },
          },
          needle: {
            op: 'field',
            expression: {
              field: 'id',
            },
          },
        },
      },
    ],
  },
});

const getVideosByIdPQL = (videoIds: string[]) => ({
  op: 'query',
  expression: {
    type: 'youtube_video',
    select: [{ op: 'obj', expression: { type: 'youtube_video' } }],
    where: [
      {
        op: 'in',
        expression: {
          haystack: {
            op: 'constant_list',
            expression: {
              values: videoIds,
            },
          },
          needle: {
            op: 'field',
            expression: {
              field: 'id',
            },
          },
        },
      },
    ],
  },
});

const MimirYoutubeChannelDecoder = t.type(
  {
    id: t.string,
    name: t.union([t.null, t.string]),
    avatar_url: t.union([t.null, t.string]),
    custom_url: t.union([t.null, t.string]),
  },
  'MimirYoutubeChannel'
);
export type MimirYoutubeChannel = t.TypeOf<typeof MimirYoutubeChannelDecoder>;

const MimirTwitchChannelDecoder = t.type(
  {
    id: t.string,
    name: t.union([t.null, t.string]),
    avatar_url: t.union([t.null, t.string]),
  },
  'MimirTwitchChannel'
);

const MimirYoutubeVideoDecoder = t.type(
  {
    id: t.string,
    title: t.union([t.null, t.string]),
  },
  'MimirYoutubeVideo'
);

const MimirYoutubeChannelResponseDecoder = t.type(
  { youtube_channel_key: t.string, youtube_channel: MimirYoutubeChannelDecoder },
  'MimirYoutubeChannelResponseDecoder'
);
const MimirYoutubeVideoResponseDecoder = t.type(
  { youtube_video_key: t.string, youtube_video: MimirYoutubeVideoDecoder },
  'MimirResponseDecoder'
);

const MimirTwitchChannelResponseDecoder = t.type(
  { twitch_channel_key: t.string, twitch_channel: MimirTwitchChannelDecoder },
  'MimirTwitchChannelResponseDecoder'
);

export async function getChannelsByIdsAndPlatform(ids: string[], platform: 'youtube' | 'twitch') {
  if (!ids.length) {
    return [];
  }

  if (platform === 'twitch') {
    return getTwitchChannelsByIds(ids);
  }

  return getChannelsByIds(ids);
}

export async function getChannelsByIds(ids: string[]) {
  if (!ids.length) {
    return [];
  }
  const res = await imtApi.post('/admin/youtube', { query: getChannelsByIdMimirPQL(ids) });
  return decodeResponseArray(res, MimirYoutubeChannelResponseDecoder).map((c) => c.youtube_channel);
}

async function getTwitchChannelsByIds(ids: string[]) {
  if (!ids.length) {
    return [];
  }
  const res = await imtApi.post('/admin/twitch', { query: getTwitchChannelsByIdMimirPQL(ids) });
  return decodeResponseArray(res, MimirTwitchChannelResponseDecoder).map((c) => c.twitch_channel);
}

export async function getVideosById(ids: string[]) {
  if (!ids.length) {
    return [];
  }
  const res = await imtApi.post('/admin/youtube', { query: getVideosByIdPQL(ids) });
  return decodeResponseArray(res, MimirYoutubeVideoResponseDecoder).map((v) => v.youtube_video);
}
