import React from 'react';
import * as C from '@chakra-ui/react';
import _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { Card, CardBody, EmptyState, DropdownSelect } from '@sharkpunch/idun';
import { useAsync } from 'react-async-hook';
import { getRequestId } from '../../api-helpers';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Account,
  AccountIdAndName,
  fetchAccountsByEmail,
  fetchAccountsByYoutubeHandle,
  fetchAccountsByYoutubeChannelId,
  fetchAccountsByDisplayName,
  getAccountByIdOrThrow,
  getChannelsByIds,
  MimirYoutubeChannel,
  Role,
  getAllAccountNameAndIdPairs,
} from '../../api-clients/imt-api-accounts';
import { CAMPAIGN_APP_URL } from '../../config';
import {
  notEmpty,
  currencyFormatter,
  PricedOffer,
  StatusType,
  filtersToPostgrestFormat,
  isYoutubeChannelLike,
  YOUTUBE_HOSTS,
  OfferStatus,
  getRoleLabel,
  emailRegex,
} from '../../helpers';
import { getLinkedConnectionsById, LinkedConnection } from '../../api-clients/imt-api-connections';
import { getPricedOffersByChannelId } from '../../api-clients/imt-api-offers';
import {
  getManagementInvitations,
  getManagerToYoutubeChannel,
  ManagementInvitation,
  ManagementInvitationStatus,
  ManagerToYoutubeChannel,
} from '../../api-clients/imt-api-management-invites';
import { isNotFoundError } from '../../errors';
import { fetchDeals, PayoutTask, Deal } from '../../api-clients/imt-api-deals';
import { PayoutTaskStatus } from '../../api-clients/imt-api-agreements';
import {
  useTable,
  useSortBy,
  useRowSelect,
  useGlobalFilter,
  useFilters,
  Column,
  Row,
} from 'react-table';
import ReactTableBase from '../react-table/ReactTableBase';
import {
  getManagerTeamByManagerId,
  getManagerTeamChannels,
  getManagerTeamMembers,
} from '../../api-clients/imt-api-manager-teams';
import { format } from 'date-fns';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const KEYWORD_SEARCH_PARAM_NAME = 'q';
const SEARCH_TYPE_PARAM_NAME = 'search_type';

type LinkedConnectionWithManagerData = LinkedConnection & {
  manager: ManagerToYoutubeChannel | null;
};

type AccountSearchResult = {
  account: Account;
  linkedConnectionsWithManagers: LinkedConnectionWithManagerData[];
  pricedOffers: PricedOffer[];
  managementInvitations: ManagementInvitation[];
  payoutTasks: PayoutTask[];
  deals: Deal[];
  channels: MimirYoutubeChannel[];
};

const getStatusBadgeForOffer = (offerorStatus: StatusType, offereeStatus: StatusType) => {
  if (offerorStatus === 'accepted' && offereeStatus === 'accepted') {
    return <C.Badge colorScheme="green">Accepted</C.Badge>;
  }

  if (offerorStatus === 'rejected' || offereeStatus === 'rejected') {
    return <C.Badge colorScheme="red">Rejected</C.Badge>;
  }

  if (offerorStatus === 'revoked') {
    return <C.Badge colorScheme="red">Revoked</C.Badge>;
  }

  if (offerorStatus === 'cancelled' || offereeStatus === 'cancelled') {
    return <C.Badge colorScheme="red">Cancelled</C.Badge>;
  }

  if (offereeStatus === 'pending') {
    return <C.Badge colorScheme="yellow">Pending</C.Badge>;
  }

  return <C.Badge colorScheme="gray">Unknown</C.Badge>;
};

const getManagementInvitationStatusBadge = (status: ManagementInvitationStatus) => {
  switch (status) {
    case 'pending':
      return <C.Badge colorScheme="yellow">Pending</C.Badge>;
    case 'accepted':
      return <C.Badge colorScheme="green">Accepted</C.Badge>;
    case 'declined':
      return <C.Badge colorScheme="red">Declined</C.Badge>;
    default:
      return <C.Badge colorScheme="gray">Unknown</C.Badge>;
  }
};

const getPayoutStatusBadge = (status: PayoutTaskStatus) => {
  switch (status) {
    case 'payment_info_pending':
      return <C.Badge colorScheme="yellow">Pending</C.Badge>;
    case 'payment_info_added':
      return <C.Badge colorScheme="yellow">Added</C.Badge>;
    case 'payment_info_confirmed':
      return <C.Badge colorScheme="cyan">Confirmed</C.Badge>;
    case 'payment_info_invalid':
      return <C.Badge colorScheme="orange">Invalid</C.Badge>;
    case 'paid':
      return <C.Badge colorScheme="green">Paid</C.Badge>;
    case 'payment_failed':
      return <C.Badge colorScheme="red">Failed</C.Badge>;
    default:
      return <C.Badge colorScheme="gray">Unknown</C.Badge>;
  }
};

const AccountTable = ({ data }: { data: AccountSearchResult[] }) => {
  const sortType = React.useMemo(
    () =>
      (rowA: Row<AccountSearchResult>, rowB: Row<AccountSearchResult>): number =>
        rowA.values['account.displayName']?.localeCompare(rowB.values['account.displayName']) || -1,
    []
  );

  const columns: Column<AccountSearchResult>[] = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'account',
        Cell: ({ value }) => (
          <C.HStack>
            <C.Avatar size="sm" src={value.avatarUrl || undefined} />
            <C.Text size="sm">{value.displayName}</C.Text>
          </C.HStack>
        ),
        sortType,
      },
      {
        Header: 'Signup email',
        accessor: (row) => row.account.email,
      },
      {
        Header: 'Contact email',
        accessor: (row) => row.account.contactEmail,
      },
      {
        Header: 'Id',
        accessor: (row) => row.account.id,
      },
      {
        Header: 'Role',
        accessor: (row) => row.account.role,
        Cell: ({ value }: { value: Role }) => {
          return <C.Text>{getRoleLabel(value)}</C.Text>;
        },
      },
      {
        Header: 'Channels',
        accessor: 'channels',
        Cell: ({
          value,
          row: { original },
        }: {
          value: MimirYoutubeChannel[];
          row: Row<AccountSearchResult>;
        }) => {
          const linkedConnectionsWithManagers = original.linkedConnectionsWithManagers;
          return (
            <C.Stack>
              {value.map((c) => {
                const managersLink = linkedConnectionsWithManagers.find(
                  (l) => l.platform_id === c.id
                );
                return (
                  <C.Box key={c.id}>
                    <C.HStack>
                      <C.Avatar size="sm" src={c.avatar_url || ''} />
                      <C.Link href={`https://youtube.com/channel/${c.id}`}>{c.name}</C.Link>
                    </C.HStack>

                    {managersLink?.manager && (
                      <>
                        <C.Badge colorScheme="yellow">
                          <C.Link
                            href={`${CAMPAIGN_APP_URL}/admin/manager-teams/${managersLink.manager.managerTeamId}`}>{`Managed by team ${managersLink.manager.managerTeamId}`}</C.Link>
                        </C.Badge>
                      </>
                    )}
                  </C.Box>
                );
              })}
            </C.Stack>
          );
        },
      },
    ],
    [sortType]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn: {
        // This could be a default filter, but we don't use that anywhere now so just return empty
        // (`defaultColumn.Filter` is required when using col filters)
        Filter: <></>,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect
  );

  return <ReactTableBase tableInstance={tableInstance} withGlobalFilter withColumnFilters />;
};

const OfferTable = ({
  data,
  channels,
}: {
  data: PricedOffer[];
  channels: MimirYoutubeChannel[];
}) => {
  const columns: Column[] = React.useMemo(
    () => [
      {
        Header: 'Campaign id',
        accessor: 'offeror.platform_id',
        Cell: ({ value }: { value: string }) => (
          <C.Link href={`${CAMPAIGN_APP_URL}/admin/campaigns/${value}`}>{value}</C.Link>
        ),
      },
      {
        Header: 'Channel',
        accessor: 'offeree.platform_id',
        Cell: ({ value }: { value: string }) => {
          const channel = channels.find((c) => c.id === value);

          return channel ? (
            <C.HStack>
              <C.Avatar size="sm" src={channel.avatar_url || ''} />
              <C.Link href={`https://youtube.com/channel/${channel.id}`}>{channel.name}</C.Link>
            </C.HStack>
          ) : (
            <C.Link to={`/deals?channel.id=eq.${value}`} as={Link}>
              {value}
            </C.Link>
          );
        },
      },
      {
        Header: 'Created',
        accessor: 'created' as const,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }: { value: OfferStatus }) => {
          const offerorStatus = value.offeror_status;
          const offereeStatus = value.offeree_status;
          return getStatusBadgeForOffer(offerorStatus, offereeStatus);
        },
      },
      {
        Header: 'Price',
        accessor: 'pricing.price',
        Cell: ({ value }: { value: number }) => <C.Text>{currencyFormatter(value)}</C.Text>,
      },
      {
        Header: 'Creator reason',
        accessor: 'status.offeree_reason',
        Cell: ({ value }: { value: string }) => {
          return <C.Text>{value}</C.Text>;
        },
      },
      {
        Header: 'Matchmade reason',
        accessor: 'status.offeror_reason',
        Cell: ({ value }: { value: string }) => {
          return <C.Text>{value}</C.Text>;
        },
      },
      {
        Header: 'Id',
        accessor: 'id' as const,
        Cell: ({ value }: { value: string }) => <C.Badge textTransform="none">{value}</C.Badge>,
      },
    ],
    [channels]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn: {
        // This could be a default filter, but we don't use that anywhere now so just return empty
        // (`defaultColumn.Filter` is required when using col filters)
        Filter: <></>,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect
  );

  const numericCellColumnIds = ['offeror.platform_id', 'pricing.price'];

  return (
    <ReactTableBase
      tableInstance={tableInstance}
      numericCellColumnIds={numericCellColumnIds}
      withGlobalFilter
      withColumnFilters
    />
  );
};

const DealTable = ({ data, channels }: { data: Deal[]; channels: MimirYoutubeChannel[] }) => {
  const columns: Column[] = React.useMemo(
    () => [
      {
        Header: 'Campaign id',
        accessor: 'campaign.id',
        Cell: ({ value }: { value: string }) => (
          <C.Link href={`${CAMPAIGN_APP_URL}/admin/campaigns/${value}`}>{value}</C.Link>
        ),
      },
      {
        Header: 'Channel',
        accessor: 'channel.id',
        Cell: ({ value }: { value: string }) => {
          const channel = channels.find((c) => c.id === value);

          return channel ? (
            <C.HStack>
              <C.Avatar size="sm" src={channel.avatar_url || ''} />
              <C.Link href={`https://youtube.com/channel/${channel.id}`}>{channel.name}</C.Link>
            </C.HStack>
          ) : (
            <C.Link to={`/deals?channel.id=eq.${value}`} as={Link}>
              {value}
            </C.Link>
          );
        },
      },
      {
        Header: 'Content',
        accessor: 'content.url',
        Cell: ({ value }: { value: string | null }) =>
          value ? <C.Link href={value}>{value}</C.Link> : <C.Text>Not available</C.Text>,
      },
      {
        Header: 'Link to deal view',
        accessor: 'deal.id',
        Cell: ({ value }: { value: string }) => <C.Link href={`/deals/${value}`}>{value}</C.Link>,
      },
    ],
    [channels]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn: {
        // This could be a default filter, but we don't use that anywhere now so just return empty
        // (`defaultColumn.Filter` is required when using col filters)
        Filter: <></>,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect
  );

  return <ReactTableBase tableInstance={tableInstance} withGlobalFilter withColumnFilters />;
};

const ManagementInvitationTable = ({
  data,
  channels,
}: {
  data: ManagementInvitation[];
  channels: MimirYoutubeChannel[];
}) => {
  const columns: Column[] = React.useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }: { value: 'pending' | 'accepted' | 'declined' }) => {
          return getManagementInvitationStatusBadge(value);
        },
      },
      {
        Header: 'Channel',
        accessor: 'channelId',
        Cell: ({ value }: { value: string }) => {
          const channel = channels.find((c) => c.id === value);
          return channel ? (
            <C.HStack>
              <C.Avatar size="sm" src={channel.avatar_url || ''} />
              <C.Link href={`https://youtube.com/channel/${channel.id}`}>{channel.name}</C.Link>
            </C.HStack>
          ) : (
            <C.Link href={`https://www.youtube.com/channel/${value}`}>{value}</C.Link>
          );
        },
      },
      {
        Header: 'Manager team id',
        accessor: 'managerTeamId',
        Cell: ({ value }: { value: string }) => (
          <C.Link href={`${CAMPAIGN_APP_URL}/admin/manager-teams/${value}`}>{value}</C.Link>
        ),
      },
      {
        Header: 'Manager name',
        accessor: 'managerName',
      },
      {
        Header: 'Manager email',
        accessor: 'managerEmail',
      },
    ],
    [channels]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn: {
        // This could be a default filter, but we don't use that anywhere now so just return empty
        // (`defaultColumn.Filter` is required when using col filters)
        Filter: <></>,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect
  );

  return <ReactTableBase tableInstance={tableInstance} withGlobalFilter withColumnFilters />;
};

const PayoutTaskTable = ({
  data,
  channels,
}: {
  data: PayoutTask[];
  channels: MimirYoutubeChannel[];
}) => {
  const columns: Column[] = React.useMemo(
    () => [
      {
        Header: 'Deal',
        accessor: 'reference_id',
        Cell: ({ value }: { value: string }) => {
          let label;
          if (value.startsWith('ID')) {
            const dealId = value.split('ID')[1].trim();
            if (dealId) {
              label = <C.Link href={`/deals/${dealId}`}>{dealId}</C.Link>;
            }
          }

          const wiseLink = `https://wise.com/all-transactions?search=${value}`;

          return (
            <C.VStack alignItems="start" minWidth={75}>
              {label ? label : <C.Text>Reference id: {value}</C.Text>}
              <C.Link isExternal href={wiseLink}>
                Wise
                <ExternalLinkIcon mx="1" />
              </C.Link>
            </C.VStack>
          );
        },
      },
      {
        Header: 'Channel',
        accessor: 'meta',
        Cell: ({ value }: { value: Record<string, unknown> }) => {
          const channelId = value.channel_id;
          const channel =
            typeof channelId === 'string' ? channels.find((c) => c.id === channelId) : null;
          return channel ? (
            <C.HStack>
              <C.Avatar size="sm" src={channel.avatar_url || ''} />
              <C.Link href={`https://youtube.com/channel/${channel.id}`}>{channel.name}</C.Link>
            </C.HStack>
          ) : channelId && typeof channelId === 'string' ? (
            <C.Link href={`https://www.youtube.com/channel/${channelId}`}>{channelId}</C.Link>
          ) : (
            <C.Text>'-'</C.Text>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }: { value: PayoutTaskStatus }) => getPayoutStatusBadge(value),
      },
      {
        Header: 'Display info',
        accessor: 'display_info',
        Cell: ({ value }: { value: string }) => <C.Text minWidth={150}>{value}</C.Text>,
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: ({ value }: { value: number }) => <C.Text>{currencyFormatter(value)}</C.Text>,
      },
      {
        Header: 'Payout date',
        accessor: 'payout_date',
        Cell: ({ value }: { value: string }) => (
          <C.Text>{value ? format(new Date(value), 'yyyy-MM-dd') : '-'}</C.Text>
        ),
      },
      {
        Header: 'Revenue recognition date',
        accessor: 'revenue_recognition_date',
        Cell: ({ value }: { value: string }) => (
          <C.Text>{value ? format(new Date(value), 'yyyy-MM-dd') : '-'}</C.Text>
        ),
      },
    ],
    [channels]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn: {
        // This could be a default filter, but we don't use that anywhere now so just return empty
        // (`defaultColumn.Filter` is required when using col filters)
        Filter: <></>,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect
  );

  return <ReactTableBase tableInstance={tableInstance} withGlobalFilter withColumnFilters />;
};

export enum SearchType {
  YOUTUBE = 'YOUTUBE',
  ACCOUNT_ID = 'ACCOUNT_ID',
  EMAIL = 'EMAIL',
  DEAL_ID = 'DEAL_ID',
  ACCOUNT_NAME = 'ACCOUNT_NAME',
}
const isValidSearchType = (val: string | null): val is SearchType =>
  typeof val === 'string' && val in SearchType;

const searchAccountsByYoutubeIdHandleOrUrl = async (keyword: string): Promise<Account[]> => {
  let accounts: Account[] = [];
  let youtubeChannelId = '';
  let youtubeChannelHandle = '';

  try {
    const validUrl = new URL(keyword);
    youtubeChannelId =
      YOUTUBE_HOSTS.includes(validUrl.hostname) && validUrl.pathname.includes('/channel/')
        ? validUrl.pathname.replace('/channel/', '')
        : '';
    youtubeChannelHandle =
      YOUTUBE_HOSTS.includes(validUrl.hostname) && validUrl.pathname.startsWith('/@')
        ? validUrl.pathname.substring(1)
        : '';
  } catch (e) {
    // keyword is not a valid url, and that's fine
  }

  if (!!youtubeChannelId || isYoutubeChannelLike(keyword)) {
    accounts = accounts.concat(await fetchAccountsByYoutubeChannelId(youtubeChannelId || keyword));
  }
  if (!!youtubeChannelHandle || keyword.startsWith('@')) {
    accounts = accounts.concat(await fetchAccountsByYoutubeHandle(youtubeChannelHandle || keyword));
  }

  return accounts;
};

const searchAccountById = async (keyword: string): Promise<Account[]> => {
  try {
    return [await getAccountByIdOrThrow(keyword)];
  } catch (e) {
    if (isNotFoundError(e)) {
      // No account found, not an error
      return [];
    }
    throw e;
  }
};

const searchAccountsByDealId = async (keyword: string): Promise<Account[]> => {
  const dealIdFilter = filtersToPostgrestFormat([{ id: 'deal.id', value: [keyword] }]);
  // There should only be one deal with one id
  const [deals] = await fetchDeals({ filters: dealIdFilter });
  if (deals) {
    return fetchAccountsByYoutubeChannelId(deals.channel.id);
  }
  return [];
};

const getAccountMatchesByKeywordAndType = async ({
  keyword,
  searchType,
}: {
  keyword: string;
  searchType: string;
}): Promise<Account[]> => {
  switch (searchType) {
    case SearchType.YOUTUBE:
      return searchAccountsByYoutubeIdHandleOrUrl(keyword);
    case SearchType.ACCOUNT_ID:
      return searchAccountById(keyword);
    case SearchType.ACCOUNT_NAME:
      return fetchAccountsByDisplayName(keyword);
    case SearchType.DEAL_ID:
      return searchAccountsByDealId(keyword);
    case SearchType.EMAIL:
      return fetchAccountsByEmail(keyword);
    default:
      throw new Error('Invalid search type');
  }
};

const getManagerAccountsByAccounts = async (accounts: Account[]): Promise<Account[]> => {
  const managerTeamIds = await Promise.all(
    accounts
      .map(async (account) => {
        const linkedConnections = await getLinkedConnectionsById(String(account.id));
        const managerTeamsToChannels: (ManagerToYoutubeChannel | null)[] = await Promise.all(
          linkedConnections.flat().map(async (c) => {
            try {
              return await getManagerToYoutubeChannel(c.platform_id);
            } catch (e) {
              // 404 is what the API responds when a channel is not managed.
              // Thus it is a valid and expected status code and we should
              // continue by returning null. If error was not Axios error,
              // response is missing or the status code is not 404, raise the error
              if (!isNotFoundError(e)) {
                throw e;
              }
              return null;
            }
          })
        );
        return managerTeamsToChannels.filter(notEmpty).map((team) => team.managerTeamId);
      })
      .flat()
  );
  const managerTeamMembers = await Promise.all(
    managerTeamIds.flat().map((id) => getManagerTeamMembers(id))
  );
  return (
    await Promise.all(
      managerTeamMembers.flat().map((member) => searchAccountById(String(member.id)))
    )
  ).flat();
};

const getRootNodesFromAccountTreeByAccounts = async (accounts: Account[]): Promise<Account[]> => {
  const managerAccounts = await getManagerAccountsByAccounts(accounts);
  return _.uniqBy([...accounts, ...managerAccounts], 'id');
};

const getFullAccountTreeByRootAccountNode = async (account: Account): Promise<Account[]> => {
  if (account.role === 'influencer_manager') {
    const managerTeam = await getManagerTeamByManagerId(account.id);
    const managedAccountIds = (await getManagerTeamChannels(managerTeam.id)).map(
      (managed) => managed.id
    );
    const managedAccounts = await Promise.all(
      managedAccountIds.map(async (id) => getAccountByIdOrThrow(String(id)))
    );
    return [account, ...managedAccounts];
  }
  return [account];
};

export const searchAccounts = async ({
  keyword,
  searchType,
}: {
  keyword: string;
  searchType: string;
}): Promise<Account[]> => {
  const matches = await getAccountMatchesByKeywordAndType({ keyword, searchType });
  const rootNodes = await getRootNodesFromAccountTreeByAccounts(matches);
  return _.uniqBy(
    (
      await Promise.all(
        rootNodes.map(async (rootNode) => getFullAccountTreeByRootAccountNode(rootNode))
      )
    ).flat(),
    'id'
  );
};

// Fetch all deals for all channels by paging through API
// We need to do this since we don't have (or want to have)
// paging in the deals tables, and there might be more than
// 50 deals per each channel (which is what `fetchDeals`
// default limit is set to)
const fetchAllDealsForChannels = async (channelIds: string[]) => {
  // We didn't get any channel ids -- let's not fetch anything
  if (!channelIds.length) {
    return [];
  }
  let limit = 50;
  let offset = 0;
  let hasNextPage = true;

  let deals: Deal[] = [];

  while (hasNextPage) {
    const dealsBatch = await fetchDeals({
      limit,
      offset,
      filters: filtersToPostgrestFormat([{ id: 'channel.id', value: channelIds }]),
    });
    deals = deals.concat(dealsBatch);

    if (dealsBatch.length < limit) {
      hasNextPage = false;
    } else {
      offset = limit + offset;
    }
  }

  return deals;
};

const searchAccountsAndRelatedData = async ({
  keyword,
  searchType,
}: {
  keyword: string;
  searchType: string;
}): Promise<AccountSearchResult[]> => {
  const accounts = (await searchAccounts({ keyword, searchType })).filter(notEmpty);

  const accountsSearchResults = await Promise.all(
    accounts.map(async (account) => {
      const linkedConnections = await getLinkedConnectionsById(String(account.id));
      const managerTeamsToChannels: (ManagerToYoutubeChannel | null)[] = await Promise.all(
        linkedConnections.flat().map(async (c) => {
          try {
            return await getManagerToYoutubeChannel(c.platform_id);
          } catch (e) {
            // 404 is what the API responds when a channel is not managed.
            // Thus it is a valid and expected status code and we should
            // continue by returning null. If error was not Axios error,
            // response is missing or the status code is not 404, raise the error
            if (!isNotFoundError(e)) {
              throw e;
            }
            return null;
          }
        })
      );
      const linkedConnectionsWithManagers = linkedConnections.map((connection) => {
        const managerTeamToChannel = managerTeamsToChannels.find(
          (m) => m?.channelId === connection.platform_id
        );
        return {
          ...connection,
          manager: managerTeamToChannel || null,
        };
      });

      const pricedOffers = (
        await Promise.all(
          linkedConnections.map(async (c) => {
            return await getPricedOffersByChannelId(c.platform_id);
          })
        )
      )
        .flat()
        // Order by created
        .sort((a, b) => {
          return b.created.localeCompare(a.created);
        });

      const managementInvitations = await getManagementInvitations(account.id);

      return {
        account,
        linkedConnectionsWithManagers,
        pricedOffers,
        managementInvitations,
      };
    })
  );

  const channelIds = accountsSearchResults
    .map((a) => a.linkedConnectionsWithManagers.map((c) => c.platform_id))
    .flat();

  const [channels, deals] = await Promise.all([
    getChannelsByIds(channelIds),
    fetchAllDealsForChannels(channelIds),
  ]);

  return accountsSearchResults.map((a) => {
    const accountDeals = deals.filter((d) =>
      a.linkedConnectionsWithManagers.some((link) => link.platform_id === d.channel.id)
    );
    const payoutTasks = accountDeals.map((d) => d.payout_task).filter(notEmpty);

    return {
      ...a,
      channels: channels.filter((c) =>
        a.linkedConnectionsWithManagers.some((link) => link.platform_id === c.id)
      ),
      deals: accountDeals,
      payoutTasks,
    };
  });
};

export const SearchForm = ({
  accounts,
  keyword,
  searchType,
  executeSearch,
  onSelectTarget,
  loading,
}: {
  accounts: AccountIdAndName[];
  keyword: string;
  searchType: SearchType;
  executeSearch: (keyword: string, searchType: string) => void;
  onSelectTarget: (searchType: string) => void;
  loading: boolean;
}) => {
  const { register, handleSubmit, control, watch } = useForm({
    defaultValues: { keyword, searchType: searchType || '' },
  });

  const onSubmit = async (data: { [key: string]: string }) => {
    executeSearch(data.keyword, data.searchType);
  };

  let input;
  if (searchType === SearchType.ACCOUNT_NAME) {
    input = (
      <Controller
        render={({ field: { onChange, value } }) => (
          <DropdownSelect<AccountIdAndName>
            limitItems={30}
            inputPlaceholder="Input a name..."
            onChange={(i) => {
              i && onChange(i.displayName);
            }}
            items={accounts}
            initialItem={accounts.find((c) => c.id === parseInt(value))}
            itemToLabel={(c) => c.displayName}
          />
        )}
        control={control}
        name="keyword"
      />
    );
  } else {
    input = (
      <C.Input
        {...register('keyword', {
          required: 'Please enter a keyword to search with.',
        })}
      />
    );
  }

  const watchedKeyword = watch('keyword');

  let text = '';
  if (watchedKeyword.length === 0) {
    text = '';
  } else if (
    !(
      watchedKeyword.startsWith('@') ||
      watchedKeyword.startsWith('UC') ||
      watchedKeyword.startsWith('https://')
    ) &&
    searchType === SearchType.YOUTUBE
  ) {
    text = `${watchedKeyword} doesn't look like a YouTube channel ID, url or handle.`;
  } else if (searchType === SearchType.EMAIL && !emailRegex.test(watchedKeyword)) {
    text = `${watchedKeyword} doesn't look like an email.`;
  } else if (
    isNaN(Number(watchedKeyword)) &&
    [SearchType.DEAL_ID, SearchType.ACCOUNT_ID].includes(searchType)
  ) {
    text = `${watchedKeyword} doesn't look like account ID or deal ID.`;
  }

  return (
    <C.Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <C.Stack spacing={8}>
        <C.Stack spacing={8}>
          <C.FormControl maxW="md" isInvalid={text.length > 0}>
            <C.FormLabel fontSize="lg">Search term</C.FormLabel>
            {input}
            <C.FormErrorMessage data-testid="search-type-mismatch">{text}</C.FormErrorMessage>
          </C.FormControl>
          <C.FormControl>
            <C.FormLabel fontSize="lg">Search term is a...</C.FormLabel>
            <C.RadioGroup onChange={onSelectTarget} defaultValue={searchType}>
              <C.Stack>
                <C.Radio value={SearchType.ACCOUNT_ID} {...register('searchType')}>
                  Account id
                </C.Radio>
                <C.Radio value={SearchType.YOUTUBE} {...register('searchType')}>
                  YouTube channel id, handle or URL
                </C.Radio>
                <C.Radio value={SearchType.ACCOUNT_NAME} {...register('searchType')}>
                  Account name
                </C.Radio>
                <C.Radio value={SearchType.DEAL_ID} {...register('searchType')}>
                  Deal id
                </C.Radio>
                <C.Radio value={SearchType.EMAIL} {...register('searchType')}>
                  Email
                </C.Radio>
              </C.Stack>
            </C.RadioGroup>
          </C.FormControl>
        </C.Stack>
        <C.Box>
          <C.Button isLoading={loading} colorScheme="cyan" type="submit">
            Search
          </C.Button>
        </C.Box>
      </C.Stack>
    </C.Box>
  );
};

const DataList = ({
  result,
  loading,
}: {
  result: AccountSearchResult[] | undefined;
  loading: boolean;
}) => {
  if (loading) {
    return (
      <C.Center>
        <C.Spinner />
      </C.Center>
    );
  }

  if (!result) {
    return null;
  }

  if (!result.length) {
    return (
      <EmptyState
        title="Nothing was found with keywords"
        description="Try another search query, or contact fixer if you think this is an error"
      />
    );
  }

  const pricedOffers = result.map((account) => account.pricedOffers).flat();
  const deals = result.map((account) => account.deals).flat();
  const managementInvitations = result.map((account) => account.managementInvitations).flat();
  const payoutTasks = result.map((account) => account.payoutTasks).flat();
  const channels = result.map((account) => account.channels).flat();

  return (
    <C.Stack spacing={8}>
      <Card>
        <CardBody>
          <C.Box p={[0, 6]}>
            <C.Stack spacing={8}>
              <C.Heading size="lg">Accounts</C.Heading>
              <AccountTable data={result} />
            </C.Stack>
          </C.Box>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <C.Box p={[0, 6]}>
            <C.Heading size="lg">Offers</C.Heading>
            <OfferTable data={pricedOffers} channels={channels} />
          </C.Box>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <C.Box p={[0, 6]}>
            <C.Heading size="lg">Deals</C.Heading>
            <DealTable data={deals} channels={channels} />
          </C.Box>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <C.Box p={[0, 6]}>
            <C.Heading size="lg">Management invitations</C.Heading>
            <ManagementInvitationTable data={managementInvitations} channels={channels} />
          </C.Box>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <C.Box p={[0, 6]}>
            <C.Heading size="lg">Payouts</C.Heading>
            <PayoutTaskTable data={payoutTasks} channels={channels} />
          </C.Box>
        </CardBody>
      </Card>
    </C.Stack>
  );
};

const ErrorBlock = (props: { error: Error }) => {
  const { error } = props;
  if (!error) {
    return null;
  }
  const requestId = getRequestId(error);

  return (
    <C.Box key={requestId}>
      <C.Alert status="error">
        <C.Show above="sm">
          <C.AlertIcon />
        </C.Show>
        <C.Stack spacing={0}>
          <C.AlertTitle>{error.message}</C.AlertTitle>
          <C.AlertDescription>
            Try reloading the page or contact @fixer if the issue persists.
          </C.AlertDescription>
          {requestId && (
            <C.Text fontSize="xs" opacity={0.5}>
              Error id: {requestId}
            </C.Text>
          )}
        </C.Stack>
      </C.Alert>
    </C.Box>
  );
};

export function MatchmadeDataExplorer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const keyword = searchParams.get(KEYWORD_SEARCH_PARAM_NAME) || '';
  const searchParam = searchParams.get(SEARCH_TYPE_PARAM_NAME);
  const searchType = isValidSearchType(searchParam) ? searchParam : SearchType.ACCOUNT_ID;

  const { result: accounts } = useAsync(getAllAccountNameAndIdPairs, []);

  const {
    result: searchAccountsResult,
    loading: loadingAccounts,
    error: accountsError,
  } = useAsync(
    async () =>
      keyword && searchType ? searchAccountsAndRelatedData({ keyword, searchType }) : undefined,
    [keyword, searchType]
  );

  return (
    <C.Container maxW="container.xl" my={10}>
      <C.Stack spacing={8}>
        <Card>
          <CardBody>
            <C.Box p={[0, 6]}>
              <C.Stack spacing={16}>
                <SearchForm
                  keyword={keyword}
                  searchType={searchType}
                  executeSearch={(keyword: string, searchType: string) => {
                    setSearchParams({
                      q: keyword,
                      search_type: searchType,
                    });
                  }}
                  onSelectTarget={(searchType: string) => {
                    setSearchParams({ search_type: searchType });
                  }}
                  accounts={accounts || []}
                  loading={loadingAccounts}
                />
                {accountsError && <ErrorBlock error={accountsError} />}
              </C.Stack>
            </C.Box>
          </CardBody>
        </Card>
        <DataList result={searchAccountsResult} loading={loadingAccounts} />
      </C.Stack>
    </C.Container>
  );
}
