import * as C from '@chakra-ui/react';
import { useAsyncCallback } from 'react-async-hook';
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { showErrorToast, showSuccessToast } from '../../helpers';
import { DealItem, publishContent } from '../../api-clients/imt-api-agreements';
import { MdOndemandVideo } from 'react-icons/md';

function DealPublishedContent({
  dealItem,
  agreementId,
}: {
  dealItem: DealItem;
  agreementId: number;
}) {
  const { isOpen, onOpen, onClose } = C.useDisclosure();
  const [updatedDealItem, setUpdatedDealItem] = useState(dealItem);

  const { handleSubmit, setValue, control } = useForm<{ newUrl: string }>({
    mode: 'onChange',
  });

  const saveUrl = useAsyncCallback(
    async (newUrl: string) => publishContent(newUrl, 'youtube_video', agreementId),
    {
      onSuccess: (result) => {
        setUpdatedDealItem({
          contentText: result.contentText,
          contentUrl: result.contentUrl,
          status: result.status,
        });
        showSuccessToast('Success', 'Content linked to agreement');
        onClose();
      },
      onError: (error) => {
        if (error.message) {
          showErrorToast('Error: Failed to link content to agreement', error.message);
        }
      },
    }
  );

  const onSaveUrl = (data: { newUrl: string }) => {
    saveUrl.execute(data.newUrl);
  };

  const contentUrl = updatedDealItem ? updatedDealItem.contentUrl : '';

  useEffect(() => {
    setValue('newUrl', contentUrl);
  }, [contentUrl, setValue]);

  return (
    <C.Popover trigger="click" id="publish_content_popover" onClose={onClose} isOpen={isOpen}>
      <C.PopoverTrigger>
        <C.Button onClick={onOpen} size="sm" variant="ghost" fontSize="md">
          {updatedDealItem ? (
            <>
              <C.Text fontWeight="normal" mr="1">
                Published Content
              </C.Text>
              <C.Tag
                size="sm"
                colorScheme={
                  updatedDealItem.status === 'deleted'
                    ? 'purple'
                    : updatedDealItem.status === 'in_review'
                    ? 'yellow'
                    : 'green'
                }>
                <C.Icon as={MdOndemandVideo} />
              </C.Tag>
            </>
          ) : (
            <C.Text fontWeight="normal">No Published Content</C.Text>
          )}
        </C.Button>
      </C.PopoverTrigger>
      <C.Portal>
        <C.PopoverContent minWidth="30rem">
          <C.PopoverArrow />
          <C.PopoverHeader fontWeight="bold" border="0">
            Link content to agreement
          </C.PopoverHeader>
          <C.PopoverBody>
            <form onSubmit={handleSubmit(onSaveUrl)}>
              <Controller
                name="newUrl"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <C.HStack>
                    <C.Input onChange={onChange} defaultValue={value} />
                    <C.Button
                      type="submit"
                      isLoading={saveUrl.loading}
                      isDisabled={!value || value === updatedDealItem?.contentUrl}
                      colorScheme="cyan">
                      Save
                    </C.Button>
                  </C.HStack>
                )}
              />
            </form>
          </C.PopoverBody>
        </C.PopoverContent>
      </C.Portal>
    </C.Popover>
  );
}

export default DealPublishedContent;
