import * as C from '@chakra-ui/react';
import { demographicFilters } from '../../helpers';

const cpmRulesFromCsvColumns = [
  {
    col: 'dimension',
    type: Object.keys(demographicFilters).join(', '),
    example: 'country',
  },
  {
    col: 'name',
    type: 'text',
    example: 'FI',
  },
  {
    col: 'cpm_per_percentage',
    type: 'number (amount in USD)',
    example: '0.42',
  },
];

export default function CpmRulesFromCsvAccordion() {
  return (
    <C.Accordion allowToggle>
      <C.AccordionItem>
        <C.AccordionButton>
          <C.Box flex="1" textAlign="left">
            Click here to view accepted formatting for the CSV file
          </C.Box>
          <C.AccordionIcon />
        </C.AccordionButton>
        <C.AccordionPanel>
          <C.Table data-testid="csv-columns-help">
            <C.Thead>
              <C.Tr>
                <C.Th>Column</C.Th>
                <C.Th>Format</C.Th>
                <C.Th>Example</C.Th>
              </C.Tr>
            </C.Thead>
            <C.Tbody>
              {cpmRulesFromCsvColumns.map((column) => (
                <C.Tr key={column.col}>
                  <C.Td>
                    <C.Text fontFamily="monospace">{column.col}</C.Text>
                  </C.Td>
                  <C.Td>
                    <C.Text>{column.type}</C.Text>
                  </C.Td>
                  <C.Td>
                    <C.Code>{column.example}</C.Code>
                  </C.Td>
                </C.Tr>
              ))}
            </C.Tbody>
          </C.Table>
          <C.Text mt={10}>Example file:</C.Text>
          <C.Box backgroundColor="gray.100" padding={4} fontFamily="monospace">
            <C.Text>dimension,name,cpm_per_percentage</C.Text>
            <C.Text>country,FI,0.12</C.Text>
            <C.Text>gender,female,20</C.Text>
            <C.Text>operatingSystem,ANDROID,4.2</C.Text>
            <C.Text>ageGroup,age13-17,11</C.Text>
            <C.Text>deviceType,DESKTOP,100</C.Text>
          </C.Box>
          <C.Text mt={10}>Allowed values per dimension:</C.Text>
          <C.Table data-testid="csv-columns-help">
            <C.Thead>
              <C.Tr>
                <C.Th>Dimension</C.Th>
                <C.Th>Values</C.Th>
              </C.Tr>
            </C.Thead>
            <C.Tbody>
              {Object.entries(demographicFilters).map(([k, v]) => (
                <C.Tr key={k}>
                  <C.Td>
                    <C.Text fontFamily="monospace">{k}</C.Text>
                  </C.Td>
                  <C.Td>
                    <C.Text>{v.items.map((i) => i.id).join(', ')}</C.Text>
                  </C.Td>
                </C.Tr>
              ))}
            </C.Tbody>
          </C.Table>
        </C.AccordionPanel>
      </C.AccordionItem>
    </C.Accordion>
  );
}
